import { Component, ViewChild, ElementRef, HostListener } from '@angular/core';
import { NotificationEventService } from '../../services/notification-event.service';
import { WebSocketService } from '../../services/web-socket.service';
import { CollapseLayoutService } from '../../services/collapseLayout.service';
import { BehaviorSubject, finalize, takeUntil } from 'rxjs';
import { UserInfoService } from '../../services/user-info.service';
import { environment } from 'src/environments/environment';
import { isEmpty, size } from 'lodash';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss'],
})

export class NotificationCenterComponent extends BaseComponent {
  constructor(
    private _notificationEventService: NotificationEventService,
    private _io: WebSocketService,
    private _collapseLayoutService: CollapseLayoutService,
    private _userInfoService: UserInfoService
  ) {
    super();}
  
  @ViewChild('infiniteScroll') infiniteScroll?: ElementRef;

  ITEMS_PER_PAGE = 10;

  activeTab = 'alert';
  decodePath: string;
  currentUser: any;
  data = new Map();
  loadingData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  processingData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  unreadCount = 0;
  preventInteract: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  events: any[] = []

  
  @HostListener('window:scroll', ['$event'])
  scrollHandler(event: any) {
    if (
      this.infiniteScroll!.nativeElement.scrollHeight <=
      this.infiniteScroll!.nativeElement.offsetHeight +
        Math.abs(this.infiniteScroll!.nativeElement.scrollTop) +
        1
    ) {
      if (!this.loadingData.value) {
        this.loadMore();
      }
    }
  }

  onInit() {
    this.initData();
    this._notificationEventService.unreadCountChanged$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(count => {
      this.unreadCount = this.unreadCount + count;
    });
    this._userInfoService.userSubject$.subscribe(user => {
      if (!!user) {
        this.currentUser = user;        
      }
    });

    this._notificationEventService.newEvent$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((event) => {
      const data = this.prepareURLItem(event);
      this.assignEvent(data);
    })

    
    // Initial load for the first tab.
    this.loadMore();
  }

  onDestroy() {}

  assignEvent(event: any) {
    if (!event) return;
    if (!['alert', 'help_center', 'system', 'mention_chat', 'mention_video', 'new_video', 'tutorial'].includes(event.type)) {
      return;
    }
    let type = 'alert';

    switch (event.type) {
      case 'alert':
      case 'mention_chat':
        type = 'alert';
        break;
      case 'help_center':
      case 'mention_video':
      case 'new_video':
      case 'tutorial':
        type = 'help_center';
        break;
      case 'system':
        type = 'system';
        break;
    }
    // todo: update totalResults
    const data = this.data.get(type);
    data.results?.splice(0, 0, event);
    this.data.set(type, {
      ...data,
      totalResults: data?.totalResults + 1,
    });
  }

  tabClicked(tab: any) {
    this.activeTab = tab;
    const tabItems = this.data.get(tab)?.results; 
    size(tabItems) < this.ITEMS_PER_PAGE && this.loadMore(true);
  }

  initData() {
    if ((environment as any)?.featureSwitches?.notification === 'off') return;
    this.data.set('alert', { results: [], completedInitialLoad: false });    
    this.data.set('help_center', { results: [], completedInitialLoad: false });    
    this.data.set('system', { results: [], completedInitialLoad: false });    
    this._notificationEventService.countUnreadEvents().subscribe((data: any) => {
      this.unreadCount = data?.count || 0;
    });    
  }

  markEventAsRead(eventId: string) {
    const events = this.data.get(this.activeTab).results;
    events.forEach((event: any) => {
      if (event.id === eventId) {
        if (!event?.is_read) {
          this._notificationEventService.markEventAsRead(eventId).subscribe(() => {
            this._notificationEventService.decreaseUnreadCount(1);
          });
        };
        event.is_read = true;
      }
    });
  }

  markEventAsDeleted(eventId: string) {
    const alerts = this.data.get(this.activeTab);
    const results = alerts.results.filter((event: any) => event.id !== eventId);
    this.data.set(this.activeTab, {...alerts, results});
    this._notificationEventService.markEventAsDeleted(eventId).subscribe((event) => {
      if (!event.is_read) {
        this._notificationEventService.decreaseUnreadCount(1);
      }
    });
  }

  markAllEventsAsDeleted() {
    this.preventInteract.next(true)    
    this.processingData.next(true)    
    this._notificationEventService.deleteAllEvents(this.activeTab).pipe(
      finalize(()=>{
        this.preventInteract.next(false)        
        this.processingData.next(false)        
      })).subscribe({
        next: () => {
          this.data.set(this.activeTab, {
            results: [],
            completedInitialLoad: true,
          });
        },
        error: (error: any) => {
          console.error(error);
        }
      });
  }

  closePanel() {
    this._collapseLayoutService.toggleNotificationPanel(false);
  }

  loadMore(forceLoadData: boolean = false) {
    if (this.loadingData.value) return;
    const { results, totalResults } = this.data.get(this.activeTab) || {};
    if (!forceLoadData && size(results) >= totalResults) {
      return;
    };
    this.loadingData.next(true);
    const type = this.activeTab;
    this._notificationEventService.getEvents(this.activeTab, size(this.data.get(type)?.results), this.ITEMS_PER_PAGE).subscribe((data:any) => {
      this.prepareData(type, data);
      this.loadingData.next(false);
    });
  }

  displayUnreadCount(count: number) {
    if (count > 99) {
      return '99+';
    }
    if (count < 0) {
      return '0';
    }
    return `${count}`;
  }

  parseQueryParams(query: string): any {
    return query.split('&').reduce((acc: any, param) => {
      const [key, value] = param.split('=');
      acc[key] = value;
      return acc;
    }, {});
  }

  private prepareData(type: string, data: any) {
    const results = this.data.get(type)?.results || [];
    const enrichData =  data?.results?.map((item:any) => {
      return this.prepareURLItem(item);
    })
    this.data.set(type, {...enrichData, totalResults: data?.totalResults, results: [...results, ...enrichData ], completedInitialLoad: true});
  }

  private prepareURLItem(item: any) {
    const decodedPath = decodeURIComponent(item.relative_path || '');
    const [path, query] = decodedPath.split('?');
    const queryParams = query ? this.parseQueryParams(query) : {};
    return { ...item, basePath: path, queryParams };
  }
}
