import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[positiveDecimalInput]',
})
export class PositiveDecimalInputDirective {
  @Input() min: number = 0;
  @Input() max: number = Number.MAX_VALUE;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    let inputValue = inputElement.value;

    // Remove non-numeric characters except the first decimal point
    inputValue = inputValue.replace(/[^\d.]/g, (match) => {
      // Keep the first decimal point
      if (match === '.') {
        return inputValue.indexOf('.') === -1 ? match : '';
      }
      return '';
    });

    // Parse the input value as a float
    let parsedValue = parseFloat(inputValue);

    // Ensure the parsed value is within the specified min and max range
    if (isNaN(parsedValue) || parsedValue < this.min) {
      parsedValue = this.min;
    } else if (parsedValue > this.max) {
      parsedValue = this.max;
    }

    // Update the input value with the sanitized and bounded value
    inputElement.value = parsedValue.toString();
  }
}
