<div class="p-relative">
    <ng-container *ngIf="isShow">
        <div #container class="tutorial-panel" [innerHTML]="replaceTags(tutorialContent) | safe : 'html'"></div>
        <template #alertContainer></template>
    </ng-container>
    <!-- <div (click)="goBack()"> Click me to back the list </div> -->

    <!-- Loading Overlay -->
    <div class="content-progress-bar-tab" *ngIf="loadingData | async">
        <span class="loader"></span>
        <span class="text-progress">Loading ...</span>
    </div>
</div>