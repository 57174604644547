import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class passingExtendInfoService {
  private approvalStageMessage = new BehaviorSubject<object>({});
  currentApprovalStageMessage = this.approvalStageMessage.asObservable();

  constructor() {}
  updateApprovalMessage(dataExtendInfo: object) {
    this.approvalStageMessage.next(dataExtendInfo);
  }

  getApprovalMessage() {
    return this.approvalStageMessage.getValue();
  }
}
