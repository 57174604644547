import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';

import { AppHelper } from '../utilities/app.helper';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from './notification.service';
import { AppConstant } from '../utilities/app.constant';

const sasToken = environment.sasToken;

@Injectable({
  providedIn: 'root',
})
export class AzureBlobService extends BaseService {
  accountname = environment.blobAccountName;

  containerUseravatar = 'useravatar';

  containerHelCenterImage = 'thumbnail';

  containerHelpCenterVideo = 'video';

  interventionChatAttachment = 'interventionchatattachment';

  constructor(
    httpClient: HttpClient, 
    private _notificationService: NotificationService) {
      super(httpClient);
  }

  // New Approach
  public getFileByUrl(url: string): Observable<Blob> {
    const uri = 'file/get-file';
    return this.get(uri, { params: { url }, responseType: 'blob' as 'json' });
  }

  public getVideoStreamUrl(url: string): string {
    return `${environment.apiUrl}/file/stream-file?url=${url}`;
  }

  public fetchFile(url: string): Observable<string> {
    return new Observable<string>((observer) => {
      this.getFileByUrl(url).subscribe({
        next: (blob) => {
          observer.next(URL.createObjectURL(blob));
          observer.complete();
        },
        error: (error) => {
          console.error('Error fetching file:', error);
          observer.error(error);
        },
      });
    });
  }

  public loadFile(url: string, name: string = ''): Observable<Blob> {
    const fileName = decodeURIComponent(name || url.split('/').pop()?.slice(14) || 'file');

    return new Observable<Blob>((observer) => {
      this.getFileByUrl(url).subscribe({
        next: (blob: Blob) => {
          const fileType = this.getFileTypeFromUrl(url);
          const fileBlob: Blob = new File([blob], fileName, { type: fileType });
          observer.next(fileBlob);
          observer.complete();
        },
        error: (error) => {
          console.error('Error fetching File:', error);
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Loading Document',
            content: `The document "${fileName}" is no longer available, please try again`,
          });
          observer.error(error);
        }
      });
    });
  }

  private getFileTypeFromUrl(url: string): string {
    const extension = url?.split('.')?.pop()?.toLowerCase();
    switch (extension) {
      case 'pdf':
        return 'application/pdf';
      case 'png':
        return 'image/png';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'mp4':
        return 'video/mp4';
      default:
        return 'application/octet-stream';
    }
  }
  
  // =============================================================
}
