
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExpandService {
  private expandData: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public expandData$ = this.expandData.asObservable();

  setExpandData(data: any) {
    this.expandData.next(data)
  }

  getExpandData() {
    return this.expandData.getValue();
  }

  /*
    {
      isDialogOpen: true,
      headerLabel: 'Nominated by',
      nominateList: [...mominatedList],
      triggerElementId: locationId,
      userId: string
    }
      OR
    {}
  */
}
