import { BehaviorSubject, Subject } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AzureAdService {
  isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  userNameLoggedIn: BehaviorSubject<string | undefined> = new BehaviorSubject<
    string | undefined
  >('Unknown user');

  userMailLoggedIn: BehaviorSubject<string | undefined> = new BehaviorSubject<
    string | undefined
  >('Unknown user');

  redirectUrl: string = '';
  constructor() {}
}
