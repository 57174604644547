export const asRigSidebar = {
  data: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <rect x="8" y="4" width="8" height="2" rx="1"/>
  <rect x="16.1406" y="17.375" width="9.01914" height="1" rx="0.5" transform="rotate(-150 16.1406 17.375)"/>
  <rect x="15.6406" y="12.8672" width="9.01914" height="1" rx="0.5" transform="rotate(150 15.6406 12.8672)"/>
  <rect width="8" height="1" rx="0.5" transform="matrix(-0.866025 -0.5 -0.5 0.866025 16.4277 11)"/>
  <rect width="8" height="1" rx="0.5" transform="matrix(-0.866025 0.5 0.5 0.866025 14.4277 7)"/>
  <rect x="5.44629" y="19.0059" width="15.0003" height="2" rx="1" transform="rotate(-75 5.44629 19.0059)"/>
  <rect width="15.0017" height="2" rx="1" transform="matrix(0.258819 0.965926 0.965926 -0.258819 12.8701 5.01562)"/>
  <rect x="4" y="18" width="16" height="2" rx="1"/>
  </svg>`,
  name: 'rigSibar',
};

// old rig
// <svg width="14" height="12" viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg">
//   <path d="M13.5232 5.03112C13.7871 6.03575 13.2014 7.06861 12.213 7.33683L11.8542 7.43329C11.4607 7.54152 11.0533 7.30389 10.9468 6.90156L10.3635 4.68997L2.53476 6.74864V10.4942H3.51161L4.74078 6.92509L6.4468 6.44984L5.85884 8.23558H7.4005L6.76624 6.39102L8.20374 6.01222L9.74773 10.4942H12.926C13.3357 10.4942 13.6667 10.8307 13.6667 11.2471C13.6667 11.6636 13.3357 12 12.926 12H1.07411C0.665087 12 0.333374 11.6636 0.333374 11.2471C0.333374 10.8307 0.665087 10.4942 1.07411 10.4942H1.44449V4.28294C1.44449 3.97002 1.69333 3.71828 2.00004 3.71828C2.30675 3.71828 2.5556 3.97002 2.5556 4.28294V5.19111L5.61578 4.38646L5.9306 3.46889C6.03476 3.16773 6.31486 2.96539 6.62967 2.96539C6.94449 2.96539 7.22458 3.16773 7.32875 3.46889L7.47458 3.89474L9.98152 3.23596L9.41439 1.08413C9.30791 0.68251 9.54171 0.2696 9.93754 0.162032L10.2963 0.064604C11.2824 -0.204458 12.2987 0.391473 12.5625 1.39563L13.5232 5.03112ZM5.08106 10.4942H8.17828L7.91902 9.74135H5.34032L5.08106 10.4942Z"/>
//   </svg>
