import { Subject, concatMap, map, of, pipe, switchMap, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { IConfirmDialog } from '../interface/common';
import { AppConstant } from '../utilities/app.constant';
import { DialogActionApp, OptionButtonType } from '../type';

@Injectable({
  providedIn: 'root',
})
export class ConfirmDialogService {
  private dataSubject: Subject<IConfirmDialog> = new Subject<IConfirmDialog>();
  public dataSubjectAction$ = this.dataSubject.asObservable();
  
  private triggerSubject = new Subject();
  public triggerObservable = this.triggerSubject.asObservable();

  private confirmDialog = AppConstant.DEFAULT_DIALOG
  constructor() { }

  public setDialog(data: Partial<IConfirmDialog>) {
    this.dataSubject.next({...this.confirmDialog, ...data});
  }

  public clearDialog() {
    this.dataSubject.next(this.confirmDialog);
  }

  // Common Dialog
  public initDataErrorDialog(error: string) {
    // console.error(error);
    this.setDialog({
      isVisible: true,
      header: 'Initialization',
      haveDialogMessage: true,
      dialogMessage:
        'The initial initialization data could not be imported and required trying.',
      havePrimaryButton: true,
      primaryButtonLabel: 'Retry Now',
      isValidPrimaryButton: true,
      buttonEvent: (event: OptionButtonType) =>
        this.onButtonClickDialog(event, 'Reload'),
    });
  }

  // Message Dialog
  public messageErrorDialog(error: string) {
    this.setDialog({
      isVisible: true,
      header: 'Initialization',
      haveDialogMessage: true,
      dialogMessage: error,
      havePrimaryButton: true,
      primaryButtonLabel: 'Retry Now',
      isValidPrimaryButton: true,
      buttonEvent: (event: OptionButtonType) =>
        this.onButtonClickDialog(event, 'Reload'),
    });
  }

  onButtonClickDialog(
    option: OptionButtonType,
    dialogType: DialogActionApp
  ): void {
    switch (option) {
      case AppConstant.OPTION_BUTTON.YES:
        switch (dialogType) {
          case 'Reload':
            window.location.reload();
            break;
          default:
            break;
        }
        break;
      case AppConstant.OPTION_BUTTON.CANCEL:
        this.setDialog({
          isVisible: false,
        });
        break;
      default:
        break;
    }
  }
}
