<app-chat-message-text
  *ngIf="(message.type == 'text' || message.type == 'image' || message.type == 'attachment') && message.senderType !== 'system'"
  [message]="message"
  [isMine]="isMine"
  [previousMessage]="previousMessage"
  [template]="template"
></app-chat-message-text>
<app-chat-message-event
  *ngIf="(message.type == 'text' || message.type == 'image' || message.type == 'attachment') && message.senderType === 'system'"
  [message]="message"
  [isMine]="isMine"
  [previousMessage]="previousMessage"
></app-chat-message-event>
<app-chat-message-confirm
    *ngIf="message.type == 'action_button__resolution'"
    [message]="message"
></app-chat-message-confirm>