<div class="custom-dialog" [ngStyle]="positionStyle" [ngClass]="{'show': isDialogOpen}">
  <ng-container *ngFor="let nominate of expandData.nominateList">
  <div class="wapper-content" id="expand-content">
      <div>
        <span class="sub-label"> {{ expandData.headerLabel }}</span> {{ nominate.header }}
      </div>
      <div *ngIf="nominate.content" class="my-2 wrap-content">
        <span
          appExpandableContent
          [content]="nominate.content"
          [sizeBreakLine]="99999"
          [sizeContent]="99999"
          [userId]="this.expandData.userId"
        ></span>
      </div>
      <div>
        <span class="sub-label">{{ nominate.footer }}</span>
      </div>
    </div>
  </ng-container>
</div>