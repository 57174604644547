<div class="chat-window d-flex flex-column">
	<div class="chat-window-header d-flex justify-content-between align-items-end">
		<div>
			Live Support
		</div>
		<div class="chat-window-header-action d-flex gap-2">
			<div class="action" (click)="hideChatWindow()"><i class="pi pi-minus"></i></div>
		</div>
	</div>
	<div class="chat-window-action">
		<ng-container [ngSwitch]="currentStep$ | async">
			<div *ngSwitchCase="'REPORT'">
				<div (click)="goBack()" class="back-step gap-2">
					<div><i class="pi pi-chevron-left"></i></div>
					<div>Report an issue</div>
				</div>
			</div>
			<div *ngSwitchCase="'LIVE'">
				<div (click)="goBack()" class="back-step gap-2">
					<div><i class="pi pi-chevron-left"></i></div>
					<div>Connect with an agent</div>
				</div>
			</div>
			<div *ngSwitchDefault></div>
		</ng-container>
	</div>

	<div class="chat-window-body">
		<ng-container [ngSwitch]="currentStep$ | async">
			<div class="h-100" *ngSwitchCase="'REPORT'">
				<app-chat-report></app-chat-report>
			</div>
			
			<div class="h-100" *ngSwitchCase="'LIVE'">
				<app-chat-live-support (emitTotalUnread)="setTotalUnread($event)"></app-chat-live-support>
			</div>

			<div *ngSwitchCase="'SENT'" class="default-template d-flex flex-column align-items-center justify-content-center gap-2 mb-5">
				<div class="d-flex flex-column align-items-center gap-2 mb-2">
					<div>
						<svg-icon class="icon-info" key="sentSupport" size="xxl"></svg-icon>
					</div>
					<div>Your issue was successfully sent!</div>
				</div>
				<div class="step-button step-button--secondary" (click)="showForm('REPORT')">Report an issue</div>
				<div class="step-button step-button--secondary" (click)="showForm('LIVE')">Connect with an agent</div>
			</div>

			<div *ngSwitchCase="'SENT_AGENT'" class="default-template d-flex flex-column align-items-center justify-content-center gap-2 mb-5">
				<div class="d-flex flex-column align-items-center gap-2 mb-2">
					<div>
						<svg-icon class="icon-info" key="sentSupport" size="xxl"></svg-icon>
					</div>
					<div class="mb-2">Your message was successfully sent!</div>
					<div class="text-center">The support team will respond to you as soon as possible.</div>
				</div>
				<div class="step-button step-button--primary" (click)="goBack()">Close</div>
			</div>


			<div *ngSwitchDefault class="default-template d-flex flex-column align-items-center justify-content-center gap-2 mb-5">
				<div class="d-flex flex-column align-items-center gap-2 mb-2">
					<div>
						<svg-icon class="icon-info" key="liveSupport" size="xxl"></svg-icon>
					</div>
					<div>Please choose an action</div>
				</div>
				<div class="step-button step-button--secondary" (click)="showForm('REPORT')">Report an issue</div>
				<div
					*ngIf="totalUnreadMsg > 0; else notBadge"
					class="step-button step-button--secondary"
					(click)="showForm('LIVE')"
					pBadge
					severity="danger"
					[value]="totalUnreadMsg.toString()"
				>Connect with an agent</div>
				<ng-template #notBadge>
					<div class="step-button step-button--secondary" (click)="showForm('LIVE')">Connect with an agent</div>
				</ng-template>
			</div>
		</ng-container>
		
	</div>

	<app-confirmation-overlay
		[confirmMessage]="'Are you sure to close the chat?'"
		[primaryText]="'Yes, close the chat'"
		[secondaryText]="'Back to chat'"
    (confirm)="closeChatWindow()"
    (cancel)="cancelClose()"
    #confirmationOverlay>
  </app-confirmation-overlay>

  <app-loading [isLoading]="(isLoading$ | async)!" [isIconShow]="true" [size]="'50px'"></app-loading>
</div>
<div class="overlay" (click)="hideChatWindow()"></div>
