import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { AppHelper } from 'src/app/shared/utilities/app.helper';

@Component({
  selector: 'app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppMenuComponent {
  @Input() optionList: any;
  @Input() appendTo: string | null = null;
  @Input() styleClass: string = '';

  @ViewChild('menuOptions') menuOptions: any;
  AppHelper = AppHelper;

  constructor() {}

  getMenuItemsForItem(optionList: any[]): any {
    return optionList;
  }

  toggle(event: any) {
    this.menuOptions.toggle(event);
  }
}
