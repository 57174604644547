import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppConstant } from '../utilities/app.constant';


@Injectable({
  providedIn: 'root'
})
export class ChatWindowService {
  private currentChatStepSubject = new BehaviorSubject<AppConstant.ChatStep | null>(null);
  currentChatStepSubject$ = this.currentChatStepSubject.asObservable();
 
  private currentStepSubject = new BehaviorSubject<AppConstant.Step>(null);
  currentStep$ = this.currentStepSubject.asObservable();

  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();
 
  setCurrentChatStep(step: AppConstant.ChatStep | null) {
    this.currentChatStepSubject.next(step);
  }

  getCurrentChatStep() {
    return this.currentChatStepSubject.getValue();
  }
 
  setCurrentStep(step: AppConstant.Step = null) {
    this.currentStepSubject.next(step);
  }
  
  getCurrentStep() {
    return this.currentStepSubject.getValue();
  }

  setLoading(value: boolean) {
    this.isLoadingSubject.next(value);
  }
}