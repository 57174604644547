import { Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AzureBlobService } from '../services/azure-blob.service';
import { NotificationService } from '../services/notification.service';
import { AppConstant } from '../utilities/app.constant';

@Directive({
  selector: '[appBlobImage]'
})
export class BlobImageDirective implements OnChanges {
  @Input('imageUrl') imageUrl!: string;

  constructor(private el: ElementRef, private blobService: AzureBlobService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['imageUrl'] && this.imageUrl) {
      this.loadImage();
    }
  }

  private loadImage(): void {
    this.blobService.getFileByUrl(this.imageUrl).subscribe(
     {
        next: (blob: Blob) => {
          const url = URL.createObjectURL(blob);
          (this.el.nativeElement as HTMLImageElement).src = url;
        },
        error: (error) => {
          console.error('Error fetching appBlobImage:', error);
        }
      });
     }
}

@Directive({
  selector: '[appBlobFile]'
})
export class BlobFileDirective {
  @Input('fileUrl') fileUrl!: string;
  @Output() fileError = new EventEmitter<void>();

  constructor(
    private el: ElementRef,
    private blobService: AzureBlobService,
    private _notificationService: NotificationService
  ) {}

  @HostListener('click')
  onClick(): void {
    if (this.fileUrl) {
      this.loadFile();
    }
  }
  private loadFile(): void {
    const fileName = this.fileUrl.split('/').pop() || 'file';
    this.blobService.getFileByUrl(this.fileUrl).subscribe({
      next: (blob: Blob) => {
        const fileType = this.getFileTypeFromUrl(this.fileUrl);
        const fileBlob = new Blob([blob], { type: fileType });
        const url = URL.createObjectURL(fileBlob);
        window.open(url, '_blank');
      },
      error: (error) => {
        console.error('Error fetching appBlobFile:', error);
        this.fileError.emit();
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Loading Document',
          content: `The document "${fileName}" is no longer available, please try again`,
        });
      }
    });
  }

  private getFileTypeFromUrl(url: string): string {
    const extension = url?.split('.')?.pop()?.toLowerCase();
    switch (extension) {
      case 'pdf':
        return 'application/pdf';
      case 'png':
        return 'image/png';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'mp4':
        return 'video/mp4';
      default:
        return 'application/octet-stream';
    }
  }
}