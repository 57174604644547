import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'milisecondToMMSS',
})
export class TimeMilisecondToMMSSPipe implements PipeTransform {
  /**
   * Transforms the input value from milliseconds to a formatted MM:SS time string.
   *
   * @param value - The value in milliseconds to be transformed.
   * @returns The formatted MM:SS time string.
   */

  transform(value: any): string {
    const millisToMinutesAndSeconds = (millis: number) => {
      let minutes: any = Math.floor(millis / 60000);
      let seconds: any = ((millis % 60000) / 1000).toFixed(0);
      return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
    };

    return millisToMinutesAndSeconds(value);
  }
}
