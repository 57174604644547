import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({ selector: '[blockSpecialCharacter]' })
export class blockSpecialCharacter {
  constructor(private hostElement: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(e: any) {
    const value = e.target.value;

    if (e.key === '!') {
      e.preventDefault();
    }
  }
}
