<ng-container
  [ngTemplateOutlet]="noti"
  [ngTemplateOutletContext]="{ color: statusClass }"
></ng-container>

<ng-template let-noti="color" #noti>
  <div style="position: relative">
    <div
      class="notification__item"
      [class]="noti"
      [class]="primaryColorFlag + '-border'"
      [ngClass]="{
        active:
          selectedInterventionId === interventionLog.interventionId
            ? true
            : false
      }"
      (click)="
        showInterventionLog(
          interventionLog.rigJournalId,
          interventionLog.interventionId
        )
      "
    >
      <div class="notification__item__header">
        <div class="notification__item__header-title d-flex">
          <div class="text-title">
            <svg-icon class="custom-svg-icon" key="well" size="md"></svg-icon>
          </div>
          <div class="text-title-wrap">
            {{
              collapseName(interventionLog.wellName) + " / " + collapseName(interventionLog.wellboreName)
            }}
            <span class="tooltipText title">
              {{ interventionLog.wellName }} / {{ interventionLog.wellboreName }}
            </span>
          </div>
        </div>
        <div class="notification__item__header-time d-flex gap-2">
          <div>
            <svg-icon class="custom-svg-icon" key="time" size="md"></svg-icon>
          </div>

          <div>
            {{ shortTimeHHMM }}
          </div>
        </div>
      </div>
      <div class="notification__item__content">
        <ng-container
          *ngFor="let event of eventHierarchy; first as isFirst; let i = index"
        >
          <span
            [style]="
              isFirst
                ? 'padding: 0px 8px 8px 0px'
                : 'border-left: 2px solid #0e1117; padding: 0px 8px 0px 8px'
            "
            >{{ event?.trim() }}</span
          >
        </ng-container>
        <p>
          {{ interventionLog.description }}
        </p>
      </div>
      <div class="notification__item__feature">
        <div class="notification__item__feature-left">
          <div
            class="notification__item__feature-left-item d-flex"
            [pTooltip]="interventionLog.rigName"
            tooltipPosition="bottom"
          >
            <div>
              <svg-icon class="custom-svg-icon" key="rig" size="md"></svg-icon>
            </div>
            <div class="notificationInfo">
              {{ interventionLog.rigName }}
            </div>
          </div>
          <div
            class="notification__item__feature-left-item d-flex"
            [pTooltip]="interventionLog.projectName"
            tooltipPosition="bottom"
          >
            <div>
              <svg-icon class="custom-svg-icon" key="project" size="md"></svg-icon>
            </div>
            <div class="notificationInfo">
              {{ interventionLog.projectName }}
            </div>
          </div>
          <div
            class="notification__item__feature-left-item d-flex"
            [pTooltip]="interventionLog.operatorName"
            tooltipPosition="bottom"
          >
            <div>
              <svg-icon class="custom-svg-icon" key="operator" size="md"></svg-icon>
            </div>
            <div class="notificationInfo">
              {{ interventionLog.operatorName || "Operator" }}
            </div>
          </div>
          <div
            class="notification__item__feature-left-item item-last d-flex"
            [pTooltip]="
              interventionLog.country + ' - ' + interventionLog.region
            "
            tooltipPosition="bottom"
          >
            <div>
              <div *ngIf="interventionLog.country">
                <div class="country-item country-item-value">
                  <i
                    class="flag flag-{{
                      interventionLog.country.toLowerCase().slice(0, 2)
                    }}"
                  ></i>
                </div>
              </div>
            </div>
            <div class="notificationInfo">
              {{ interventionLog.country + " - " + interventionLog.region }}
            </div>
          </div>
        </div>
      </div>
      <div class="notification__item__INITIATED">
        <div
          class="notification__item__INITIATED-status d-flex gap-2"
          [class]="noti"
          [pTooltip]="statusTextHover"
          tooltipPosition="bottom"
        >
          <div>
            {{ interventionLog.status === "INITIATE" ? "INITIATE" : "RESOLVE" }}
          </div>
          <div>
            <svg-icon class="custom-svg-icon"
              [key]="
                ['I_0_1', 'R_1_1', 'I_1_1'].includes(interventionLog.stateCode)
                  ? 'unReadEnvelope'
                  : 'readEnvelope'
              "
              size="md"
            ></svg-icon>
          </div>
          <div class="notification__item__INITIATED-status-detail">
            {{ statusTextHover }}
          </div>
        </div>

        <div
          *ngIf="actionCheking"
          class="notification__item__action INITIATED-time d-flex gap-2"
        >
          <div
            class="action-info action-icon"
            *ngIf="actionIcon"
            [ngClass]="actionUrgent ? 'red-label' : ''"
          >
            <svg-icon class="custom-svg-icon" key="hourglass" size="md"></svg-icon>
          </div>
          <div *ngIf="actionInfor === ''" class="action-info">
            {{
              remainingTime - 1000 > 0
                ? (remainingTime | milisecondToMMSS)
                : "Time Out"
            }}
            Min
          </div>
          <div
            *ngIf="actionInfor !== ''"
            class="action-info"
            [ngClass]="actionUrgent ? 'red-label' : ''"
          >
            {{ actionInfor }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="notification__item__feature-right cursor-pointer"
      data-bs-toggle="modal"
      data-bs-target="#exampleModal"
      style="position: absolute; right: 24px; bottom: 62px"
      (click)="
        getMouseLocation(
          $event,
          interventionLog?.primaryContact?.data || 'No contact data'
        )
      "
    >
      <svg-icon class="custom-svg-icon" key="phone" size="md"></svg-icon>
    </div>
  </div>
  <!-- No Further Action Required - white text - final done -->
  <ng-template>
    <div
      *ngIf="statusClass !== 'yellow-border'"
      class="notification__item__action INITIATED-time d-flex"
    >
      <div class="action-info">No Further Action Required</div>
    </div>
  </ng-template>

  <!-- No Further Action Required - white text - final done -->
  <ng-template>
    <div
      *ngIf="statusClass !== 'yellow-border'"
      class="notification__item__action INITIATED-time d-flex"
    >
      <div class="action-info">No Further Action Required</div>
    </div>
  </ng-template>
</ng-template>
