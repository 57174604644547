import * as moment from 'moment';

import {
  Component,
  Input,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { map, takeUntil, takeWhile, tap, timer } from 'rxjs';

import { BaseComponent } from 'src/app/shared/components/base.component';
import { Router } from '@angular/router';
import { passingExtendInfoService } from 'src/app/shared/services/passingExtendInfo.service';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { InterventionService } from 'src/app/shared/services/intervention.service';
import { getEventById } from 'src/app/shared/utilities/master-data.helper';

@Component({
  selector: 'app-nofitication-item',
  templateUrl: './nofitication-item.component.html',
  styleUrls: ['./nofitication-item.component.scss'],
})
export class NofiticationItemComponent extends BaseComponent {
  @Input() interventionLog: any;

  @Input() selectedInterventionId: string = '';

  // Setting Color Border Status
  statusClass: 'yellow-border' | 'green-border' | 'red-border' = 'yellow-border';

  eventHierarchy: any;
  shortTimeHHMM = 'HH:MM';

  // Setting Text Hover Status
  statusTextHover:
    'Updating ...' |
    'Initiated but not sent (draft)' |
    'Initiate Completed' |
    'Initiated and sent (awaiting resolution)' |
    'Initiated and sent (requires immediate resolution)' |
    'Resolution drafted but not sent' |
    'Resolution drafted but not sent (requires immediate resolution)' |
    'Resolution Completed'
    = 'Updating ...'

  // Setting Able have hourglass Icon
  actionIcon = false;

  //
  actionCheking = false;

  // Setting Text action need to do
  actionInfor = 'Updating ...';

  // Red color
  actionUrgent = false;
  // remainingTime
  remainingTime = 0;
  @ViewChild('infoCard') infoCard: any;

  primaryColorFlag: string = 'green';

  constructor(
    private _passingDataService: passingExtendInfoService,
    private _interventionService: InterventionService,
    private _router: Router
  ) {
    super();
  }
  onInit(): void {
    if (this.interventionLog.isComplete) {
      this.setStatusTagMaskAsComplete(true, this.interventionLog);
    } else {
      this.setStatusTagMaskAsComplete(false, this.interventionLog);
    }
  }

  // Logic for updating status of Alert / Intervention Log
  ngOnChanges(changes: SimpleChanges) {
    const keyCheck = 'isComplete';
    if (changes.interventionLog) {
      const currentValue = changes.interventionLog.currentValue[keyCheck];
      const previousValue =
        changes.interventionLog.previousValue &&
        changes.interventionLog.previousValue[keyCheck];
      if (currentValue !== previousValue) {
        if (currentValue) {
          this.setStatusTagMaskAsComplete(true, this.interventionLog);
        } else {
          this.setStatusTagMaskAsComplete(false, this.interventionLog);
        }
      }
    }
  }

  setStatusTagMaskAsComplete(maskComplete: boolean, interventionLog: any) {
    if (maskComplete) {
      // Case RESOLVE Complete
      interventionLog.status = 'INITIATE';
      this.statusClass = 'green-border';
      this.statusTextHover = 'Initiate Completed';
      this.actionIcon = false;
      this.actionInfor = 'No Further Action Required';
      this.actionCheking = true;
    } else {
      // Set Which Color Border should be display (Original Selected or Override in Resolve Tab)
      this.primaryColorFlag = !interventionLog.isRequiredFutherAction
        ? interventionLog.flagColor
        : interventionLog.sendNotificationOfResolutionTotheSameDistribution
        ? interventionLog.sendNotificationOfResolutionTotheSameDistribution
        : interventionLog.flagColor;

      // Setting remain time if have
      if (interventionLog.timeExpire &&  !['I_0_0', 'I_1_0'].includes(
        interventionLog.stateCode
      )) {
        const countdown$ = timer(0, 1000).pipe(
          map((secondsElapsed) => {
            return (
              new Date(interventionLog.timeExpire).getTime() -
              new Date().getTime()
            );
          })
        );

        if (
          (interventionLog.status === 'INITIATE' &&
            !interventionLog.hasSent &&
            !interventionLog.timeExpire) ||
          (interventionLog.status === 'RESOLUTION' && interventionLog.hasSent)
        ) {
        } else {
          countdown$
            .pipe(
              tap((time) => {
                //
                this.actionCheking = true;
                if (interventionLog.status === 'INITIATE') {
                  if (
                    !interventionLog.isRequiredFutherAction &&
                    interventionLog.timeExpire
                  ) {
                    this.statusClass = 'green-border';
                    this.statusTextHover = 'Initiate Completed';

                    this.actionIcon = false;
                    this.actionInfor = 'No Further Action Required';

                    this.actionCheking = true;
                  } else if (time > 0) {
                    this.actionInfor = '';
                    this.actionUrgent = false;
                  } else {
                    this.actionInfor = 'Resolution Required';
                    this.actionIcon = true;
                    this.actionUrgent = true;
                    this.statusClass = 'red-border';
                    this.statusTextHover = 'Initiated and sent (requires immediate resolution)';
                  }
                }
                if (interventionLog.status === 'RESOLUTION') {
                  if (time > 0) {
                    this.actionInfor = '';
                    this.actionIcon = true;
                    this.actionUrgent = false;
                    this.statusTextHover = 'Resolution drafted but not sent';
                  } else {
                    this.actionInfor = 'Resolution Required';
                    this.actionIcon = true;
                    this.actionUrgent = true;
                    this.statusClass = 'red-border';
                    this.statusTextHover = 'Resolution drafted but not sent (requires immediate resolution)';
                  }
                }
              }),
              takeWhile((time) => time > 0),
              takeUntil(this.destroy$)
            )
            .subscribe((secondsLeft) => {
              this.remainingTime = secondsLeft;
              //
            });
        }
      }

      if (
        !interventionLog.hasSent &&
        interventionLog.status === 'INITIATE' &&
        !interventionLog.timeExpire
      ) {
        // Hover Text: Initiated but not sent (draft)
        // Icon: None
        // Action Info: None
        // Action Cheking: Dont Show anything

        this.statusClass = 'yellow-border';
        this.statusTextHover = 'Initiated but not sent (draft)';

        this.actionIcon = false;
        this.actionInfor = '';

        this.actionCheking = false;
      } else if (
        interventionLog.hasSent &&
        interventionLog.isRequiredFutherAction &&
        interventionLog.status === 'INITIATE' &&
        !interventionLog.timeExpire
      ) {
        // Case: Overide Editing
        // Hover Text: Initiated but not sent (draft)
        // Icon: None
        // Action Info: None
        // Action Cheking: Dont Show anything
        this.statusClass = 'yellow-border';
        this.statusTextHover = 'Initiated but not sent (draft)';

        this.actionIcon = false;
        this.actionInfor = '';

        this.actionCheking = false;
      } else if (
        interventionLog.hasSent &&
        interventionLog.status === 'INITIATE' &&
        !interventionLog.isRequiredFutherAction
      ) {
        // Hover Text: Initiate Completed
        // Icon: None
        // Action: No Further Action Required
        // Action Cheking: Show Action Text
        this.statusClass = 'green-border';
        this.statusTextHover = 'Initiate Completed';

        this.actionIcon = false;
        this.actionInfor = 'No Further Action Required';

        this.actionCheking = true;
      } else if (
        !interventionLog.hasSent &&
        interventionLog.status === 'INITIATE' &&
        interventionLog.timeExpire
      ) {
        // Hover Text: Initiated but not sent (draft)
        // Icon: None
        // Action: None
        // Action Cheking: Dont Show anything

        this.statusClass = 'yellow-border';
        this.statusTextHover = 'Initiated but not sent (draft)';

        this.actionIcon = false;
        this.actionInfor = '';

        this.actionCheking = false;
      } else if (
        interventionLog.hasSent &&
        interventionLog.status === 'INITIATE' &&
        interventionLog.timeExpire
      ) {
        // Hover Text: Initiated and sent (awaiting resolution)
        // Icon: Yes
        // Action: Remain Time
        // Action Cheking: Show Remain Time

        this.statusClass = 'green-border';
        this.statusTextHover = 'Initiated and sent (awaiting resolution)';

        this.actionIcon = true;
        this.actionInfor = '';

        this.actionCheking = true;
      } else if (
        interventionLog.hasSent &&
        interventionLog.status === 'RESOLUTION'
      ) {
        // Hover Text: Resolution Completed
        // Icon: No
        // Action: No Further Action Required
        // Action Cheking: Show No Further Action Required

        this.statusClass = 'green-border';
        this.statusTextHover = 'Resolution Completed';

        this.actionIcon = false;
        this.actionInfor = 'No Further Action Required';

        this.actionCheking = true;
      }
    }

    // Setting display Time format HH:MM
    this.shortTimeHHMM = this.descriptionDate(this.interventionLog.updatedAt);

    // Setting Event Hierarchy:  Borehole | Presure | Standing
    const tempEventObj = getEventById(this.interventionLog?.event);
    if (tempEventObj?.hierarchy) {
      this.eventHierarchy = tempEventObj.hierarchy?.split('/');
    } else {
      this.eventHierarchy = [tempEventObj?.label] || [
        'Updating...',
      ];
    }
  }

  showInterventionLog(rigJournalId: string, alertId: string) {
    // Nơi sẽ emitvalue cũ đi
    this._interventionService.setInterventionLogsActive(alertId);

    this._router.navigate([
      `/home/rig/${rigJournalId}/alert/${alertId}`,
    ]);
  }

  private descriptionDate(date: string) {
    let dateFormat: String[] = AppHelper.DateFunctions.formatDateTime(
      new Date(date),
      true
    ).split(',');
    let isToday: Boolean = moment(date).isSame(moment(), 'day');
    if (isToday) return dateFormat[1] + ', ' + 'Today';

    let isYesterday: Boolean = moment(date).isSame(
      moment().subtract(1, 'day'),
      'day'
    );
    if (isYesterday) return dateFormat[1] + ', ' + 'Yesterday';
    else return dateFormat[1] + ', ' + dateFormat[0];
  }

  getMouseLocation(event: any, primaryContact: any[] | string) {
    this._passingDataService.updateApprovalMessage({
      clientX: event.clientX,
      clientY: event.clientY,
      primaryContact: primaryContact,
    });
  }
  onDestroy(): void {}

  collapseName(name: string): string {
    return name?.length > 20 ? name.slice(0, 20) + '...' : name;
  }
}
