<div class="video-detail">
  <p-dialog
    [(visible)]="display"
    [modal]="true"
    [dismissableMask]="true"
    [closable]="true"
    (onHide)="onComponentDestroy()"
  >
    <!-- <ng-template pTemplate="header">
      <span class="text-xl font-bold">Header Content</span>
    </ng-template> -->
    <div class="video" *ngIf="video != {}">
      <video controls #videoPlayer (play)="onPlayVideo($event)">
        <source [src]="video.srcVideo ? (video.srcVideo | safe: 'resourceUrl') : ''" type="video/mp4" />
        Browser not supported
      </video>
    </div>
    <div class="footer-video">
      <div
        class="flex flex-row justify-content-between mt-4 title-action-video"
      >
        <p class="title-text-action" [title]="video.title">{{ video.title }}</p>
        <div class="gap-2 interact-action" [ngClass]="{disabled : (disableButtonVote$ | async) || user.role === 'Viewer'}">
          <i
            class="pi cursor-pointer"
            [ngClass]="
              this.userInteraction.interactionType === 'like'
                ? 'pi-thumbs-up-fill'
                : 'pi-thumbs-up'
            "
            (click)="interact('like')"
          ></i>
          <span>{{ interactionSummary.like }}</span>
          <i
            class="pi cursor-pointer"
            [ngClass]="
              this.userInteraction.interactionType === 'dislike'
                ? 'pi-thumbs-down-fill'
                : 'pi-thumbs-down'
            "
            (click)="interact('dislike')"
          ></i>
          <span>{{ interactionSummary.dislike }}</span>
          <!-- <i class="pi pi-share-alt cursor-pointer"></i> -->
        </div>
      </div>
      <p class="mt-3 pb-3 time-video" appExpandableContent [content]="video.description" [sizeContent]="260" ></p>
      <div class="comment-and-add-comment-video">
        <div *ngIf="isOpenMentionList" class="listSuggestion">
          <p-listbox 
            optionLabel="displayName" 
            [options]="userRelatedShow" 
            [style]="{'width':'15rem'}" 
            [listStyle]="{'height': '120px', 'max-height': '120px', 'margin-top': '-121px'}"
            (onClick)="addTag($event)"
          >
            <ng-template let-user pTemplate="item">
              <span>{{ user.displayName }} ({{ user.role}})</span>
            </ng-template>
          </p-listbox>
        </div>
        <div
          pInputTextarea
          #content
          id="custom-p-textarea"
          class="description-content"
          placeholder="Add a comment..."
          spellcheck="true"
          tabindex="0"
          (keypress)="onKeyPress($event)"
          (keyup)="onKeyUp($event)"
          contenteditable="true"
        ></div>        
        <div
          *ngIf="getErrorContent()"
          class="comment-error"
        >
          Please enter no more than 1000 characters
        </div>
        <div
          class="mt-3 flex flex-row mb-3 justify-content-between align-items-center add-comment-video"
        >
          <p class="time-comment">
            {{ commentList.length ? commentList.length : "No" }} comment{{
              commentList.length > 1 ? "s" : ""
            }}
          </p>
          <button
            pButton
            pRipple
            type="button"
            label="Comment"
            class="p-button-secondary button-send-comment"
            [ngClass]="isDisabledComment() ? 'send-button-disabled' : ''"
            [disabled]="isDisabledComment()"
            (click)="addComment()"
          ></button>
        </div>
        <ng-container *ngIf="commentList.length">
          <ng-container *ngFor="let item of commentList">
            <app-comment-video [userId]="item.user?.id" [comment]="item" [isSupport]="user.role === 'Support'" [isAdmin]="user.role === 'Admin'" [isViewer]="user.role === 'Viewer'" (menuSelected)="onMenuSelected($event)"></app-comment-video>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </p-dialog>
</div>
