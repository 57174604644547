export const MULTI_LEVEL = {
  SINGLE_SELECT: {
    ANY_LEVEL: {
      LABEL: 'any-level',
      OPTION: {},
    },
    LOWEST_LEVEL: {
      LABEL: 'lowest-level',
      OPTION: {},
    }
  },
  MULTIPLE_SELECT: {

  }
};
