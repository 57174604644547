import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getRole',
})
export class StringGetRolePipe implements PipeTransform {
  /**
   * Transforms the input object to 'Role Label' Engineer(TEST) with discipline, otherwise returns the original value like Admin, Manager.
   *
   * @param value - The input object to be transformed.
   * @returns The transformed objec to Engineer(TEST), otherwise returns the original value like Admin, Manager.
   */

  transform(value: any): any {
    if (!value) {
      return 'Select Role';
    } else {
      if (value.parent) {
        return `${value.parent.label} (${value.label})`;
      } else {
        return value.label;
      }
    }
  }
}
