<!-- Modal -->
<div
  style="z-index: 1101"
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div
    *ngIf="isShowPanelWhenLoading"
    class="modal-dialog"
    [style.left]="
      isEmptyData
        ? passingData.clientX - 70 + 'px'
        : passingData.clientX - 338 + 'px'
    "
    [style.top]="passingData.clientY + 12 + 'px'"
    [style.margin]="0"
  >
    <div
      class="modal-content"
      [style.width]="isEmptyData ? '145px !important' : '680px !important'"
    >
      <div
        *ngIf="!isEmptyData"
        class="modal-body extend-scroll"
        style="max-height: 177px; overflow: auto"
      >
        <!-- {{ passingData.primaryContact | json }} -->
        <div
          *ngFor="
            let user of filterPrimaryContact(passingData.primaryContact);
            let i = index
          "
        >
          <div class="user-data">
            <div class="d-flex">
              <div
                *ngIf="
                  !user['FIRST NAME'] && !user['LAST NAME'];
                  else elseBlock
                "
              >
                <div class="text-content" style="min-width: 120px">
                  {{ user.EMAIL | NameEmail }}
                </div>
              </div>
              <ng-template #elseBlock>
                <div class="text-content" style="min-width: 120px">
                  {{ user["FIRST NAME"] + " " + user["LAST NAME"] }}
                </div>
              </ng-template>

              <div class="text-content text-order" style="min-width: 20px">
                {{ user.ORDER }}
              </div>

              <div class="text-content" style="min-width: 160px">
                {{ user.TITLE }}
              </div>

              <div style="min-width: 160px" class="d-flex">
                <div
                  class="text-content text-gray"
                  [ngClass]="{
                    'hyper-link-green': checkDomain('true', user.EMAIL)
                  }"
                  style="min-width: 20px; margin-top: -3px"
                  (click)="
                    makeCallMsTeams(user.EMAIL, checkDomain('true', user.EMAIL))
                  "
                >
                  <svg-icon key="phone" size="md"></svg-icon>
                </div>
                <div
                  *ngIf="!user.PHONE"
                  style="min-width: 100px"
                  class="text-content text-gray"
                >
                  {{ "N/A" }}
                </div>
                <div
                  *ngIf="user.PHONE"
                  id="phone-number"
                  style="min-width: 100px"
                  class="text-content text-gray hyper-link"
                  (click)="makeCall(user.PHONE)"
                >
                  {{ user.PHONE || "N/A" }}
                </div>

                <div style="margin-top: -2px; line-height: 12px">
                  <svg-icon
                    class="cursor-pointer text-content text-gray"
                    key="clipBoard"
                    size="md"
                    (click)="copyText(user.PHONE || 'N/A')"
                  >
                    ></svg-icon
                  >
                </div>
              </div>

              <div style="min-width: 100px" class="d-flex">
                <div *ngIf="!user.EMAIL" class="text-content text-gray">
                  {{ "N/A" }}
                </div>
                <div
                  id="mail"
                  *ngIf="user.EMAIL"
                  class="text-content text-gray hyper-link"
                  (click)="makeMailMsOutlook(user.EMAIL, '', '')"
                >
                  {{ user.EMAIL }}
                </div>

                <div style="margin-top: -2px; line-height: 12px">
                  <svg-icon
                    class="cursor-pointer text-content text-gray"
                    key="clipBoard"
                    size="md"
                    (click)="copyText(user.EMAIL || 'N/A')"
                  >
                    ></svg-icon
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isEmptyData" class="modal-body">
        <div>
          <div class="user-data">
            <div class="d-flex justify-content-center text-center">
              <div class="text-content" style="min-width: 120px">
                {{ this.passingData.primaryContact }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>