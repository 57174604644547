import {
  Directive,
  HostBinding,
  HostListener,
  Output,
  EventEmitter,
  Input,
  OnInit,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

export interface FileHandle {
  file: File;
  url: SafeUrl;
}

@Directive({
  selector: '[appDrag]',
})
export class DragDirective implements OnInit {
  @Input() isViewer: boolean = false;

  @Output() files: EventEmitter<FileHandle[]> = new EventEmitter();

  @HostBinding('style.background') private background = 'transparent'; // not file
  @HostBinding('style.border-color') private borderColor = '#818f99'; // not file
  @HostBinding('style.color') private color = '#818f99'; // not file

  constructor(private sanitizer: DomSanitizer) {}
  ngOnInit(): void {
    if (this.isViewer) {
      this.borderColor = '#3b4957';
      this.color = '#3b4957';
    }
  }

  @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    if (!this.isViewer) {
      this.background = 'transparent'; // have file
      this.borderColor = '#3275b8'; // have file
      this.color = '#3275b8'; // have file
    }
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'transparent'; // not file
    this.borderColor = '#818f99'; // not file
    this.color = '#818f99'; // not file
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'transparent'; // not file
    this.borderColor = '#818f99'; // not file
    this.color = '#818f99'; // not file

    let files: FileHandle[] = [];
    if (evt?.dataTransfer?.files) {
      for (let i = 0; i < evt.dataTransfer.files.length; i++) {
        const file = evt.dataTransfer.files[i];
        const url = this.sanitizer.bypassSecurityTrustUrl(
          window.URL.createObjectURL(file)
        );
        files.push({ file, url });
      }
    }
    if (files.length > 0) {
      this.files.emit(files);
    }
  }
}
