import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { InterventionService } from 'src/app/shared/services/intervention.service';
import { CollapseLayoutService } from 'src/app/shared/services/collapseLayout.service';
import { BehaviorSubject, finalize } from 'rxjs';
import { HomeService } from 'src/app/shared/services/home.service';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent extends BaseComponent {
  @Input()
  notificationData: any;

  @Input()
  isCollapse: boolean = true;

  @Input()
  call!: boolean;

  @Output()
  minimizeNotification: EventEmitter<boolean> = new EventEmitter<boolean>();

  selectedInterventionId: string = '';

  interventionLogs$: any;
  restoreInterventionLogs$: any[] = [];

  interventionLogsActive$: any;

  interventionLogLength: number = 0;
  interventionLogTotalLength: number = 0;

  rigCardId: string = '';
  currentUser: any;

  storeFilter = {
    searchString: '',
  };

  chipFilter = '';

  @ViewChild('infiniteScroll') infiniteScroll?: ElementRef;

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoadingLoadMore$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private _interventionService: InterventionService,
    private _collapseLayoutService: CollapseLayoutService,
    private _homeService: HomeService
  ) {
    super();
  }

  onInit(): void {    
    this.interventionLogs$ = this._interventionService.interventionLogs$;
    this.interventionLogsActive$ = this._interventionService.interventionLogsActive$;
    
    this.interventionLogs$.subscribe((list: any) => {
      this.interventionLogLength = list.length;
    });

    this._interventionService.interventionLogsTotal$.subscribe((total: number) => {
      this.interventionLogTotalLength = total || 0;
    });

    this._interventionService.interventionLogsFilter$.subscribe((LogsFilter: { rigJournalId: string, rigName: string }) => {
      this.chipFilter = LogsFilter.rigName;
    });

    this._interventionService.interventionLogsLoading$.subscribe(
      (nextStatus: any) => {
        this.isLoading$.next(nextStatus);
      }
    );

  }

  emitChangeSize() {
    this.isCollapse = !this.isCollapse;
    this._collapseLayoutService.updateCollapseLayout(this.isCollapse);
  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event: any) {    
    if (
      (this.infiniteScroll!.nativeElement.scrollHeight <=
        this.infiniteScroll!.nativeElement.offsetHeight +
        Math.abs(this.infiniteScroll!.nativeElement.scrollTop) + 1) && !this._interventionService.getTriggerRigCard()
    ) {
      if (!this.isLoadingLoadMore$.getValue() 
        && (this.infiniteScroll!.nativeElement.scrollHeight > this.infiniteScroll!.nativeElement.clientHeight)
        && this.interventionLogLength !== this.interventionLogTotalLength) {
        this.loadMore(this.interventionLogLength);
      }
    }
  }

  loadMore(skip: number) {
    let filterPayload = this._homeService.getPayloadFilter();
    this.isLoadingLoadMore$.next(true);

    this._interventionService.getInterventionLogsFilter(filterPayload, skip, AppConstant.ITEMS_PER_PAGE).pipe(
      finalize(() => this.isLoadingLoadMore$.next(false))
    ).subscribe({
      next: (appendData: any) => {
        this._interventionService.loadMoreAlertLog(appendData);
      },
      error: (error: any) => {
        console.log(error);
      }
    })
  }

  onDestroy(): void { }
}
