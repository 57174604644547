import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'NameEmail',
})
export class StringNameEmailPipe implements PipeTransform {
  /**
   * Transforms the input value to 'NA' if it is falsy, otherwise returns the original value.
   *
   * @param value - The input value to be transformed.
   * @param replaceData - (Optional) The default value to return if the input value is empty.
   * @returns The transformed value, either 'NA' or the original value or the default value.
   */

  transform(value: any): any {
    if (value) {
      let nameEmail = value.split('@').shift();
      return nameEmail ? nameEmail : value;
    }
    return value;
  }
}
