export enum typeUser {
  admin = 'Admin',
  support = 'Support',
  manager = 'Manager',
  engineer = 'Engineer',
  viewer = 'Viewer',
}

export enum DistributionTemplate {
  uploadYet = 'uploadYet',
  uploading = 'uploading',
  uploaded = 'uploaded',
  uploadYetReload = 'uploadYet-reload',
  nonPermission = 'nonPermission',
  errorLoading = 'errorLoading',
}

export enum EVIDENCE_TYPE_PROCESS {
  INITIATE = 'initiate',
  RESOLVE = 'resolve'
}

export enum ALERT_TYPE {
  INTERVENTION = 'intervention',
  REPORT = 'report',  
  ROADMAP = 'roadmap'
}

export enum ALERT_TEMPLATE {
  DEFAULT = 'default',
  ROADMAP = 'roadmap'
}

export enum MODULE_NAME {
  PROJECT = 'Project',
  RIG_TILE = 'Rig Tile',
  WELLBORE = 'Wellbore',
  INTERVAL = 'Interval',
  RUN = 'Run',
  ALERT = 'Alert',
  MAIN_PAGE = 'Main Page',
  ALERT_MANAGEMENT = 'Alert Management',
  TEAM_MANAGEMENT = 'Team Management',
  RIG_MANAGEMENT = 'Rig Management',
  MAILBOX_MANAGEMENT = 'Mailbox Management',
  HELP_CENTER_MANAGEMENT = 'Help Center',
}


export enum USER_PERMISSION {
  ADD = 'add',
  EDIT = "edit",
  DELETE = "delete",
  VIEW = "view",
  MARK_COMPLETED = "markCompleted",
  RESET = "reset",
  SEND_MAIL = "sendMail"
}