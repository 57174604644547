export const ALERT_EXPORT_FILTER = {
    TIME_RANGE: {
      LABEL: 'Time Range',
      OPTION: {},
    },
    ALERT_TYPE: {
        LABEL: 'Alert Type',
        OPTION: {},
    },
    STATUS: {
        LABEL: 'Status',
        OPTION: {},
      },
    EVENT: {
      LABEL: 'Event',
      OPTION: {},
    },
    DISCIPLINE: {
      LABEL: 'Discipline',
      OPTION: {},
    },
    WELLBORE: {
      LABEL: 'Wellbore',
      OPTION: {},
    },
    INTERVAL: {
      LABEL: 'Interval',
      OPTION: {},
    },
    RUN: {
    	LABEL: 'Run',
      OPTION: {},
    },
    DATA_FIELDS: {
      LABEL: 'Data Fields',
      OPTION: {},
    },
    FORMAT: {
      LABEL: 'Format',
      OPTION: {},
    },
    OPTION: {
      PLACE_HOLDER: 'All',
    },
  };