 <div class="tutorial p-3 text-sm font-medium">
   <!-- header -->
  <div>
    <div class="d-flex justify-content-between ext-xl font-semibold mb-3">
      <div>
        <ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index; let last = last">
          <span class="tutorial-breadcrumb" [ngClass]="{'back' : breadcrumbs.length > 1, 'd-none': last}" (click)="navigateToLevel(i)">{{ breadcrumb }} 
            <svg-icon class="custom-svg-icon-down" key="rightarrow" size="md"></svg-icon>
          </span>
          <span class="tutorial-breadcrumb" [ngClass]="{'d-none': !last }">{{ breadcrumb }}</span>
        </ng-container>
      </div>
      <div>
        <svg-icon 
          (click)="closePanel()"
          class="custom-svg-icon cursor-pointer"
          key="close"
          size="md"
        ></svg-icon>
      </div>
    </div>
  </div>

  <!-- Content -->
  <div>
    <!-- Tutorial list -->
    <ng-container *ngIf="!selectedItem">
      <div class="tutorial-item d-flex align-items-center" *ngFor="let item of tutorials" (click)="showDetails(item.id, item.title, item.level)" >
        <div *ngIf="item.svg">
          <svg-icon class="custom-svg-icon tutorial-icon me-2" [key]="item.svg" [size]="item.sizeIcon"></svg-icon>
        </div>
        <div class="tutorial-title" *ngIf="!item.isHide">
          {{ item.title }} <span *ngIf="item.topicList">({{ getVisibleItemCount(item.topicList) }})</span>
        </div>
      </div>
      <div *ngIf="tutorials.length === 0" class="d-flex align-items-start flex-column">
        <div>
          There are currently no topics for this module. 
        </div>
        <div>
          Please visit the <span class="link" (click)="redirectTo('help-center')">Help Center</span> with Admin Role for further updates.
        </div>
      </div>
    </ng-container>

    <!-- Tutorial Details -->
    <ng-container *ngIf="selectedItem">
      <app-tutorial-detail 
        [selectedItem]="selectedItem" 
        [tutorialContent]="tutorialContent" 
        [isShow]="!(loadingData | async)"
        (showList)="goBack($event)">
      </app-tutorial-detail>
    </ng-container>


    <!-- Loading Overlay -->
    <div class="content-progress-bar" *ngIf="loadingData | async">
      <span class="loader"></span>
      <span class="text-progressbar">Loading ...</span>
    </div>
  </div>
</div>