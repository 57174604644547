import { RigJournalMask } from './../models/rig_journal/rig_journal.model';
import { BehaviorSubject } from 'rxjs';

import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DistributionService extends BaseService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  private distributionRender = new BehaviorSubject<object>({});
  public distributionRender$ = this.distributionRender.asObservable();

  private distributionData = new BehaviorSubject<object>({});
  public distributionData$ = this.distributionData.asObservable();

  private distributionURL = new BehaviorSubject<object>({});
  public distributionURL$ = this.distributionURL.asObservable();

  private rigcardUpdate = new BehaviorSubject<object>({});
  public rigcardUpdate$ = this.rigcardUpdate.asObservable();

  setDisplay(nextState: any) {
    this.distributionRender.next(nextState);
  }

  getDisplay() {
    return this.distributionRender.getValue()
  }

  setDisData(data: any) {
    this.distributionData.next(data);
  }

  getDisData() {
    return this.distributionData.getValue()
  }

  setDisURL(url: any) {
    this.distributionURL.next(url);
  }

  getDisURL() {
    return this.distributionURL.getValue()
  }

  setFocusRigCard(data: any) {
    this.rigcardUpdate.next(data);
  }

  getFocusRigCard() {
    return this.rigcardUpdate.getValue()
  }
}
