<span class="message-time" *ngIf="message.isToday && !message.isTodayLastItem">
    {{ message.timestamp | date: 'shortTime' }}
</span>

<span class="message-time" *ngIf="message.isToday && message.isTodayLastItem">
    Today, {{ message.timestamp | date: 'shortTime' }}
</span>

<span class="message-time" *ngIf="!message.isToday && message.isPast">
    {{ message.timestamp | date: 'dd/MM/yyyy' }}, {{ message.timestamp | date: 'shortTime' }}
</span>