import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter, Observable, of, switchMap } from 'rxjs';
import { AzureAdService } from '../services/azure-ad.service';


//https://blog.mastykarz.nl/redirect-custom-login-page-securing-angular-app-msal/
@Injectable({ providedIn: 'root' })
export class CanActivateGuard implements CanActivate {
  constructor(
    private _msalService: MsalService,
    private _azureAdService: AzureAdService,
    private _router: Router,
    private _msalBroadcastService: MsalBroadcastService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      switchMap(() => {
        if (this._msalService.instance.getAllAccounts().length) {
          return of(true);
        }
        this._azureAdService.redirectUrl = state.url;
        this._router.navigate(['/login']);
        return of(false);
      })
    );
  }
}
