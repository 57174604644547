import { Directive, Input, ElementRef, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[appRandomAvatar]',
})
export class RandomAvatarDirective implements OnInit {
  AVATAR_SIZE = {
    SM: 'small',
    SMM: 'extra-small',
    SMMM: 'extra-small-secondary',
    MD: 'medium',
    LG: 'large',
  };

  @Input('appRandomAvatar') dataLabel: string = 'UU';
  @Input('avatarSize') avatarSize: string = this.AVATAR_SIZE.SM;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    const charCodeRed = this.dataLabel.charCodeAt(0);
    const charCodeGreen = this.dataLabel.charCodeAt(1) || charCodeRed;

    const red = Math.pow(charCodeRed, 7) % 400;
    const green = Math.pow(charCodeGreen, 7) % 400;
    const blue = (red + green) % 400;

    let color, backgroundColor, filter, boxShadow, borderRadius;

    backgroundColor = `rgba(${red}, ${green}, ${blue}, 0.4)`;
    filter = `brightness(1.4)`;
    color = `rgb(${red}, ${green}, ${blue})`;
    boxShadow = '1px 1px 5px 0px rgb(0 0 0 / 75%)';
    borderRadius = '50%';

    // Apply generated styles to the element
    this.renderer.setStyle(this.el.nativeElement, 'color', color);
    this.renderer.setStyle(
      this.el.nativeElement,
      'background-color',
      backgroundColor
    );

    this.renderer.setStyle(this.el.nativeElement, 'color', color);
    this.renderer.setStyle(this.el.nativeElement, 'filter', filter);
    this.renderer.setStyle(this.el.nativeElement, 'box-shadow', boxShadow);
    this.renderer.setStyle(
      this.el.nativeElement,
      'border-radius',
      borderRadius
    );
    this.renderer.setStyle(this.el.nativeElement, 'margin-right', '8px');

    switch (this.avatarSize) {
      case this.AVATAR_SIZE.SM:
        this.renderer.setStyle(this.el.nativeElement, 'height', '24px');
        this.renderer.setStyle(this.el.nativeElement, 'width', '24px');
        break;
      case this.AVATAR_SIZE.SMM:
        this.renderer.setStyle(this.el.nativeElement, 'height', '24px');
        this.renderer.setStyle(this.el.nativeElement, 'width', '32px');
        break;
      case this.AVATAR_SIZE.SMMM:
        this.renderer.setStyle(this.el.nativeElement, 'height', '32px');
        this.renderer.setStyle(this.el.nativeElement, 'width', '32px');
        break;
      case this.AVATAR_SIZE.MD:
        this.renderer.setStyle(this.el.nativeElement, 'height', '64px');
        this.renderer.setStyle(this.el.nativeElement, 'width', '64px');
        break;

      default:
        break;
    }
  }
}
