export const UNIT_SYSTEM = {
  unit: {
    API: {
      Diameter: 'in',
      Depth: 'ft',
    },
    'API - US Survey Feet': {
      Diameter: 'in',
      Depth: 'ft',
    },
    'Mixed API': {
      Diameter: 'in',
      Depth: 'm',
    },
    SI: {
      Diameter: 'mm',
      Depth: 'm',
    },
  },
  converter: {
    m2ft: 3.28084,
    ft2m: 0.3048,
    mm2in: 0.0393701,
    in2mm: 25.4,
  },
};

export const ALERT_DATA = [
  {
    interventionId: '073bb436-7a67-4240-8112-495409a8fa20',
    interventionUrl: null,
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1',
    holeMD: '2',
    currentOperation: '3',
    event: {
      event_id: 'EV01',
      label: 'Flowback Volume',
      data: 'Flowback Volume',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Volumetrics/Flowback Volume',
    },
    originalRisk: '6',
    risk: '6',
    contactMethod: null,
    eventDescription: '4',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation: '5',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '7',
    timeSaved: null,
    localTimestamps: '1704867600000',
    rigTimestamps: '1704864000000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: null,
    createUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    status: 'INITIATE',
    hasSent: false,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: false,
    isCustomRisk: false,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1704867684297_log.json',
    auditTrail: [
      {
        logId: '88a3f914-9fd5-4308-9b6a-44f747bece28',
        interventionId: '073bb436-7a67-4240-8112-495409a8fa20',
        action: 'Created',
        timestamps: '2024-01-10T06:21:24.255Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: null,
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Draft)',
    stateCode: 'I_1_0',
    engineer: null,
    engineerId: null,
    initiated: null,
    resolved: null,
    avatarLink: null,
    remoteCenter: null,
    remoteCenterId: null,
    disciplineName: null,
    createdAt: '2024-01-10T06:21:24.232Z',
    updatedAt: '2024-01-10T06:21:24.255Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: null,
    isComplete: null,
  },
  {
    interventionId: '34599d8e-dbb2-4467-8d6f-5c16105ac32b',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705399158606_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12',
    holeMD: '23',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV53',
      label: 'Torque',
      data: 'Torque',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'BHA/Operating Limits/Torque',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: null,
    eventDescription: 'hhhhhh',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'ádfghjk heheeh',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: 'ádfg',
    timeSaved: null,
    localTimestamps: '1705395360000',
    rigTimestamps: '1705391760000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: '2024-01-16T09:44:13.404Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705395527599_log.json',
    auditTrail: [
      {
        logId: '2913dcd2-621f-4831-831c-06faf51d2586',
        interventionId: '34599d8e-dbb2-4467-8d6f-5c16105ac32b',
        action: 'Saved',
        timestamps: '2024-01-16T11:01:31.260Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '50ef9c06-b9d0-4511-870a-55839f74fda7',
        interventionId: '34599d8e-dbb2-4467-8d6f-5c16105ac32b',
        action: 'Saved',
        timestamps: '2024-01-16T09:59:15.445Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '6d4bc45d-68be-481f-a49b-2e90f314e1ca',
        interventionId: '34599d8e-dbb2-4467-8d6f-5c16105ac32b',
        action: 'Sent',
        timestamps: '2024-01-16T08:59:13.439Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'c03b60f3-dd16-4d37-9d89-b60b907e5d0e',
        interventionId: '34599d8e-dbb2-4467-8d6f-5c16105ac32b',
        action: 'Created',
        timestamps: '2024-01-16T08:58:47.575Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Completed)',
    stateCode: 'I_1_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-16T08:59:13.439Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-16T08:58:47.548Z',
    updatedAt: '2024-01-16T11:01:31.260Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '2edeeb68-d72f-4cda-b1cf-3b4f23a35d7d',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test',
      type: 'TYPE06',
      runNo: '111',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '12',
      startMeasuredDepth: '16',
      tdMeasuredDepth: '26',
      belowRotaryTimestamp: '10/02/2023, 10:16 PM',
      runTdTimestamp: '10/04/2023, 10:16 PM',
      aboveRotaryTimestamp: '10/11/2023, 10:16 PM',
      tallyUrl:
        'https://hpmvrodevscusstapp.blob.core.windows.net/tally/1698719909012_sample-file.pdf',
    },
    isComplete: null,
  },
  {
    interventionId: '282d98e8-3eb9-45fa-990d-8cb51c7f3c3d',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705404608927_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1',
    holeMD: '2',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV53',
      label: 'Torque',
      data: 'Torque',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'BHA/Operating Limits/Torque',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod:
      '[{"id":"phone","label":"Phone","data":"Phone"},{"id":"C1","label":"C1 - Drilling Superintendent","data":"+1 832-123-4568"},{"id":"C2","label":"C2 - Project Manager","data":"+1 832-123-4569"},{"id":"C3","label":"C3 - Technical Advisor","data":"+1 832-123-4573"},{"id":"C4","label":"C4 - Developer","data":"+1 132-434-6534"},{"id":"E1","label":"E1 - Tester","data":"+1 832-123-4575"},{"id":"E2","label":"E2 - Tester","data":"+1 832-123-4576"},{"id":"E3","label":"E3 - Tester","data":"+1 123-253-4645"},{"id":"E4","label":"E4 - Developer","data":"+1 123-253-4646"}]',
    eventDescription: 'h',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'b',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: false,
    isRecommendedActionTaken: 'no',
    resolution: 'hh',
    sendNotificationOfResolutionTotheSameDistribution: '',
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1705403400000',
    rigTimestamps: '1705399800000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'RESOLUTION',
    hasSent: false,
    timeExpire: '2024-01-16T11:59:30.283Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705403502181_log.json',
    auditTrail: [
      {
        logId: '0a1cc8da-7b28-4a89-a6e0-665dedf78194',
        interventionId: '282d98e8-3eb9-45fa-990d-8cb51c7f3c3d',
        action: 'Saved',
        timestamps: '2024-01-16T11:30:05.814Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '1a7a8b7b-fb02-49c6-94c6-297de4d49f7e',
        interventionId: '282d98e8-3eb9-45fa-990d-8cb51c7f3c3d',
        action: 'Sent',
        timestamps: '2024-01-16T11:14:30.319Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '5ac396ad-6ec6-4dff-bc94-e9ccb0c77274',
        interventionId: '282d98e8-3eb9-45fa-990d-8cb51c7f3c3d',
        action: 'Saved',
        timestamps: '2024-01-16T11:29:19.788Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '798a1500-69b9-440b-9702-b82ae61912d4',
        interventionId: '282d98e8-3eb9-45fa-990d-8cb51c7f3c3d',
        action: 'Created',
        timestamps: '2024-01-16T11:11:42.156Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: 'c26ae52c-b873-49a1-b3f3-cf35cdfe1890',
        interventionId: '282d98e8-3eb9-45fa-990d-8cb51c7f3c3d',
        action: 'Saved',
        timestamps: '2024-01-16T11:14:23.971Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Resolve (Draft)',
    stateCode: 'R_1_0',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-16T11:14:30.319Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-16T11:11:42.130Z',
    updatedAt: '2024-01-16T11:30:05.814Z',
    eventType: 'intervention',
    interval: {
      intervalId: 'e20a8970-8f5c-40a7-a627-dcc8c5039ae9',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: '1',
      unit: 'SI',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '51',
      openHoleDiameter: '51',
      lastShoeMeasuredDepth: '235.34',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '51057d99-7501-4cee-aab7-80a8e1aaae34',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: 'e20a8970-8f5c-40a7-a627-dcc8c5039ae9',
      description: '6',
      type: 'TYPE10',
      runNo: '6',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '6',
      startMeasuredDepth: '7',
      tdMeasuredDepth: '7',
      belowRotaryTimestamp: '10/28/2023, 10:40 AM',
      runTdTimestamp: '10/29/2023, 10:40 AM',
      aboveRotaryTimestamp: '10/30/2023, 10:40 AM',
      tallyUrl: null,
    },
    isComplete: null,
  },
  {
    interventionId: '33dcad71-6d31-4b69-ad0e-197134bc6f92',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705897871820_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1',
    holeMD: '2',
    currentOperation:
      '{"operation_id":"OP14","data":"Pulling out with Drilling Assembly","label":"Pulling out with Drilling Assembly"}',
    event: {
      event_id: 'EV01',
      label: 'Flowback Volume',
      data: 'Flowback Volume',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Volumetrics/Flowback Volume',
    },
    originalRisk:
      '[{"risk_id":"BOPR01","data":"Unstable/problematic borehole conditions","label":"Unstable/problematic borehole conditions"},{"risk_id":"BOPR04","data":"String running failures","label":"String running failures"}]',
    risk: [
      {
        risk_id: 'BOPR01',
        label: 'Unstable/problematic borehole conditions',
        data: 'Unstable/problematic borehole conditions',
      },
      {
        risk_id: 'BOPR04',
        label: 'String running failures',
        data: 'String running failures',
      },
    ],
    contactMethod: '[{"id":"email","label":"Email","data":"Email"}]',
    eventDescription: 'ThanhNC40 22.1',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation: 'ThanhNC40 22.1',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: 'yes',
    resolution: 'ThanhNC40 22.1',
    sendNotificationOfResolutionTotheSameDistribution: '',
    rigBasedValidationDetail: 'ThanhNC40 22.1',
    timeSaved: 22,
    localTimestamps: '1705894620000',
    rigTimestamps: '1705891020000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
    createUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    status: 'RESOLUTION',
    hasSent: false,
    timeExpire: '2024-01-22T04:23:47.781Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705894719110_log.json',
    auditTrail: [
      {
        logId: '27b70181-2d0b-4f27-a221-3e873d74e0ba',
        interventionId: '33dcad71-6d31-4b69-ad0e-197134bc6f92',
        action: 'Saved',
        timestamps: '2024-01-22T04:31:10.227Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '2f224f72-d5b3-44dd-8771-6f4bf6537b33',
        interventionId: '33dcad71-6d31-4b69-ad0e-197134bc6f92',
        action: 'Created',
        timestamps: '2024-01-22T03:38:39.037Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '4b3f4ef2-fd4a-4cc2-8fbd-6c407305ede0',
        interventionId: '33dcad71-6d31-4b69-ad0e-197134bc6f92',
        action: 'Sent',
        timestamps: '2024-01-22T03:38:47.819Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'dd55917d-451e-4599-8767-c11364933e0f',
        interventionId: '33dcad71-6d31-4b69-ad0e-197134bc6f92',
        action: 'Saved',
        timestamps: '2024-01-22T03:39:19.390Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Resolve (Draft)',
    stateCode: 'R_1_0',
    engineer: 'Thanh Nguyen Cong (TEST)',
    engineerId: '365dbd8b-3313-4833-8efb-ffc478695821',
    initiated: '2024-01-22T03:38:47.819Z',
    resolved: null,
    avatarLink: null,
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-22T03:38:39.013Z',
    updatedAt: '2024-01-22T04:31:10.227Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: null,
    isComplete: null,
  },
  {
    interventionId: '17793d6b-5bca-41d7-97ce-9f6483d7e5e4',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705898106163_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1',
    holeMD: '2',
    currentOperation:
      '{"operation_id":"OP01","data":"Drilling Ahead","label":"Drilling Ahead"}',
    event: {
      event_id: 'EV01',
      label: 'Flowback Volume',
      data: 'Flowback Volume',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Volumetrics/Flowback Volume',
    },
    originalRisk:
      '[{"risk_id":"NOOP01","data":"Community/Strikes","label":"Community/Strikes"}]',
    risk: [
      {
        risk_id: 'NOOP01',
        label: 'Community/Strikes',
        data: 'Community/Strikes',
      },
    ],
    contactMethod:
      '[{"id":"phone","label":"Phone","data":"Phone"},{"contact_id":"C2","label":"C2 - Project Manager","data":"+1 832-123-4569"},{"contact_id":"C3","label":"C3 - Technical Advisor","data":"+1 832-123-4573"},{"contact_id":"C4","label":"C4 - Developer","data":"+1 132-434-6534"},{"contact_id":"E1","label":"E1 - Tester","data":"+1 832-123-4575"},{"contact_id":"E2","label":"E2 - Tester","data":"+1 832-123-4576"},{"contact_id":"E3","label":"E3 - Tester","data":"+1 123-253-4645"},{"contact_id":"E4","label":"E4 - Developer","data":"+1 123-253-4646"},{"id":"email","label":"Email","data":"Email"}]',
    eventDescription: 'ThanhNC40 11:34',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation: 'ThanhNC40 11:34',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: 'yes',
    resolution: 'ThanhNC40 11:34',
    sendNotificationOfResolutionTotheSameDistribution: '',
    rigBasedValidationDetail: 'ThanhNC40 11:34',
    timeSaved: 22,
    localTimestamps: '1705898040000',
    rigTimestamps: '1705894440000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    createUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    status: 'RESOLUTION',
    hasSent: false,
    timeExpire: '2024-01-22T05:20:10.179Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705898102579_log.json',
    auditTrail: [
      {
        logId: '2bb5bcdc-e165-4cb1-8f22-bb08400df406',
        interventionId: '17793d6b-5bca-41d7-97ce-9f6483d7e5e4',
        action: 'Saved',
        timestamps: '2024-01-22T04:35:25.036Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
      {
        logId: '4d65c0d8-82f9-4cb3-817d-fc073956931c',
        interventionId: '17793d6b-5bca-41d7-97ce-9f6483d7e5e4',
        action: 'Created',
        timestamps: '2024-01-22T04:35:02.550Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: 'fae9dc07-3c29-44e3-898b-e5a7b886f67d',
        interventionId: '17793d6b-5bca-41d7-97ce-9f6483d7e5e4',
        action: 'Sent',
        timestamps: '2024-01-22T04:35:10.215Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: 'VRO Testing',
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Resolve (Draft)',
    stateCode: 'R_1_0',
    engineer: 'Thanh Nguyen Cong (TEST)',
    engineerId: '365dbd8b-3313-4833-8efb-ffc478695821',
    initiated: '2024-01-22T04:35:10.215Z',
    resolved: null,
    avatarLink: null,
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-22T04:35:02.531Z',
    updatedAt: '2024-01-22T04:35:25.036Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: null,
    isComplete: null,
  },
  {
    interventionId: '1e7e745d-ee5c-414b-a4b3-15678914d644',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705981189980_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '2',
    holeMD: '3',
    currentOperation: 'ThanhNC40 2:40',
    event: {
      event_id: 'EV13',
      label: 'Standpipe',
      data: 'Standpipe',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Pressure/Standpipe',
    },
    originalRisk: 'ThanhNC40 2:40',
    risk: 'ThanhNC40 2:40',
    contactMethod: null,
    eventDescription: 'ThanhNC40 2:40',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation: 'ThanhNC40 2:40',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: 'ThanhNC40 2:40',
    timeSaved: null,
    localTimestamps: '1705909200000',
    rigTimestamps: '1705905600000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    createUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: '2024-01-23T04:25:18.753Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: false,
    isCustomRisk: false,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705909254290_log.json',
    auditTrail: [
      {
        logId: '02f7c1ea-d197-4a48-8604-9364895cbfc6',
        interventionId: '1e7e745d-ee5c-414b-a4b3-15678914d644',
        action: 'Saved',
        timestamps: '2024-01-22T08:39:25.917Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Tue Nguyen Minh',
        userId: '59d90418-7015-4036-91b7-f5635f5e4c3a',
        userRemoteCenter: null,
      },
      {
        logId: '65d6490b-27f5-49ed-a865-2c5c94bc93e1',
        interventionId: '1e7e745d-ee5c-414b-a4b3-15678914d644',
        action: 'Created',
        timestamps: '2024-01-22T07:40:54.268Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '7927731d-f8b8-4c50-b78c-f9fff3fd62c1',
        interventionId: '1e7e745d-ee5c-414b-a4b3-15678914d644',
        action: 'Saved',
        timestamps: '2024-01-22T09:12:47.005Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Tue Nguyen Minh',
        userId: '59d90418-7015-4036-91b7-f5635f5e4c3a',
        userRemoteCenter: null,
      },
      {
        logId: '8e5d7b1d-7cc7-4feb-9d75-444cd3b68403',
        interventionId: '1e7e745d-ee5c-414b-a4b3-15678914d644',
        action: 'Marked completed',
        timestamps: '2024-01-23T05:38:56.795Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Tue Nguyen Minh',
        userId: '59d90418-7015-4036-91b7-f5635f5e4c3a',
        userRemoteCenter: null,
      },
      {
        logId: 'bdbfe40a-49e2-4589-981f-62c60f8ab84d',
        interventionId: '1e7e745d-ee5c-414b-a4b3-15678914d644',
        action: 'Sent',
        timestamps: '2024-01-23T03:40:18.795Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'bf1954f6-3459-4564-9a95-3548c2370d1a',
        interventionId: '1e7e745d-ee5c-414b-a4b3-15678914d644',
        action: 'Saved',
        timestamps: '2024-01-23T03:39:39.080Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Completed)',
    stateCode: 'I_0_1',
    engineer: 'Thanh Nguyen Cong (TEST)',
    engineerId: '365dbd8b-3313-4833-8efb-ffc478695821',
    initiated: '2024-01-23T03:40:18.795Z',
    resolved: null,
    avatarLink: null,
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-22T07:40:54.251Z',
    updatedAt: '2024-01-23T05:38:56.795Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: null,
    isComplete: true,
  },
  {
    interventionId: '391f81ed-9839-44f0-8b05-02f0487c1c5a',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706000799592_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '124',
    holeMD: '566,346',
    currentOperation:
      '{"operation_id":"OP14","data":"Pulling out with Drilling Assembly","label":"Pulling out with Drilling Assembly"}',
    event: {
      event_id: 'EV71',
      label: 'No Data Exchange',
      data: 'No Data Exchange',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Data Acquisition/No Data Exchange',
    },
    originalRisk:
      '[{"risk_id":"ETSE02","data":"Casing / Tubing Running Equipment/Tools Failures","label":"Casing / Tubing Running Equipment/Tools Failures"},{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
      {
        risk_id: 'ETSE02',
        label: 'Casing / Tubing Running Equipment/Tools Failures',
        data: 'Casing / Tubing Running Equipment/Tools Failures',
      },
    ],
    contactMethod: '[{"id":"email","label":"Email","data":"Email"}]',
    eventDescription: 'htest',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'hhhh',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: false,
    isRecommendedActionTaken: 'no',
    resolution: 'hhh',
    sendNotificationOfResolutionTotheSameDistribution: '',
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1706000700000',
    rigTimestamps: '1705997100000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'RESOLUTION',
    hasSent: false,
    timeExpire: '2024-01-23T09:51:48.315Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706000798424_log.json',
    auditTrail: [
      {
        logId: '25d014b4-bd52-44a2-a05e-ff53b9d095d7',
        interventionId: '391f81ed-9839-44f0-8b05-02f0487c1c5a',
        action: 'Sent',
        timestamps: '2024-01-23T09:06:48.351Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '46bb1de6-2f44-4bed-a087-45f8dc26c422',
        interventionId: '391f81ed-9839-44f0-8b05-02f0487c1c5a',
        action: 'Saved',
        timestamps: '2024-01-23T09:07:09.083Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'e41cba7a-e2d6-4320-a4bf-fc4708635363',
        interventionId: '391f81ed-9839-44f0-8b05-02f0487c1c5a',
        action: 'Created',
        timestamps: '2024-01-23T09:06:38.407Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Resolve (Draft)',
    stateCode: 'R_1_0',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-23T09:06:48.351Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-23T09:06:38.386Z',
    updatedAt: '2024-01-23T09:07:09.083Z',
    eventType: 'intervention',
    interval: {
      intervalId: 'e20a8970-8f5c-40a7-a627-dcc8c5039ae9',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: '1',
      unit: 'SI',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '51',
      openHoleDiameter: '51',
      lastShoeMeasuredDepth: '235.34',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '7368b53c-23cd-4397-83ef-f8bcc4d8d815',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: 'e20a8970-8f5c-40a7-a627-dcc8c5039ae9',
      description: '4',
      type: 'TYPE10',
      runNo: '4',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '4',
      startMeasuredDepth: '4',
      tdMeasuredDepth: '4',
      belowRotaryTimestamp: '10/27/2023, 10:23 AM',
      runTdTimestamp: '10/28/2023, 10:23 AM',
      aboveRotaryTimestamp: '10/30/2023, 10:23 AM',
      tallyUrl: null,
    },
    isComplete: null,
  },
  {
    interventionId: '34c27e54-3268-468b-ab68-631aec06e2d4',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706084689293_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '123',
    holeMD: '1,234',
    currentOperation:
      '{"operation_id":"OP07","data":"Tripping in with Cleanout Assembly","label":"Tripping in with Cleanout Assembly"}',
    event: {
      event_id: 'EV53',
      label: 'Torque',
      data: 'Torque',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'BHA/Operating Limits/Torque',
    },
    originalRisk:
      '[{"risk_id":"ETSE07","data":"Directional Drilling Equipment Failure","label":"Directional Drilling Equipment Failure"}]',
    risk: [
      {
        risk_id: 'ETSE07',
        label: 'Directional Drilling Equipment Failure',
        data: 'Directional Drilling Equipment Failure',
      },
    ],
    contactMethod:
      '[{"id":"phone","label":"Phone","data":"Phone"},{"contact_id":"C2","label":"C2 - Project Manager","data":"+1 832-123-4569"},{"contact_id":"C3","label":"C3 - Technical Advisor","data":"+1 832-123-4573"},{"contact_id":"C4","label":"C4 - Developer","data":"+1 132-434-6534"},{"contact_id":"E1","label":"E1 - Tester","data":"+1 832-123-4575"},{"contact_id":"E2","label":"E2 - Tester","data":"+1 832-123-4576"},{"contact_id":"E3","label":"E3 - Tester","data":"+1 123-253-4645"},{"contact_id":"E4","label":"E4 - Developer","data":"+1 123-253-4646"}]',
    eventDescription: 'ab',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'h',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: false,
    isRecommendedActionTaken: 'no',
    resolution: 'h',
    sendNotificationOfResolutionTotheSameDistribution: '',
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1706083080000',
    rigTimestamps: '1706079480000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'RESOLUTION',
    hasSent: true,
    timeExpire: '2024-01-24T08:45:10.785Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706083167783_log.json',
    auditTrail: [
      {
        logId: '06c7fc3c-9fba-4562-a618-d28f09cfb1cf',
        interventionId: '34c27e54-3268-468b-ab68-631aec06e2d4',
        action: 'Saved',
        timestamps: '2024-01-24T08:24:47.010Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '56a0f5e3-9537-448d-af39-7e704b523394',
        interventionId: '34c27e54-3268-468b-ab68-631aec06e2d4',
        action: 'Sent',
        timestamps: '2024-01-24T08:00:10.821Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '5bc97626-1092-4967-9ef2-9c0a073bd418',
        interventionId: '34c27e54-3268-468b-ab68-631aec06e2d4',
        action: 'Created',
        timestamps: '2024-01-24T07:59:27.763Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: 'a47a6502-de8c-4efc-ba2f-0a5b198f0691',
        interventionId: '34c27e54-3268-468b-ab68-631aec06e2d4',
        action: 'Saved',
        timestamps: '2024-01-24T08:24:14.548Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'ab1e4c9d-7576-49de-9cde-eec9cc8506ae',
        interventionId: '34c27e54-3268-468b-ab68-631aec06e2d4',
        action: 'Saved',
        timestamps: '2024-01-24T08:06:59.990Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'caf081fa-cb4b-47db-bb76-2686592c5ccd',
        interventionId: '34c27e54-3268-468b-ab68-631aec06e2d4',
        action: 'Saved',
        timestamps: '2024-01-24T08:03:21.892Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'cf8e6d79-0089-4504-b98e-3d57c3f0ce07',
        interventionId: '34c27e54-3268-468b-ab68-631aec06e2d4',
        action: 'Sent',
        timestamps: '2024-01-24T08:25:31.972Z',
        isFirstSent: true,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Resolve (Completed)',
    stateCode: 'R_1_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-24T08:00:10.821Z',
    resolved: '2024-01-24T08:25:31.972Z',
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-24T07:59:27.714Z',
    updatedAt: '2024-01-24T08:25:31.972Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: null,
    isComplete: null,
  },
  {
    interventionId: '0f1e100e-fd1f-4e64-83c1-a97a2af96d09',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706596231711_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12',
    holeMD: '124',
    currentOperation:
      '{"operation_id":"OP01","data":"Drilling Ahead","label":"Drilling Ahead"}',
    event: {
      event_id: 'EV99',
      label: 'Drill Pipe',
      data: 'Drill Pipe',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default', 'roadmap'],
      leaf: false,
      hierarchy: 'Flat Time Optimization/Connections/Drill Pipe',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: null,
    eventDescription: 'hhh',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation: 'hh',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: false,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1706596140000',
    rigTimestamps: '1706592540000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: null,
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706596229267_log.json',
    auditTrail: [
      {
        logId: '1e88dc66-26bc-4a16-968e-f6e89f673446',
        interventionId: '0f1e100e-fd1f-4e64-83c1-a97a2af96d09',
        action: 'Created',
        timestamps: '2024-01-30T06:30:29.245Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '526f60af-c96c-4fbc-b005-081b9fcdf11d',
        interventionId: '0f1e100e-fd1f-4e64-83c1-a97a2af96d09',
        action: 'Sent',
        timestamps: '2024-01-30T06:30:59.408Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: {
      chatId: '34f873c6-bf73-4811-b046-c1ab082a5131',
      content: 'g',
      interventionId: '0f1e100e-fd1f-4e64-83c1-a97a2af96d09',
      userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
      attachments: [],
      nominateInformation: [
        {
          userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
          userDisplayName: 'Nga Tran Thi Kieu',
          userRole: 'Admin',
          timestamps: 1708683500350,
          mail: 'Nga.TranThiKieu@halliburton.com',
        },
      ],
      nominateAt: '1708683500350',
      nominateVote: 1,
      createdAt: '2024-02-23T10:18:19.785Z',
      updatedAt: '2024-02-23T10:18:20.350Z',
    },
    currentState: 'Initiate (Completed)',
    stateCode: 'I_0_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-30T06:30:59.408Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-30T06:30:29.216Z',
    updatedAt: '2024-01-30T06:30:59.408Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '38187b2f-df00-430a-8975-ccf2cfae03e9',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test 1hahashagds 12/.23347"h',
      type: 'TYPE10',
      runNo: '12',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '23',
      startMeasuredDepth: '23',
      tdMeasuredDepth: '2,435',
      belowRotaryTimestamp: '10/17/2023, 10:13 PM',
      runTdTimestamp: '10/20/2023, 10:14 PM',
      aboveRotaryTimestamp: '10/22/2023, 10:14 PM',
      tallyUrl: null,
    },
    isComplete: null,
  },
  {
    interventionId: '0f0f6b1f-c719-4cca-bc80-751cc85957da',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706596649168_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12',
    holeMD: '24',
    currentOperation:
      '{"operation_id":"OP01","data":"Drilling Ahead","label":"Drilling Ahead"}',
    event: {
      event_id: 'EV22',
      label: 'Hydraulics Roadmap',
      data: 'Hydraulics Roadmap',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default', 'roadmap'],
      leaf: false,
      hierarchy: 'Borehole/Hydraulics/Hydraulics Roadmap',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: null,
    eventDescription: 'N_test ahah',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation: 'hahaha',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: false,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1706596440000',
    rigTimestamps: '1706592840000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: null,
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706596647568_log.json',
    auditTrail: [
      {
        logId: '053406ee-2cde-4b18-bb3d-5d954a7cf5da',
        interventionId: '0f0f6b1f-c719-4cca-bc80-751cc85957da',
        action: 'Sent',
        timestamps: '2024-01-30T06:39:29.256Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'c0871b4b-97b8-4419-bae9-bf33cc1f27c3',
        interventionId: '0f0f6b1f-c719-4cca-bc80-751cc85957da',
        action: 'Created',
        timestamps: '2024-01-30T06:37:27.548Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Completed)',
    stateCode: 'I_0_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-30T06:39:29.256Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-30T06:37:27.524Z',
    updatedAt: '2024-01-30T06:39:29.256Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '38187b2f-df00-430a-8975-ccf2cfae03e9',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test 1hahashagds 12/.23347"h',
      type: 'TYPE10',
      runNo: '12',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '23',
      startMeasuredDepth: '23',
      tdMeasuredDepth: '2,435',
      belowRotaryTimestamp: '10/17/2023, 10:13 PM',
      runTdTimestamp: '10/20/2023, 10:14 PM',
      aboveRotaryTimestamp: '10/22/2023, 10:14 PM',
      tallyUrl: null,
    },
    isComplete: null,
  },
  {
    interventionId: '21bde103-8a92-4f82-9479-6d0cce634c96',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706603668859_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12',
    holeMD: '12',
    currentOperation:
      '{"operation_id":"OP01","data":"Drilling Ahead","label":"Drilling Ahead"}',
    event: {
      event_id: 'EV01',
      label: 'Flowback Volume',
      data: 'Flowback Volume',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Volumetrics/Flowback Volume',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: null,
    eventDescription: 'test',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'test n',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1706603580000',
    rigTimestamps: '1706599980000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: null,
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: '2024-01-30T09:19:37.674Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706603666036_log.json',
    auditTrail: [
      {
        logId: '4b13d6a0-e637-4e26-9379-cfcc6a888e9b',
        interventionId: '21bde103-8a92-4f82-9479-6d0cce634c96',
        action: 'Sent',
        timestamps: '2024-01-30T08:34:37.708Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '8d0ad204-6db0-42af-b2b8-19e336adfbd4',
        interventionId: '21bde103-8a92-4f82-9479-6d0cce634c96',
        action: 'Created',
        timestamps: '2024-01-30T08:34:26.024Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Completed)',
    stateCode: 'I_1_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-30T08:34:37.708Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-30T08:34:25.996Z',
    updatedAt: '2024-01-30T08:34:37.708Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '38187b2f-df00-430a-8975-ccf2cfae03e9',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test 1hahashagds 12/.23347"h',
      type: 'TYPE10',
      runNo: '12',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '23',
      startMeasuredDepth: '23',
      tdMeasuredDepth: '2,435',
      belowRotaryTimestamp: '10/17/2023, 10:13 PM',
      runTdTimestamp: '10/20/2023, 10:14 PM',
      aboveRotaryTimestamp: '10/22/2023, 10:14 PM',
      tallyUrl: null,
    },
    isComplete: null,
  },
  {
    interventionId: '1107249f-48b1-4fdb-8e90-ac4643273e34',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706622521264_N_TestJh_AlertRoadmap.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1,234',
    holeMD: '1,335',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV17',
      label: 'Distance to Plan',
      data: 'Distance to Plan',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default', 'roadmap'],
      leaf: false,
      hierarchy: 'Borehole/Well Trajectory/Distance to Plan',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: null,
    eventDescription:
      '1. Test new template\n2. Test new template\n3. Test new template\n4. Test new template\n5. Test new template\n6. Test new template',
    effectiveDiameter: '',
    flag: 'red',
    unit: 'API',
    recommendation:
      '1. Test new template\n2. Test new template\n3. Test new template\n4. Test new template\n5. Test new template\n6. Test new template',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: false,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1706611500000',
    rigTimestamps: '1706607900000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: false,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706611665610_log.json',
    auditTrail: [
      {
        logId: '240cb873-91ef-47cd-b143-096077d45df1',
        interventionId: '1107249f-48b1-4fdb-8e90-ac4643273e34',
        action: 'Saved',
        timestamps: '2024-01-30T13:48:38.787Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '2967d7fd-7865-451f-b2f8-9a20f29fced7',
        interventionId: '1107249f-48b1-4fdb-8e90-ac4643273e34',
        action: 'Created',
        timestamps: '2024-01-30T10:47:45.572Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: 'b4c3e5ec-1d8b-449f-9d8e-39a1657b4f1d',
        interventionId: '1107249f-48b1-4fdb-8e90-ac4643273e34',
        action: 'Saved',
        timestamps: '2024-01-30T10:48:50.942Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Draft)',
    stateCode: 'I_0_0',
    engineer: null,
    engineerId: null,
    initiated: null,
    resolved: null,
    avatarLink: null,
    remoteCenter: null,
    remoteCenterId: null,
    disciplineName: null,
    createdAt: '2024-01-30T10:47:45.509Z',
    updatedAt: '2024-01-30T13:48:38.787Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '38187b2f-df00-430a-8975-ccf2cfae03e9',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test 1hahashagds 12/.23347"h',
      type: 'TYPE10',
      runNo: '12',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '23',
      startMeasuredDepth: '23',
      tdMeasuredDepth: '2,435',
      belowRotaryTimestamp: '10/17/2023, 10:13 PM',
      runTdTimestamp: '10/20/2023, 10:14 PM',
      aboveRotaryTimestamp: '10/22/2023, 10:14 PM',
      tallyUrl: null,
    },
    isComplete: null,
  },
  {
    interventionId: '3c6c8120-c058-4499-b749-1b7b177b8eb1',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706510971663_N_TestJh_AlertReport.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12',
    holeMD: '23',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV94',
      label: 'Daily Report',
      data: 'Daily Report',
      styleClass: 'lowestChild',
      eventType: 'report',
      template: ['default'],
      leaf: false,
      hierarchy: 'Reports/Daily Report',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"},{"risk_id":"ETSE02","data":"Casing / Tubing Running Equipment/Tools Failures","label":"Casing / Tubing Running Equipment/Tools Failures"},{"risk_id":"ETSE03","data":"Tubulars Related Failures / Damage","label":"Tubulars Related Failures / Damage"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
      {
        risk_id: 'ETSE02',
        label: 'Casing / Tubing Running Equipment/Tools Failures',
        data: 'Casing / Tubing Running Equipment/Tools Failures',
      },
      {
        risk_id: 'ETSE03',
        label: 'Tubulars Related Failures / Damage',
        data: 'Tubulars Related Failures / Damage',
      },
    ],
    contactMethod: null,
    eventDescription: 'hhh',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'test n',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1706509620000',
    rigTimestamps: '1706506020000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: null,
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: '2024-01-29T07:36:18.343Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706510968269_log.json',
    auditTrail: [
      {
        logId: 'b451820a-aa4e-4c23-bf9e-70819d63ddd6',
        interventionId: '3c6c8120-c058-4499-b749-1b7b177b8eb1',
        action: 'Created',
        timestamps: '2024-01-29T06:49:28.252Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: 'ca199bcc-6fe0-48c1-b54b-abce6858e142',
        interventionId: '3c6c8120-c058-4499-b749-1b7b177b8eb1',
        action: 'Sent',
        timestamps: '2024-01-29T06:51:18.408Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Completed)',
    stateCode: 'I_1_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-29T06:51:18.408Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-29T06:49:28.186Z',
    updatedAt: '2024-02-06T15:56:43.645Z',
    eventType: 'report',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '38187b2f-df00-430a-8975-ccf2cfae03e9',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test 1hahashagds 12/.23347"h',
      type: 'TYPE10',
      runNo: '12',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '23',
      startMeasuredDepth: '23',
      tdMeasuredDepth: '2,435',
      belowRotaryTimestamp: '10/17/2023, 10:13 PM',
      runTdTimestamp: '10/20/2023, 10:14 PM',
      aboveRotaryTimestamp: '10/22/2023, 10:14 PM',
      tallyUrl: null,
    },
    isComplete: null,
  },
  {
    interventionId: '3f701cf4-3744-4fbd-bc81-81267b07a4d9',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705561125546_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1',
    holeMD: '12',
    currentOperation:
      '{"operation_id":"OP01","data":"Drilling Ahead","label":"Drilling Ahead"}',
    event: {
      event_id: 'EV01',
      label: 'Flowback Volume',
      data: 'Flowback Volume',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Volumetrics/Flowback Volume',
    },
    originalRisk:
      '[{"risk_id":"BOPR01","data":"Unstable/problematic borehole conditions","label":"Unstable/problematic borehole conditions"}]',
    risk: [
      {
        risk_id: 'BOPR01',
        label: 'Unstable/problematic borehole conditions',
        data: 'Unstable/problematic borehole conditions',
      },
    ],
    contactMethod: null,
    eventDescription: 'Again',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'Again',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: 'Again',
    timeSaved: null,
    localTimestamps: '1705560720000',
    rigTimestamps: '1705557120000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
    createUserId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
    status: 'INITIATE',
    hasSent: false,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705560798739_log.json',
    auditTrail: [
      {
        logId: '2662fa41-00aa-4f2f-872b-74d53c575a5c',
        interventionId: '3f701cf4-3744-4fbd-bc81-81267b07a4d9',
        action: 'Created',
        timestamps: '2024-01-18T06:53:18.519Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '850deeac-0147-4556-ab79-c0b455ead412',
        interventionId: '3f701cf4-3744-4fbd-bc81-81267b07a4d9',
        action: 'Saved',
        timestamps: '2024-01-18T06:58:38.841Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Draft)',
    stateCode: 'I_1_0',
    engineer: null,
    engineerId: null,
    initiated: null,
    resolved: null,
    avatarLink: null,
    remoteCenter: null,
    remoteCenterId: null,
    disciplineName: null,
    createdAt: '2024-01-18T06:53:18.303Z',
    updatedAt: '2024-02-06T15:56:44.071Z',
    eventType: 'intervention',
    interval: null,
    run: null,
    isComplete: null,
  },
  {
    interventionId: '414a175f-588c-4511-8d2a-9e2d4f082fa5',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705565308198_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12',
    holeMD: '235',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV71',
      label: 'No Data Exchange',
      data: 'No Data Exchange',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Data Acquisition/No Data Exchange',
    },
    originalRisk:
      '[{"risk_id":"HSEH01","data":"Health incident","label":"Health incident"}]',
    risk: [
      {
        risk_id: 'HSEH01',
        label: 'Health incident',
        data: 'Health incident',
      },
    ],
    contactMethod: null,
    eventDescription: 'test q',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'hahahha',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1705564680000',
    rigTimestamps: '1705561080000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: false,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705564759198_log.json',
    auditTrail: [
      {
        logId: '1760b6d5-f0f6-4be5-8f13-8d444e0f5a67',
        interventionId: '414a175f-588c-4511-8d2a-9e2d4f082fa5',
        action: 'Saved',
        timestamps: '2024-01-18T08:08:25.592Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '3a96ac8c-ee9a-41e9-902c-2e74844f330c',
        interventionId: '414a175f-588c-4511-8d2a-9e2d4f082fa5',
        action: 'Created',
        timestamps: '2024-01-18T07:59:19.182Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '7b774eae-dbd3-45b8-97d6-f45d01250ba5',
        interventionId: '414a175f-588c-4511-8d2a-9e2d4f082fa5',
        action: 'Saved',
        timestamps: '2024-01-18T08:05:45.676Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'f84c0bcf-235b-4211-bbc7-a81241ab21ae',
        interventionId: '414a175f-588c-4511-8d2a-9e2d4f082fa5',
        action: 'Saved',
        timestamps: '2024-01-18T08:02:05.877Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Draft)',
    stateCode: 'I_1_0',
    engineer: null,
    engineerId: null,
    initiated: null,
    resolved: null,
    avatarLink: null,
    remoteCenter: null,
    remoteCenterId: null,
    disciplineName: null,
    createdAt: '2024-01-18T07:59:19.138Z',
    updatedAt: '2024-02-06T15:56:45.130Z',
    eventType: 'intervention',
    interval: null,
    run: null,
    isComplete: null,
  },
  {
    interventionId: '43605f54-7fc5-4da1-85e8-e1af1164d251',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706584668809_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12',
    holeMD: '13',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV01',
      label: 'Flowback Volume',
      data: 'Flowback Volume',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Volumetrics/Flowback Volume',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: null,
    eventDescription: 'test a',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation: 'test',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1706584560000',
    rigTimestamps: '1706580960000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: null,
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: '2024-01-30T04:02:56.477Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706584667485_log.json',
    auditTrail: [
      {
        logId: '65bfb0bf-499c-4320-80b0-21c41473c7c4',
        interventionId: '43605f54-7fc5-4da1-85e8-e1af1164d251',
        action: 'Sent',
        timestamps: '2024-01-30T03:17:56.511Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '9e003a1c-ae27-4f9a-8ef8-8e287276deec',
        interventionId: '43605f54-7fc5-4da1-85e8-e1af1164d251',
        action: 'Created',
        timestamps: '2024-01-30T03:17:47.473Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Completed)',
    stateCode: 'I_1_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-30T03:17:56.511Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-30T03:17:47.452Z',
    updatedAt: '2024-02-06T15:56:46.183Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '38187b2f-df00-430a-8975-ccf2cfae03e9',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test 1hahashagds 12/.23347"h',
      type: 'TYPE10',
      runNo: '12',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '23',
      startMeasuredDepth: '23',
      tdMeasuredDepth: '2,435',
      belowRotaryTimestamp: '10/17/2023, 10:13 PM',
      runTdTimestamp: '10/20/2023, 10:14 PM',
      aboveRotaryTimestamp: '10/22/2023, 10:14 PM',
      tallyUrl: null,
    },
    isComplete: null,
  },
  {
    interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1699407720871_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '32',
    holeMD: '235',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV71',
      label: 'No Data Exchange',
      data: 'No Data Exchange',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Data Acquisition/No Data Exchange',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"},{"risk_id":"ETSE02","data":"Casing / Tubing Running Equipment/Tools Failures","label":"Casing / Tubing Running Equipment/Tools Failures"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
      {
        risk_id: 'ETSE02',
        label: 'Casing / Tubing Running Equipment/Tools Failures',
        data: 'Casing / Tubing Running Equipment/Tools Failures',
      },
    ],
    contactMethod: '[{"id":"email","label":"Email","data":"Email"}]',
    eventDescription:
      'dfdjgkdfhlfgkljTesthttps://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api -winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation:
      'dfdjgkdfhlfgkljTesthttps://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api -winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: false,
    isRecommendedActionTaken: 'no',
    resolution:
      'We sent you this notification due to a default subscription. View | Unsubscribe\nMicrosoft respects your privacy. Review our Online Services Privacy Statement.\nOne Microsoft Way, Redmond, WA, USA 98052.\nSent from Azure DevOps We sent you this notification due to a default subscription. View | Unsubscribe\nMicrosoft respects your privacy. Review our Online Services Privacy Statement.\nOne Microsoft Way, Redmond, WA, USA 98052.\nSent from Azure DevOpsWe sent you this notification due to a default subscription. View | Unsubscribe\nMicrosoft respects your privacy. Review our Online Services Privacy Statement.\nOne Microsoft Way, Redmond, WA, USA 98052.\nSent from Azure DevOps We sent you this notification due to a default subscription. View | Unsubscribe\nMicrosoft respects your privacy. Review our Online Services Privacy Statement.\nOne Microsoft Way, Redmond, WA, USA 98052.\nSent from Azure DevOpsWe sent you this notification due to a default subscription. View | Unsubscribe\nMicrosoft respects your privacy. Review our Online Services Privacy Statement.\nOne Microsoft Way, Redmond, WA, USA 98052.\nSent from Azure DevOps We sent you this notification due to a default subscription. View | Unsubscribe\nMicrosoft respects your privacy. Review our Online Services Privacy Statement.\nOne Microsoft Way, Redmond, WA, USA 98052.\nSent from Azure DevOpsWe sent you this notification due to a default subscription. View | Unsubscribe\nMicrosoft respects your privacy. Review our Online Services Privacy Statement.\nOne Microsoft Way, Redmond, WA, USA 98052.\nSent from Azure DevOps We sent you this notification due to a default subscription. View | Unsubscribe\nMicrosoft respects your privacy. Review our Online Services Privacy Statement.\nOne Microsoft Way, Redmond, WA, USA 98052.\nSent from Azure DevOpsWe sent you this notification due to a default subscription. View | Unsubscribe\nMicrosoft respects your privacy. Review our Online Services Privacy Statement.',
    sendNotificationOfResolutionTotheSameDistribution: 'purple',
    rigBasedValidationDetail:
      'dfdjgkdfhlfgkljTesthttps://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api -winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-',
    timeSaved: 0,
    localTimestamps: '1699285560000',
    rigTimestamps: '1699285560000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'RESOLUTION',
    hasSent: true,
    timeExpire: '2023-11-06T16:32:47.109Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1699285656472_log.json',
    auditTrail: [
      {
        logId: '029fce85-de76-40c9-99be-300c193d99e4',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T08:54:39.372Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '08fda4c7-da87-4dd6-bd56-640dbc88a79f',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T09:23:02.059Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '0bce4985-c5f8-46df-9679-1e158c1e4708',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T07:10:47.526Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '0dce307b-c080-48e5-935d-6e35c0a0cd3c',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T12:30:53.454Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '0f355a8f-9f1a-486b-a3c3-0236a5c5da0c',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T11:10:07.348Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '11ab4134-3a96-45be-acda-ceb0838bd8c8',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T08:32:48.628Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '1442595f-b2dd-43a7-9109-b71fb0123754',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T11:34:44.211Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '171cca01-6b92-4b2b-8592-07d8192ef680',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T09:37:22.560Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '18436c21-0537-4313-8680-6ca621db0694',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T09:19:42.557Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '19a8530c-dda7-4170-a949-d3537d089baf',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T07:08:17.939Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '1ae7be8c-a494-463c-acaa-5d824dd4c327',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T11:25:21.163Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '1da98021-0488-450a-a625-3ca83a0cc909',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T11:02:08.060Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '226b4298-e611-4e4c-ad41-0a5d5f4667be',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T08:57:24.265Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '2586ed85-1e85-4dab-b2e9-a2e94fdeb29f',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T08:32:33.870Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '2766988d-b21a-43e0-81d7-633df4481427',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T08:38:43.930Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '28320431-ea81-4a9f-a5df-f39ffede1cae',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T08:42:32.148Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '297eeb80-e9dd-40f8-a8b5-38e6d355764b',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-06T16:55:42.234Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Marius Moldvaer',
        userId: '020f7316-f3a2-46c5-a511-3d5d6676cb84',
        userRemoteCenter: null,
      },
      {
        logId: '2bbf2624-f350-4fb5-8e73-9eb0a3643d28',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T08:59:18.922Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '2c65460e-5769-4b24-b7fe-f6790a48a71e',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T10:58:29.540Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '2de20ab3-7eb2-415d-ad01-08d6e9484837',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T09:23:32.692Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '3412f560-506b-4462-b8df-dff23d12bdf3',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T11:00:12.556Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '39017136-0e33-4c5f-9d7f-635e2ba5b34a',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T05:30:54.992Z',
        isFirstSent: true,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '393650d9-6a7a-481b-9ec3-a9e2ea7d0456',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T09:55:15.984Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '3cc88288-aa65-4e15-9c3b-8bca170f9eb1',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T05:29:23.695Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '42891ebb-e34a-48ff-b031-7cea002f70b8',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T07:17:09.269Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '43049461-2413-45eb-bdc1-839083f28a07',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T08:49:27.616Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '4319e4ef-0df6-4570-ae3a-65efe011faec',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T08:42:58.307Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '4708d1fc-46c5-4916-a5b2-4a54d12eab90',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T08:39:55.193Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '4e5afeb8-82f2-470c-90c2-aa5c2ad8184d',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T13:39:24.876Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Tuyet Nguyen',
        userId: 'bce7ddc8-1a4a-4a29-b1e8-ebf96f3433ec',
        userRemoteCenter: null,
      },
      {
        logId: '521fc0f0-df0e-4251-8c2d-0feb0299821c',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T08:47:28.607Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '52b53d11-10f7-408b-a716-247133c1f759',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T07:08:44.628Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '55d62594-83f9-4bab-b2b5-9807ca252d88',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-06T16:56:35.364Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Marius Moldvaer',
        userId: '020f7316-f3a2-46c5-a511-3d5d6676cb84',
        userRemoteCenter: null,
      },
      {
        logId: '5a6af42e-8df1-4897-bcaa-953172dac7d2',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T07:01:30.589Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '62ac21f5-70a4-497a-8cdb-1ae4e4ad3025',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T07:18:45.689Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '662d392c-876e-4157-a477-32a38e6bbbdd',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T11:27:23.594Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '665fc31b-7da9-4bce-9cf5-9e599e232a06',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T07:16:37.447Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '68801eb4-8c6c-4acd-902e-a97228ff629b',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T09:19:52.216Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '6fba9928-0be4-4376-b009-b5a50dbcaf8c',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T11:20:51.111Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '709a620d-39b4-4a94-a905-7dacd0014e38',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T08:34:18.755Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '73e66032-1c31-420e-a311-0bd0b7a4e4f5',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T07:10:29.702Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '73fcf774-1961-4a2a-bacf-4dbd7ce504cc',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T08:33:30.042Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '7578c437-cf45-4078-9aa1-a9d9f18d0aeb',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-08T01:42:09.442Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '7c8f865e-3d8c-457b-b920-73c4e8fb15e8',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T08:39:09.967Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '7cfa1097-9260-4578-a885-2d41c2a6fce2',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-08T01:41:18.694Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '7cfef747-9d30-4993-ab7e-030f306d1b83',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T08:48:37.380Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '7e8202eb-73ab-4011-b86c-9120c67d0ae0',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T08:34:10.120Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '7e9b6ba1-ac20-4e8f-b2df-797dcbcd3fab',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T08:47:07.052Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '86982e3f-8f3b-40c8-9199-fee76559d3f5',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T08:55:59.033Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '8a40cc1d-9692-4a27-b773-4f09d8b5c57e',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T09:04:06.065Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '8c7e3a26-8209-4de3-a62f-02f6e2550781',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T09:51:53.895Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '8f412bac-0476-4fb5-a6c2-83347a0a0f60',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T07:19:15.640Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '8f8ff21e-24b4-431b-96fe-758417fd4f19',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T08:43:34.460Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '8f9ec4d2-01ef-476e-abf8-d9c022773d06',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T08:33:17.093Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '90e383ff-37c4-4d33-aeb5-2a179688cd0a',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T08:54:21.034Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '95f7d354-27b6-4f04-bfad-cd1f1ab090a5',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-08T01:41:04.039Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '970cfc14-3805-44d8-8d9f-3f43c38c2760',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T09:52:06.593Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '993b3c12-f774-4c91-b5ef-39cc5f2d38c4',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T11:00:43.078Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '9991220d-f41b-4864-bcc6-ead92526cd48',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T10:58:42.020Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '9d762f66-028c-41fe-8888-2e31bea26a00',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-06T15:48:34.482Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'a5fab5c2-3e92-404d-9559-1b22b0459c6b',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T08:57:16.193Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: 'aa6026bf-c0ef-4022-87ec-2df247866958',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T11:24:52.436Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: 'ac11bf85-367c-4c1c-8e24-13073a9bf840',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T08:41:53.908Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'ac49ac40-29f2-4283-af0a-bd26fc9c106d',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T09:37:34.545Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'ac91c157-6f03-44d3-8768-c9c38b8e333e',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T03:56:27.429Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: 'adebcdad-c6a0-4221-8eb6-249398ae1dd1',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T11:34:34.555Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: 'b11ec6f2-f02f-4bfb-b459-54d925d5939c',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T08:35:14.502Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'b2bdc026-0120-4430-b19c-3726a60fac06',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Created',
        timestamps: '2023-11-06T15:47:36.452Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: 'bd02028b-c4ad-4bf0-8159-068589ff9f7d',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-06T15:47:47.168Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'bde2bc45-528f-4040-a7c2-65e41e6ea08a',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T07:15:51.210Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: 'bfe2c2b8-afd4-4732-8a14-60a46a488be7',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T11:09:16.992Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: 'c01d4c8b-6ac3-4628-a2f7-d2d556259fcb',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T12:30:42.554Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: 'c439ca07-be6f-4faa-80b2-aa9887029dfc',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T08:40:07.681Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'c4e5fa72-2ac3-4925-bd13-90a4e41af307',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T05:30:10.590Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'c6b3d3b8-a895-4a5a-8bea-fea92338dc03',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T09:39:22.891Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: 'c8b4c4b6-c2ea-4ada-98be-e8cabd8963c7',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T10:39:35.613Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: 'c8c85967-bfc3-40c5-8060-29e05a7aea95',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T09:03:56.900Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: 'c9b899b6-6460-4e97-a01b-39b5cef8cd52',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T09:01:37.183Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: 'ca6c3010-3133-4447-8be5-a254d674fd4c',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-08T01:41:57.570Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: 'cd347ed4-10ca-438a-8b81-ac4fe78bdea8',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T08:34:51.210Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'ce3e6d03-d2a2-4f2b-b9fe-4356c6e10dba',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T09:55:27.629Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'dac07748-99b1-42c3-bbd3-27b9da804cc5',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T08:58:20.579Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'dbc9488c-efdf-4656-a4a0-9a0d804e4fec',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T10:40:01.092Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'e18a9ccf-2e70-4963-8966-e16dbf535a6d',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T08:42:16.155Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'e4c2c903-b41e-4c04-a823-fc463789ffd6',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T08:56:28.523Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'eb85e094-5789-41c0-8f3e-f8db70a6cf08',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T09:40:11.515Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'ec5c9df0-256b-4955-a716-a43d38695ab2',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T11:28:04.962Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'ecf664ad-1390-446b-8508-b49968acf197',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T08:58:13.462Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: 'ee4eec9b-bc49-4b8f-861f-08f9a238c52f',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T09:00:31.228Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'f65b3d13-87f5-48d1-ae2e-09b8de02bd32',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Saved',
        timestamps: '2023-11-07T04:01:17.810Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: 'f6d98150-ef1f-4dd9-a83d-c5c67ad4e4b6',
        interventionId: '4643116b-d4e9-420b-ba94-85a925624f07',
        action: 'Sent',
        timestamps: '2023-11-07T11:21:12.464Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Resolve (Completed)',
    stateCode: 'R_1_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2023-11-06T15:47:47.168Z',
    resolved: '2023-11-07T05:30:54.992Z',
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2023-11-06T15:47:36.431Z',
    updatedAt: '2024-02-06T15:56:47.022Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '2edeeb68-d72f-4cda-b1cf-3b4f23a35d7d',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test',
      type: 'TYPE06',
      runNo: '111',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '12',
      startMeasuredDepth: '16',
      tdMeasuredDepth: '26',
      belowRotaryTimestamp: '10/02/2023, 10:16 PM',
      runTdTimestamp: '10/04/2023, 10:16 PM',
      aboveRotaryTimestamp: '10/11/2023, 10:16 PM',
      tallyUrl:
        'https://hpmvrodevscusstapp.blob.core.windows.net/tally/1698719909012_sample-file.pdf',
    },
    isComplete: null,
  },
  {
    interventionId: '49fc79b1-a453-4018-86e1-a02a9403154d',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706007749904_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '123',
    holeMD: '235,346',
    currentOperation:
      '{"operation_id":"OP07","data":"Tripping in with Cleanout Assembly","label":"Tripping in with Cleanout Assembly"}',
    event: {
      event_id: 'EV75',
      label: 'Loss of Communication',
      data: 'Loss of Communication',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Data Acquisition/Loss of Communication',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod:
      '[{"id":"phone","label":"Phone","data":"Phone"},{"contact_id":"C2","label":"C2 - Project Manager","data":"+1 832-123-4569"},{"contact_id":"C3","label":"C3 - Technical Advisor","data":"+1 832-123-4573"},{"contact_id":"C4","label":"C4 - Developer","data":"+1 132-434-6534"},{"contact_id":"E1","label":"E1 - Tester","data":"+1 832-123-4575"},{"contact_id":"E2","label":"E2 - Tester","data":"+1 832-123-4576"},{"contact_id":"E3","label":"E3 - Tester","data":"+1 123-253-4645"},{"contact_id":"E4","label":"E4 - Developer","data":"+1 123-253-4646"}]',
    eventDescription: 'hhha',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'test na',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: false,
    isRecommendedActionTaken: 'no',
    resolution: 'á',
    sendNotificationOfResolutionTotheSameDistribution: '',
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1706007660000',
    rigTimestamps: '1706004060000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'RESOLUTION',
    hasSent: false,
    timeExpire: '2024-01-23T11:47:46.356Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706007749124_log.json',
    auditTrail: [
      {
        logId: 'd0fc2cfb-9f56-49ba-95b6-9a843c224735',
        interventionId: '49fc79b1-a453-4018-86e1-a02a9403154d',
        action: 'Created',
        timestamps: '2024-01-23T11:02:29.095Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: 'efcb770a-38c3-46e0-83f0-30995702aece',
        interventionId: '49fc79b1-a453-4018-86e1-a02a9403154d',
        action: 'Saved',
        timestamps: '2024-01-23T11:03:59.442Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'f073b095-b38d-4a45-a5c8-6bab6dbb0f09',
        interventionId: '49fc79b1-a453-4018-86e1-a02a9403154d',
        action: 'Sent',
        timestamps: '2024-01-23T11:02:46.391Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Resolve (Draft)',
    stateCode: 'R_1_0',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-23T11:02:46.391Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-23T11:02:29.068Z',
    updatedAt: '2024-02-06T15:56:48.085Z',
    eventType: 'intervention',
    interval: {
      intervalId: 'e20a8970-8f5c-40a7-a627-dcc8c5039ae9',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: '1',
      unit: 'SI',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '51',
      openHoleDiameter: '51',
      lastShoeMeasuredDepth: '235.34',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '51057d99-7501-4cee-aab7-80a8e1aaae34',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: 'e20a8970-8f5c-40a7-a627-dcc8c5039ae9',
      description: '6',
      type: 'TYPE10',
      runNo: '6',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '6',
      startMeasuredDepth: '7',
      tdMeasuredDepth: '7',
      belowRotaryTimestamp: '10/28/2023, 10:40 AM',
      runTdTimestamp: '10/29/2023, 10:40 AM',
      aboveRotaryTimestamp: '10/30/2023, 10:40 AM',
      tallyUrl: null,
    },
    isComplete: null,
  },
  {
    interventionId: '4c0459f1-1777-4f39-aef8-c16a95f00876',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706082828432_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '123',
    holeMD: '235',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV71',
      label: 'No Data Exchange',
      data: 'No Data Exchange',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Data Acquisition/No Data Exchange',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"},{"risk_id":"ETSE02","data":"Casing / Tubing Running Equipment/Tools Failures","label":"Casing / Tubing Running Equipment/Tools Failures"}]',
    risk: [
      {
        risk_id: 'ETSE02',
        label: 'Casing / Tubing Running Equipment/Tools Failures',
        data: 'Casing / Tubing Running Equipment/Tools Failures',
      },
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: null,
    eventDescription: 'a',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'aa',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1706082600000',
    rigTimestamps: '1706079000000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: null,
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: '2024-01-24T08:38:58.022Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706082827083_log.json',
    auditTrail: [
      {
        logId: '28851447-45f7-49c5-829b-cd8948a2addc',
        interventionId: '4c0459f1-1777-4f39-aef8-c16a95f00876',
        action: 'Sent',
        timestamps: '2024-01-24T07:53:58.064Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '93f57067-e2ac-45fb-8052-da39a43af604',
        interventionId: '4c0459f1-1777-4f39-aef8-c16a95f00876',
        action: 'Created',
        timestamps: '2024-01-24T07:53:47.061Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Completed)',
    stateCode: 'I_1_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-24T07:53:58.064Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-24T07:53:47.011Z',
    updatedAt: '2024-02-06T15:56:48.295Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '2edeeb68-d72f-4cda-b1cf-3b4f23a35d7d',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test',
      type: 'TYPE06',
      runNo: '111',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '12',
      startMeasuredDepth: '16',
      tdMeasuredDepth: '26',
      belowRotaryTimestamp: '10/02/2023, 10:16 PM',
      runTdTimestamp: '10/04/2023, 10:16 PM',
      aboveRotaryTimestamp: '10/11/2023, 10:16 PM',
      tallyUrl:
        'https://hpmvrodevscusstapp.blob.core.windows.net/tally/1698719909012_sample-file.pdf',
    },
    isComplete: null,
  },
  {
    interventionId: '51fad1bb-2c81-41f5-8916-05a3b0ce8eb9',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706081945482_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12',
    holeMD: '123',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV71',
      label: 'No Data Exchange',
      data: 'No Data Exchange',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Data Acquisition/No Data Exchange',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: null,
    eventDescription: 'Test',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'ghjk',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1706081760000',
    rigTimestamps: '1706078160000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706081828941_log.json',
    auditTrail: [
      {
        logId: '164aa704-efa9-416c-a2c2-f3995dd5c4bd',
        interventionId: '51fad1bb-2c81-41f5-8916-05a3b0ce8eb9',
        action: 'Created',
        timestamps: '2024-01-24T07:37:08.923Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '501974ae-f0c9-44c2-8a9b-ae258e6a6239',
        interventionId: '51fad1bb-2c81-41f5-8916-05a3b0ce8eb9',
        action: 'Sent',
        timestamps: '2024-01-24T07:38:03.565Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '93f532cc-6165-4f93-a8ab-b3bd66824334',
        interventionId: '51fad1bb-2c81-41f5-8916-05a3b0ce8eb9',
        action: 'Saved',
        timestamps: '2024-01-24T07:39:04.690Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Draft)',
    stateCode: 'I_1_0',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-24T07:38:03.565Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-24T07:37:08.891Z',
    updatedAt: '2024-02-06T15:56:49.565Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '38187b2f-df00-430a-8975-ccf2cfae03e9',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test 1hahashagds 12/.23347"h',
      type: 'TYPE10',
      runNo: '12',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '23',
      startMeasuredDepth: '23',
      tdMeasuredDepth: '2,435',
      belowRotaryTimestamp: '10/17/2023, 10:13 PM',
      runTdTimestamp: '10/20/2023, 10:14 PM',
      aboveRotaryTimestamp: '10/22/2023, 10:14 PM',
      tallyUrl: null,
    },
    isComplete: null,
  },
  {
    interventionId: '54663132-128d-460a-9e43-ce5bf887616b',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706584089282_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12',
    holeMD: '13',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV53',
      label: 'Torque',
      data: 'Torque',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'BHA/Operating Limits/Torque',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: null,
    eventDescription: 'hhha',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation: 'jhh',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: 'jshhfg',
    timeSaved: null,
    localTimestamps: '1706584020000',
    rigTimestamps: '1706580420000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: null,
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: '2024-01-30T03:53:14.249Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706584087995_log.json',
    auditTrail: [
      {
        logId: '59244455-2871-4036-852f-6842128732d9',
        interventionId: '54663132-128d-460a-9e43-ce5bf887616b',
        action: 'Created',
        timestamps: '2024-01-30T03:08:07.974Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: 'ad612704-5b9d-4a38-92fa-18f73994f138',
        interventionId: '54663132-128d-460a-9e43-ce5bf887616b',
        action: 'Sent',
        timestamps: '2024-01-30T03:08:14.284Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Completed)',
    stateCode: 'I_1_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-30T03:08:14.284Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-30T03:08:07.923Z',
    updatedAt: '2024-02-06T15:56:49.989Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '38187b2f-df00-430a-8975-ccf2cfae03e9',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test 1hahashagds 12/.23347"h',
      type: 'TYPE10',
      runNo: '12',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '23',
      startMeasuredDepth: '23',
      tdMeasuredDepth: '2,435',
      belowRotaryTimestamp: '10/17/2023, 10:13 PM',
      runTdTimestamp: '10/20/2023, 10:14 PM',
      aboveRotaryTimestamp: '10/22/2023, 10:14 PM',
      tallyUrl: null,
    },
    isComplete: null,
  },
  {
    interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1699352691185_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1',
    holeMD: '1',
    currentOperation: '1',
    event: {
      event_id: 'EV04',
      label: 'Downhole Losses',
      data: 'Downhole Losses',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Well Control/Downhole Losses',
    },
    originalRisk: '1',
    risk: '1',
    contactMethod: '[{"id":"email","label":"Email","data":"Email"}]',
    eventDescription: '1',
    effectiveDiameter: null,
    flag: 'purple',
    unit: 'API',
    recommendation: '1',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: false,
    isRecommendedActionTaken: 'no',
    resolution:
      'dfdjgkdfhlfgkljTesthttps://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api -winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-4fee7b129e84https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/e28be639-7037-4663-847e-767ad87b1cb0/intervention/45584e28-5b57-494f-9862-',
    sendNotificationOfResolutionTotheSameDistribution: 'yellow',
    rigBasedValidationDetail: '1',
    timeSaved: 0,
    localTimestamps: '1698632460000',
    rigTimestamps: '1698632460000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
    createUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    status: 'RESOLUTION',
    hasSent: true,
    timeExpire: '2023-11-06T16:00:22.374Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: false,
    isCustomRisk: false,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1698632523241_log.json',
    auditTrail: [
      {
        logId: '03bebfcf-3e1e-43d6-9d07-26aea5d380fe',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Saved',
        timestamps: '2023-11-06T15:09:34.041Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '14d4c87a-5a17-4bfd-aba8-6324cd27eb7d',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Saved',
        timestamps: '2023-11-07T09:57:03.868Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '1795e608-3f24-496c-aab7-45b2d8902ef9',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Sent',
        timestamps: '2023-11-06T15:16:31.649Z',
        isFirstSent: true,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '1dca6901-c13a-499c-8bab-183523c133b0',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Saved',
        timestamps: '2023-11-06T15:16:06.220Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '3690521e-3de3-4494-96ce-29c2cd1a9476',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Saved',
        timestamps: '2023-11-07T10:01:42.169Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '44c2a4ad-574a-4b5f-aa35-1ab058242f0b',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Saved',
        timestamps: '2023-11-07T10:13:05.929Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '4b77aafa-877e-47ec-b7e7-180675ca908d',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Saved',
        timestamps: '2023-11-06T15:46:27.557Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '4bc6c744-f39f-40c6-8f18-126f6178ad27',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Saved',
        timestamps: '2023-11-06T15:15:07.221Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '4d3cfc86-d5b9-470b-8efa-1d2935be4297',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Sent',
        timestamps: '2023-11-06T15:15:22.405Z',
        isFirstSent: false,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '569a6758-6adb-4d27-a07f-a9af49a587cb',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Saved',
        timestamps: '2023-11-06T15:44:40.785Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '7f3700e4-3720-4303-b792-a9b1bc1b3a21',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Sent',
        timestamps: '2023-11-07T10:08:19.950Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '86efaee4-dc27-4bf6-aeb1-6edf205c6d8f',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Saved',
        timestamps: '2023-11-06T15:44:14.153Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '8d0d367e-c168-46c0-91e5-6fb5ad02485c',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Saved',
        timestamps: '2023-11-06T15:11:41.919Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '963bad5b-c43b-4235-97d6-fd8c30d7b103',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Sent',
        timestamps: '2023-11-02T04:38:59.789Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '9711fa73-f54f-4c1f-bd1b-7d57170a5815',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Saved',
        timestamps: '2023-11-06T15:34:49.415Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '98aee737-8815-4a0e-ba29-706c1df82b3d',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Saved',
        timestamps: '2023-11-06T15:40:31.602Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '9c8f8a35-2dcd-458e-8450-97e296821491',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Saved',
        timestamps: '2023-11-07T10:08:12.033Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '9dc6aa9a-b3f9-463c-b9f8-f4aabd367b85',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Created',
        timestamps: '2023-10-30T02:22:03.218Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: 'a14c7d3c-1683-4ffd-8b27-8f4052bf786f',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Sent',
        timestamps: '2023-11-07T10:01:51.391Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'b42a7987-a915-469e-8a78-7c3efa465cd2',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Saved',
        timestamps: '2023-11-06T15:35:40.097Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'b88e905a-fb34-416e-ab09-15455459345b',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Saved',
        timestamps: '2023-11-07T10:00:02.290Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: 'b950ed56-a6e0-4aae-9fe7-c477db13439a',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Saved',
        timestamps: '2023-11-02T04:38:50.314Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: 'bbbb3bfb-e43a-488a-9e72-1f17bbce4597',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Saved',
        timestamps: '2023-11-06T15:43:34.423Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'de3b7526-6a1e-424d-a466-ebf91ce1599c',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Saved',
        timestamps: '2023-11-06T15:39:04.422Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'ec2b2141-a016-439f-a001-f47ee210f3fd',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Saved',
        timestamps: '2023-11-07T10:24:49.160Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: 'ec34038c-29f4-4c74-8696-913d42a6e038',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Sent',
        timestamps: '2023-11-07T10:13:30.903Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'ee22bc1c-bfcf-4f7b-a395-46020c034ba3',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Sent',
        timestamps: '2023-11-07T09:57:13.847Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'ee933d63-f135-4ad0-8520-28d067fe6bd2',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Sent',
        timestamps: '2023-11-07T10:25:08.169Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'fdea1ec1-8fef-4e54-869f-c6d3d9969338',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Sent',
        timestamps: '2023-11-07T10:00:25.862Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'fe8c860d-da90-443d-993c-e5cd42cc4c15',
        interventionId: '5625f4cf-308e-4dd0-95cd-a71e5692b505',
        action: 'Saved',
        timestamps: '2023-11-06T15:11:01.912Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Resolve (Completed)',
    stateCode: 'R_1_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
    initiated: '2023-11-02T04:38:59.789Z',
    resolved: '2023-11-06T15:16:31.649Z',
    avatarLink: null,
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2023-10-30T02:22:03.179Z',
    updatedAt: '2024-02-06T15:56:50.837Z',
    eventType: 'intervention',
    interval: null,
    run: null,
    isComplete: null,
  },
  {
    interventionId: '56ef2c02-a28d-4884-bde7-293bc7c87e41',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705544341293_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1',
    holeMD: '23',
    currentOperation:
      '{"operation_id":"OP14","data":"Pulling out with Drilling Assembly","label":"Pulling out with Drilling Assembly"}',
    event: {
      event_id: 'EV71',
      label: 'No Data Exchange',
      data: 'No Data Exchange',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Data Acquisition/No Data Exchange',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: null,
    eventDescription: 'Test',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'hhh',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1705486020000',
    rigTimestamps: '1705482420000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: false,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705486141257_log.json',
    auditTrail: [
      {
        logId: '04717542-2919-4feb-b6d6-8061558e6501',
        interventionId: '56ef2c02-a28d-4884-bde7-293bc7c87e41',
        action: 'Saved',
        timestamps: '2024-01-18T01:07:01.224Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '302726ef-a071-4479-a1b4-361cf6acbd8e',
        interventionId: '56ef2c02-a28d-4884-bde7-293bc7c87e41',
        action: 'Saved',
        timestamps: '2024-01-18T01:31:21.612Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: 'd55c330d-23ef-4c4d-8c26-5d773a8dbddd',
        interventionId: '56ef2c02-a28d-4884-bde7-293bc7c87e41',
        action: 'Created',
        timestamps: '2024-01-17T10:09:01.241Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: 'e9300725-c18e-4926-8bda-dde7b3f79e44',
        interventionId: '56ef2c02-a28d-4884-bde7-293bc7c87e41',
        action: 'Saved',
        timestamps: '2024-01-17T11:07:08.236Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'f48a5392-0465-4b22-8a38-773878f6ebc7',
        interventionId: '56ef2c02-a28d-4884-bde7-293bc7c87e41',
        action: 'Saved',
        timestamps: '2024-01-18T02:18:57.359Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: 'fa3ab203-5ff4-49b3-b373-24aeacf13523',
        interventionId: '56ef2c02-a28d-4884-bde7-293bc7c87e41',
        action: 'Saved',
        timestamps: '2024-01-18T01:04:54.645Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Draft)',
    stateCode: 'I_1_0',
    engineer: null,
    engineerId: null,
    initiated: null,
    resolved: null,
    avatarLink: null,
    remoteCenter: null,
    remoteCenterId: null,
    disciplineName: null,
    createdAt: '2024-01-17T10:09:01.221Z',
    updatedAt: '2024-02-06T15:56:51.473Z',
    eventType: 'intervention',
    interval: {
      intervalId: 'e20a8970-8f5c-40a7-a627-dcc8c5039ae9',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: '1',
      unit: 'SI',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '51',
      openHoleDiameter: '51',
      lastShoeMeasuredDepth: '235.34',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '7368b53c-23cd-4397-83ef-f8bcc4d8d815',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: 'e20a8970-8f5c-40a7-a627-dcc8c5039ae9',
      description: '4',
      type: 'TYPE10',
      runNo: '4',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '4',
      startMeasuredDepth: '4',
      tdMeasuredDepth: '4',
      belowRotaryTimestamp: '10/27/2023, 10:23 AM',
      runTdTimestamp: '10/28/2023, 10:23 AM',
      aboveRotaryTimestamp: '10/30/2023, 10:23 AM',
      tallyUrl: null,
    },
    isComplete: null,
  },
  {
    interventionId: '5de6ebd6-a57b-42fe-adc5-932da50409be',
    interventionUrl: null,
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12',
    holeMD: '22',
    currentOperation:
      '{"operation_id":"OP01","data":"Drilling Ahead","label":"Drilling Ahead"}',
    event: {
      event_id: 'EV01',
      label: 'Flowback Volume',
      data: 'Flowback Volume',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Volumetrics/Flowback Volume',
    },
    originalRisk:
      '[{"risk_id":"BOPR01","data":"Unstable/problematic borehole conditions","label":"Unstable/problematic borehole conditions"}]',
    risk: [
      {
        risk_id: 'BOPR01',
        label: 'Unstable/problematic borehole conditions',
        data: 'Unstable/problematic borehole conditions',
      },
    ],
    contactMethod: null,
    eventDescription: '12',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation: '12',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '12',
    timeSaved: null,
    localTimestamps: '1704352080000',
    rigTimestamps: '1704348480000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: null,
    createUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    status: 'INITIATE',
    hasSent: false,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1704352152248_log.json',
    auditTrail: [
      {
        logId: '59cae75d-1bb9-4f92-ad14-966b6c8f5f40',
        interventionId: '5de6ebd6-a57b-42fe-adc5-932da50409be',
        action: 'Created',
        timestamps: '2024-01-04T07:09:12.230Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: null,
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Draft)',
    stateCode: 'I_1_0',
    engineer: null,
    engineerId: null,
    initiated: null,
    resolved: null,
    avatarLink: null,
    remoteCenter: null,
    remoteCenterId: null,
    disciplineName: null,
    createdAt: '2024-01-04T07:09:12.203Z',
    updatedAt: '2024-02-06T15:56:52.107Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: null,
    isComplete: null,
  },
  {
    interventionId: '5f705c27-294b-4eb1-aeaf-edc0123fa9dc',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1699407870456_N_TestJh_AlertReport.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '22',
    holeMD: '465',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV94',
      label: 'Daily Report',
      data: 'Daily Report',
      styleClass: 'lowestChild',
      eventType: 'report',
      template: ['default'],
      leaf: false,
      hierarchy: 'Reports/Daily Report',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: null,
    eventDescription: 'haha',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'test',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1699407660000',
    rigTimestamps: '1699407660000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: '2023-11-08T02:29:41.485Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1699407763941_log.json',
    auditTrail: [
      {
        logId: '1516edff-c193-4b09-ba50-b05e523fc4ff',
        interventionId: '5f705c27-294b-4eb1-aeaf-edc0123fa9dc',
        action: 'Created',
        timestamps: '2023-11-08T01:42:43.920Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '3e711b66-35ce-4570-903c-b6f34fb78851',
        interventionId: '5f705c27-294b-4eb1-aeaf-edc0123fa9dc',
        action: 'Marked completed',
        timestamps: '2023-11-14T08:25:29.768Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '80a64070-af51-48c0-a15c-5f91176be4bb',
        interventionId: '5f705c27-294b-4eb1-aeaf-edc0123fa9dc',
        action: 'Saved',
        timestamps: '2023-11-08T01:43:25.155Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '815c459c-15e8-4f23-a4a7-f35219e58798',
        interventionId: '5f705c27-294b-4eb1-aeaf-edc0123fa9dc',
        action: 'Sent',
        timestamps: '2023-11-08T01:43:36.599Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '8cf9366f-70d0-4406-8066-54661691ffeb',
        interventionId: '5f705c27-294b-4eb1-aeaf-edc0123fa9dc',
        action: 'Saved',
        timestamps: '2023-11-08T01:44:26.454Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'fb44283a-a732-4408-ad7e-29b5356f5e60',
        interventionId: '5f705c27-294b-4eb1-aeaf-edc0123fa9dc',
        action: 'Status reset',
        timestamps: '2023-11-14T08:25:37.283Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'ff62ae13-87d0-4d37-93b4-5c584721fb4b',
        interventionId: '5f705c27-294b-4eb1-aeaf-edc0123fa9dc',
        action: 'Sent',
        timestamps: '2023-11-08T01:44:41.515Z',
        isFirstSent: false,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Completed)',
    stateCode: 'I_1_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2023-11-08T01:43:36.599Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2023-11-08T01:42:43.900Z',
    updatedAt: '2024-02-06T15:56:52.534Z',
    eventType: 'report',
    interval: {
      intervalId: 'e20a8970-8f5c-40a7-a627-dcc8c5039ae9',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: '1',
      unit: 'SI',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '51',
      openHoleDiameter: '51',
      lastShoeMeasuredDepth: '235.34',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '51057d99-7501-4cee-aab7-80a8e1aaae34',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: 'e20a8970-8f5c-40a7-a627-dcc8c5039ae9',
      description: '6',
      type: 'TYPE10',
      runNo: '6',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '6',
      startMeasuredDepth: '7',
      tdMeasuredDepth: '7',
      belowRotaryTimestamp: '10/28/2023, 10:40 AM',
      runTdTimestamp: '10/29/2023, 10:40 AM',
      aboveRotaryTimestamp: '10/30/2023, 10:40 AM',
      tallyUrl: null,
    },
    isComplete: false,
  },
  {
    interventionId: '6313ee69-fdc8-404a-8d7f-a93ae9326371',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1703993562436_N_TestJh_AlertReport.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '20',
    holeMD: '99',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV94',
      label: 'Daily Report',
      data: 'Daily Report',
      styleClass: 'lowestChild',
      eventType: 'report',
      template: ['default'],
      leaf: false,
      hierarchy: 'Reports/Daily Report',
    },
    originalRisk:
      '[{"risk_id":"BOPR02","data":"Well Trajectory Correction","label":"Well Trajectory Correction"}]',
    risk: [
      {
        risk_id: 'BOPR02',
        label: 'Well Trajectory Correction',
        data: 'Well Trajectory Correction',
      },
    ],
    contactMethod: null,
    eventDescription: 'Daily Report: 31.12.2023',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation: 'Last day of the year 2023',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: 'Happy new year',
    timeSaved: null,
    localTimestamps: '1703993400000',
    rigTimestamps: '1703989800000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: null,
    createUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    status: 'INITIATE',
    hasSent: false,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1703993560056_log.json',
    auditTrail: [
      {
        logId: 'e12a875d-6676-4616-bd46-0ed99a9b6ac1',
        interventionId: '6313ee69-fdc8-404a-8d7f-a93ae9326371',
        action: 'Created',
        timestamps: '2023-12-31T03:32:40.024Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: null,
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Draft)',
    stateCode: 'I_1_0',
    engineer: null,
    engineerId: null,
    initiated: null,
    resolved: null,
    avatarLink: null,
    remoteCenter: null,
    remoteCenterId: null,
    disciplineName: null,
    createdAt: '2023-12-31T03:32:39.970Z',
    updatedAt: '2024-02-06T15:56:53.380Z',
    eventType: 'report',
    interval: {
      intervalId: 'e20a8970-8f5c-40a7-a627-dcc8c5039ae9',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: '1',
      unit: 'SI',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '51',
      openHoleDiameter: '51',
      lastShoeMeasuredDepth: '235.34',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '51057d99-7501-4cee-aab7-80a8e1aaae34',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: 'e20a8970-8f5c-40a7-a627-dcc8c5039ae9',
      description: '6',
      type: 'TYPE10',
      runNo: '6',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '6',
      startMeasuredDepth: '7',
      tdMeasuredDepth: '7',
      belowRotaryTimestamp: '10/28/2023, 10:40 AM',
      runTdTimestamp: '10/29/2023, 10:40 AM',
      aboveRotaryTimestamp: '10/30/2023, 10:40 AM',
      tallyUrl: null,
    },
    isComplete: null,
  },
  {
    interventionId: '69a9ca30-6de2-479d-b633-04936e43ad7e',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706583645883_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12',
    holeMD: '23',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV53',
      label: 'Torque',
      data: 'Torque',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'BHA/Operating Limits/Torque',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: null,
    eventDescription: 'test',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'hhh',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1706583540000',
    rigTimestamps: '1706579940000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: null,
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: false,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706583639478_log.json',
    auditTrail: [
      {
        logId: '87a1e87f-9d76-4be2-b702-273303266f48',
        interventionId: '69a9ca30-6de2-479d-b633-04936e43ad7e',
        action: 'Created',
        timestamps: '2024-01-30T03:00:39.455Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Draft)',
    stateCode: 'I_1_0',
    engineer: null,
    engineerId: null,
    initiated: null,
    resolved: null,
    avatarLink: null,
    remoteCenter: null,
    remoteCenterId: null,
    disciplineName: null,
    createdAt: '2024-01-30T03:00:39.406Z',
    updatedAt: '2024-02-06T15:56:55.282Z',
    eventType: 'intervention',
    interval: {
      intervalId: 'e20a8970-8f5c-40a7-a627-dcc8c5039ae9',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: '1',
      unit: 'SI',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '51',
      openHoleDiameter: '51',
      lastShoeMeasuredDepth: '235.34',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '51057d99-7501-4cee-aab7-80a8e1aaae34',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: 'e20a8970-8f5c-40a7-a627-dcc8c5039ae9',
      description: '6',
      type: 'TYPE10',
      runNo: '6',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '6',
      startMeasuredDepth: '7',
      tdMeasuredDepth: '7',
      belowRotaryTimestamp: '10/28/2023, 10:40 AM',
      runTdTimestamp: '10/29/2023, 10:40 AM',
      aboveRotaryTimestamp: '10/30/2023, 10:40 AM',
      tallyUrl: null,
    },
    isComplete: null,
  },
  {
    interventionId: '6b9f88da-e9d8-4baf-b9f3-afa9a0c4cd29',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1699442758590_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1',
    holeMD: '1',
    currentOperation: '1',
    event: {
      event_id: 'EV04',
      label: 'Downhole Losses',
      data: 'Downhole Losses',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Well Control/Downhole Losses',
    },
    originalRisk: '1',
    risk: '1',
    contactMethod: null,
    eventDescription: '1',
    effectiveDiameter: null,
    flag: 'yellow',
    unit: 'API',
    recommendation: '1',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '1',
    timeSaved: null,
    localTimestamps: '1699347060000',
    rigTimestamps: '1699347060000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
    createUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    status: 'INITIATE',
    hasSent: false,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: false,
    isCustomRisk: false,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1699347129286_log.json',
    auditTrail: [
      {
        logId: '48ccf3c2-db0c-415a-a03a-1b907837990f',
        interventionId: '6b9f88da-e9d8-4baf-b9f3-afa9a0c4cd29',
        action: 'Saved',
        timestamps: '2023-11-08T11:25:22.293Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '866b97a8-b52a-4726-8a2d-a6f6a3bcf3b9',
        interventionId: '6b9f88da-e9d8-4baf-b9f3-afa9a0c4cd29',
        action: 'Saved',
        timestamps: '2023-11-08T11:24:30.003Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '87295359-97a6-472a-aced-0c1bede64d1e',
        interventionId: '6b9f88da-e9d8-4baf-b9f3-afa9a0c4cd29',
        action: 'Created',
        timestamps: '2023-11-07T08:52:09.251Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: 'b45abe9a-6ffb-420e-8712-075fef80b295',
        interventionId: '6b9f88da-e9d8-4baf-b9f3-afa9a0c4cd29',
        action: 'Saved',
        timestamps: '2023-11-08T11:25:54.652Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Draft)',
    stateCode: 'I_1_0',
    engineer: null,
    engineerId: null,
    initiated: null,
    resolved: null,
    avatarLink: null,
    remoteCenter: null,
    remoteCenterId: null,
    disciplineName: null,
    createdAt: '2023-11-07T08:52:09.230Z',
    updatedAt: '2024-02-06T15:56:55.704Z',
    eventType: 'intervention',
    interval: null,
    run: null,
    isComplete: null,
  },
  {
    interventionId: '6e57d84f-62f1-4b5c-a915-605d46ed0665',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1704427325513_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '2',
    holeMD: '2',
    currentOperation: '2',
    event: {
      event_id: 'EV01',
      label: 'Flowback Volume',
      data: 'Flowback Volume',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Volumetrics/Flowback Volume',
    },
    originalRisk: '2',
    risk: '2',
    contactMethod:
      '[{"id":"phone","label":"Phone","data":"Phone"},{"contact_id":"C1","label":"C1 - Drilling Superintendent","data":"+1 832-123-4568"},{"contact_id":"C2","label":"C2 - Project Manager","data":"+1 832-123-4569"},{"contact_id":"C3","label":"C3 - Technical Advisor","data":"+1 832-123-4573"},{"contact_id":"C4","label":"C4 - Developer","data":"+1 132-434-6534"},{"contact_id":"E1","label":"E1 - Tester","data":"+1 832-123-4575"},{"contact_id":"E2","label":"E2 - Tester","data":"+1 832-123-4576"},{"contact_id":"E3","label":"E3 - Tester","data":"+1 123-253-4645"},{"contact_id":"E4","label":"E4 - Developer","data":"+1 123-253-4646"}]',
    eventDescription: '2',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation: '2',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: 'yes',
    resolution: '12',
    sendNotificationOfResolutionTotheSameDistribution: '',
    rigBasedValidationDetail: '2',
    timeSaved: 1,
    localTimestamps: '1704427080000',
    rigTimestamps: '1704423480000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    createUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    status: 'RESOLUTION',
    hasSent: false,
    timeExpire: '2024-01-05T04:45:36.771Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: false,
    isCustomRisk: false,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1704427143986_log.json',
    auditTrail: [
      {
        logId: '15efc0c4-6398-4f4b-8490-e2af114bcb85',
        interventionId: '6e57d84f-62f1-4b5c-a915-605d46ed0665',
        action: 'Status reset',
        timestamps: '2024-01-15T07:39:46.614Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '28e917a4-88e7-40ad-9087-c25587811fc7',
        interventionId: '6e57d84f-62f1-4b5c-a915-605d46ed0665',
        action: 'Created',
        timestamps: '2024-01-05T03:59:03.969Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '2c98ebf5-c7d0-4475-ae1c-4e2d8e445a35',
        interventionId: '6e57d84f-62f1-4b5c-a915-605d46ed0665',
        action: 'Saved',
        timestamps: '2024-01-05T04:02:04.642Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
      {
        logId: '4b4fd64b-d404-46f1-aaff-768cd2ebd7da',
        interventionId: '6e57d84f-62f1-4b5c-a915-605d46ed0665',
        action: 'Saved',
        timestamps: '2024-01-05T04:01:16.496Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
      {
        logId: '662d8116-cfa9-4a76-a8d6-3edae8eb76c9',
        interventionId: '6e57d84f-62f1-4b5c-a915-605d46ed0665',
        action: 'Sent',
        timestamps: '2024-01-05T04:00:36.815Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '6e7c1f95-a715-45b3-956d-058fc31ef232',
        interventionId: '6e57d84f-62f1-4b5c-a915-605d46ed0665',
        action: 'Marked completed',
        timestamps: '2024-01-05T08:44:44.756Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '873925ed-d641-48f6-9062-55c88cf69fd0',
        interventionId: '6e57d84f-62f1-4b5c-a915-605d46ed0665',
        action: 'Saved',
        timestamps: '2024-01-05T04:00:25.892Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
      {
        logId: 'c1a1c709-6458-440e-a8e6-4fe436971096',
        interventionId: '6e57d84f-62f1-4b5c-a915-605d46ed0665',
        action: 'Saved',
        timestamps: '2024-01-05T03:59:35.154Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Resolve (Draft)',
    stateCode: 'R_1_0',
    engineer: 'Thanh Nguyen Cong (TEST)',
    engineerId: '365dbd8b-3313-4833-8efb-ffc478695821',
    initiated: '2024-01-05T04:00:36.815Z',
    resolved: null,
    avatarLink: null,
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-05T03:59:03.957Z',
    updatedAt: '2024-02-06T15:56:55.914Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: null,
    isComplete: false,
  },
  {
    interventionId: '6f330e44-622f-4ef7-a769-944ea1949678',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706003677530_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1',
    holeMD: '2',
    currentOperation: '3',
    event: {
      event_id: 'EV01',
      label: 'Flowback Volume',
      data: 'Flowback Volume',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Volumetrics/Flowback Volume',
    },
    originalRisk: 'Check query',
    risk: 'Check query',
    contactMethod: null,
    eventDescription: 'Check query',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation: 'Check query',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: 'Check query',
    timeSaved: null,
    localTimestamps: '1706003580000',
    rigTimestamps: '1705999980000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: '2024-01-23T10:39:51.777Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: false,
    isCustomRisk: false,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706003676221_log.json',
    auditTrail: [
      {
        logId: '01e2b07f-aa0c-445e-a453-c3ef6d78b539',
        interventionId: '6f330e44-622f-4ef7-a769-944ea1949678',
        action: 'Sent',
        timestamps: '2024-01-23T09:54:51.821Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '4405565c-749e-4fa1-9119-fdc9ce9ac005',
        interventionId: '6f330e44-622f-4ef7-a769-944ea1949678',
        action: 'Created',
        timestamps: '2024-01-23T09:54:36.203Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '4cd0f531-99b2-4a2e-baf6-6d025a0deaab',
        interventionId: '6f330e44-622f-4ef7-a769-944ea1949678',
        action: 'Saved',
        timestamps: '2024-01-24T07:35:37.611Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '9b20cf0c-a64d-462f-ba4e-4409598d33e0',
        interventionId: '6f330e44-622f-4ef7-a769-944ea1949678',
        action: 'Saved',
        timestamps: '2024-01-24T03:57:12.941Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Completed)',
    stateCode: 'I_1_1',
    engineer: 'Thanh Nguyen Cong (TEST)',
    engineerId: '365dbd8b-3313-4833-8efb-ffc478695821',
    initiated: '2024-01-23T09:54:51.821Z',
    resolved: null,
    avatarLink: null,
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-23T09:54:36.180Z',
    updatedAt: '2024-02-06T15:56:56.337Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: null,
    isComplete: null,
  },
  {
    interventionId: '6fbc45b9-8a24-4c7c-b31d-6beecf6dc07d',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705413811652_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '2',
    holeMD: '22',
    currentOperation:
      '{"operation_id":"OP01","data":"Drilling Ahead","label":"Drilling Ahead"}',
    event: {
      event_id: 'EV53',
      label: 'Torque',
      data: 'Torque',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'BHA/Operating Limits/Torque',
    },
    originalRisk:
      '[{"risk_id":"HSEH01","data":"Health incident","label":"Health incident"}]',
    risk: [
      {
        risk_id: 'HSEH01',
        label: 'Health incident',
        data: 'Health incident',
      },
    ],
    contactMethod:
      '[{"id":"phone","label":"Phone","data":"Phone"},{"contact_id":"C2","label":"C2 - Project Manager","data":"+1 832-123-4569"},{"contact_id":"C3","label":"C3 - Technical Advisor","data":"+1 832-123-4573"},{"contact_id":"C4","label":"C4 - Developer","data":"+1 132-434-6534"},{"contact_id":"E1","label":"E1 - Tester","data":"+1 832-123-4575"},{"contact_id":"E2","label":"E2 - Tester","data":"+1 832-123-4576"},{"contact_id":"E3","label":"E3 - Tester","data":"+1 123-253-4645"},{"contact_id":"E4","label":"E4 - Developer","data":"+1 123-253-4646"}]',
    eventDescription: 'NEW T',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'NEW T',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: false,
    isRecommendedActionTaken: 'no',
    resolution:
      'home/rig/758b6495-8cb9-4705-a17a-2836b5fea42d/intervention/6fbc45b9-8a24-4c7c-b31d-6beecf6dc07d',
    sendNotificationOfResolutionTotheSameDistribution: '',
    rigBasedValidationDetail: 'NEW T',
    timeSaved: null,
    localTimestamps: '1705413300000',
    rigTimestamps: '1705409700000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
    status: 'RESOLUTION',
    hasSent: true,
    timeExpire: '2024-01-16T14:42:37.465Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705413361496_log.json',
    auditTrail: [
      {
        logId: '4a80a4c7-2538-4310-9578-45d1eee2ef4b',
        interventionId: '6fbc45b9-8a24-4c7c-b31d-6beecf6dc07d',
        action: 'Sent',
        timestamps: '2024-01-16T13:57:37.505Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '6785e901-a582-497c-99fe-8275d78d2d03',
        interventionId: '6fbc45b9-8a24-4c7c-b31d-6beecf6dc07d',
        action: 'Saved',
        timestamps: '2024-01-16T13:58:34.209Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '9c790c9a-5c38-49a5-b1eb-dbeb8fcd63e1',
        interventionId: '6fbc45b9-8a24-4c7c-b31d-6beecf6dc07d',
        action: 'Saved',
        timestamps: '2024-01-16T14:01:35.351Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '9cf763ef-c461-4e81-9487-8aac96233fc8',
        interventionId: '6fbc45b9-8a24-4c7c-b31d-6beecf6dc07d',
        action: 'Saved',
        timestamps: '2024-01-16T14:03:28.940Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'b6c41786-6248-476f-8223-563934ebea0f',
        interventionId: '6fbc45b9-8a24-4c7c-b31d-6beecf6dc07d',
        action: 'Saved',
        timestamps: '2024-01-16T14:02:18.252Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: 'be69675d-a373-4a53-8773-63190e2f09bf',
        interventionId: '6fbc45b9-8a24-4c7c-b31d-6beecf6dc07d',
        action: 'Created',
        timestamps: '2024-01-16T13:56:01.474Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: 'c0ee04d2-a320-4016-859e-3d8f9eca8028',
        interventionId: '6fbc45b9-8a24-4c7c-b31d-6beecf6dc07d',
        action: 'Saved',
        timestamps: '2024-01-16T14:00:59.855Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: 'c24feee6-a892-4882-8272-fe1674f14e59',
        interventionId: '6fbc45b9-8a24-4c7c-b31d-6beecf6dc07d',
        action: 'Saved',
        timestamps: '2024-01-16T13:57:24.990Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'e12a4e9e-4fa5-43be-a832-123f7423cc0a',
        interventionId: '6fbc45b9-8a24-4c7c-b31d-6beecf6dc07d',
        action: 'Saved',
        timestamps: '2024-01-16T13:59:34.761Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'e780943f-30bf-44f8-80e3-ad75c74be893',
        interventionId: '6fbc45b9-8a24-4c7c-b31d-6beecf6dc07d',
        action: 'Sent',
        timestamps: '2024-01-16T14:03:41.927Z',
        isFirstSent: true,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Resolve (Completed)',
    stateCode: 'R_1_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-16T13:57:37.505Z',
    resolved: '2024-01-16T14:03:41.927Z',
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-16T13:56:01.445Z',
    updatedAt: '2024-02-06T15:56:56.552Z',
    eventType: 'intervention',
    interval: null,
    run: null,
    isComplete: null,
  },
  {
    interventionId: '71d6a8b3-ff1a-463d-b9d6-dd988c6c5bca',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1699418734954_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12',
    holeMD: '24',
    currentOperation: '1',
    event: {
      event_id: 'EV71',
      label: 'No Data Exchange',
      data: 'No Data Exchange',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Data Acquisition/No Data Exchange',
    },
    originalRisk: '1',
    risk: '1',
    contactMethod:
      '[{"id":"email","label":"Email","data":"Email"},{"id":"phone","label":"Phone","data":"Phone"},{"contact_id":"C1","label":"C1 - Drilling Superintendent","data":"+1 832-123-4568"},{"contact_id":"C2","label":"C2 - Project Manager","data":"+1 832-123-4569"},{"contact_id":"C3","label":"C3 - Technical Advisor","data":"+1 832-123-4573"},{"contact_id":"C4","label":"C4 - Developer","data":"+1 132-434-6534"},{"contact_id":"E1","label":"E1 - Tester","data":"+1 832-123-4575"},{"contact_id":"E2","label":"E2 - Tester","data":"+1 832-123-4576"},{"contact_id":"E3","label":"E3 - Tester","data":"+1 123-253-4645"},{"contact_id":"E4","label":"E4 - Developer","data":"+1 123-253-4646"},{"id":"chat","label":"Chat","data":"Chat"}]',
    eventDescription: '1',
    effectiveDiameter: null,
    flag: 'purple',
    unit: 'API',
    recommendation: '1',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: false,
    isRecommendedActionTaken: 'no',
    resolution: 'Test ahjkl',
    sendNotificationOfResolutionTotheSameDistribution: 'purple',
    rigBasedValidationDetail: '1',
    timeSaved: 132,
    localTimestamps: '1698632520000',
    rigTimestamps: '1698632520000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
    createUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    status: 'RESOLUTION',
    hasSent: true,
    timeExpire: '2023-10-30T14:44:12.216Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: false,
    isCustomRisk: false,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1698632585063_log.json',
    auditTrail: [
      {
        logId: '057d5d09-914e-4d16-98c0-791dbc4241e6',
        interventionId: '71d6a8b3-ff1a-463d-b9d6-dd988c6c5bca',
        action: 'Saved',
        timestamps: '2023-10-30T11:14:54.309Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '0f6572f6-18d7-4f82-95a1-493e90e62935',
        interventionId: '71d6a8b3-ff1a-463d-b9d6-dd988c6c5bca',
        action: 'Created',
        timestamps: '2023-10-30T02:23:05.040Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '462b89a4-5f49-4733-a8b0-53866d8312a2',
        interventionId: '71d6a8b3-ff1a-463d-b9d6-dd988c6c5bca',
        action: 'Saved',
        timestamps: '2023-10-30T14:00:38.041Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '5661faa1-f02c-4ac5-b6fb-532cf84ab39c',
        interventionId: '71d6a8b3-ff1a-463d-b9d6-dd988c6c5bca',
        action: 'Sent',
        timestamps: '2023-10-30T14:01:05.864Z',
        isFirstSent: true,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '5cac05c9-17d1-4855-8d5c-21727cf9b240',
        interventionId: '71d6a8b3-ff1a-463d-b9d6-dd988c6c5bca',
        action: 'Saved',
        timestamps: '2023-11-08T03:00:57.267Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '74228293-d702-41fd-89d4-004b3c1e0a71',
        interventionId: '71d6a8b3-ff1a-463d-b9d6-dd988c6c5bca',
        action: 'Saved',
        timestamps: '2023-11-08T03:01:11.200Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '7751ee6a-64aa-43d2-9891-29014831cbed',
        interventionId: '71d6a8b3-ff1a-463d-b9d6-dd988c6c5bca',
        action: 'Saved',
        timestamps: '2023-11-08T04:45:30.949Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '7ec5bfdf-de3b-4bb4-b504-052c06bb47e6',
        interventionId: '71d6a8b3-ff1a-463d-b9d6-dd988c6c5bca',
        action: 'Saved',
        timestamps: '2023-10-30T13:58:12.824Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '858b9779-4fda-4d1d-a871-793d779d4796',
        interventionId: '71d6a8b3-ff1a-463d-b9d6-dd988c6c5bca',
        action: 'Saved',
        timestamps: '2023-10-30T13:59:00.112Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '87d44eac-73ed-46f8-ad61-926c51c50f82',
        interventionId: '71d6a8b3-ff1a-463d-b9d6-dd988c6c5bca',
        action: 'Sent',
        timestamps: '2023-10-30T13:59:12.247Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'b5f79c53-5817-4010-88fb-f54308f6af9e',
        interventionId: '71d6a8b3-ff1a-463d-b9d6-dd988c6c5bca',
        action: 'Sent',
        timestamps: '2023-11-08T04:46:04.541Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'eecf130e-8b8e-45cc-9af3-8e9e10d983e9',
        interventionId: '71d6a8b3-ff1a-463d-b9d6-dd988c6c5bca',
        action: 'Saved',
        timestamps: '2023-11-08T03:00:33.313Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Resolve (Completed)',
    stateCode: 'R_1_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2023-10-30T13:59:12.247Z',
    resolved: '2023-10-30T14:01:05.864Z',
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2023-10-30T02:23:05.023Z',
    updatedAt: '2024-02-06T15:56:57.188Z',
    eventType: 'intervention',
    interval: null,
    run: null,
    isComplete: null,
  },
  {
    interventionId: '73809feb-ba81-4cde-9841-cf034e41d4c7',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705408324795_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12',
    holeMD: '23',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV53',
      label: 'Torque',
      data: 'Torque',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'BHA/Operating Limits/Torque',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: '[{"id":"email","label":"Email","data":"Email"}]',
    eventDescription: 'Testing',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'Test',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: false,
    isRecommendedActionTaken: 'no',
    resolution: 'Test',
    sendNotificationOfResolutionTotheSameDistribution: '',
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1705407960000',
    rigTimestamps: '1705404360000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'RESOLUTION',
    hasSent: false,
    timeExpire: '2024-01-16T13:15:58.583Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705408247240_log.json',
    auditTrail: [
      {
        logId: '4da65d56-1e81-4af1-af49-f1f0c8b8f084',
        interventionId: '73809feb-ba81-4cde-9841-cf034e41d4c7',
        action: 'Sent',
        timestamps: '2024-01-16T12:30:58.620Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '68e64a40-2c2c-4419-bd8b-9aef5994aeea',
        interventionId: '73809feb-ba81-4cde-9841-cf034e41d4c7',
        action: 'Saved',
        timestamps: '2024-01-16T12:32:02.577Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '81d3810e-b872-4f72-a5f2-67d3c162f4a5',
        interventionId: '73809feb-ba81-4cde-9841-cf034e41d4c7',
        action: 'Created',
        timestamps: '2024-01-16T12:30:47.216Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Resolve (Draft)',
    stateCode: 'R_1_0',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-16T12:30:58.620Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-16T12:30:47.171Z',
    updatedAt: '2024-02-06T15:56:57.611Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: null,
    isComplete: null,
  },
  {
    interventionId: '74b557da-9758-419c-829c-2f6851f7fb38',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1704351407175_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1',
    holeMD: '2',
    currentOperation:
      '{"operation_id":"OP01","data":"Drilling Ahead","label":"Drilling Ahead"}',
    event: {
      event_id: 'EV01',
      label: 'Flowback Volume',
      data: 'Flowback Volume',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Volumetrics/Flowback Volume',
    },
    originalRisk:
      '[{"risk_id":"BOPR01","data":"Unstable/problematic borehole conditions","label":"Unstable/problematic borehole conditions"}]',
    risk: [
      {
        risk_id: 'BOPR01',
        label: 'Unstable/problematic borehole conditions',
        data: 'Unstable/problematic borehole conditions',
      },
    ],
    contactMethod: null,
    eventDescription: '12',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation: '12',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '12',
    timeSaved: null,
    localTimestamps: '1704351360000',
    rigTimestamps: '1704347760000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: null,
    createUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    status: 'INITIATE',
    hasSent: false,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1704351404682_log.json',
    auditTrail: [
      {
        logId: 'f4f10acb-ee5e-4910-b646-d147d3cdbf0c',
        interventionId: '74b557da-9758-419c-829c-2f6851f7fb38',
        action: 'Created',
        timestamps: '2024-01-04T06:56:44.667Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: null,
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Draft)',
    stateCode: 'I_1_0',
    engineer: null,
    engineerId: null,
    initiated: null,
    resolved: null,
    avatarLink: null,
    remoteCenter: null,
    remoteCenterId: null,
    disciplineName: null,
    createdAt: '2024-01-04T06:56:44.647Z',
    updatedAt: '2024-02-06T15:56:58.037Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: null,
    isComplete: null,
  },
  {
    interventionId: '778c744d-f6b3-470c-8611-0ce6e5aeffb2',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1704714539108_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '132',
    holeMD: '124,235',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV53',
      label: 'Torque',
      data: 'Torque',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'BHA/Operating Limits/Torque',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: null,
    eventDescription:
      '1. Test EnterIf a test (or a function called by the test) accepts a mutable argument, and then mutates it\n2. If a test (or a function called by the test) accepts a mutable argument, and then mutates it\n3. If a test (or a function called by the test) accepts a mutable argument, and then mutates it\n1. Test EnterIf a test (or a function called by the test) accepts a mutable argument, and then mutates it\n2. If a test (or a function called by the test) accepts a mutable argument, and then mutates it\n3. If a test (or a function called by the test) accepts a mutable argument, and then mutates it\n1. Test EnterIf a test (or a function called by the test) accepts a mutable argument, and then mutates it\n2. If a test (or a function called by the test) accepts a mutable argument, and then mutates',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation:
      '1. Test EnterIf a test (or a function called by the test) accepts a mutable argument, and then mutates it\n2. If a test (or a function called by the test) accepts a mutable argument, and then mutates it\n3. If a test (or a function called by the test) accepts a mutable argument, and then mutates it\n1. Test EnterIf a test (or a function called by the test) accepts a mutable argument, and then mutates it\n2. If a test (or a function called by the test) accepts a mutable argument, and then mutates it\n3. If a test (or a function called by the test) accepts a mutable argument, and then mutates it\n1. Test EnterIf a test (or a function called by the test) accepts a mutable argument, and then mutates it\n2. If a test (or a function called by the test) accepts a mutable argument, and then mutates',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1704714000000',
    rigTimestamps: '1704710400000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: false,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1704714383498_log.json',
    auditTrail: [
      {
        logId: '976358c2-29fd-4ccc-a462-2ddec7bcc3ca',
        interventionId: '778c744d-f6b3-470c-8611-0ce6e5aeffb2',
        action: 'Created',
        timestamps: '2024-01-08T11:46:23.487Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: 'd9c62bf7-9a13-49cd-864e-64590142117a',
        interventionId: '778c744d-f6b3-470c-8611-0ce6e5aeffb2',
        action: 'Saved',
        timestamps: '2024-01-08T11:48:56.272Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Draft)',
    stateCode: 'I_1_0',
    engineer: null,
    engineerId: null,
    initiated: null,
    resolved: null,
    avatarLink: null,
    remoteCenter: null,
    remoteCenterId: null,
    disciplineName: null,
    createdAt: '2024-01-08T11:46:23.460Z',
    updatedAt: '2024-02-06T15:56:59.537Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '2edeeb68-d72f-4cda-b1cf-3b4f23a35d7d',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test',
      type: 'TYPE06',
      runNo: '111',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '12',
      startMeasuredDepth: '16',
      tdMeasuredDepth: '26',
      belowRotaryTimestamp: '10/02/2023, 10:16 PM',
      runTdTimestamp: '10/04/2023, 10:16 PM',
      aboveRotaryTimestamp: '10/11/2023, 10:16 PM',
      tallyUrl:
        'https://hpmvrodevscusstapp.blob.core.windows.net/tally/1698719909012_sample-file.pdf',
    },
    isComplete: null,
  },
  {
    interventionId: '77e2aa55-9b0a-48c1-8172-6bc464140da3',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705388970169_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1',
    holeMD: '2',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV71',
      label: 'No Data Exchange',
      data: 'No Data Exchange',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Data Acquisition/No Data Exchange',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: '[{"id":"email","label":"Email","data":"Email"}]',
    eventDescription: 'New',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'hh',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: false,
    isRecommendedActionTaken: 'no',
    resolution: 'NEW',
    sendNotificationOfResolutionTotheSameDistribution: '',
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1705388880000',
    rigTimestamps: '1705385280000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'RESOLUTION',
    hasSent: false,
    timeExpire: '2024-01-16T07:55:00.097Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705388967074_log.json',
    auditTrail: [
      {
        logId: '0df3ab5d-8866-49e7-8361-6561446b790f',
        interventionId: '77e2aa55-9b0a-48c1-8172-6bc464140da3',
        action: 'Saved',
        timestamps: '2024-01-16T12:42:33.795Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '31a11fb5-7235-4f3f-b337-d329995c56b6',
        interventionId: '77e2aa55-9b0a-48c1-8172-6bc464140da3',
        action: 'Created',
        timestamps: '2024-01-16T07:09:27.046Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '3bd3b591-8c12-4d58-be77-7458c3e025b3',
        interventionId: '77e2aa55-9b0a-48c1-8172-6bc464140da3',
        action: 'Sent',
        timestamps: '2024-01-16T07:10:00.149Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '58770d16-fb24-46bc-8b21-22cd09e15ae9',
        interventionId: '77e2aa55-9b0a-48c1-8172-6bc464140da3',
        action: 'Saved',
        timestamps: '2024-01-16T12:48:31.777Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '7e8f117f-2fae-4dd3-9545-77397d73fdf4',
        interventionId: '77e2aa55-9b0a-48c1-8172-6bc464140da3',
        action: 'Saved',
        timestamps: '2024-01-16T12:50:32.399Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '9e8c8980-819d-4d98-b3df-cad0066f4053',
        interventionId: '77e2aa55-9b0a-48c1-8172-6bc464140da3',
        action: 'Saved',
        timestamps: '2024-01-16T12:41:22.746Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: 'b211479c-94a3-4fc4-b995-bee6ebde1bf1',
        interventionId: '77e2aa55-9b0a-48c1-8172-6bc464140da3',
        action: 'Saved',
        timestamps: '2024-01-16T12:46:17.747Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Resolve (Draft)',
    stateCode: 'R_1_0',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-16T07:10:00.149Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-16T07:09:27.012Z',
    updatedAt: '2024-02-06T15:57:00.171Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '2edeeb68-d72f-4cda-b1cf-3b4f23a35d7d',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test',
      type: 'TYPE06',
      runNo: '111',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '12',
      startMeasuredDepth: '16',
      tdMeasuredDepth: '26',
      belowRotaryTimestamp: '10/02/2023, 10:16 PM',
      runTdTimestamp: '10/04/2023, 10:16 PM',
      aboveRotaryTimestamp: '10/11/2023, 10:16 PM',
      tallyUrl:
        'https://hpmvrodevscusstapp.blob.core.windows.net/tally/1698719909012_sample-file.pdf',
    },
    isComplete: null,
  },
  {
    interventionId: '7a21707d-ab1c-46ff-a8c4-cd81acd6aa32',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705426291012_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12',
    holeMD: '22',
    currentOperation:
      '{"operation_id":"OP01","data":"Drilling Ahead","label":"Drilling Ahead"}',
    event: {
      event_id: 'EV53',
      label: 'Torque',
      data: 'Torque',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'BHA/Operating Limits/Torque',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod:
      '[{"id":"email","label":"Email","data":"Email"},{"id":"phone","label":"Phone","data":"Phone"},{"contact_id":"C2","label":"C2 - Project Manager","data":"+1 832-123-4569"},{"contact_id":"C3","label":"C3 - Technical Advisor","data":"+1 832-123-4573"},{"contact_id":"C4","label":"C4 - Developer","data":"+1 132-434-6534"},{"contact_id":"E1","label":"E1 - Tester","data":"+1 832-123-4575"},{"contact_id":"E2","label":"E2 - Tester","data":"+1 832-123-4576"},{"contact_id":"E3","label":"E3 - Tester","data":"+1 123-253-4645"},{"contact_id":"E4","label":"E4 - Developer","data":"+1 123-253-4646"}]',
    eventDescription: 'tue Test edit (1)',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'test',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: false,
    isRecommendedActionTaken: 'no',
    resolution: 'Test edit 16/01 (1)',
    sendNotificationOfResolutionTotheSameDistribution: '',
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1705414140000',
    rigTimestamps: '1705410540000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'RESOLUTION',
    hasSent: false,
    timeExpire: '2024-01-16T14:56:04.618Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705414257331_log.json',
    auditTrail: [
      {
        logId: '127ee148-810b-4cf4-a461-5e247b6e2fea',
        interventionId: '7a21707d-ab1c-46ff-a8c4-cd81acd6aa32',
        action: 'Saved',
        timestamps: '2024-01-16T14:21:13.489Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Tue Nguyen Minh',
        userId: '59d90418-7015-4036-91b7-f5635f5e4c3a',
        userRemoteCenter: null,
      },
      {
        logId: '1894396e-5f31-41aa-b439-921265e7379d',
        interventionId: '7a21707d-ab1c-46ff-a8c4-cd81acd6aa32',
        action: 'Created',
        timestamps: '2024-01-16T14:10:57.296Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '18b5c00f-b030-48cc-ac8e-2812fb04ab40',
        interventionId: '7a21707d-ab1c-46ff-a8c4-cd81acd6aa32',
        action: 'Saved',
        timestamps: '2024-01-16T14:12:13.598Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '23f4f472-acff-47e6-adcb-d5843a90163a',
        interventionId: '7a21707d-ab1c-46ff-a8c4-cd81acd6aa32',
        action: 'Saved',
        timestamps: '2024-01-16T14:22:43.100Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '2fb97d1a-31e5-4712-ac46-c8081596a903',
        interventionId: '7a21707d-ab1c-46ff-a8c4-cd81acd6aa32',
        action: 'Saved',
        timestamps: '2024-01-16T14:49:40.637Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Tue Nguyen Minh',
        userId: '59d90418-7015-4036-91b7-f5635f5e4c3a',
        userRemoteCenter: null,
      },
      {
        logId: '43855956-1fe2-4067-802d-be87490ae90a',
        interventionId: '7a21707d-ab1c-46ff-a8c4-cd81acd6aa32',
        action: 'Saved',
        timestamps: '2024-01-17T07:15:42.186Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '5340473d-002e-48d8-a385-39995b5f9800',
        interventionId: '7a21707d-ab1c-46ff-a8c4-cd81acd6aa32',
        action: 'Saved',
        timestamps: '2024-01-16T17:33:58.156Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Marius Moldvaer',
        userId: '020f7316-f3a2-46c5-a511-3d5d6676cb84',
        userRemoteCenter: null,
      },
      {
        logId: '9338abc7-a00f-4ebf-84da-09f635c8c82b',
        interventionId: '7a21707d-ab1c-46ff-a8c4-cd81acd6aa32',
        action: 'Saved',
        timestamps: '2024-01-16T17:33:28.026Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Marius Moldvaer',
        userId: '020f7316-f3a2-46c5-a511-3d5d6676cb84',
        userRemoteCenter: null,
      },
      {
        logId: '9621cbb8-909b-4bbe-8051-8ce2d0ba6026',
        interventionId: '7a21707d-ab1c-46ff-a8c4-cd81acd6aa32',
        action: 'Saved',
        timestamps: '2024-01-16T14:13:22.948Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '9b263fc9-c2d4-4764-af2e-d154a7579f2d',
        interventionId: '7a21707d-ab1c-46ff-a8c4-cd81acd6aa32',
        action: 'Saved',
        timestamps: '2024-01-16T14:29:10.902Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Tue Nguyen Minh',
        userId: '59d90418-7015-4036-91b7-f5635f5e4c3a',
        userRemoteCenter: null,
      },
      {
        logId: 'b1b1d347-afe2-4b0c-b83b-881f271d038c',
        interventionId: '7a21707d-ab1c-46ff-a8c4-cd81acd6aa32',
        action: 'Sent',
        timestamps: '2024-01-16T14:11:04.654Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'e1ca517c-9366-4e1c-b06f-1c2133f8c7d1',
        interventionId: '7a21707d-ab1c-46ff-a8c4-cd81acd6aa32',
        action: 'Saved',
        timestamps: '2024-01-16T17:31:30.556Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Marius Moldvaer',
        userId: '020f7316-f3a2-46c5-a511-3d5d6676cb84',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Resolve (Draft)',
    stateCode: 'R_1_0',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-16T14:11:04.654Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-16T14:10:57.276Z',
    updatedAt: '2024-02-06T15:57:00.819Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: null,
    isComplete: null,
  },
  {
    interventionId: '7b34f3a2-50d4-4b28-a349-6768e1b3cca1',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706069549882_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1',
    holeMD: '2',
    currentOperation: '3',
    event: {
      event_id: 'EV01',
      label: 'Flowback Volume',
      data: 'Flowback Volume',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Volumetrics/Flowback Volume',
    },
    originalRisk: 'BUG Updating',
    risk: 'BUG Updating',
    contactMethod: null,
    eventDescription: 'BUG Updating',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation: 'BUG Updating',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: 'BUG Updating',
    timeSaved: null,
    localTimestamps: '1706069340000',
    rigTimestamps: '1706065740000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    createUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: '2024-01-24T04:57:37.778Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: false,
    isCustomRisk: false,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706069420002_log.json',
    auditTrail: [
      {
        logId: '4a7636ea-deaf-453b-86c0-518eeb3f9bc5',
        interventionId: '7b34f3a2-50d4-4b28-a349-6768e1b3cca1',
        action: 'Created',
        timestamps: '2024-01-24T04:10:19.982Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: 'b59ead3e-9786-43c0-86aa-d9cbbcaedbc4',
        interventionId: '7b34f3a2-50d4-4b28-a349-6768e1b3cca1',
        action: 'Saved',
        timestamps: '2024-01-24T04:12:27.970Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
      {
        logId: 'fe9074d8-698a-4a86-b15e-26aae9ccf46c',
        interventionId: '7b34f3a2-50d4-4b28-a349-6768e1b3cca1',
        action: 'Sent',
        timestamps: '2024-01-24T04:12:37.819Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: 'VRO Testing',
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Completed)',
    stateCode: 'I_1_1',
    engineer: 'Thanh Nguyen Cong (TEST)',
    engineerId: '365dbd8b-3313-4833-8efb-ffc478695821',
    initiated: '2024-01-24T04:12:37.819Z',
    resolved: null,
    avatarLink: null,
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-24T04:10:19.960Z',
    updatedAt: '2024-02-06T15:57:01.241Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: null,
    isComplete: null,
  },
  {
    interventionId: '7beb4e45-b4fa-435b-9c45-d3bd257130d7',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1699444054905_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '124235.467',
    runNumber: '23',
    bitMD: '12',
    holeMD: '23',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV71',
      label: 'No Data Exchange',
      data: 'No Data Exchange',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Data Acquisition/No Data Exchange',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: '[{"id":"email","label":"Email","data":"Email"}]',
    eventDescription: 'testyukl;',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'nzbsdh',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: 'no',
    resolution: 'sdfgh',
    sendNotificationOfResolutionTotheSameDistribution: '',
    rigBasedValidationDetail: '',
    timeSaved: 0,
    localTimestamps: '1699443900000',
    rigTimestamps: '1699443900000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'RESOLUTION',
    hasSent: true,
    timeExpire: '2023-11-08T12:31:45.630Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1699443989747_log.json',
    auditTrail: [
      {
        logId: '48b02765-c97b-41d0-93bb-c0f3bc3524bb',
        interventionId: '7beb4e45-b4fa-435b-9c45-d3bd257130d7',
        action: 'Sent',
        timestamps: '2023-11-08T11:46:45.660Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '52c7804b-c61c-4de2-9774-390b4046ae47',
        interventionId: '7beb4e45-b4fa-435b-9c45-d3bd257130d7',
        action: 'Saved',
        timestamps: '2023-11-08T11:47:33.993Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '82dc2bd5-4170-4824-8599-e80eb026747d',
        interventionId: '7beb4e45-b4fa-435b-9c45-d3bd257130d7',
        action: 'Created',
        timestamps: '2023-11-08T11:46:29.722Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '8fb37329-2a54-4879-803c-7a701dd71efa',
        interventionId: '7beb4e45-b4fa-435b-9c45-d3bd257130d7',
        action: 'Sent',
        timestamps: '2023-11-08T11:47:39.677Z',
        isFirstSent: true,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'cb4d62d6-b5c6-41b4-acf3-dbc9f21be0bb',
        interventionId: '7beb4e45-b4fa-435b-9c45-d3bd257130d7',
        action: 'Saved',
        timestamps: '2023-11-08T11:47:18.912Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Resolve (Completed)',
    stateCode: 'R_1_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2023-11-08T11:46:45.660Z',
    resolved: '2023-11-08T11:47:39.677Z',
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2023-11-08T11:46:29.698Z',
    updatedAt: '2024-02-06T15:57:01.455Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '2edeeb68-d72f-4cda-b1cf-3b4f23a35d7d',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test',
      type: 'TYPE06',
      runNo: '111',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '12',
      startMeasuredDepth: '16',
      tdMeasuredDepth: '26',
      belowRotaryTimestamp: '10/02/2023, 10:16 PM',
      runTdTimestamp: '10/04/2023, 10:16 PM',
      aboveRotaryTimestamp: '10/11/2023, 10:16 PM',
      tallyUrl:
        'https://hpmvrodevscusstapp.blob.core.windows.net/tally/1698719909012_sample-file.pdf',
    },
    isComplete: null,
  },
  {
    interventionId: '80d44ecf-5bcd-4c5e-87f2-716a6f2d5ccd',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1703592898511_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12.24',
    holeMD: '34.35',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV01',
      label: 'Flowback Volume',
      data: 'Flowback Volume',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Volumetrics/Flowback Volume',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"},{"risk_id":"ETSE02","data":"Casing / Tubing Running Equipment/Tools Failures","label":"Casing / Tubing Running Equipment/Tools Failures"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
      {
        risk_id: 'ETSE02',
        label: 'Casing / Tubing Running Equipment/Tools Failures',
        data: 'Casing / Tubing Running Equipment/Tools Failures',
      },
    ],
    contactMethod: null,
    eventDescription: 'Test A',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'hhhh',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1703590860000',
    rigTimestamps: '1703587260000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: '2023-12-26T13:00:15.670Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1703590956067_log.json',
    auditTrail: [
      {
        logId: '0a331edc-33e7-48ae-8d9b-f838a6440386',
        interventionId: '80d44ecf-5bcd-4c5e-87f2-716a6f2d5ccd',
        action: 'Saved',
        timestamps: '2023-12-26T12:14:56.642Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '89b91e74-a4fd-4d63-b56b-ea106387be62',
        interventionId: '80d44ecf-5bcd-4c5e-87f2-716a6f2d5ccd',
        action: 'Saved',
        timestamps: '2023-12-26T11:43:58.728Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '8eae7772-d7e1-43be-8eca-235fbf7b8d3f',
        interventionId: '80d44ecf-5bcd-4c5e-87f2-716a6f2d5ccd',
        action: 'Marked completed',
        timestamps: '2023-12-27T03:41:48.640Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Tuyet Nguyen',
        userId: 'bce7ddc8-1a4a-4a29-b1e8-ebf96f3433ec',
        userRemoteCenter: null,
      },
      {
        logId: '9ce1488e-036c-487d-ad6b-73b9a0d2ff55',
        interventionId: '80d44ecf-5bcd-4c5e-87f2-716a6f2d5ccd',
        action: 'Sent',
        timestamps: '2023-12-26T12:15:15.725Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'ad8b47c1-bb6d-40b9-acc0-e1bd2079494f',
        interventionId: '80d44ecf-5bcd-4c5e-87f2-716a6f2d5ccd',
        action: 'Created',
        timestamps: '2023-12-26T11:42:36.032Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: 'ed10ae20-5d76-43b7-b358-3e3a988959b9',
        interventionId: '80d44ecf-5bcd-4c5e-87f2-716a6f2d5ccd',
        action: 'Saved',
        timestamps: '2023-12-26T11:44:47.872Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: {
      chatId: '2559c984-df3d-447f-a0f3-09d1512acab9',
      content: 'sđasd',
      interventionId: '80d44ecf-5bcd-4c5e-87f2-716a6f2d5ccd',
      userId: '59d90418-7015-4036-91b7-f5635f5e4c3a',
      attachments: [],
      nominateInformation: [
        {
          userId: '59d90418-7015-4036-91b7-f5635f5e4c3a',
          userDisplayName: 'Tue Nguyen Minh',
          userRole: 'Admin',
          timestamps: 1703648190846,
          mail: 'Tue.NguyenMinh@halliburton.com',
        },
        {
          userId: 'bce7ddc8-1a4a-4a29-b1e8-ebf96f3433ec',
          userDisplayName: 'Tuyet Nguyen',
          userRole: 'Admin',
          timestamps: 1703648680127,
          mail: 'Tuyet.Nguyen@halliburton.com',
        },
      ],
      nominateAt: '1703648190846',
      nominateVote: 2,
      createdAt: '2023-12-27T03:36:30.321Z',
      updatedAt: '2023-12-27T03:44:40.127Z',
    },
    currentState: 'Initiate (Completed)',
    stateCode: 'I_0_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2023-12-26T12:15:15.725Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2023-12-26T11:42:35.999Z',
    updatedAt: '2024-02-06T15:57:03.564Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '2edeeb68-d72f-4cda-b1cf-3b4f23a35d7d',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test',
      type: 'TYPE06',
      runNo: '111',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '12',
      startMeasuredDepth: '16',
      tdMeasuredDepth: '26',
      belowRotaryTimestamp: '10/02/2023, 10:16 PM',
      runTdTimestamp: '10/04/2023, 10:16 PM',
      aboveRotaryTimestamp: '10/11/2023, 10:16 PM',
      tallyUrl:
        'https://hpmvrodevscusstapp.blob.core.windows.net/tally/1698719909012_sample-file.pdf',
    },
    isComplete: true,
  },
  {
    interventionId: '82a271ac-ce75-4db7-a7c1-79947983bd89',
    interventionUrl: null,
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1',
    holeMD: '1',
    currentOperation: '1',
    event: {
      event_id: 'EV01',
      label: 'Flowback Volume',
      data: 'Flowback Volume',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Volumetrics/Flowback Volume',
    },
    originalRisk: '1',
    risk: '1',
    contactMethod: null,
    eventDescription: '1',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation: '1',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '1',
    timeSaved: null,
    localTimestamps: '1704856020000',
    rigTimestamps: '1704852420000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: null,
    createUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    status: 'INITIATE',
    hasSent: false,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: false,
    isCustomRisk: false,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1704856085805_log.json',
    auditTrail: [
      {
        logId: '33e18669-aa7f-4976-9287-9efa46e07e84',
        interventionId: '82a271ac-ce75-4db7-a7c1-79947983bd89',
        action: 'Created',
        timestamps: '2024-01-10T03:08:05.782Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: null,
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Draft)',
    stateCode: 'I_1_0',
    engineer: null,
    engineerId: null,
    initiated: null,
    resolved: null,
    avatarLink: null,
    remoteCenter: null,
    remoteCenterId: null,
    disciplineName: null,
    createdAt: '2024-01-10T03:08:05.757Z',
    updatedAt: '2024-02-06T15:57:03.992Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: null,
    isComplete: null,
  },
  {
    interventionId: '83039afd-157f-4c2f-99a4-083d44f94853',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706625787296_N_TestJh_AlertRoadmap.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '22',
    holeMD: '23',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV97',
      label: 'Trip Schedule',
      data: 'Trip Schedule',
      styleClass: 'lowestChild',
      eventType: 'report',
      template: ['roadmap'],
      leaf: false,
      hierarchy: 'Reports/Trip Schedule',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: null,
    eventDescription: 'test 30/1',
    effectiveDiameter: '',
    flag: 'blue',
    unit: 'API',
    recommendation: 'test 30/1',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: false,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: 'test 30/1',
    timeSaved: null,
    localTimestamps: '1706625480000',
    rigTimestamps: '1706621880000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
    createUserId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
    status: 'INITIATE',
    hasSent: false,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706625644765_log.json',
    auditTrail: [
      {
        logId: '78ff6386-df35-43ea-bfa2-c3ed7cda106a',
        interventionId: '83039afd-157f-4c2f-99a4-083d44f94853',
        action: 'Created',
        timestamps: '2024-01-30T14:40:44.743Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: 'fafbc626-377a-4a6b-980a-55bdb1e931ae',
        interventionId: '83039afd-157f-4c2f-99a4-083d44f94853',
        action: 'Saved',
        timestamps: '2024-01-30T14:42:58.393Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Draft)',
    stateCode: 'I_0_0',
    engineer: null,
    engineerId: null,
    initiated: null,
    resolved: null,
    avatarLink: null,
    remoteCenter: null,
    remoteCenterId: null,
    disciplineName: null,
    createdAt: '2024-01-30T14:40:44.714Z',
    updatedAt: '2024-02-06T15:57:04.204Z',
    eventType: 'report',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '38187b2f-df00-430a-8975-ccf2cfae03e9',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test 1hahashagds 12/.23347"h',
      type: 'TYPE10',
      runNo: '12',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '23',
      startMeasuredDepth: '23',
      tdMeasuredDepth: '2,435',
      belowRotaryTimestamp: '10/17/2023, 10:13 PM',
      runTdTimestamp: '10/20/2023, 10:14 PM',
      aboveRotaryTimestamp: '10/22/2023, 10:14 PM',
      tallyUrl: null,
    },
    isComplete: null,
  },
  {
    interventionId: '85575cb8-ebef-4bc3-925c-07c3733ca7c7',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706603203297_N_TestJh_AlertRoadmap.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12',
    holeMD: '13',
    currentOperation:
      '{"operation_id":"OP01","data":"Drilling Ahead","label":"Drilling Ahead"}',
    event: {
      event_id: 'EV97',
      label: 'Trip Schedule',
      data: 'Trip Schedule',
      styleClass: 'lowestChild',
      eventType: 'report',
      template: ['roadmap'],
      leaf: false,
      hierarchy: 'Reports/Trip Schedule',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"},{"risk_id":"ETSE02","data":"Casing / Tubing Running Equipment/Tools Failures","label":"Casing / Tubing Running Equipment/Tools Failures"},{"risk_id":"ETSE03","data":"Tubulars Related Failures / Damage","label":"Tubulars Related Failures / Damage"},{"risk_id":"ETSE05","data":"Drill String Failure (DP, DC, JARS, STB, NB, ETC)","label":"Drill String Failure (DP, DC, JARS, STB, NB, ETC)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
      {
        risk_id: 'ETSE03',
        label: 'Tubulars Related Failures / Damage',
        data: 'Tubulars Related Failures / Damage',
      },
      {
        risk_id: 'ETSE02',
        label: 'Casing / Tubing Running Equipment/Tools Failures',
        data: 'Casing / Tubing Running Equipment/Tools Failures',
      },
      {
        risk_id: 'ETSE05',
        label: 'Drill String Failure (DP, DC, JARS, STB, NB, ETC)',
        data: 'Drill String Failure (DP, DC, JARS, STB, NB, ETC)',
      },
    ],
    contactMethod: null,
    eventDescription:
      'https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/758b6495-8cb9-4705-a17a-2836b5fea42d\nhttps://hpm-vro-dev-scus-app-api-winterfell.azure\n\nwebsites.net/home/rig/758b6495-8cb9-4705-a17a-2836b5fea42d\nhttps://hpm-vro-dev-scus-app-api-winterfell.azurewebsites\n\n.net/home/rig/758b6495-8cb9-4705-a17a-2836b5fea42d\nhttps://hpm-vro-dev-scus-app-api-winterfell.azurewe\nbsites.net/home/rig/758b6495-8cb9-4705-a17a-2836b5fea42dhttps:/\nhttps://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/h\nome/rig/758b6495-8cb9-4705-a17a-2836b5fea42d\n/hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/r\nig/758b6495-8cb9-4705-a17a-2836b5fea42dhttps://hpm-vro-dev-scus-app-api-winterfell.azurewe\nbsites.net/home/rig/758b6495-8cb9-4705-a17a-2836b5fea42d\nhttps://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/758b6495-8cb9-4705-a17a-2836b5fea42d',
    effectiveDiameter: '',
    flag: 'red',
    unit: 'API',
    recommendation:
      'https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/758b6495-8cb9-4705-a17a-2836b5fea42d\nhttps://hpm-vro-dev-scus-app-api-winterfell.azure\n\nwebsites.net/home/rig/758b6495-8cb9-4705-a17a-2836b5fea42d\nhttps://hpm-vro-dev-scus-app-api-winterfell.azurewebsites\n\n.net/home/rig/758b6495-8cb9-4705-a17a-2836b5fea42d\nhttps://hpm-vro-dev-scus-app-api-winterfell.azurewe\nbsites.net/home/rig/758b6495-8cb9-4705-a17a-2836b5fea42dhttps:/\nhttps://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/h\nome/rig/758b6495-8cb9-4705-a17a-2836b5fea42d\n/hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/r\nig/758b6495-8cb9-4705-a17a-2836b5fea42dhttps://hpm-vro-dev-scus-app-api-winterfell.azurewe\nbsites.net/home/rig/758b6495-8cb9-4705-a17a-2836b5fea42d\nhttps://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/758b6495-8cb9-4705-a17a-2836b5fea42d',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: false,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1706597220000',
    rigTimestamps: '1706593620000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: false,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706597361390_log.json',
    auditTrail: [
      {
        logId: '2f668e91-fa5e-49fb-871f-6cf884c94224',
        interventionId: '85575cb8-ebef-4bc3-925c-07c3733ca7c7',
        action: 'Created',
        timestamps: '2024-01-30T06:49:21.374Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '9c71b17a-8cd1-4836-8232-d70769f9e2c1',
        interventionId: '85575cb8-ebef-4bc3-925c-07c3733ca7c7',
        action: 'Saved',
        timestamps: '2024-01-30T08:26:41.127Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'd3d175fe-b890-4b77-b507-e35913c49f8e',
        interventionId: '85575cb8-ebef-4bc3-925c-07c3733ca7c7',
        action: 'Saved',
        timestamps: '2024-01-30T06:51:00.943Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Draft)',
    stateCode: 'I_0_0',
    engineer: null,
    engineerId: null,
    initiated: null,
    resolved: null,
    avatarLink: null,
    remoteCenter: null,
    remoteCenterId: null,
    disciplineName: null,
    createdAt: '2024-01-30T06:49:21.332Z',
    updatedAt: '2024-02-06T15:57:04.843Z',
    eventType: 'report',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '38187b2f-df00-430a-8975-ccf2cfae03e9',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test 1hahashagds 12/.23347"h',
      type: 'TYPE10',
      runNo: '12',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '23',
      startMeasuredDepth: '23',
      tdMeasuredDepth: '2,435',
      belowRotaryTimestamp: '10/17/2023, 10:13 PM',
      runTdTimestamp: '10/20/2023, 10:14 PM',
      aboveRotaryTimestamp: '10/22/2023, 10:14 PM',
      tallyUrl: null,
    },
    isComplete: null,
  },
  {
    interventionId: '870e1453-74b3-45f6-bb43-22b9afdbeba9',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1703649069653_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12',
    holeMD: '23.23',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV71',
      label: 'No Data Exchange',
      data: 'No Data Exchange',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Data Acquisition/No Data Exchange',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: null,
    eventDescription: 'hahahah',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'test',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1703648880000',
    rigTimestamps: '1703645280000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: null,
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: '2023-12-27T04:36:18.024Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1703649068228_log.json',
    auditTrail: [
      {
        logId: '260987b1-3f85-419a-8b90-19ed5200b989',
        interventionId: '870e1453-74b3-45f6-bb43-22b9afdbeba9',
        action: 'Marked completed',
        timestamps: '2024-01-05T08:47:00.409Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Tuyet Nguyen',
        userId: 'bce7ddc8-1a4a-4a29-b1e8-ebf96f3433ec',
        userRemoteCenter: null,
      },
      {
        logId: 'd66f4f12-8428-4a28-bd21-22eae3507382',
        interventionId: '870e1453-74b3-45f6-bb43-22b9afdbeba9',
        action: 'Created',
        timestamps: '2023-12-27T03:51:08.187Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: 'ee70f53b-8831-440e-a587-8008eb9132ef',
        interventionId: '870e1453-74b3-45f6-bb43-22b9afdbeba9',
        action: 'Sent',
        timestamps: '2023-12-27T03:51:18.075Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: {
      chatId: 'e4da2b43-b00d-4e92-bf85-f2a50ba12aea',
      content: 'ahahahhhh',
      interventionId: '870e1453-74b3-45f6-bb43-22b9afdbeba9',
      userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
      attachments: [],
      nominateInformation: [
        {
          userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
          userDisplayName: 'Nga Tran Thi Kieu',
          userRole: 'Engineer',
          timestamps: 1703649231169,
          discipline: 'TEST',
          remoteCenter: 'VRO Testing',
          mail: 'Nga.TranThiKieu@halliburton.com',
        },
      ],
      nominateAt: '1703649231169',
      nominateVote: 1,
      createdAt: '2023-12-27T03:53:47.754Z',
      updatedAt: '2023-12-27T03:56:37.804Z',
    },
    currentState: 'Initiate (Completed)',
    stateCode: 'I_0_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2023-12-27T03:51:18.075Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2023-12-27T03:51:08.150Z',
    updatedAt: '2024-02-06T15:57:06.331Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '2edeeb68-d72f-4cda-b1cf-3b4f23a35d7d',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test',
      type: 'TYPE06',
      runNo: '111',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '12',
      startMeasuredDepth: '16',
      tdMeasuredDepth: '26',
      belowRotaryTimestamp: '10/02/2023, 10:16 PM',
      runTdTimestamp: '10/04/2023, 10:16 PM',
      aboveRotaryTimestamp: '10/11/2023, 10:16 PM',
      tallyUrl:
        'https://hpmvrodevscusstapp.blob.core.windows.net/tally/1698719909012_sample-file.pdf',
    },
    isComplete: true,
  },
  {
    interventionId: '87f30eb7-f40c-4a5c-a118-d43fcb69d4cd',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705891679697_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '23',
    holeMD: '55',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV83',
      label: 'Rig Operations',
      data: 'Rig Operations',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Operational Update/Rig Operations',
    },
    originalRisk:
      '[{"risk_id":"BOPR01","data":"Unstable/problematic borehole conditions","label":"Unstable/problematic borehole conditions"}]',
    risk: [
      {
        risk_id: 'BOPR01',
        label: 'Unstable/problematic borehole conditions',
        data: 'Unstable/problematic borehole conditions',
      },
    ],
    contactMethod:
      '[{"id":"phone","label":"Phone","data":"Phone"},{"contact_id":"C2","label":"C2 - Project Manager","data":"+1 832-123-4569"},{"contact_id":"C3","label":"C3 - Technical Advisor","data":"+1 832-123-4573"},{"contact_id":"C4","label":"C4 - Developer","data":"+1 132-434-6534"},{"contact_id":"E1","label":"E1 - Tester","data":"+1 832-123-4575"},{"contact_id":"E2","label":"E2 - Tester","data":"+1 832-123-4576"},{"contact_id":"E3","label":"E3 - Tester","data":"+1 123-253-4645"},{"contact_id":"E4","label":"E4 - Developer","data":"+1 123-253-4646"}]',
    eventDescription: 'Again 1',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'Again',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: false,
    isRecommendedActionTaken: 'no',
    resolution: 'test',
    sendNotificationOfResolutionTotheSameDistribution: '',
    rigBasedValidationDetail: 'Again 1',
    timeSaved: null,
    localTimestamps: '1705561320000',
    rigTimestamps: '1705557720000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
    status: 'RESOLUTION',
    hasSent: false,
    timeExpire: '2024-01-22T03:33:11.396Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705561607520_log.json',
    auditTrail: [
      {
        logId: '5309399a-7f90-4674-9201-cc3fd46fd361',
        interventionId: '87f30eb7-f40c-4a5c-a118-d43fcb69d4cd',
        action: 'Saved',
        timestamps: '2024-01-22T02:48:40.459Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '5e7ce1ab-4fff-453a-953d-3461127878ec',
        interventionId: '87f30eb7-f40c-4a5c-a118-d43fcb69d4cd',
        action: 'Saved',
        timestamps: '2024-01-18T07:08:03.095Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '74ba092b-6da8-4ff2-acd4-7c48698eb178',
        interventionId: '87f30eb7-f40c-4a5c-a118-d43fcb69d4cd',
        action: 'Saved',
        timestamps: '2024-01-22T02:47:57.797Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '92842072-4996-4605-925a-a48131dee4a7',
        interventionId: '87f30eb7-f40c-4a5c-a118-d43fcb69d4cd',
        action: 'Created',
        timestamps: '2024-01-18T07:06:46.629Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: 'f917060d-26f1-4ca1-bccc-443e5d2efceb',
        interventionId: '87f30eb7-f40c-4a5c-a118-d43fcb69d4cd',
        action: 'Sent',
        timestamps: '2024-01-22T02:48:11.432Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Resolve (Draft)',
    stateCode: 'R_1_0',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-22T02:48:11.432Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-18T07:06:46.397Z',
    updatedAt: '2024-02-06T15:57:06.755Z',
    eventType: 'intervention',
    interval: null,
    run: null,
    isComplete: null,
  },
  {
    interventionId: '89362ea3-48ec-424c-961c-84ef285a4d16',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706603033828_N_TestJh_AlertRoadmap.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12',
    holeMD: '33',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV17',
      label: 'Distance to Plan',
      data: 'Distance to Plan',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default', 'roadmap'],
      leaf: false,
      hierarchy: 'Borehole/Well Trajectory/Distance to Plan',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: null,
    eventDescription: 'hhh',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation:
      'https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/758b6495-8cb9-4705-a17a-2836b5fea42dhttps://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/758b6495-8cb9-4705-a17a-2836b5fea42d',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: false,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail:
      'https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/758b6495-8cb9-4705-a17a-2836b5fea42d',
    timeSaved: null,
    localTimestamps: '1706601840000',
    rigTimestamps: '1706598240000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706601943774_log.json',
    auditTrail: [
      {
        logId: '4ef6a50f-adfb-47f4-8d81-e1d84a5704c3',
        interventionId: '89362ea3-48ec-424c-961c-84ef285a4d16',
        action: 'Created',
        timestamps: '2024-01-30T08:05:43.756Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: 'aaaaa876-e3b7-4476-8f49-9ffcdb5e9143',
        interventionId: '89362ea3-48ec-424c-961c-84ef285a4d16',
        action: 'Sent',
        timestamps: '2024-01-30T08:24:18.027Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'fc214538-cc40-499a-afd3-f5ba8e7ab77a',
        interventionId: '89362ea3-48ec-424c-961c-84ef285a4d16',
        action: 'Saved',
        timestamps: '2024-01-30T08:23:51.893Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Completed)',
    stateCode: 'I_0_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-30T08:24:18.027Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-30T08:05:43.731Z',
    updatedAt: '2024-02-06T15:57:07.175Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '38187b2f-df00-430a-8975-ccf2cfae03e9',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test 1hahashagds 12/.23347"h',
      type: 'TYPE10',
      runNo: '12',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '23',
      startMeasuredDepth: '23',
      tdMeasuredDepth: '2,435',
      belowRotaryTimestamp: '10/17/2023, 10:13 PM',
      runTdTimestamp: '10/20/2023, 10:14 PM',
      aboveRotaryTimestamp: '10/22/2023, 10:14 PM',
      tallyUrl: null,
    },
    isComplete: null,
  },
  {
    interventionId: '8a95d1de-c9f3-494a-887a-454a4dfb66fa',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706261557826_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1',
    holeMD: '2',
    currentOperation: '3',
    event: {
      event_id: 'EV01',
      label: 'Flowback Volume',
      data: 'Flowback Volume',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Volumetrics/Flowback Volume',
    },
    originalRisk: 'Today',
    risk: 'Today',
    contactMethod: null,
    eventDescription:
      'Today\nToday\nToday\nToday\nToday\nToday\nToday\nToday sdfsddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddsdfsddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddsdfsddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddsdfsdddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd\nToday\nToday\nToday\nToday 12\nToday 13\nToday 14\nToday 15',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation: 'Today',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: false,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: 'Today',
    timeSaved: null,
    localTimestamps: '1706150220000',
    rigTimestamps: '1706146620000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
    createUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: false,
    isCustomRisk: false,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706150257498_log.json',
    auditTrail: [
      {
        logId: '0ebd7313-f72e-4984-83a7-90aefa6d136c',
        interventionId: '8a95d1de-c9f3-494a-887a-454a4dfb66fa',
        action: 'Saved',
        timestamps: '2024-01-26T09:32:34.455Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '301163f6-5680-46a1-8a1b-8eb8d364e829',
        interventionId: '8a95d1de-c9f3-494a-887a-454a4dfb66fa',
        action: 'Saved',
        timestamps: '2024-01-26T07:44:42.374Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '6e79a735-5b91-40c3-8222-73df03059472',
        interventionId: '8a95d1de-c9f3-494a-887a-454a4dfb66fa',
        action: 'Saved',
        timestamps: '2024-01-26T04:33:35.634Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '6fa80b56-f862-4b60-a420-a75ced78ba1f',
        interventionId: '8a95d1de-c9f3-494a-887a-454a4dfb66fa',
        action: 'Sent',
        timestamps: '2024-01-26T04:33:45.162Z',
        isFirstSent: false,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '82159d9f-bc69-4606-9619-50660c4f26c0',
        interventionId: '8a95d1de-c9f3-494a-887a-454a4dfb66fa',
        action: 'Created',
        timestamps: '2024-01-25T02:37:37.476Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '86be4e7d-3dd7-4030-80c9-ed548f5d8125',
        interventionId: '8a95d1de-c9f3-494a-887a-454a4dfb66fa',
        action: 'Saved',
        timestamps: '2024-01-25T02:38:23.698Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
      {
        logId: 'c066e7d5-f01b-494b-b5a4-94ef3be8acc2',
        interventionId: '8a95d1de-c9f3-494a-887a-454a4dfb66fa',
        action: 'Sent',
        timestamps: '2024-01-25T02:37:44.422Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: 'VRO Testing',
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Completed)',
    stateCode: 'I_0_1',
    engineer: 'Thanh Nguyen Cong (TEST)',
    engineerId: '365dbd8b-3313-4833-8efb-ffc478695821',
    initiated: '2024-01-25T02:37:44.422Z',
    resolved: null,
    avatarLink: null,
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-25T02:37:37.447Z',
    updatedAt: '2024-02-06T15:57:07.596Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '2edeeb68-d72f-4cda-b1cf-3b4f23a35d7d',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test',
      type: 'TYPE06',
      runNo: '111',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '12',
      startMeasuredDepth: '16',
      tdMeasuredDepth: '26',
      belowRotaryTimestamp: '10/02/2023, 10:16 PM',
      runTdTimestamp: '10/04/2023, 10:16 PM',
      aboveRotaryTimestamp: '10/11/2023, 10:16 PM',
      tallyUrl:
        'https://hpmvrodevscusstapp.blob.core.windows.net/tally/1698719909012_sample-file.pdf',
    },
    isComplete: null,
  },
  {
    interventionId: '9080da55-c7bd-464c-b5c4-5f1a32c2a685',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706564680814_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1',
    holeMD: '2',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV01',
      label: 'Flowback Volume',
      data: 'Flowback Volume',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Volumetrics/Flowback Volume',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"},{"risk_id":"ETSE02","data":"Casing / Tubing Running Equipment/Tools Failures","label":"Casing / Tubing Running Equipment/Tools Failures"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
      {
        risk_id: 'ETSE02',
        label: 'Casing / Tubing Running Equipment/Tools Failures',
        data: 'Casing / Tubing Running Equipment/Tools Failures',
      },
    ],
    contactMethod: null,
    eventDescription: 'hhh',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'kk',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: false,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1706564580000',
    rigTimestamps: '1706560980000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: null,
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706564679450_log.json',
    auditTrail: [
      {
        logId: 'c16e861a-81fa-4908-b312-1f1460e67ee1',
        interventionId: '9080da55-c7bd-464c-b5c4-5f1a32c2a685',
        action: 'Sent',
        timestamps: '2024-01-29T21:44:51.462Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'dacec955-a646-4136-af17-84bb0209ec11',
        interventionId: '9080da55-c7bd-464c-b5c4-5f1a32c2a685',
        action: 'Created',
        timestamps: '2024-01-29T21:44:39.418Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Completed)',
    stateCode: 'I_0_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-29T21:44:51.462Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-29T21:44:39.349Z',
    updatedAt: '2024-02-06T15:57:09.039Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '38187b2f-df00-430a-8975-ccf2cfae03e9',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test 1hahashagds 12/.23347"h',
      type: 'TYPE10',
      runNo: '12',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '23',
      startMeasuredDepth: '23',
      tdMeasuredDepth: '2,435',
      belowRotaryTimestamp: '10/17/2023, 10:13 PM',
      runTdTimestamp: '10/20/2023, 10:14 PM',
      aboveRotaryTimestamp: '10/22/2023, 10:14 PM',
      tallyUrl: null,
    },
    isComplete: null,
  },
  {
    interventionId: '93ac59c7-646b-40fd-9d00-112f12a367ea',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705978641675_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '3',
    holeMD: '5',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV01',
      label: 'Flowback Volume',
      data: 'Flowback Volume',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Volumetrics/Flowback Volume',
    },
    originalRisk: 'ThanhNC40 2:41',
    risk: 'ThanhNC40 2:41',
    contactMethod: null,
    eventDescription: 'ThanhNC40 2:41\nHAhahah\nahahdh',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation: 'ThanhNC40 2:41',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: 'ThanhNC40 2:41',
    timeSaved: null,
    localTimestamps: '1705909260000',
    rigTimestamps: '1705905660000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: '2024-01-22T08:26:35.389Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: false,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705909291065_log.json',
    auditTrail: [
      {
        logId: '631e80fe-eacf-4d78-be01-b90e5ed7e881',
        interventionId: '93ac59c7-646b-40fd-9d00-112f12a367ea',
        action: 'Saved',
        timestamps: '2024-01-23T02:57:20.443Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'ce458cea-4604-4613-bf4f-3efa34ced3a0',
        interventionId: '93ac59c7-646b-40fd-9d00-112f12a367ea',
        action: 'Created',
        timestamps: '2024-01-22T07:41:31.054Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: 'd464beea-5191-4679-a7f9-070b6e0c6cfa',
        interventionId: '93ac59c7-646b-40fd-9d00-112f12a367ea',
        action: 'Marked completed',
        timestamps: '2024-01-23T01:13:58.562Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'fc04ec83-dc3d-458e-9840-d99fc1c69fc4',
        interventionId: '93ac59c7-646b-40fd-9d00-112f12a367ea',
        action: 'Sent',
        timestamps: '2024-01-22T07:41:35.423Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: 'VRO Testing',
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Completed)',
    stateCode: 'I_0_1',
    engineer: 'Thanh Nguyen Cong (TEST)',
    engineerId: '365dbd8b-3313-4833-8efb-ffc478695821',
    initiated: '2024-01-22T07:41:35.423Z',
    resolved: null,
    avatarLink: null,
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-22T07:41:31.039Z',
    updatedAt: '2024-02-06T15:57:10.097Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: null,
    isComplete: true,
  },
  {
    interventionId: '95852cbf-a8b4-45ee-8caa-d6edf7b0cb8d',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705992486106_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1',
    holeMD: '2',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV08',
      label: 'Trip Gas',
      data: 'Trip Gas',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Gas/Trip Gas',
    },
    originalRisk: 'ThanhNC40 Jan 23th',
    risk: 'ThanhNC40 Jan 23th',
    contactMethod:
      '[{"id":"phone","label":"Phone","data":"Phone"},{"contact_id":"C2","label":"C2 - Project Manager","data":"+1 832-123-4569"},{"contact_id":"C3","label":"C3 - Technical Advisor","data":"+1 832-123-4573"},{"contact_id":"C4","label":"C4 - Developer","data":"+1 132-434-6534"},{"contact_id":"E1","label":"E1 - Tester","data":"+1 832-123-4575"},{"contact_id":"E2","label":"E2 - Tester","data":"+1 832-123-4576"},{"contact_id":"E3","label":"E3 - Tester","data":"+1 123-253-4645"},{"contact_id":"E4","label":"E4 - Developer","data":"+1 123-253-4646"}]',
    eventDescription: 'ThanhNC40 Jan 23th',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation: 'ThanhNC40 Jan 23th',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: 'yes',
    resolution: 'ThanhNC40 Jan 23th',
    sendNotificationOfResolutionTotheSameDistribution: '',
    rigBasedValidationDetail: 'ThanhNC40 Jan 23th',
    timeSaved: 22,
    localTimestamps: '1705992360000',
    rigTimestamps: '1705988760000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    createUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    status: 'RESOLUTION',
    hasSent: false,
    timeExpire: '2024-01-23T07:33:11.861Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: false,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705992483186_log.json',
    auditTrail: [
      {
        logId: '125a70e2-fe0e-4e6f-a978-971abbc956ac',
        interventionId: '95852cbf-a8b4-45ee-8caa-d6edf7b0cb8d',
        action: 'Saved',
        timestamps: '2024-01-23T06:48:47.687Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
      {
        logId: '49cb5630-6a10-4367-8b28-88940204eb37',
        interventionId: '95852cbf-a8b4-45ee-8caa-d6edf7b0cb8d',
        action: 'Created',
        timestamps: '2024-01-23T06:48:03.163Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: 'c972641d-5a0d-4236-a392-d5d0861f9342',
        interventionId: '95852cbf-a8b4-45ee-8caa-d6edf7b0cb8d',
        action: 'Sent',
        timestamps: '2024-01-23T06:48:11.899Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: 'VRO Testing',
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Resolve (Draft)',
    stateCode: 'R_1_0',
    engineer: 'Thanh Nguyen Cong (TEST)',
    engineerId: '365dbd8b-3313-4833-8efb-ffc478695821',
    initiated: '2024-01-23T06:48:11.899Z',
    resolved: null,
    avatarLink: null,
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-23T06:48:03.118Z',
    updatedAt: '2024-02-06T15:57:10.307Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: null,
    isComplete: null,
  },
  {
    interventionId: '9d96806b-5501-4ac9-b275-523a1cdf8877',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705992954606_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '2',
    holeMD: '3',
    currentOperation: 'ThanhNC40 Jan 23th No1',
    event: {
      event_id: 'EV57',
      label: 'Bit Bounce',
      data: 'Bit Bounce',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'BHA/Vibration/Bit Bounce',
    },
    originalRisk: 'ThanhNC40 Jan 23th No2',
    risk: 'ThanhNC40 Jan 23th No2',
    contactMethod: null,
    eventDescription: 'ThanhNC40 Jan 23th No2',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation: 'ThanhNC40 Jan 23th No2',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: 'ThanhNC40 Jan 23th No2',
    timeSaved: null,
    localTimestamps: '1705992600000',
    rigTimestamps: '1705989000000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    createUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: '2024-01-23T07:41:00.559Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: false,
    isCustomRisk: false,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705992633834_log.json',
    auditTrail: [
      {
        logId: '24d23f6f-c51e-41ed-993e-ab9a4862e2e6',
        interventionId: '9d96806b-5501-4ac9-b275-523a1cdf8877',
        action: 'Created',
        timestamps: '2024-01-23T06:50:33.810Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '5c5842ec-ffbf-44e5-82e9-2884312f8b87',
        interventionId: '9d96806b-5501-4ac9-b275-523a1cdf8877',
        action: 'Saved',
        timestamps: '2024-01-23T06:55:52.061Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
      {
        logId: '7f7e020f-1500-4d04-9e33-ba1a3248c2bc',
        interventionId: '9d96806b-5501-4ac9-b275-523a1cdf8877',
        action: 'Saved',
        timestamps: '2024-01-23T06:54:35.855Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
      {
        logId: 'a1a8d021-4156-402e-aec8-8c77b4934339',
        interventionId: '9d96806b-5501-4ac9-b275-523a1cdf8877',
        action: 'Sent',
        timestamps: '2024-01-23T06:56:00.595Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'fe3080d8-5736-40b8-b122-9ec81eda5aee',
        interventionId: '9d96806b-5501-4ac9-b275-523a1cdf8877',
        action: 'Marked completed',
        timestamps: '2024-01-23T06:56:40.483Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Completed)',
    stateCode: 'I_0_1',
    engineer: 'Thanh Nguyen Cong (TEST)',
    engineerId: '365dbd8b-3313-4833-8efb-ffc478695821',
    initiated: '2024-01-23T06:56:00.595Z',
    resolved: null,
    avatarLink: null,
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-23T06:50:33.793Z',
    updatedAt: '2024-02-06T15:57:12.201Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: null,
    isComplete: true,
  },
  {
    interventionId: 'a5c940d6-b044-491d-a79e-156afec49b4c',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706003739180_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1',
    holeMD: '1',
    currentOperation: '1',
    event: {
      event_id: 'EV53',
      label: 'Torque',
      data: 'Torque',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'BHA/Operating Limits/Torque',
    },
    originalRisk: 'Check query 1',
    risk: 'Check query 1',
    contactMethod:
      '[{"id":"phone","label":"Phone","data":"Phone"},{"contact_id":"C2","label":"C2 - Project Manager","data":"+1 832-123-4569"},{"contact_id":"C3","label":"C3 - Technical Advisor","data":"+1 832-123-4573"},{"contact_id":"C4","label":"C4 - Developer","data":"+1 132-434-6534"},{"contact_id":"E1","label":"E1 - Tester","data":"+1 832-123-4575"},{"contact_id":"E2","label":"E2 - Tester","data":"+1 832-123-4576"},{"contact_id":"E3","label":"E3 - Tester","data":"+1 123-253-4645"},{"contact_id":"E4","label":"E4 - Developer","data":"+1 123-253-4646"}]',
    eventDescription: 'Check query 1',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation: 'Check query 1',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: 'yes',
    resolution: 'Check query 1',
    sendNotificationOfResolutionTotheSameDistribution: '',
    rigBasedValidationDetail: 'Check query 1',
    timeSaved: 22,
    localTimestamps: '1706003640000',
    rigTimestamps: '1706000040000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    createUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    status: 'RESOLUTION',
    hasSent: false,
    timeExpire: '2024-01-23T10:40:42.379Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: false,
    isCustomRisk: false,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706003738099_log.json',
    auditTrail: [
      {
        logId: '173764bb-1505-4e6e-81d2-ec22c58c4f89',
        interventionId: 'a5c940d6-b044-491d-a79e-156afec49b4c',
        action: 'Sent',
        timestamps: '2024-01-23T09:55:42.408Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '3821ea6f-6277-4463-af72-b0a415f8da48',
        interventionId: 'a5c940d6-b044-491d-a79e-156afec49b4c',
        action: 'Saved',
        timestamps: '2024-01-23T09:55:59.840Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
      {
        logId: '436583ae-902c-4686-b545-e7472ef7d6bf',
        interventionId: 'a5c940d6-b044-491d-a79e-156afec49b4c',
        action: 'Created',
        timestamps: '2024-01-23T09:55:38.086Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: null,
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Resolve (Draft)',
    stateCode: 'R_1_0',
    engineer: 'Thanh Nguyen Cong (TEST)',
    engineerId: '365dbd8b-3313-4833-8efb-ffc478695821',
    initiated: '2024-01-23T09:55:42.408Z',
    resolved: null,
    avatarLink: null,
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-23T09:55:38.065Z',
    updatedAt: '2024-02-06T15:57:15.367Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: null,
    isComplete: null,
  },
  {
    interventionId: 'ac916701-e086-48c9-89e3-0c4ac01c94fd',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705378166167_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12',
    holeMD: '124.35',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV57',
      label: 'Bit Bounce',
      data: 'Bit Bounce',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'BHA/Vibration/Bit Bounce',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: '[{"contact_id":"chat","label":"Chat","data":"Chat"}]',
    eventDescription: 'Edit TTT',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation:
      'test with long content in ticket OKKO\nLine 2: test with long content in ticket test with long content in ticket  ',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: 'yes',
    resolution: 'Edit 1 TTT',
    sendNotificationOfResolutionTotheSameDistribution: '',
    rigBasedValidationDetail: '',
    timeSaved: 11,
    localTimestamps: '1703825580000',
    rigTimestamps: '1703821980000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'RESOLUTION',
    hasSent: false,
    timeExpire: '2023-12-29T07:36:40.679Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1703825715251_log.json',
    auditTrail: [
      {
        logId: '018dd902-632a-4270-81b8-c44ccd9181f3',
        interventionId: 'ac916701-e086-48c9-89e3-0c4ac01c94fd',
        action: 'Saved',
        timestamps: '2024-01-02T08:59:53.823Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
      {
        logId: '1d35ed36-fdc4-4df7-8e01-0ae6c6b4d8c3',
        interventionId: 'ac916701-e086-48c9-89e3-0c4ac01c94fd',
        action: 'Status reset',
        timestamps: '2024-01-05T08:55:46.900Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '20544544-58e4-4e75-84fa-93bd4a0b6db0',
        interventionId: 'ac916701-e086-48c9-89e3-0c4ac01c94fd',
        action: 'Saved',
        timestamps: '2024-01-16T02:17:27.844Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '258fdc94-dcdd-4f48-8885-300a50c9f447',
        interventionId: 'ac916701-e086-48c9-89e3-0c4ac01c94fd',
        action: 'Saved',
        timestamps: '2024-01-15T07:45:52.463Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '2def8956-26cd-4b33-b002-83aae64f0302',
        interventionId: 'ac916701-e086-48c9-89e3-0c4ac01c94fd',
        action: 'Saved',
        timestamps: '2024-01-02T08:56:54.649Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
      {
        logId: '2e942d2d-7b9a-4de8-9ca2-cb44ceb1cf6a',
        interventionId: 'ac916701-e086-48c9-89e3-0c4ac01c94fd',
        action: 'Saved',
        timestamps: '2024-01-02T08:43:34.460Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
      {
        logId: '34b74496-1c8f-4408-bf76-358ab327eedb',
        interventionId: 'ac916701-e086-48c9-89e3-0c4ac01c94fd',
        action: 'Saved',
        timestamps: '2024-01-02T08:44:18.176Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
      {
        logId: '39a09b24-bc39-4859-91de-b8534cfacc27',
        interventionId: 'ac916701-e086-48c9-89e3-0c4ac01c94fd',
        action: 'Marked completed',
        timestamps: '2024-01-15T07:40:08.134Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '4b61cb63-9f86-4bdb-912d-776dbb10ead8',
        interventionId: 'ac916701-e086-48c9-89e3-0c4ac01c94fd',
        action: 'Saved',
        timestamps: '2024-01-16T04:09:22.033Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '4f58c441-70db-4972-9b8f-6a2e27154377',
        interventionId: 'ac916701-e086-48c9-89e3-0c4ac01c94fd',
        action: 'Saved',
        timestamps: '2023-12-29T06:54:52.268Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '5aa25755-1b60-46d9-b8ba-f74270258934',
        interventionId: 'ac916701-e086-48c9-89e3-0c4ac01c94fd',
        action: 'Created',
        timestamps: '2023-12-29T04:55:15.215Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '68fa5b50-5d74-4626-9300-9cd623308cb5',
        interventionId: 'ac916701-e086-48c9-89e3-0c4ac01c94fd',
        action: 'Saved',
        timestamps: '2024-01-16T02:18:12.732Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '6941ef07-055e-4da1-954c-98bbea40ddee',
        interventionId: 'ac916701-e086-48c9-89e3-0c4ac01c94fd',
        action: 'Saved',
        timestamps: '2023-12-29T06:52:18.532Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '7670162d-dceb-4a06-8dbf-3a8b5a5aa030',
        interventionId: 'ac916701-e086-48c9-89e3-0c4ac01c94fd',
        action: 'Saved',
        timestamps: '2023-12-29T08:54:56.104Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '79cc62db-6b48-453b-8700-134226464655',
        interventionId: 'ac916701-e086-48c9-89e3-0c4ac01c94fd',
        action: 'Saved',
        timestamps: '2024-01-02T07:00:50.984Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '81b2da5f-41cf-46be-a40d-539c0280b5f3',
        interventionId: 'ac916701-e086-48c9-89e3-0c4ac01c94fd',
        action: 'Saved',
        timestamps: '2023-12-29T06:52:49.167Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'a4e2dc4e-3a28-45c6-8deb-efa311f2de49',
        interventionId: 'ac916701-e086-48c9-89e3-0c4ac01c94fd',
        action: 'Sent',
        timestamps: '2023-12-29T08:49:16.877Z',
        isFirstSent: false,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'a54d32ec-7f49-4504-975d-9f91c20356a6',
        interventionId: 'ac916701-e086-48c9-89e3-0c4ac01c94fd',
        action: 'Status reset',
        timestamps: '2024-01-15T07:40:22.228Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'aae0e106-9488-4a89-928d-ba026cf1658b',
        interventionId: 'ac916701-e086-48c9-89e3-0c4ac01c94fd',
        action: 'Saved',
        timestamps: '2023-12-29T06:47:18.071Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'b87a3eab-f518-4414-a8f9-91209571324d',
        interventionId: 'ac916701-e086-48c9-89e3-0c4ac01c94fd',
        action: 'Saved',
        timestamps: '2023-12-29T08:49:00.769Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'bf041cef-0fd2-43bb-b773-acb03f9792ee',
        interventionId: 'ac916701-e086-48c9-89e3-0c4ac01c94fd',
        action: 'Sent',
        timestamps: '2023-12-29T06:51:40.726Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'c868d108-5200-4012-962f-4e9c8e0944c4',
        interventionId: 'ac916701-e086-48c9-89e3-0c4ac01c94fd',
        action: 'Marked completed',
        timestamps: '2024-01-05T08:52:07.596Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'e2eac12c-717a-4964-a1a7-4b60b6218190',
        interventionId: 'ac916701-e086-48c9-89e3-0c4ac01c94fd',
        action: 'Saved',
        timestamps: '2024-01-02T07:22:19.246Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: {
      chatId: 'b66791a3-42fd-44de-840e-e28ce3fcc79f',
      content: 'b',
      interventionId: 'ac916701-e086-48c9-89e3-0c4ac01c94fd',
      userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
      attachments: [],
      nominateInformation: [
        {
          userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
          userDisplayName: 'Nga Tran Thi Kieu',
          userRole: 'Engineer',
          timestamps: 1704185854195,
          discipline: 'TEST',
          remoteCenter: 'VRO Testing',
          mail: 'Nga.TranThiKieu@halliburton.com',
        },
        {
          userId: '020f7316-f3a2-46c5-a511-3d5d6676cb84',
          userDisplayName: 'Marius Moldvaer',
          userRole: 'Admin',
          timestamps: 1704217689565,
          mail: 'Marius.Moldvaer@halliburton.com',
        },
      ],
      nominateAt: '1704185854195',
      nominateVote: 2,
      createdAt: '2024-01-02T08:57:32.591Z',
      updatedAt: '2024-01-02T17:48:09.565Z',
    },
    currentState: 'Resolve (Draft)',
    stateCode: 'R_1_0',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2023-12-29T06:51:40.726Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2023-12-29T04:55:15.174Z',
    updatedAt: '2024-02-06T15:57:16.234Z',
    eventType: 'intervention',
    interval: null,
    run: null,
    isComplete: false,
  },
  {
    interventionId: 'b03e3eea-b540-42f5-b778-39a235474b75',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1704182066815_N_TestJh_AlertReport.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12',
    holeMD: '22',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV94',
      label: 'Daily Report',
      data: 'Daily Report',
      styleClass: 'lowestChild',
      eventType: 'report',
      template: ['default'],
      leaf: false,
      hierarchy: 'Reports/Daily Report',
    },
    originalRisk:
      '[{"risk_id":"BOPR02","data":"Well Trajectory Correction","label":"Well Trajectory Correction"}]',
    risk: [
      {
        risk_id: 'BOPR02',
        label: 'Well Trajectory Correction',
        data: 'Well Trajectory Correction',
      },
    ],
    contactMethod: null,
    eventDescription: 'Morrning',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation: 'Morrning',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: 'Morrning',
    timeSaved: null,
    localTimestamps: '1704181980000',
    rigTimestamps: '1704178380000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: null,
    createUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    status: 'INITIATE',
    hasSent: false,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1704182063367_log.json',
    auditTrail: [
      {
        logId: 'daaf6b86-39ec-4e7c-a3c0-c228bbd010cf',
        interventionId: 'b03e3eea-b540-42f5-b778-39a235474b75',
        action: 'Created',
        timestamps: '2024-01-02T07:54:23.344Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: null,
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Draft)',
    stateCode: 'I_1_0',
    engineer: null,
    engineerId: null,
    initiated: null,
    resolved: null,
    avatarLink: null,
    remoteCenter: null,
    remoteCenterId: null,
    disciplineName: null,
    createdAt: '2024-01-02T07:54:23.314Z',
    updatedAt: '2024-02-06T15:57:17.080Z',
    eventType: 'report',
    interval: {
      intervalId: 'e20a8970-8f5c-40a7-a627-dcc8c5039ae9',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: '1',
      unit: 'SI',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '51',
      openHoleDiameter: '51',
      lastShoeMeasuredDepth: '235.34',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '51057d99-7501-4cee-aab7-80a8e1aaae34',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: 'e20a8970-8f5c-40a7-a627-dcc8c5039ae9',
      description: '6',
      type: 'TYPE10',
      runNo: '6',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '6',
      startMeasuredDepth: '7',
      tdMeasuredDepth: '7',
      belowRotaryTimestamp: '10/28/2023, 10:40 AM',
      runTdTimestamp: '10/29/2023, 10:40 AM',
      aboveRotaryTimestamp: '10/30/2023, 10:40 AM',
      tallyUrl: null,
    },
    isComplete: null,
  },
  {
    interventionId: 'b87ad84f-5ae9-4ed5-99f2-040e43bcc27f',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705559752914_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1',
    holeMD: '12',
    currentOperation:
      '{"operation_id":"OP01","data":"Drilling Ahead","label":"Drilling Ahead"}',
    event: {
      event_id: 'EV01',
      label: 'Flowback Volume',
      data: 'Flowback Volume',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Volumetrics/Flowback Volume',
    },
    originalRisk:
      '[{"risk_id":"BOPR01","data":"Unstable/problematic borehole conditions","label":"Unstable/problematic borehole conditions"}]',
    risk: [
      {
        risk_id: 'BOPR01',
        label: 'Unstable/problematic borehole conditions',
        data: 'Unstable/problematic borehole conditions',
      },
    ],
    contactMethod: null,
    eventDescription: 'EVENT',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'EVENT',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: 'EVENT',
    timeSaved: null,
    localTimestamps: '1705559640000',
    rigTimestamps: '1705556040000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: null,
    createUserId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
    status: 'INITIATE',
    hasSent: false,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705559750914_log.json',
    auditTrail: [
      {
        logId: '1da99c96-624e-46c9-8855-a7b3b4deeb95',
        interventionId: 'b87ad84f-5ae9-4ed5-99f2-040e43bcc27f',
        action: 'Created',
        timestamps: '2024-01-18T06:35:50.891Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: null,
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Draft)',
    stateCode: 'I_1_0',
    engineer: null,
    engineerId: null,
    initiated: null,
    resolved: null,
    avatarLink: null,
    remoteCenter: null,
    remoteCenterId: null,
    disciplineName: null,
    createdAt: '2024-01-18T06:35:50.853Z',
    updatedAt: '2024-02-06T15:57:18.971Z',
    eventType: 'intervention',
    interval: null,
    run: null,
    isComplete: null,
  },
  {
    interventionId: 'bb6208c0-7d8a-4ad2-b03d-d924bbc702e5',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705992581597_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '2',
    holeMD: '3',
    currentOperation:
      '{"operation_id":"OP01","data":"Drilling Ahead","label":"Drilling Ahead"}',
    event: {
      event_id: 'EV01',
      label: 'Flowback Volume',
      data: 'Flowback Volume',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Volumetrics/Flowback Volume',
    },
    originalRisk: 'ThanhNC40 Jan 23th No1',
    risk: 'ThanhNC40 Jan 23th No1',
    contactMethod: null,
    eventDescription: 'ThanhNC40 Jan 23th No1',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation: 'ThanhNC40 Jan 23th No1',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: 'ThanhNC40 Jan 23th No1',
    timeSaved: null,
    localTimestamps: '1705992480000',
    rigTimestamps: '1705988880000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: null,
    createUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: '2024-01-23T07:34:47.573Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: false,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705992580889_log.json',
    auditTrail: [
      {
        logId: '5c001f78-4da7-4156-9d3d-2d8b7a55291d',
        interventionId: 'bb6208c0-7d8a-4ad2-b03d-d924bbc702e5',
        action: 'Sent',
        timestamps: '2024-01-23T06:49:47.606Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'bd7e460b-b31a-4835-a5bc-671f808bd619',
        interventionId: 'bb6208c0-7d8a-4ad2-b03d-d924bbc702e5',
        action: 'Created',
        timestamps: '2024-01-23T06:49:40.864Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: null,
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Completed)',
    stateCode: 'I_1_1',
    engineer: 'Thanh Nguyen Cong (TEST)',
    engineerId: '365dbd8b-3313-4833-8efb-ffc478695821',
    initiated: '2024-01-23T06:49:47.606Z',
    resolved: null,
    avatarLink: null,
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-23T06:49:40.846Z',
    updatedAt: '2024-02-06T15:57:20.457Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: null,
    isComplete: null,
  },
  {
    interventionId: 'c1d4d591-168a-43fb-b3e6-3fffbead02c6',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705909208449_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1',
    holeMD: '1',
    currentOperation: '1',
    event: {
      event_id: 'EV53',
      label: 'Torque',
      data: 'Torque',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'BHA/Operating Limits/Torque',
    },
    originalRisk: 'ThanhNC40 2:39',
    risk: 'ThanhNC40 2:39',
    contactMethod:
      '[{"id":"phone","label":"Phone","data":"Phone"},{"contact_id":"C2","label":"C2 - Project Manager","data":"+1 832-123-4569"},{"contact_id":"C3","label":"C3 - Technical Advisor","data":"+1 832-123-4573"},{"contact_id":"C4","label":"C4 - Developer","data":"+1 132-434-6534"},{"contact_id":"E1","label":"E1 - Tester","data":"+1 832-123-4575"},{"contact_id":"E2","label":"E2 - Tester","data":"+1 832-123-4576"},{"contact_id":"E3","label":"E3 - Tester","data":"+1 123-253-4645"},{"contact_id":"E4","label":"E4 - Developer","data":"+1 123-253-4646"}]',
    eventDescription: 'ThanhNC40 2:39',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation: 'ThanhNC40 2:39',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: 'yes',
    resolution: 'ThanhNC40 2:39',
    sendNotificationOfResolutionTotheSameDistribution: '',
    rigBasedValidationDetail: 'ThanhNC40 2:39',
    timeSaved: 22,
    localTimestamps: '1705909140000',
    rigTimestamps: '1705905540000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    createUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    status: 'RESOLUTION',
    hasSent: false,
    timeExpire: '2024-01-22T08:25:13.456Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: false,
    isCustomRisk: false,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705909207475_log.json',
    auditTrail: [
      {
        logId: '0a59ae9b-a25f-4ef1-8c73-3525ddbf191a',
        interventionId: 'c1d4d591-168a-43fb-b3e6-3fffbead02c6',
        action: 'Created',
        timestamps: '2024-01-22T07:40:07.455Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '1d983fc0-8513-4652-bc4a-9083d555f0bf',
        interventionId: 'c1d4d591-168a-43fb-b3e6-3fffbead02c6',
        action: 'Marked completed',
        timestamps: '2024-01-23T08:58:45.928Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'e459c11f-9c9a-481d-bf27-0c1c3327f243',
        interventionId: 'c1d4d591-168a-43fb-b3e6-3fffbead02c6',
        action: 'Sent',
        timestamps: '2024-01-22T07:40:13.489Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'fba69fbe-fb3b-4cb4-827f-2dfea77550d4',
        interventionId: 'c1d4d591-168a-43fb-b3e6-3fffbead02c6',
        action: 'Saved',
        timestamps: '2024-01-22T07:40:26.981Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Completed)',
    stateCode: 'I_0_1',
    engineer: 'Thanh Nguyen Cong (TEST)',
    engineerId: '365dbd8b-3313-4833-8efb-ffc478695821',
    initiated: '2024-01-22T07:40:13.489Z',
    resolved: null,
    avatarLink: null,
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-22T07:40:07.439Z',
    updatedAt: '2024-02-06T15:57:21.727Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: null,
    isComplete: true,
  },
  {
    interventionId: 'c3ff66cb-fb17-487b-ab77-69d0a47edd97',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1704698913002_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1',
    holeMD: '2',
    currentOperation: '3',
    event: {
      event_id: 'EV53',
      label: 'Torque',
      data: 'Torque',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'BHA/Operating Limits/Torque',
    },
    originalRisk: '6',
    risk: '6',
    contactMethod: null,
    eventDescription: '4',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation: '5',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '7',
    timeSaved: null,
    localTimestamps: '1704698880000',
    rigTimestamps: '1704695280000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: null,
    createUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    status: 'INITIATE',
    hasSent: false,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: false,
    isCustomRisk: false,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1704698910360_log.json',
    auditTrail: [
      {
        logId: '168daaf9-83f3-4873-89dd-326947efa19c',
        interventionId: 'c3ff66cb-fb17-487b-ab77-69d0a47edd97',
        action: 'Created',
        timestamps: '2024-01-08T07:28:30.341Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: null,
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Draft)',
    stateCode: 'I_1_0',
    engineer: null,
    engineerId: null,
    initiated: null,
    resolved: null,
    avatarLink: null,
    remoteCenter: null,
    remoteCenterId: null,
    disciplineName: null,
    createdAt: '2024-01-08T07:28:30.275Z',
    updatedAt: '2024-02-06T15:57:22.359Z',
    eventType: 'intervention',
    interval: {
      intervalId: 'e20a8970-8f5c-40a7-a627-dcc8c5039ae9',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: '1',
      unit: 'SI',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '51',
      openHoleDiameter: '51',
      lastShoeMeasuredDepth: '235.34',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '51057d99-7501-4cee-aab7-80a8e1aaae34',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: 'e20a8970-8f5c-40a7-a627-dcc8c5039ae9',
      description: '6',
      type: 'TYPE10',
      runNo: '6',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '6',
      startMeasuredDepth: '7',
      tdMeasuredDepth: '7',
      belowRotaryTimestamp: '10/28/2023, 10:40 AM',
      runTdTimestamp: '10/29/2023, 10:40 AM',
      aboveRotaryTimestamp: '10/30/2023, 10:40 AM',
      tallyUrl: null,
    },
    isComplete: null,
  },
  {
    interventionId: 'c470164f-12ab-4e11-b460-1352388b2893',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706584897313_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12',
    holeMD: '12',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV01',
      label: 'Flowback Volume',
      data: 'Flowback Volume',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Volumetrics/Flowback Volume',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: null,
    eventDescription: 'hhh',
    effectiveDiameter: '',
    flag: 'yellow',
    unit: 'API',
    recommendation: 'test',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: false,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1706584800000',
    rigTimestamps: '1706581200000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: null,
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706584895967_log.json',
    auditTrail: [
      {
        logId: '7c373378-ef1a-4e21-b8bb-01def92d7332',
        interventionId: 'c470164f-12ab-4e11-b460-1352388b2893',
        action: 'Sent',
        timestamps: '2024-01-30T03:21:45.075Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'ce8a17b7-7d52-466c-952c-6a6f3569eb91',
        interventionId: 'c470164f-12ab-4e11-b460-1352388b2893',
        action: 'Created',
        timestamps: '2024-01-30T03:21:35.955Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Completed)',
    stateCode: 'I_0_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-30T03:21:45.075Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-30T03:21:35.936Z',
    updatedAt: '2024-02-06T15:57:22.571Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '38187b2f-df00-430a-8975-ccf2cfae03e9',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test 1hahashagds 12/.23347"h',
      type: 'TYPE10',
      runNo: '12',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '23',
      startMeasuredDepth: '23',
      tdMeasuredDepth: '2,435',
      belowRotaryTimestamp: '10/17/2023, 10:13 PM',
      runTdTimestamp: '10/20/2023, 10:14 PM',
      aboveRotaryTimestamp: '10/22/2023, 10:14 PM',
      tallyUrl: null,
    },
    isComplete: null,
  },
  {
    interventionId: 'c81414d4-07fa-4e89-a6a5-b731cb399690',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705386688703_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12',
    holeMD: '123',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV71',
      label: 'No Data Exchange',
      data: 'No Data Exchange',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Data Acquisition/No Data Exchange',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod:
      '[{"id":"email","label":"Email","data":"Email"},{"id":"phone","label":"Phone","data":"Phone"},{"contact_id":"C2","label":"C2 - Project Manager","data":"+1 832-123-4569"},{"contact_id":"C3","label":"C3 - Technical Advisor","data":"+1 832-123-4573"},{"contact_id":"C4","label":"C4 - Developer","data":"+1 132-434-6534"},{"contact_id":"E1","label":"E1 - Tester","data":"+1 832-123-4575"},{"contact_id":"E2","label":"E2 - Tester","data":"+1 832-123-4576"},{"contact_id":"E3","label":"E3 - Tester","data":"+1 123-253-4645"},{"contact_id":"E4","label":"E4 - Developer","data":"+1 123-253-4646"}]',
    eventDescription: 'hhh edit',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'Nttt',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: false,
    isRecommendedActionTaken: 'no',
    resolution: 'h',
    sendNotificationOfResolutionTotheSameDistribution: '',
    rigBasedValidationDetail: '',
    timeSaved: 1,
    localTimestamps: '1705386060000',
    rigTimestamps: '1705382460000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'RESOLUTION',
    hasSent: false,
    timeExpire: '2024-01-16T07:08:21.345Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705386169939_log.json',
    auditTrail: [
      {
        logId: '0ae7b3df-5222-4406-bf2d-2c2258e4e0d5',
        interventionId: 'c81414d4-07fa-4e89-a6a5-b731cb399690',
        action: 'Sent',
        timestamps: '2024-01-16T06:23:21.379Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '0f4faacb-b949-418a-ac6e-d655426e4b1b',
        interventionId: 'c81414d4-07fa-4e89-a6a5-b731cb399690',
        action: 'Saved',
        timestamps: '2024-01-16T06:29:09.549Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '49a3fa00-6b80-4615-8a7b-ae6b0633ba10',
        interventionId: 'c81414d4-07fa-4e89-a6a5-b731cb399690',
        action: 'Created',
        timestamps: '2024-01-16T06:22:49.915Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: 'a397c1b5-9cff-4e47-8c56-f3a3c088d995',
        interventionId: 'c81414d4-07fa-4e89-a6a5-b731cb399690',
        action: 'Saved',
        timestamps: '2024-01-22T02:47:15.767Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'f13c69d2-f626-442c-85fb-28ae345fb0cb',
        interventionId: 'c81414d4-07fa-4e89-a6a5-b731cb399690',
        action: 'Saved',
        timestamps: '2024-01-16T06:31:26.915Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Resolve (Draft)',
    stateCode: 'R_1_0',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-16T06:23:21.379Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-16T06:22:49.889Z',
    updatedAt: '2024-02-06T15:57:23.207Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '2edeeb68-d72f-4cda-b1cf-3b4f23a35d7d',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test',
      type: 'TYPE06',
      runNo: '111',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '12',
      startMeasuredDepth: '16',
      tdMeasuredDepth: '26',
      belowRotaryTimestamp: '10/02/2023, 10:16 PM',
      runTdTimestamp: '10/04/2023, 10:16 PM',
      aboveRotaryTimestamp: '10/11/2023, 10:16 PM',
      tallyUrl:
        'https://hpmvrodevscusstapp.blob.core.windows.net/tally/1698719909012_sample-file.pdf',
    },
    isComplete: null,
  },
  {
    interventionId: 'c8154f1a-3a66-4524-8718-fdff089ac579',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706535741375_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1',
    holeMD: '11',
    currentOperation:
      '{"operation_id":"OP01","data":"Drilling Ahead","label":"Drilling Ahead"}',
    event: {
      event_id: 'EV17',
      label: 'Distance to Plan',
      data: 'Distance to Plan',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default', 'roadmap'],
      leaf: false,
      hierarchy: 'Borehole/Well Trajectory/Distance to Plan',
    },
    originalRisk:
      '[{"risk_id":"BOPR01","data":"Unstable/problematic borehole conditions","label":"Unstable/problematic borehole conditions"}]',
    risk: [
      {
        risk_id: 'BOPR01',
        label: 'Unstable/problematic borehole conditions',
        data: 'Unstable/problematic borehole conditions',
      },
    ],
    contactMethod: null,
    eventDescription: 'BaoPG3 1',
    effectiveDiameter: '',
    flag: 'blue',
    unit: 'API',
    recommendation: 'BaoPG3 1',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: false,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: 'BaoPG3 1',
    timeSaved: null,
    localTimestamps: '1706535660000',
    rigTimestamps: '1706532060000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: null,
    createUserId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
    status: 'INITIATE',
    hasSent: false,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706535737669_log.json',
    auditTrail: [
      {
        logId: '42cb8571-22b5-4534-86fe-f11e72ed03c7',
        interventionId: 'c8154f1a-3a66-4524-8718-fdff089ac579',
        action: 'Created',
        timestamps: '2024-01-29T13:42:17.442Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: null,
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Draft)',
    stateCode: 'I_0_0',
    engineer: null,
    engineerId: null,
    initiated: null,
    resolved: null,
    avatarLink: null,
    remoteCenter: null,
    remoteCenterId: null,
    disciplineName: null,
    createdAt: '2024-01-29T13:42:17.220Z',
    updatedAt: '2024-02-06T15:57:23.419Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '38187b2f-df00-430a-8975-ccf2cfae03e9',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test 1hahashagds 12/.23347"h',
      type: 'TYPE10',
      runNo: '12',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '23',
      startMeasuredDepth: '23',
      tdMeasuredDepth: '2,435',
      belowRotaryTimestamp: '10/17/2023, 10:13 PM',
      runTdTimestamp: '10/20/2023, 10:14 PM',
      aboveRotaryTimestamp: '10/22/2023, 10:14 PM',
      tallyUrl: null,
    },
    isComplete: null,
  },
  {
    interventionId: 'd2098d21-3292-4a0d-a6b4-eeb92d44a60a',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705637861815_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12',
    holeMD: '12',
    currentOperation:
      '{"operation_id":"OP07","data":"Tripping in with Cleanout Assembly","label":"Tripping in with Cleanout Assembly"}',
    event: {
      event_id: 'EV53',
      label: 'Torque',
      data: 'Torque',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'BHA/Operating Limits/Torque',
    },
    originalRisk:
      '[{"risk_id":"HSEH01","data":"Health incident","label":"Health incident"}]',
    risk: [
      {
        risk_id: 'HSEH01',
        label: 'Health incident',
        data: 'Health incident',
      },
    ],
    contactMethod: null,
    eventDescription: 'a',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'ád',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1705637700000',
    rigTimestamps: '1705634100000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: false,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705637794988_log.json',
    auditTrail: [
      {
        logId: '7c13d2f2-9d7a-486c-955e-c1ce93bb1e2c',
        interventionId: 'd2098d21-3292-4a0d-a6b4-eeb92d44a60a',
        action: 'Created',
        timestamps: '2024-01-19T04:16:34.964Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '7d9479e6-c502-4d4c-8857-b519ccb32e74',
        interventionId: 'd2098d21-3292-4a0d-a6b4-eeb92d44a60a',
        action: 'Saved',
        timestamps: '2024-01-19T04:17:38.661Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Draft)',
    stateCode: 'I_1_0',
    engineer: null,
    engineerId: null,
    initiated: null,
    resolved: null,
    avatarLink: null,
    remoteCenter: null,
    remoteCenterId: null,
    disciplineName: null,
    createdAt: '2024-01-19T04:16:34.941Z',
    updatedAt: '2024-02-06T15:57:28.357Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '38187b2f-df00-430a-8975-ccf2cfae03e9',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test 1hahashagds 12/.23347"h',
      type: 'TYPE10',
      runNo: '12',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '23',
      startMeasuredDepth: '23',
      tdMeasuredDepth: '2,435',
      belowRotaryTimestamp: '10/17/2023, 10:13 PM',
      runTdTimestamp: '10/20/2023, 10:14 PM',
      aboveRotaryTimestamp: '10/22/2023, 10:14 PM',
      tallyUrl: null,
    },
    isComplete: null,
  },
  {
    interventionId: 'd8b14dc2-4683-4820-a9a4-b6c5cabb3f18',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705969904960_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1',
    holeMD: '3',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV71',
      label: 'No Data Exchange',
      data: 'No Data Exchange',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Data Acquisition/No Data Exchange',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: null,
    eventDescription: 'hh',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'kh',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1705969800000',
    rigTimestamps: '1705966200000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: null,
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: '2024-01-23T01:26:38.668Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705969903609_log.json',
    auditTrail: [
      {
        logId: '7c4f01e7-17dd-4afc-ab5f-92169e197051',
        interventionId: 'd8b14dc2-4683-4820-a9a4-b6c5cabb3f18',
        action: 'Created',
        timestamps: '2024-01-23T00:31:43.577Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: 'efd89e0a-f0e8-4a4e-9c10-18976d61fec4',
        interventionId: 'd8b14dc2-4683-4820-a9a4-b6c5cabb3f18',
        action: 'Sent',
        timestamps: '2024-01-23T00:41:38.730Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Completed)',
    stateCode: 'I_1_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-23T00:41:38.730Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-23T00:31:43.545Z',
    updatedAt: '2024-02-06T15:57:29.415Z',
    eventType: 'intervention',
    interval: {
      intervalId: 'e20a8970-8f5c-40a7-a627-dcc8c5039ae9',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: '1',
      unit: 'SI',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '51',
      openHoleDiameter: '51',
      lastShoeMeasuredDepth: '235.34',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '7368b53c-23cd-4397-83ef-f8bcc4d8d815',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: 'e20a8970-8f5c-40a7-a627-dcc8c5039ae9',
      description: '4',
      type: 'TYPE10',
      runNo: '4',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '4',
      startMeasuredDepth: '4',
      tdMeasuredDepth: '4',
      belowRotaryTimestamp: '10/27/2023, 10:23 AM',
      runTdTimestamp: '10/28/2023, 10:23 AM',
      aboveRotaryTimestamp: '10/30/2023, 10:23 AM',
      tallyUrl: null,
    },
    isComplete: null,
  },
  {
    interventionId: 'd917732d-f7ec-40c0-b456-e41b2eedc0be',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706534512562_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '2',
    holeMD: '22',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV17',
      label: 'Distance to Plan',
      data: 'Distance to Plan',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default', 'roadmap'],
      leaf: false,
      hierarchy: 'Borehole/Well Trajectory/Distance to Plan',
    },
    originalRisk:
      '[{"risk_id":"RRFA01","data":"Circulation System Failure","label":"Circulation System Failure"},{"risk_id":"RRFA02","data":"Derrick and Substructure Failure","label":"Derrick and Substructure Failure"}]',
    risk: [
      {
        risk_id: 'RRFA02',
        label: 'Derrick and Substructure Failure',
        data: 'Derrick and Substructure Failure',
      },
      {
        risk_id: 'RRFA01',
        label: 'Circulation System Failure',
        data: 'Circulation System Failure',
      },
    ],
    contactMethod: null,
    eventDescription: 'BaoPG3',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'BaoPG3',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: false,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: 'BaoPG3',
    timeSaved: null,
    localTimestamps: '1706534400000',
    rigTimestamps: '1706530800000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: null,
    createUserId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
    status: 'INITIATE',
    hasSent: false,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706534496001_log.json',
    auditTrail: [
      {
        logId: 'b04a6b78-bdab-4f23-a2bb-a9f1c748975c',
        interventionId: 'd917732d-f7ec-40c0-b456-e41b2eedc0be',
        action: 'Created',
        timestamps: '2024-01-29T13:21:35.781Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: null,
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Draft)',
    stateCode: 'I_0_0',
    engineer: null,
    engineerId: null,
    initiated: null,
    resolved: null,
    avatarLink: null,
    remoteCenter: null,
    remoteCenterId: null,
    disciplineName: null,
    createdAt: '2024-01-29T13:21:35.346Z',
    updatedAt: '2024-02-06T15:57:29.628Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '38187b2f-df00-430a-8975-ccf2cfae03e9',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test 1hahashagds 12/.23347"h',
      type: 'TYPE10',
      runNo: '12',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '23',
      startMeasuredDepth: '23',
      tdMeasuredDepth: '2,435',
      belowRotaryTimestamp: '10/17/2023, 10:13 PM',
      runTdTimestamp: '10/20/2023, 10:14 PM',
      aboveRotaryTimestamp: '10/22/2023, 10:14 PM',
      tallyUrl: null,
    },
    isComplete: null,
  },
  {
    interventionId: 'd9526373-9e82-49a1-9dac-def6227facdb',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705303886646_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '123',
    holeMD: '345',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV71',
      label: 'No Data Exchange',
      data: 'No Data Exchange',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Data Acquisition/No Data Exchange',
    },
    originalRisk:
      '[{"risk_id":"ETSE02","data":"Casing / Tubing Running Equipment/Tools Failures","label":"Casing / Tubing Running Equipment/Tools Failures"},{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE02',
        label: 'Casing / Tubing Running Equipment/Tools Failures',
        data: 'Casing / Tubing Running Equipment/Tools Failures',
      },
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod:
      '[{"id":"phone","label":"Phone","data":"Phone"},{"contact_id":"C1","label":"C1 - Drilling Superintendent","data":"+1 832-123-4568"},{"contact_id":"C2","label":"C2 - Project Manager","data":"+1 832-123-4569"},{"contact_id":"C3","label":"C3 - Technical Advisor","data":"+1 832-123-4573"},{"contact_id":"C4","label":"C4 - Developer","data":"+1 132-434-6534"},{"contact_id":"E1","label":"E1 - Tester","data":"+1 832-123-4575"},{"contact_id":"E2","label":"E2 - Tester","data":"+1 832-123-4576"},{"contact_id":"E3","label":"E3 - Tester","data":"+1 123-253-4645"},{"contact_id":"E4","label":"E4 - Developer","data":"+1 123-253-4646"}]',
    eventDescription: '132',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'hsdf',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: false,
    isRecommendedActionTaken: 'no',
    resolution: 'ghj',
    sendNotificationOfResolutionTotheSameDistribution: '',
    rigBasedValidationDetail: '',
    timeSaved: 0,
    localTimestamps: '1705303140000',
    rigTimestamps: '1705299540000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'RESOLUTION',
    hasSent: true,
    timeExpire: '2024-01-15T08:05:51.843Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705303242779_log.json',
    auditTrail: [
      {
        logId: '1b203800-6173-4cf9-aa9d-18de0b6ae98c',
        interventionId: 'd9526373-9e82-49a1-9dac-def6227facdb',
        action: 'Sent',
        timestamps: '2024-01-15T07:31:32.599Z',
        isFirstSent: true,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '1c8b1087-1c39-4114-97e0-50b43dfbb60f',
        interventionId: 'd9526373-9e82-49a1-9dac-def6227facdb',
        action: 'Created',
        timestamps: '2024-01-15T07:20:42.759Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '3290b08b-5efa-4869-97a4-e1eb71a66598',
        interventionId: 'd9526373-9e82-49a1-9dac-def6227facdb',
        action: 'Saved',
        timestamps: '2024-01-15T07:27:03.164Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '895e916a-cecb-44e0-8e38-fc7c6b0c11d6',
        interventionId: 'd9526373-9e82-49a1-9dac-def6227facdb',
        action: 'Sent',
        timestamps: '2024-01-15T07:20:51.881Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '908f328a-8685-49cc-8125-0072c125cb25',
        interventionId: 'd9526373-9e82-49a1-9dac-def6227facdb',
        action: 'Saved',
        timestamps: '2024-01-15T07:21:10.128Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'cc8d6d13-8a32-43c0-82ec-bb29afa0141d',
        interventionId: 'd9526373-9e82-49a1-9dac-def6227facdb',
        action: 'Saved',
        timestamps: '2024-01-15T07:22:04.600Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'f955dc5a-0065-45f4-b503-4e3d7c248467',
        interventionId: 'd9526373-9e82-49a1-9dac-def6227facdb',
        action: 'Saved',
        timestamps: '2024-01-15T07:31:18.439Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Resolve (Completed)',
    stateCode: 'R_1_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-15T07:20:51.881Z',
    resolved: '2024-01-15T07:31:32.599Z',
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-15T07:20:42.731Z',
    updatedAt: '2024-02-06T15:57:29.840Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '2edeeb68-d72f-4cda-b1cf-3b4f23a35d7d',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test',
      type: 'TYPE06',
      runNo: '111',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '12',
      startMeasuredDepth: '16',
      tdMeasuredDepth: '26',
      belowRotaryTimestamp: '10/02/2023, 10:16 PM',
      runTdTimestamp: '10/04/2023, 10:16 PM',
      aboveRotaryTimestamp: '10/11/2023, 10:16 PM',
      tallyUrl:
        'https://hpmvrodevscusstapp.blob.core.windows.net/tally/1698719909012_sample-file.pdf',
    },
    isComplete: null,
  },
  {
    interventionId: 'da17dba6-fd25-46ee-b00a-e7a7af982b00',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1704440436159_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12',
    holeMD: '13',
    currentOperation: '1',
    event: {
      event_id: 'EV01',
      label: 'Flowback Volume',
      data: 'Flowback Volume',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Volumetrics/Flowback Volume',
    },
    originalRisk: '1',
    risk: '1',
    contactMethod: '[{"id":"email","label":"Email","data":"Email"}]',
    eventDescription:
      '1\n2\n3\n4\n5\n6\n7\n8\n9\n0\n1\n2\n3\n4\n5\n6\n7\n8\n9\n0',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation:
      '1\n2\n3\n4\n5\n6\n7\n8\n9\n0\n1\n2\n3\n4\n5\n6\n7\n8\n9\n0',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: 'yes',
    resolution: '1\n2\n3\n4\n5\n6\n7\n8\n9\n0\n1\n2\n3\n4\n5\n6\n7\n8\n9\n0',
    sendNotificationOfResolutionTotheSameDistribution: '',
    rigBasedValidationDetail: '1',
    timeSaved: 1,
    localTimestamps: '1704427020000',
    rigTimestamps: '1704423420000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    status: 'RESOLUTION',
    hasSent: true,
    timeExpire: '2024-01-05T04:47:48.631Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: false,
    isCustomRisk: false,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1704427047927_log.json',
    auditTrail: [
      {
        logId: '021a30b5-37c3-424a-977d-4e35f2adc271',
        interventionId: 'da17dba6-fd25-46ee-b00a-e7a7af982b00',
        action: 'Sent',
        timestamps: '2024-01-05T04:02:48.673Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '305c4931-9f2d-4c4e-9cac-3b51e95f86ec',
        interventionId: 'da17dba6-fd25-46ee-b00a-e7a7af982b00',
        action: 'Saved',
        timestamps: '2024-01-05T06:05:55.649Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
      {
        logId: '38ac60de-2276-46db-a078-aeeddbf23914',
        interventionId: 'da17dba6-fd25-46ee-b00a-e7a7af982b00',
        action: 'Saved',
        timestamps: '2024-01-05T04:20:08.536Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
      {
        logId: '3af24aa9-3e37-4f39-a903-dc1768bfd32f',
        interventionId: 'da17dba6-fd25-46ee-b00a-e7a7af982b00',
        action: 'Saved',
        timestamps: '2024-01-05T07:40:34.589Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '52263bf4-f944-44cb-8423-58c1a826ec0b',
        interventionId: 'da17dba6-fd25-46ee-b00a-e7a7af982b00',
        action: 'Saved',
        timestamps: '2024-01-05T04:02:43.041Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
      {
        logId: '58546408-2c50-41ea-83e5-7c30b9e444b7',
        interventionId: 'da17dba6-fd25-46ee-b00a-e7a7af982b00',
        action: 'Sent',
        timestamps: '2024-01-05T06:06:05.825Z',
        isFirstSent: true,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '754b343d-09d4-48bd-9fbf-3c4fbaebee58',
        interventionId: 'da17dba6-fd25-46ee-b00a-e7a7af982b00',
        action: 'Saved',
        timestamps: '2024-01-05T03:57:59.640Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
      {
        logId: '9b773ee4-9a76-4094-9944-61308a185cf7',
        interventionId: 'da17dba6-fd25-46ee-b00a-e7a7af982b00',
        action: 'Saved',
        timestamps: '2024-01-05T07:29:26.236Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: 'c84c868f-3b7d-419c-9a81-2073f70313da',
        interventionId: 'da17dba6-fd25-46ee-b00a-e7a7af982b00',
        action: 'Saved',
        timestamps: '2024-01-05T07:40:45.955Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'd0781561-ff67-4f89-b4af-466b1b133241',
        interventionId: 'da17dba6-fd25-46ee-b00a-e7a7af982b00',
        action: 'Created',
        timestamps: '2024-01-05T03:57:27.913Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: 'fcbc7bca-e6e1-42aa-8af8-91d2124d4345',
        interventionId: 'da17dba6-fd25-46ee-b00a-e7a7af982b00',
        action: 'Saved',
        timestamps: '2024-01-05T07:39:54.031Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'fe565057-7621-48a0-b5d8-d8ed8f4418f9',
        interventionId: 'da17dba6-fd25-46ee-b00a-e7a7af982b00',
        action: 'Saved',
        timestamps: '2024-01-05T07:29:06.256Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Resolve (Completed)',
    stateCode: 'R_1_1',
    engineer: 'Thanh Nguyen Cong (TEST)',
    engineerId: '365dbd8b-3313-4833-8efb-ffc478695821',
    initiated: '2024-01-05T04:02:48.673Z',
    resolved: '2024-01-05T06:06:05.825Z',
    avatarLink: null,
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-05T03:57:27.898Z',
    updatedAt: '2024-02-06T15:57:30.051Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: null,
    isComplete: null,
  },
  {
    interventionId: 'df81ef9a-d501-420f-b7ef-1d22a2bea3a0',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706086694052_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1',
    holeMD: '2',
    currentOperation: '3',
    event: {
      event_id: 'EV08',
      label: 'Trip Gas',
      data: 'Trip Gas',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Gas/Trip Gas',
    },
    originalRisk: 'ThanhNC40   01.24',
    risk: 'ThanhNC40   01.24',
    contactMethod: null,
    eventDescription: 'ThanhNC40 01.24',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation: 'ThanhNC40 01.24',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: 'ThanhNC40 01.24',
    timeSaved: null,
    localTimestamps: '1706086560000',
    rigTimestamps: '1706082960000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    createUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: false,
    isCustomRisk: false,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706086627489_log.json',
    auditTrail: [
      {
        logId: '942cd287-f86a-4fc9-8ab8-909fead9d291',
        interventionId: 'df81ef9a-d501-420f-b7ef-1d22a2bea3a0',
        action: 'Created',
        timestamps: '2024-01-24T08:57:07.477Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: 'b4c9510b-9aff-434b-b0fd-490f8d1f6864',
        interventionId: 'df81ef9a-d501-420f-b7ef-1d22a2bea3a0',
        action: 'Sent',
        timestamps: '2024-01-24T08:58:19.951Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'c1560e61-fa94-4ce4-b213-e9c3bbbc7efd',
        interventionId: 'df81ef9a-d501-420f-b7ef-1d22a2bea3a0',
        action: 'Saved',
        timestamps: '2024-01-24T08:58:12.929Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
      {
        logId: 'dc1356b0-d596-4eeb-a09f-6257db1baded',
        interventionId: 'df81ef9a-d501-420f-b7ef-1d22a2bea3a0',
        action: 'Saved',
        timestamps: '2024-01-24T09:03:30.014Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Draft)',
    stateCode: 'I_1_0',
    engineer: 'Thanh Nguyen Cong (TEST)',
    engineerId: '365dbd8b-3313-4833-8efb-ffc478695821',
    initiated: '2024-01-24T08:58:19.951Z',
    resolved: null,
    avatarLink: null,
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-24T08:57:07.453Z',
    updatedAt: '2024-02-06T15:57:31.318Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '2edeeb68-d72f-4cda-b1cf-3b4f23a35d7d',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test',
      type: 'TYPE06',
      runNo: '111',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '12',
      startMeasuredDepth: '16',
      tdMeasuredDepth: '26',
      belowRotaryTimestamp: '10/02/2023, 10:16 PM',
      runTdTimestamp: '10/04/2023, 10:16 PM',
      aboveRotaryTimestamp: '10/11/2023, 10:16 PM',
      tallyUrl:
        'https://hpmvrodevscusstapp.blob.core.windows.net/tally/1698719909012_sample-file.pdf',
    },
    isComplete: null,
  },
  {
    interventionId: 'e6f3118f-bb3c-464b-a839-cd503b026487',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705560601603_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '11',
    holeMD: '12',
    currentOperation:
      '{"operation_id":"OP01","data":"Drilling Ahead","label":"Drilling Ahead"}',
    event: {
      event_id: 'EV01',
      label: 'Flowback Volume',
      data: 'Flowback Volume',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Volumetrics/Flowback Volume',
    },
    originalRisk:
      '[{"risk_id":"BOPR01","data":"Unstable/problematic borehole conditions","label":"Unstable/problematic borehole conditions"}]',
    risk: [
      {
        risk_id: 'BOPR01',
        label: 'Unstable/problematic borehole conditions',
        data: 'Unstable/problematic borehole conditions',
      },
    ],
    contactMethod: null,
    eventDescription: 'EVENT 11111',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'EVENT',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: 'EVENT',
    timeSaved: null,
    localTimestamps: '1705559640000',
    rigTimestamps: '1705556040000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
    createUserId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
    status: 'INITIATE',
    hasSent: false,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705559812724_log.json',
    auditTrail: [
      {
        logId: '6358f8fa-b2b9-4b6c-91d9-e860e2d98362',
        interventionId: 'e6f3118f-bb3c-464b-a839-cd503b026487',
        action: 'Created',
        timestamps: '2024-01-18T06:36:52.702Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '84ddb0b4-8bba-425d-95c6-4c5b80c47ee3',
        interventionId: 'e6f3118f-bb3c-464b-a839-cd503b026487',
        action: 'Saved',
        timestamps: '2024-01-18T06:49:59.671Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Draft)',
    stateCode: 'I_1_0',
    engineer: null,
    engineerId: null,
    initiated: null,
    resolved: null,
    avatarLink: null,
    remoteCenter: null,
    remoteCenterId: null,
    disciplineName: null,
    createdAt: '2024-01-18T06:36:52.674Z',
    updatedAt: '2024-02-06T15:57:32.805Z',
    eventType: 'intervention',
    interval: null,
    run: null,
    isComplete: null,
  },
  {
    interventionId: 'e9655ad3-650f-4bed-bbb2-1e554d9ae7c0',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705396353465_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12',
    holeMD: '23.34',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV71',
      label: 'No Data Exchange',
      data: 'No Data Exchange',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Data Acquisition/No Data Exchange',
    },
    originalRisk:
      '[{"risk_id":"ETSE02","data":"Casing / Tubing Running Equipment/Tools Failures","label":"Casing / Tubing Running Equipment/Tools Failures"},{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
      {
        risk_id: 'ETSE02',
        label: 'Casing / Tubing Running Equipment/Tools Failures',
        data: 'Casing / Tubing Running Equipment/Tools Failures',
      },
    ],
    contactMethod: '[{"id":"email","label":"Email","data":"Email"}]',
    eventDescription: 'hhhhh edit',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'hh edit',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: false,
    isRecommendedActionTaken: 'yes',
    resolution: 'hhh',
    sendNotificationOfResolutionTotheSameDistribution: '',
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1705387200000',
    rigTimestamps: '1705383600000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'RESOLUTION',
    hasSent: true,
    timeExpire: '2024-01-16T07:26:47.947Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705387298681_log.json',
    auditTrail: [
      {
        logId: '446cdeac-fe72-48b2-b218-2996b4dcc5c5',
        interventionId: 'e9655ad3-650f-4bed-bbb2-1e554d9ae7c0',
        action: 'Saved',
        timestamps: '2024-01-16T06:42:54.033Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '48a761e6-772d-4181-9ed4-632bbe6a84db',
        interventionId: 'e9655ad3-650f-4bed-bbb2-1e554d9ae7c0',
        action: 'Sent',
        timestamps: '2024-01-16T06:54:14.206Z',
        isFirstSent: true,
        status: 'Resolve (Draft)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '5a3ea3ec-234a-4664-ba42-95259db31d03',
        interventionId: 'e9655ad3-650f-4bed-bbb2-1e554d9ae7c0',
        action: 'Created',
        timestamps: '2024-01-16T06:41:38.653Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '66461a32-9c7b-43a3-9203-d721e6bbbcc2',
        interventionId: 'e9655ad3-650f-4bed-bbb2-1e554d9ae7c0',
        action: 'Sent',
        timestamps: '2024-01-16T07:06:20.219Z',
        isFirstSent: true,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '73729112-1b76-463d-9b9f-f56ffeeadd4b',
        interventionId: 'e9655ad3-650f-4bed-bbb2-1e554d9ae7c0',
        action: 'Sent',
        timestamps: '2024-01-16T06:41:47.980Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '764d08b5-19d2-4cc0-b425-d63824d9aad2',
        interventionId: 'e9655ad3-650f-4bed-bbb2-1e554d9ae7c0',
        action: 'Saved',
        timestamps: '2024-01-16T06:47:20.220Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'ad2fe31a-6ef2-473d-bb77-bbbcb0796030',
        interventionId: 'e9655ad3-650f-4bed-bbb2-1e554d9ae7c0',
        action: 'Saved',
        timestamps: '2024-01-16T06:48:31.737Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'b663dbb3-20ad-4a46-90b5-fcb52c6f9ac0',
        interventionId: 'e9655ad3-650f-4bed-bbb2-1e554d9ae7c0',
        action: 'Saved',
        timestamps: '2024-01-16T07:04:56.839Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'c18f20cf-815a-42b4-95b4-6951427e1e9c',
        interventionId: 'e9655ad3-650f-4bed-bbb2-1e554d9ae7c0',
        action: 'Saved',
        timestamps: '2024-01-16T09:12:28.496Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: 'd0fdcfc0-36fe-4cc9-b0db-9b18ec81fec2',
        interventionId: 'e9655ad3-650f-4bed-bbb2-1e554d9ae7c0',
        action: 'Saved',
        timestamps: '2024-01-16T13:16:09.760Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Resolve (Completed)',
    stateCode: 'R_1_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-16T06:41:47.980Z',
    resolved: '2024-01-16T07:06:20.219Z',
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-16T06:41:38.620Z',
    updatedAt: '2024-02-06T15:57:33.649Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '2edeeb68-d72f-4cda-b1cf-3b4f23a35d7d',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test',
      type: 'TYPE06',
      runNo: '111',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '12',
      startMeasuredDepth: '16',
      tdMeasuredDepth: '26',
      belowRotaryTimestamp: '10/02/2023, 10:16 PM',
      runTdTimestamp: '10/04/2023, 10:16 PM',
      aboveRotaryTimestamp: '10/11/2023, 10:16 PM',
      tallyUrl:
        'https://hpmvrodevscusstapp.blob.core.windows.net/tally/1698719909012_sample-file.pdf',
    },
    isComplete: null,
  },
  {
    interventionId: 'e9e2a254-b722-4152-8d73-9ac3df135119',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705400151312_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '123',
    holeMD: '345',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV53',
      label: 'Torque',
      data: 'Torque',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'BHA/Operating Limits/Torque',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod:
      '[{"id":"phone","label":"Phone","data":"Phone"},{"id":"C1","label":"C1 - Drilling Superintendent","data":"+1 832-123-4568"},{"id":"C2","label":"C2 - Project Manager","data":"+1 832-123-4569"},{"id":"C3","label":"C3 - Technical Advisor","data":"+1 832-123-4573"},{"id":"C4","label":"C4 - Developer","data":"+1 132-434-6534"},{"id":"E1","label":"E1 - Tester","data":"+1 832-123-4575"},{"id":"E2","label":"E2 - Tester","data":"+1 832-123-4576"},{"id":"E3","label":"E3 - Tester","data":"+1 123-253-4645"},{"id":"E4","label":"E4 - Developer","data":"+1 123-253-4646"},{"id":"email","label":"Email","data":"Email"},{"id":"chat","label":"Chat","data":"Chat"}]',
    eventDescription: 'hh',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'jj',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: false,
    isRecommendedActionTaken: 'not-applicable',
    resolution: 'hh',
    sendNotificationOfResolutionTotheSameDistribution: '',
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1705399200000',
    rigTimestamps: '1705395600000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'RESOLUTION',
    hasSent: false,
    timeExpire: '2024-01-16T10:46:45.738Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705399278320_log.json',
    auditTrail: [
      {
        logId: '2830a877-3c47-4d3e-aad7-ea63f675a243',
        interventionId: 'e9e2a254-b722-4152-8d73-9ac3df135119',
        action: 'Created',
        timestamps: '2024-01-16T10:01:18.298Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '6408dc69-57e9-4808-9c49-635c46cc43cd',
        interventionId: 'e9e2a254-b722-4152-8d73-9ac3df135119',
        action: 'Sent',
        timestamps: '2024-01-16T10:01:45.786Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '6bebc27c-ad90-4edf-a3d2-a6961d40ab60',
        interventionId: 'e9e2a254-b722-4152-8d73-9ac3df135119',
        action: 'Saved',
        timestamps: '2024-01-16T10:39:23.758Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'ca215389-e28f-42a9-9a86-ba7b204fa4c7',
        interventionId: 'e9e2a254-b722-4152-8d73-9ac3df135119',
        action: 'Saved',
        timestamps: '2024-01-16T10:15:49.920Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'd1154d20-846a-4d3e-b1df-dc942e38f067',
        interventionId: 'e9e2a254-b722-4152-8d73-9ac3df135119',
        action: 'Saved',
        timestamps: '2024-01-16T10:05:26.574Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Resolve (Draft)',
    stateCode: 'R_1_0',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-16T10:01:45.786Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-16T10:01:18.265Z',
    updatedAt: '2024-02-06T15:57:34.070Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '2edeeb68-d72f-4cda-b1cf-3b4f23a35d7d',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test',
      type: 'TYPE06',
      runNo: '111',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '12',
      startMeasuredDepth: '16',
      tdMeasuredDepth: '26',
      belowRotaryTimestamp: '10/02/2023, 10:16 PM',
      runTdTimestamp: '10/04/2023, 10:16 PM',
      aboveRotaryTimestamp: '10/11/2023, 10:16 PM',
      tallyUrl:
        'https://hpmvrodevscusstapp.blob.core.windows.net/tally/1698719909012_sample-file.pdf',
    },
    isComplete: null,
  },
  {
    interventionId: 'f2048289-298f-42d2-87fa-12cd8640e8eb',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706082383414_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '123',
    holeMD: '124',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV71',
      label: 'No Data Exchange',
      data: 'No Data Exchange',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Data Acquisition/No Data Exchange',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: null,
    eventDescription: 'hj',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'ghsfgd',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1706082180000',
    rigTimestamps: '1706078580000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706082311285_log.json',
    auditTrail: [
      {
        logId: '11af93f0-2c92-468c-af35-51f8cdd94a94',
        interventionId: 'f2048289-298f-42d2-87fa-12cd8640e8eb',
        action: 'Created',
        timestamps: '2024-01-24T07:45:11.262Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '6a8dac10-2522-4916-96bd-4943b953ac14',
        interventionId: 'f2048289-298f-42d2-87fa-12cd8640e8eb',
        action: 'Sent',
        timestamps: '2024-01-24T07:45:18.901Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '6abfc03c-abdb-4f1f-810e-c5d20171db68',
        interventionId: 'f2048289-298f-42d2-87fa-12cd8640e8eb',
        action: 'Saved',
        timestamps: '2024-01-24T07:46:18.718Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Draft)',
    stateCode: 'I_1_0',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-24T07:45:18.901Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-24T07:45:11.244Z',
    updatedAt: '2024-02-06T15:57:35.551Z',
    eventType: 'intervention',
    interval: {
      intervalId: 'e20a8970-8f5c-40a7-a627-dcc8c5039ae9',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: '1',
      unit: 'SI',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '51',
      openHoleDiameter: '51',
      lastShoeMeasuredDepth: '235.34',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '51057d99-7501-4cee-aab7-80a8e1aaae34',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: 'e20a8970-8f5c-40a7-a627-dcc8c5039ae9',
      description: '6',
      type: 'TYPE10',
      runNo: '6',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '6',
      startMeasuredDepth: '7',
      tdMeasuredDepth: '7',
      belowRotaryTimestamp: '10/28/2023, 10:40 AM',
      runTdTimestamp: '10/29/2023, 10:40 AM',
      aboveRotaryTimestamp: '10/30/2023, 10:40 AM',
      tallyUrl: null,
    },
    isComplete: null,
  },
  {
    interventionId: 'fd279639-1866-4d8b-95fa-d97dbecc5414',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706511680864_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1',
    holeMD: '2',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV17',
      label: 'Distance to Plan',
      data: 'Distance to Plan',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default', 'roadmap'],
      leaf: false,
      hierarchy: 'Borehole/Well Trajectory/Distance to Plan',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: null,
    eventDescription: 'hhh',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'test ne',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1706508960000',
    rigTimestamps: '1706505360000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: '2024-01-29T07:48:54.987Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706509125684_log.json',
    auditTrail: [
      {
        logId: '1786729f-93d6-4115-b1e2-569e9162caec',
        interventionId: 'fd279639-1866-4d8b-95fa-d97dbecc5414',
        action: 'Sent',
        timestamps: '2024-01-29T07:03:55.048Z',
        isFirstSent: false,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '207b5eb5-9a98-46fb-bf48-7ae21dddaf59',
        interventionId: 'fd279639-1866-4d8b-95fa-d97dbecc5414',
        action: 'Sent',
        timestamps: '2024-01-29T06:21:04.675Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '54c33b52-0ba9-41bc-83d2-0b9ca40a8c72',
        interventionId: 'fd279639-1866-4d8b-95fa-d97dbecc5414',
        action: 'Marked completed',
        timestamps: '2024-01-29T21:48:49.373Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '9256ffa8-8436-419e-b883-e380a0a15a55',
        interventionId: 'fd279639-1866-4d8b-95fa-d97dbecc5414',
        action: 'Saved',
        timestamps: '2024-01-29T06:22:26.981Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'c271d7a6-c923-4ea5-a998-ab57f7513486',
        interventionId: 'fd279639-1866-4d8b-95fa-d97dbecc5414',
        action: 'Saved',
        timestamps: '2024-01-29T07:01:17.467Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'd4629e1a-ceb6-432c-8c17-8c18bf945c90',
        interventionId: 'fd279639-1866-4d8b-95fa-d97dbecc5414',
        action: 'Saved',
        timestamps: '2024-01-29T06:55:11.965Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'e140cdc2-d4ba-4429-a865-97977b3bc5c6',
        interventionId: 'fd279639-1866-4d8b-95fa-d97dbecc5414',
        action: 'Saved',
        timestamps: '2024-01-29T06:24:49.058Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'fa9c8713-0058-42a7-850f-5ebf23121438',
        interventionId: 'fd279639-1866-4d8b-95fa-d97dbecc5414',
        action: 'Created',
        timestamps: '2024-01-29T06:18:45.652Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Completed)',
    stateCode: 'I_0_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-29T06:21:04.675Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-29T06:18:45.583Z',
    updatedAt: '2024-02-06T15:57:38.098Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '2edeeb68-d72f-4cda-b1cf-3b4f23a35d7d',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test',
      type: 'TYPE06',
      runNo: '111',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '12',
      startMeasuredDepth: '16',
      tdMeasuredDepth: '26',
      belowRotaryTimestamp: '10/02/2023, 10:16 PM',
      runTdTimestamp: '10/04/2023, 10:16 PM',
      aboveRotaryTimestamp: '10/11/2023, 10:16 PM',
      tallyUrl:
        'https://hpmvrodevscusstapp.blob.core.windows.net/tally/1698719909012_sample-file.pdf',
    },
    isComplete: true,
  },
  {
    interventionId: '0c511282-450e-4ce2-8e40-a0d9d10af207',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706174747476_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12',
    holeMD: '23',
    currentOperation:
      '{"operation_id":"OP01","data":"Drilling Ahead","label":"Drilling Ahead"}',
    event: {
      event_id: 'EV01',
      label: 'Flowback Volume',
      data: 'Flowback Volume',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Volumetrics/Flowback Volume',
    },
    originalRisk:
      '[{"risk_id":"HSEH01","data":"Health incident","label":"Health incident"}]',
    risk: [
      {
        risk_id: 'HSEH01',
        label: 'Health incident',
        data: 'Health incident',
      },
    ],
    contactMethod: null,
    eventDescription: 'hhh',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'hhh',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1706174640000',
    rigTimestamps: '1706171040000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: '2024-01-25T10:40:16.530Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706174746635_log.json',
    auditTrail: [
      {
        logId: '094a211a-60c2-4529-a29e-598f4ed47bec',
        interventionId: '0c511282-450e-4ce2-8e40-a0d9d10af207',
        action: 'Marked completed',
        timestamps: '2024-01-26T04:29:56.378Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Tuyet Nguyen',
        userId: 'bce7ddc8-1a4a-4a29-b1e8-ebf96f3433ec',
        userRemoteCenter: null,
      },
      {
        logId: '1823512f-e262-4aac-8f94-b401d2c43ecb',
        interventionId: '0c511282-450e-4ce2-8e40-a0d9d10af207',
        action: 'Saved',
        timestamps: '2024-01-26T03:36:15.650Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
      {
        logId: '481fffa6-8dc7-402c-9c5f-ecd42c5d5c88',
        interventionId: '0c511282-450e-4ce2-8e40-a0d9d10af207',
        action: 'Status Reset',
        timestamps: '2024-02-22T06:30:08.939Z',
        isFirstSent: false,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '6590310b-84ed-479b-beec-b482e60d0b80',
        interventionId: '0c511282-450e-4ce2-8e40-a0d9d10af207',
        action: 'Sent',
        timestamps: '2024-01-25T09:55:16.564Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '7965860c-fc71-44ba-9af9-7f35250886d4',
        interventionId: '0c511282-450e-4ce2-8e40-a0d9d10af207',
        action: 'Created',
        timestamps: '2024-01-25T09:25:46.610Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: {
      chatId: '8c409c60-d07a-450e-8a9b-500749a1cd44',
      content: 'd',
      interventionId: '0c511282-450e-4ce2-8e40-a0d9d10af207',
      userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
      attachments: [],
      nominateInformation: [
        {
          userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
          userDisplayName: 'Nga Tran Thi Kieu',
          userRole: 'Engineer',
          timestamps: 1706255801068,
          discipline: 'TEST',
          remoteCenter: 'VRO Testing',
          mail: 'Nga.TranThiKieu@halliburton.com',
        },
      ],
      nominateAt: '1706255801068',
      nominateVote: 1,
      createdAt: '2024-01-26T07:56:40.389Z',
      updatedAt: '2024-01-26T07:56:41.069Z',
    },
    currentState: 'Initiate (Completed)',
    stateCode: 'I_1_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-25T09:55:16.564Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-25T09:25:46.589Z',
    updatedAt: '2024-02-22T06:30:08.939Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '2edeeb68-d72f-4cda-b1cf-3b4f23a35d7d',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test',
      type: 'TYPE06',
      runNo: '111',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '12',
      startMeasuredDepth: '16',
      tdMeasuredDepth: '26',
      belowRotaryTimestamp: '10/02/2023, 10:16 PM',
      runTdTimestamp: '10/04/2023, 10:16 PM',
      aboveRotaryTimestamp: '10/11/2023, 10:16 PM',
      tallyUrl:
        'https://hpmvrodevscusstapp.blob.core.windows.net/tally/1698719909012_sample-file.pdf',
    },
    isComplete: false,
  },
  {
    interventionId: '07dcf6af-ab4f-4883-9fa0-0c198f01e592',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706583825382_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12',
    holeMD: '24',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV01',
      label: 'Flowback Volume',
      data: 'Flowback Volume',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Volumetrics/Flowback Volume',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: null,
    eventDescription: 'Test',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation: 'hhh',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: 'test',
    timeSaved: null,
    localTimestamps: '1706583720000',
    rigTimestamps: '1706580120000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: null,
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: '2024-01-30T03:49:19.693Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706583820488_log.json',
    auditTrail: [
      {
        logId: '1255272c-2999-4cdf-82f4-f253e43cffc9',
        interventionId: '07dcf6af-ab4f-4883-9fa0-0c198f01e592',
        action: 'Marked completed',
        timestamps: '2024-02-22T07:52:09.566Z',
        isFirstSent: false,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Tue Nguyen Minh',
        userId: '59d90418-7015-4036-91b7-f5635f5e4c3a',
        userRemoteCenter: null,
      },
      {
        logId: '7a13ebf1-fa5d-4521-8176-07ac80030160',
        interventionId: '07dcf6af-ab4f-4883-9fa0-0c198f01e592',
        action: 'Sent',
        timestamps: '2024-01-30T03:04:19.733Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'e9614d7d-a04d-413e-8945-2a3e3eaf2375',
        interventionId: '07dcf6af-ab4f-4883-9fa0-0c198f01e592',
        action: 'Created',
        timestamps: '2024-01-30T03:03:40.462Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Completed)',
    stateCode: 'I_0_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-30T03:04:19.733Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-30T03:03:40.437Z',
    updatedAt: '2024-02-22T07:52:09.566Z',
    eventType: 'intervention',
    interval: {
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: 'Test 1 con ga 1.9864/12" hole aaaaaaaaaaaaaaaaaah',
      unit: 'API',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '1,324',
      openHoleDiameter: '124,235.467',
      lastShoeMeasuredDepth: '3,464,576.53',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '2edeeb68-d72f-4cda-b1cf-3b4f23a35d7d',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: '5ee5f8f0-fbf6-47dc-a875-7379e3d41379',
      description: 'Test',
      type: 'TYPE06',
      runNo: '111',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '12',
      startMeasuredDepth: '16',
      tdMeasuredDepth: '26',
      belowRotaryTimestamp: '10/02/2023, 10:16 PM',
      runTdTimestamp: '10/04/2023, 10:16 PM',
      aboveRotaryTimestamp: '10/11/2023, 10:16 PM',
      tallyUrl:
        'https://hpmvrodevscusstapp.blob.core.windows.net/tally/1698719909012_sample-file.pdf',
    },
    isComplete: true,
  },
  {
    interventionId: '0e5ee8af-a152-4c25-8894-9667588498d9',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1704699286526_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '2',
    holeMD: '3',
    currentOperation: '4',
    event: {
      event_id: 'EV01',
      label: 'Flowback Volume',
      data: 'Flowback Volume',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Volumetrics/Flowback Volume',
    },
    originalRisk: '1',
    risk: '1',
    contactMethod:
      '[{"id":"phone","label":"Phone","data":"Phone"},{"contact_id":"C1","label":"C1 - Drilling Superintendent","data":"+1 832-123-4568"},{"contact_id":"C2","label":"C2 - Project Manager","data":"+1 832-123-4569"},{"contact_id":"C3","label":"C3 - Technical Advisor","data":"+1 832-123-4573"},{"contact_id":"C4","label":"C4 - Developer","data":"+1 132-434-6534"},{"contact_id":"E1","label":"E1 - Tester","data":"+1 832-123-4575"},{"contact_id":"E2","label":"E2 - Tester","data":"+1 832-123-4576"},{"contact_id":"E3","label":"E3 - Tester","data":"+1 123-253-4645"},{"contact_id":"E4","label":"E4 - Developer","data":"+1 123-253-4646"}]',
    eventDescription: '5',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'API',
    recommendation: '1',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: 'yes',
    resolution: 'q Edit',
    sendNotificationOfResolutionTotheSameDistribution: '',
    rigBasedValidationDetail: '2',
    timeSaved: 1,
    localTimestamps: '1704699000000',
    rigTimestamps: '1704695400000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '365dbd8b-3313-4833-8efb-ffc478695821',
    status: 'RESOLUTION',
    hasSent: false,
    timeExpire: '2024-01-08T08:18:57.083Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: false,
    isCustomRisk: false,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1704699070224_log.json',
    auditTrail: [
      {
        logId: '26359a61-5c02-445f-b46b-b181ac93d86c',
        interventionId: '0e5ee8af-a152-4c25-8894-9667588498d9',
        action: 'Status reset',
        timestamps: '2024-01-15T07:39:27.330Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '4193b0b3-112e-4a0f-800d-09ca2fb67b6a',
        interventionId: '0e5ee8af-a152-4c25-8894-9667588498d9',
        action: 'Marked completed',
        timestamps: '2024-01-15T07:45:29.212Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '5217a3ab-aa73-498e-8d18-93ca258a66b6',
        interventionId: '0e5ee8af-a152-4c25-8894-9667588498d9',
        action: 'Marked completed',
        timestamps: '2024-01-15T07:39:07.595Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '6835d91c-afd5-4033-a9f6-ac830868878b',
        interventionId: '0e5ee8af-a152-4c25-8894-9667588498d9',
        action: 'Status reset',
        timestamps: '2024-01-15T07:38:38.889Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '8bccb3dc-2de6-4871-b575-00bdcf2ecfa6',
        interventionId: '0e5ee8af-a152-4c25-8894-9667588498d9',
        action: 'Marked completed',
        timestamps: '2024-01-15T07:35:05.531Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '90ea7a7a-ae23-4851-b633-2d89f841cc7d',
        interventionId: '0e5ee8af-a152-4c25-8894-9667588498d9',
        action: 'Created',
        timestamps: '2024-01-08T07:31:10.207Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '97fe50ef-58c2-4be1-aa31-b84fe415461d',
        interventionId: '0e5ee8af-a152-4c25-8894-9667588498d9',
        action: 'Saved',
        timestamps: '2024-01-08T07:34:44.263Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
      {
        logId: 'a52e0b1f-2ca5-4924-85df-ff9cec7b2373',
        interventionId: '0e5ee8af-a152-4c25-8894-9667588498d9',
        action: 'Sent',
        timestamps: '2024-01-08T07:33:57.120Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'b735a835-9c4d-4f43-b9de-6fb4446bd281',
        interventionId: '0e5ee8af-a152-4c25-8894-9667588498d9',
        action: 'Saved',
        timestamps: '2024-01-08T07:34:58.162Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
      {
        logId: 'cacb2fa6-042d-4c16-b15a-1ba28bf12e1c',
        interventionId: '0e5ee8af-a152-4c25-8894-9667588498d9',
        action: 'Status Reset',
        timestamps: '2024-02-23T10:17:45.421Z',
        isFirstSent: false,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'ceea3a52-740d-4f75-a254-35787c57e2f1',
        interventionId: '0e5ee8af-a152-4c25-8894-9667588498d9',
        action: 'Saved',
        timestamps: '2024-01-15T07:55:54.187Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'fd70853a-6584-4ab9-98a2-a4ce843a7d59',
        interventionId: '0e5ee8af-a152-4c25-8894-9667588498d9',
        action: 'Saved',
        timestamps: '2024-01-08T07:33:34.236Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Thanh Nguyen Cong',
        userId: '365dbd8b-3313-4833-8efb-ffc478695821',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: {
      chatId: 'abb75467-e781-4752-9a7b-17901a2f9ebe',
      content: 'h',
      interventionId: '0e5ee8af-a152-4c25-8894-9667588498d9',
      userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
      attachments: [],
      nominateInformation: [
        {
          userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
          userDisplayName: 'Nga Tran Thi Kieu',
          userRole: 'Admin',
          timestamps: 1705313242565,
          mail: 'Nga.TranThiKieu@halliburton.com',
        },
      ],
      nominateAt: '1705313242565',
      nominateVote: 1,
      createdAt: '2024-01-15T07:51:08.836Z',
      updatedAt: '2024-01-15T10:07:22.566Z',
    },
    currentState: 'Resolve (Draft)',
    stateCode: 'R_1_0',
    engineer: 'Thanh Nguyen Cong (TEST)',
    engineerId: '365dbd8b-3313-4833-8efb-ffc478695821',
    initiated: '2024-01-08T07:33:57.120Z',
    resolved: null,
    avatarLink: null,
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-08T07:31:10.183Z',
    updatedAt: '2024-02-23T10:17:45.421Z',
    eventType: 'intervention',
    interval: {
      intervalId: 'e20a8970-8f5c-40a7-a627-dcc8c5039ae9',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: '1',
      unit: 'SI',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: '51',
      openHoleDiameter: '51',
      lastShoeMeasuredDepth: '235.34',
      isNaCaseHoleLastShoe: false,
      isNaOpenHole: false,
    },
    run: {
      runId: '51057d99-7501-4cee-aab7-80a8e1aaae34',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalId: 'e20a8970-8f5c-40a7-a627-dcc8c5039ae9',
      description: '6',
      type: 'TYPE10',
      runNo: '6',
      unit: 'API',
      rigBhaNo: '',
      bitSize: '',
      reamerSize: '',
      casingSize: '6',
      startMeasuredDepth: '7',
      tdMeasuredDepth: '7',
      belowRotaryTimestamp: '10/28/2023, 10:40 AM',
      runTdTimestamp: '10/29/2023, 10:40 AM',
      aboveRotaryTimestamp: '10/30/2023, 10:40 AM',
      tallyUrl: null,
    },
    isComplete: false,
  },
  {
    interventionId: '52bb6825-e6f5-4d8a-804a-f49296da848e',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1698647288210_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '2',
    runNumber: '2',
    bitMD: '11',
    holeMD: '222',
    currentOperation:
      '{"operation_id":"OP01","data":"Drilling Ahead","label":"Drilling Ahead"}',
    event: {
      event_id: 'EV53',
      label: 'Torque',
      data: 'Torque',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'BHA/Operating Limits/Torque',
    },
    originalRisk:
      '[{"risk_id":"BOPR01","data":"Unstable/problematic borehole conditions","label":"Unstable/problematic borehole conditions"}]',
    risk: [
      {
        risk_id: 'BOPR01',
        label: 'Unstable/problematic borehole conditions',
        data: 'Unstable/problematic borehole conditions',
      },
    ],
    contactMethod: null,
    eventDescription: 'Bao test',
    effectiveDiameter: '',
    flag: 'red',
    unit: 'API',
    recommendation: 'Bao test',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: 'Bao test',
    timeSaved: null,
    localTimestamps: '1698647220000',
    rigTimestamps: '1698647220000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: null,
    createUserId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
    status: 'INITIATE',
    hasSent: false,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1698647285511_log.json',
    auditTrail: [
      {
        logId: '4fe216ec-3d98-4e51-b5e1-a0767c015ccd',
        interventionId: '52bb6825-e6f5-4d8a-804a-f49296da848e',
        action: 'Created',
        timestamps: '2023-10-30T06:28:05.488Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: null,
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Draft)',
    stateCode: 'I_1_0',
    engineer: null,
    engineerId: null,
    initiated: null,
    resolved: null,
    avatarLink: null,
    remoteCenter: null,
    remoteCenterId: null,
    disciplineName: null,
    createdAt: '2023-10-30T06:28:05.468Z',
    updatedAt: '2024-04-02T03:27:36.342Z',
    eventType: 'intervention',
    interval: null,
    run: null,
    isComplete: null,
  },
  {
    interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1699442819392_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12.23',
    holeMD: '34.34',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV01',
      label: 'Flowback Volume',
      data: 'Flowback Volume',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Borehole/Volumetrics/Flowback Volume',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod:
      '[{"id":"phone","label":"Phone","data":"Phone"},{"contact_id":"C1","label":"C1 - Drilling Superintendent","data":"+1 832-123-4568"},{"contact_id":"C2","label":"C2 - Project Manager","data":"+1 832-123-4569"},{"contact_id":"C3","label":"C3 - Technical Advisor","data":"+1 832-123-4573"},{"contact_id":"C4","label":"C4 - Developer","data":"+1 132-434-6534"},{"contact_id":"E1","label":"E1 - Tester","data":"+1 832-123-4575"},{"contact_id":"E2","label":"E2 - Tester","data":"+1 832-123-4576"},{"contact_id":"E3","label":"E3 - Tester","data":"+1 123-253-4645"},{"contact_id":"E4","label":"E4 - Developer","data":"+1 123-253-4646"},{"id":"email","label":"Email","data":"Email"}]',
    eventDescription:
      'Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test .',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'SI',
    recommendation:
      'Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test .',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: false,
    isRecommendedActionTaken: 'not-applicable',
    resolution:
      'testThe uploaded image and the supplied data should appear correctly in the fields.The uploaded image and the supplied data should appear correctly in the fields.The uploaded image and the supplied data should appear correctly in the fields.The uploaded image and the supplied data should appear correctly in the fields.The uploaded image and the supplied data should appear correctly in the fields.The uploaded image and the supplied data should appear correctly in the fields.The uploaded image and the supplied data should appear correctly in the fields.The uploaded image and the supplied data should appear correctly in the fields.The uploaded image and the supplied data should appear correctly in the fields.The uploaded image and the supplied data should appear correctly in the fields.The uploaded image and the supplied data should appear correctly in the fields.The uploaded image and the supplied data should appear correctly in the fields.The uploaded image and the supplied data should appear correctly in the fields.The uploaded image and the supplied data should appear correctly in the fields.The uploaded image and the supplied data should appear correctly in the fields.The uploaded image and the supplied data should appear correctly in the fields.The uploaded image and the supplied data should appear correctly in the fields.The uploaded image and the supplied data should appear correctly in the fields.The uploaded image and the supplied data should appear correctly in the fields.The uploaded image and the supplied data should appear correctly in the fields.The uploaded image and the supplied data should appear correctly in the fields.The uploaded image and the supplied data should appear correctly in the fields.The uploaded image and the supplied data should appear correctly in the fields.The uploaded image and the supplied data should appear correctly in the fields.The uploaded image and th',
    sendNotificationOfResolutionTotheSameDistribution: '',
    rigBasedValidationDetail: '',
    timeSaved: 0,
    localTimestamps: '1699499700000',
    rigTimestamps: '1699499700000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'RESOLUTION',
    hasSent: true,
    timeExpire: '2023-11-08T04:00:51.727Z',
    isOverrideTime: true,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1699413033689_log.json',
    auditTrail: [
      {
        logId: '00cc6b04-cb1b-441d-992d-a57879e767be',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T03:12:00.780Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '091f0113-9e20-4c4a-bcfa-8f2b1b530533',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T10:09:38.472Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '2218ac05-74a1-4074-98e7-a44d879e1fef',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T03:27:11.665Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '265b0cee-0e12-419b-a959-2d432fd4e5bd',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T03:28:10.850Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '27aa5c71-023c-4773-a9b1-eb41236b9faa',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T03:24:43.943Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '28c13acb-292f-4b03-b5ce-c469f86eed65',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Sent',
        timestamps: '2023-11-08T03:13:09.690Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '2a2397b9-b300-4919-b58a-882121e6338c',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T04:40:55.719Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '313db65c-ac3d-4ecc-8d98-17bdb73c2875',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T03:34:31.099Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '40363245-e7e6-44bf-bfee-6ef44defbd8a',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T03:12:21.752Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '40387a25-d317-4b2b-8395-8a605d9585d9',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T03:14:42.421Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '40af9011-9289-42c9-8f4b-5f9ac6528d93',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T07:03:21.336Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '40b18301-6219-4bee-8941-6efd28e1cf76',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Sent',
        timestamps: '2023-11-08T04:47:37.263Z',
        isFirstSent: true,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '45a1f4cd-48de-4434-a214-416c0aa9711f',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T03:31:48.542Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '4772e536-5085-4214-abbc-e21b39f705c9',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T03:29:34.530Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '477e8ba0-2ac4-41cc-856b-8181fb948b23',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Sent',
        timestamps: '2023-11-08T08:51:08.314Z',
        isFirstSent: false,
        status: 'Resolve (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '4b1b5eff-ab61-493c-a20c-42ec19ccd329',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T04:46:53.195Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '4be65ec9-37d8-4cb8-8ee6-e4d711e11953',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T03:11:24.407Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '63755024-245d-4091-b5fc-cb705d27c445',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T11:26:55.632Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '6a8c1ad5-813a-49b9-b24f-78656dd56c24',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T03:18:16.086Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '72cf695d-9809-40a9-ad59-46c8eb6becbc',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T03:25:29.662Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '786c0c04-a2a4-4206-87d4-cd738a6f0fd5',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T03:29:59.627Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '85176a61-b383-48ab-805b-f7b95e2aa447',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T08:50:15.725Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '8f83554e-9c31-452d-9a63-d8a56039f601',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T07:09:34.500Z',
        isFirstSent: null,
        status: 'Resolve (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '95db81c6-b69e-4de5-af4b-6cc156d32bf0',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T03:13:55.533Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '9d7b5611-570e-4f57-9028-29d7c7ecd15c',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T03:16:54.929Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '9eede84d-1210-45b3-aa65-5a155586420f',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T03:15:32.788Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'a2acfee4-0f46-45cf-b0d7-e6195a62598d',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Sent',
        timestamps: '2023-11-08T03:15:51.754Z',
        isFirstSent: false,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'afcfd577-34c5-4dc7-acd6-ad9aaca28fc6',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Created',
        timestamps: '2023-11-08T03:10:33.673Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: 'b10cd8b5-a7e5-4df8-8d03-93bdec9e3f40',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T03:27:51.749Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'bc7a4bfe-dca9-43a8-afd9-88cfa91bf87d',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T03:12:40.237Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'dd52066f-2620-40a8-80e9-b9ff53309945',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T04:40:11.106Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: 'de94f4d9-63f7-47f1-bb97-a11b0dbcc015',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T04:47:23.772Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'e074a48a-b984-40cc-ace9-3e4cb63242fc',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T03:31:19.573Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'eb86df42-47f5-4744-b1be-ebedde7df2fe',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T03:25:57.778Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'f6870bf8-815b-4fe1-a93b-2e5265857985',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T03:17:49.595Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'fc9c4568-f2cc-482d-a13e-23919be060b2',
        interventionId: 'a2cc8a4f-2fa7-4657-bc40-819e728ddc86',
        action: 'Saved',
        timestamps: '2023-11-08T03:26:39.877Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Resolve (Completed)',
    stateCode: 'R_1_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2023-11-08T03:13:09.690Z',
    resolved: '2023-11-08T04:47:37.263Z',
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2023-11-08T03:10:33.648Z',
    updatedAt: '2024-04-02T03:27:44.196Z',
    eventType: 'intervention',
    interval: null,
    run: null,
    isComplete: null,
  },
  {
    interventionId: 'db46159c-dd1b-4b37-8a66-bf6d110ea67a',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705385901365_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '12.3',
    holeMD: '12.5',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV71',
      label: 'No Data Exchange',
      data: 'No Data Exchange',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Data Acquisition/No Data Exchange',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"},{"risk_id":"ETSE02","data":"Casing / Tubing Running Equipment/Tools Failures","label":"Casing / Tubing Running Equipment/Tools Failures"}]',
    risk: [
      {
        risk_id: 'ETSE02',
        label: 'Casing / Tubing Running Equipment/Tools Failures',
        data: 'Casing / Tubing Running Equipment/Tools Failures',
      },
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: null,
    eventDescription: 'T',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'T',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1705471980000',
    rigTimestamps: '1705471980000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: '2024-01-16T07:03:29.572Z',
    isOverrideTime: true,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705385282951_log.json',
    auditTrail: [
      {
        logId: '1a9ac487-7709-4499-8810-a0c5b9823c7d',
        interventionId: 'db46159c-dd1b-4b37-8a66-bf6d110ea67a',
        action: 'Created',
        timestamps: '2024-01-16T06:08:02.928Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '1e8702ec-b9d0-4a0a-ae9a-a3c24919f773',
        interventionId: 'db46159c-dd1b-4b37-8a66-bf6d110ea67a',
        action: 'Saved',
        timestamps: '2024-01-16T06:18:18.644Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '3b459647-dec2-4c88-b2b6-470d4f9a4da4',
        interventionId: 'db46159c-dd1b-4b37-8a66-bf6d110ea67a',
        action: 'Sent',
        timestamps: '2024-01-16T06:13:48.825Z',
        isFirstSent: false,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '3e8ef032-ac96-46a5-a640-45095bc1c32c',
        interventionId: 'db46159c-dd1b-4b37-8a66-bf6d110ea67a',
        action: 'Saved',
        timestamps: '2024-01-16T06:16:27.150Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '4123d33a-9767-4d46-83c5-7096d75df200',
        interventionId: 'db46159c-dd1b-4b37-8a66-bf6d110ea67a',
        action: 'Saved',
        timestamps: '2024-01-16T06:11:27.173Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '4995b129-2969-431a-bddd-2bcf7a77bd88',
        interventionId: 'db46159c-dd1b-4b37-8a66-bf6d110ea67a',
        action: 'Sent',
        timestamps: '2024-01-16T06:18:29.616Z',
        isFirstSent: false,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '49ef98cf-58cd-407e-8fb8-1aa6108f251d',
        interventionId: 'db46159c-dd1b-4b37-8a66-bf6d110ea67a',
        action: 'Sent',
        timestamps: '2024-01-16T06:11:39.360Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '6b4573f3-0fca-4005-9629-0fc90134f7db',
        interventionId: 'db46159c-dd1b-4b37-8a66-bf6d110ea67a',
        action: 'Saved',
        timestamps: '2024-01-16T06:13:37.877Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '7591d4f4-2628-412c-b9d4-2f4a2e089003',
        interventionId: 'db46159c-dd1b-4b37-8a66-bf6d110ea67a',
        action: 'Saved',
        timestamps: '2024-01-16T06:10:33.605Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '85efb53e-4450-4c8d-bf99-de1f31419311',
        interventionId: 'db46159c-dd1b-4b37-8a66-bf6d110ea67a',
        action: 'Saved',
        timestamps: '2024-01-16T06:15:38.436Z',
        isFirstSent: null,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '94611491-79fb-4249-a013-50fedcfa09a9',
        interventionId: 'db46159c-dd1b-4b37-8a66-bf6d110ea67a',
        action: 'Saved',
        timestamps: '2024-01-16T06:09:47.469Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: {
      chatId: '7e13ca6a-4682-4843-ab44-635e6f13aa90',
      content:
        'aaaa <span _ngcontent-vjo-c145="" id="5bdea5c0-1d00-4dde-ba42-929368c9b156" class="mine" contenteditable="false">@Nga Tran Thi Kieu</span>',
      interventionId: 'db46159c-dd1b-4b37-8a66-bf6d110ea67a',
      userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
      attachments: [],
      nominateInformation: [
        {
          userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
          userDisplayName: 'Nga Tran Thi Kieu',
          userRole: 'Admin',
          timestamps: 1712207410525,
          mail: 'Nga.TranThiKieu@halliburton.com',
        },
      ],
      nominateAt: '1712207410525',
      nominateVote: 1,
      createdAt: '2024-04-04T05:10:06.989Z',
      updatedAt: '2024-04-04T05:10:10.526Z',
    },
    currentState: 'Initiate (Completed)',
    stateCode: 'I_1_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-16T06:11:39.360Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-16T06:08:02.898Z',
    updatedAt: '2024-04-02T03:27:44.196Z',
    eventType: 'intervention',
    interval: null,
    run: null,
    isComplete: null,
  },
  {
    interventionId: '69204602-1631-4f7d-bf8c-25d3a25d9ec2',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1706000929323_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1,423',
    holeMD: '243,656',
    currentOperation:
      '{"operation_id":"OP15","data":"Pulling out with Cleanout Assembly","label":"Pulling out with Cleanout Assembly"}',
    event: {
      event_id: 'EV72',
      label: 'Limited Data Exchange',
      data: 'Limited Data Exchange',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Data Acquisition/Limited Data Exchange',
    },
    originalRisk:
      '[{"risk_id":"ETSE03","data":"Tubulars Related Failures / Damage","label":"Tubulars Related Failures / Damage"}]',
    risk: [
      {
        risk_id: 'ETSE03',
        label: 'Tubulars Related Failures / Damage',
        data: 'Tubulars Related Failures / Damage',
      },
    ],
    contactMethod: null,
    eventDescription: 'hhahdshf',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'tttt',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1706000880000',
    rigTimestamps: '1705997280000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: null,
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: '2024-01-23T09:57:12.424Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706000928665_log.json',
    auditTrail: [
      {
        logId: '01fe2cdb-5fa1-4df3-8526-e52fb74695d4',
        interventionId: '69204602-1631-4f7d-bf8c-25d3a25d9ec2',
        action: 'Created',
        timestamps: '2024-01-23T09:08:48.641Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '9fc11ae4-814d-48f0-9f1c-dd37796ce632',
        interventionId: '69204602-1631-4f7d-bf8c-25d3a25d9ec2',
        action: 'Sent',
        timestamps: '2024-01-23T09:12:12.460Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: {
      chatId: '7d2621d2-5c76-4d85-9266-d4bb4a288d67',
      content: '@bao',
      interventionId: '69204602-1631-4f7d-bf8c-25d3a25d9ec2',
      userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
      attachments: [],
      nominateInformation: [
        {
          userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
          userDisplayName: 'Nga Tran Thi Kieu',
          userRole: 'Admin',
          timestamps: 1712033592021,
          mail: 'Nga.TranThiKieu@halliburton.com',
        },
      ],
      nominateAt: '1712033592021',
      nominateVote: 1,
      createdAt: '2024-04-02T04:53:11.413Z',
      updatedAt: '2024-04-02T04:53:12.022Z',
    },
    currentState: 'Initiate (Completed)',
    stateCode: 'I_1_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-23T09:12:12.460Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-23T09:08:48.624Z',
    updatedAt: '2024-04-02T03:27:44.196Z',
    eventType: 'intervention',
    interval: null,
    run: null,
    isComplete: null,
  },
  {
    interventionId: '2587d9da-c6b1-4ab3-b283-526a02e1ff23',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1705414819684_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1',
    holeMD: '2',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV71',
      label: 'No Data Exchange',
      data: 'No Data Exchange',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Data Acquisition/No Data Exchange',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod:
      '[{"id":"email","label":"Email","data":"Email"},{"id":"chat","label":"Chat","data":"Chat"},{"id":"C3","label":"C3 - Technical Advisor","data":"+1 832-123-4573"},{"id":"C2","label":"C2 - Project Manager","data":"+1 832-123-4569"},{"id":"phone","label":"Phone","data":"Phone"},{"id":"C4","label":"C4 - Developer","data":"+1 132-434-6534"},{"id":"E1","label":"E1 - Tester","data":"+1 832-123-4575"},{"id":"E2","label":"E2 - Tester","data":"+1 832-123-4576"},{"id":"E3","label":"E3 - Tester","data":"+1 123-253-4645"},{"id":"E4","label":"E4 - Developer","data":"+1 123-253-4646"}]',
    eventDescription: 'Tue test edit',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'API',
    recommendation: 'h',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: false,
    isRecommendedActionTaken: 'no',
    resolution: 'hhh22',
    sendNotificationOfResolutionTotheSameDistribution: '',
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1705410960000',
    rigTimestamps: '1705407360000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '59d90418-7015-4036-91b7-f5635f5e4c3a',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'RESOLUTION',
    hasSent: false,
    timeExpire: '2024-01-16T14:02:38.501Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1705411008115_log.json',
    auditTrail: [
      {
        logId: '03fc4c33-b951-405a-a75f-13ed3a5c47cc',
        interventionId: '2587d9da-c6b1-4ab3-b283-526a02e1ff23',
        action: 'Saved',
        timestamps: '2024-01-16T14:06:14.070Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '0a13d32b-cb7d-48de-a6cf-8647644853e8',
        interventionId: '2587d9da-c6b1-4ab3-b283-526a02e1ff23',
        action: 'Saved',
        timestamps: '2024-01-16T14:20:16.830Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Tue Nguyen Minh',
        userId: '59d90418-7015-4036-91b7-f5635f5e4c3a',
        userRemoteCenter: null,
      },
      {
        logId: '14a81788-3d36-4393-8416-c50cecca0d9f',
        interventionId: '2587d9da-c6b1-4ab3-b283-526a02e1ff23',
        action: 'Saved',
        timestamps: '2024-01-16T13:42:42.192Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '2403c282-9a8f-416b-86ec-919e59fed947',
        interventionId: '2587d9da-c6b1-4ab3-b283-526a02e1ff23',
        action: 'Sent',
        timestamps: '2024-01-16T14:06:23.870Z',
        isFirstSent: true,
        status: 'Resolve (Draft)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '342109b3-28d1-4c1d-9ba1-7643952fdfaf',
        interventionId: '2587d9da-c6b1-4ab3-b283-526a02e1ff23',
        action: 'Created',
        timestamps: '2024-01-16T13:16:48.098Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '3af74287-68be-4a5f-b9d9-c413a47791dd',
        interventionId: '2587d9da-c6b1-4ab3-b283-526a02e1ff23',
        action: 'Saved',
        timestamps: '2024-01-16T14:07:14.517Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '3ec0b440-e852-4605-bf41-49d2b91286db',
        interventionId: '2587d9da-c6b1-4ab3-b283-526a02e1ff23',
        action: 'Saved',
        timestamps: '2024-01-16T13:18:35.918Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '45b54d97-c36e-40c2-a871-ba9795542f9e',
        interventionId: '2587d9da-c6b1-4ab3-b283-526a02e1ff23',
        action: 'Saved',
        timestamps: '2024-01-16T13:40:59.196Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '852f9b45-27f2-4566-9dbd-6a871bd7c599',
        interventionId: '2587d9da-c6b1-4ab3-b283-526a02e1ff23',
        action: 'Saved',
        timestamps: '2024-01-16T13:44:42.807Z',
        isFirstSent: null,
        status: 'Resolve (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: '88ddef0d-823a-48d0-b65e-f9f7e905936e',
        interventionId: '2587d9da-c6b1-4ab3-b283-526a02e1ff23',
        action: 'Sent',
        timestamps: '2024-01-16T13:17:38.545Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'da466dcc-4b7a-4536-9bcf-acb406167610',
        interventionId: '2587d9da-c6b1-4ab3-b283-526a02e1ff23',
        action: 'Saved',
        timestamps: '2024-01-16T13:17:29.891Z',
        isFirstSent: null,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Resolve (Draft)',
    stateCode: 'R_1_0',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-16T13:17:38.545Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-16T13:16:48.082Z',
    updatedAt: '2024-04-02T03:27:50.782Z',
    eventType: 'intervention',
    interval: null,
    run: null,
    isComplete: null,
  },
  {
    interventionId: 'a3c35f91-7d8e-4023-abdb-c4aefc80875e',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1712207968555_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '6.71',
    holeMD: '6.71',
    currentOperation: '2',
    event: {
      event_id: 'EV64',
      label: 'Downhole Tool Problems',
      data: 'Downhole Tool Problems',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'BHA/Downhole Tool Problems',
    },
    originalRisk: '2',
    risk: '2',
    contactMethod: null,
    eventDescription: '2e2e2',
    effectiveDiameter: '',
    flag: 'green',
    unit: 'SI',
    recommendation: '21323',
    isRequiredRigBasedValidation: true,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '2',
    timeSaved: null,
    localTimestamps: '1706458800000',
    rigTimestamps: '1706455200000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
    createUserId: '59d90418-7015-4036-91b7-f5635f5e4c3a',
    status: 'INITIATE',
    hasSent: false,
    timeExpire: null,
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: false,
    isCustomRisk: true,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706458912830_log.json',
    auditTrail: [
      {
        logId: '414eaf8d-c29c-4865-b73a-e9d0d48779ff',
        interventionId: 'a3c35f91-7d8e-4023-abdb-c4aefc80875e',
        action: 'Created',
        timestamps: '2024-01-28T16:21:52.802Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Tue Nguyen Minh',
        userId: null,
        userRemoteCenter: null,
      },
      {
        logId: '54d68a46-c7ad-49a4-bda4-c66cf3c7ea22',
        interventionId: 'a3c35f91-7d8e-4023-abdb-c4aefc80875e',
        action: 'Saved',
        timestamps: '2024-04-04T05:19:21.445Z',
        isFirstSent: false,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Draft)',
    stateCode: 'I_1_0',
    engineer: null,
    engineerId: null,
    initiated: null,
    resolved: null,
    avatarLink: null,
    remoteCenter: null,
    remoteCenterId: null,
    disciplineName: null,
    createdAt: '2024-01-28T16:21:52.775Z',
    updatedAt: '2024-04-04T05:19:28.756Z',
    eventType: 'intervention',
    interval: null,
    run: null,
    isComplete: null,
  },
  {
    interventionId: 'ebfbad75-0d38-48b8-976a-84c273d674b1',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1712213381821_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '3.66',
    holeMD: '7.01',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV71',
      label: 'No Data Exchange',
      data: 'No Data Exchange',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Data Acquisition/No Data Exchange',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: null,
    eventDescription:
      'HahahTest ahaahah 1/8x2.2" in test 1?2 hahahahtesst.\nTest ahaahah 1/8x2.2" in test 1?2 hahahahtesst.\nTest ahaahah 1/8x2.2" in test 1?2 hahahahtesst.\nTest ahaahah 1/8x2.2" in test 1?2 hahahahtesst.\nTest ahaahah 1/8x2.2" in test 1?2 hahahahtesst.\nTest ahaahah 1/8x2.2" in test 1?2 hahahahtesst.\nTest ahaahah 1/8x2.2" in test 1?2 hahahahtesst.\nTest ahaahah 1/8x2.2" in test 1?2 hahahahtesst.\nTest ahaahah 1/8x2.2" in test 1?2 hahahahtesst.\nTest ahaahah 1/8x2.2" in test 1?2 hahahahtesst.\nTest ahaahah 1/8x2.2" in test 1?2 hahahahtesst.\nTest ahaahah 1/8x2.2" in test 1?2 hahahahtesst.\n',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'SI',
    recommendation:
      'Test ahaahah 1/8x2.2" in test 1?2 hahahahtesst.\nTest ahaahah 1/8x2.2" in test 1?2 hahahahtesst.\nTest ahaahah 1/8x2.2" in test 1?2 hahahahtesst.\nTest ahaahah 1/8x2.2" in test 1?2 hahahahtesst.\nTest ahaahah 1/8x2.2" in test 1?2 hahahahtesst.\nTest ahaahah 1/8x2.2" in test 1?2 hahahahtesst.\nTest ahaahah 1/8x2.2" in test 1?2 hahahahtesst.\nTest ahaahah 1/8x2.2" in test 1?2 hahahahtesst.\nTest ahaahah 1/8x2.2" in test 1?2 hahahahtesst.\nTest ahaahah 1/8x2.2" in test 1?2 hahahahtesst.\nTest ahaahah 1/8x2.2" in test 1?2 hahahahtesst.\nTest ahaahah 1/8x2.2" in test 1?2 hahahahtesst.\nTest ahaahah 1/8x2.2" in test 1?2 hahahahtesst.\n',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1706007480000',
    rigTimestamps: '1706003880000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: '2024-01-23T11:44:45.849Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: false,
    auditTrailUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/audit/1706007554462_log.json',
    auditTrail: [
      {
        logId: '05313361-be2a-4084-b724-506c5f8c7934',
        interventionId: 'ebfbad75-0d38-48b8-976a-84c273d674b1',
        action: 'Sent',
        timestamps: '2024-01-23T10:59:45.891Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '37b24d38-6d1d-4c5d-804b-e287eff7d5d5',
        interventionId: 'ebfbad75-0d38-48b8-976a-84c273d674b1',
        action: 'Saved',
        timestamps: '2024-04-02T07:50:02.597Z',
        isFirstSent: false,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: '551d0a7a-9c50-41b6-b5ce-fddb819f3abf',
        interventionId: 'ebfbad75-0d38-48b8-976a-84c273d674b1',
        action: 'Saved',
        timestamps: '2024-04-04T06:49:39.357Z',
        isFirstSent: false,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Bao Pham Gia',
        userId: 'd4a867dc-337a-4b6b-ae7e-66f55b3e1d2d',
        userRemoteCenter: null,
      },
      {
        logId: 'a5d2d518-c326-47c8-9e44-809118721752',
        interventionId: 'ebfbad75-0d38-48b8-976a-84c273d674b1',
        action: 'Saved',
        timestamps: '2024-04-02T07:47:32.497Z',
        isFirstSent: false,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'c3bd261d-c065-4aee-a847-365ad862e899',
        interventionId: 'ebfbad75-0d38-48b8-976a-84c273d674b1',
        action: 'Saved',
        timestamps: '2024-04-02T07:48:59.132Z',
        isFirstSent: false,
        status: 'Initiate (Completed)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
      {
        logId: 'df785412-9f99-449c-8bfc-877be29f62c5',
        interventionId: 'ebfbad75-0d38-48b8-976a-84c273d674b1',
        action: 'Created',
        timestamps: '2024-01-23T10:59:14.438Z',
        isFirstSent: null,
        status: null,
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: null,
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: {
      chatId: 'f2302f74-e8d6-4d49-a822-67b0c0f97365',
      content: 'ad',
      interventionId: 'ebfbad75-0d38-48b8-976a-84c273d674b1',
      userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
      attachments: [],
      nominateInformation: [
        {
          userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
          userDisplayName: 'Nga Tran Thi Kieu',
          userRole: 'Admin',
          timestamps: 1712042823855,
          mail: 'Nga.TranThiKieu@halliburton.com',
        },
      ],
      nominateAt: '1712042823855',
      nominateVote: 1,
      createdAt: '2024-04-02T07:27:00.100Z',
      updatedAt: '2024-04-02T07:27:03.855Z',
    },
    currentState: 'Initiate (Completed)',
    stateCode: 'I_1_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-01-23T10:59:45.891Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-01-23T10:59:14.398Z',
    updatedAt: '2024-04-04T06:49:41.974Z',
    eventType: 'intervention',
    interval: {
      intervalId: '417ebcbc-19d1-4a3f-9e89-0308563c8a91',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: '12/8" ½" in x 37" 81/2 in122222 3242',
      unit: 'SI',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: 'null',
      openHoleDiameter: '2,222,222,222',
      lastShoeMeasuredDepth: 'null',
      isNaCaseHoleLastShoe: true,
      isNaOpenHole: false,
    },
    run: null,
    isComplete: null,
  },
  {
    interventionId: '2464518f-65ee-45b2-aaaf-dbe326a1112c',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1712217090424_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '1,324',
    holeMD: '346,546',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV71',
      label: 'No Data Exchange',
      data: 'No Data Exchange',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'Data Acquisition/No Data Exchange',
    },
    originalRisk:
      '[{"risk_id":"ETSE02","data":"Casing / Tubing Running Equipment/Tools Failures","label":"Casing / Tubing Running Equipment/Tools Failures"},{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE02',
        label: 'Casing / Tubing Running Equipment/Tools Failures',
        data: 'Casing / Tubing Running Equipment/Tools Failures',
      },
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: null,
    eventDescription:
      'test 23,23/24\'" ina https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/758b6495-8cb9-4705-a17a-2836b5fea42d\ntest 1@ ndh ><?";ơp[pôp[opopiiru8t á à ả ã ạ',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'SI',
    recommendation:
      'test 23,23/24\'" ina https://hpm-vro-dev-scus-app-api-winterfell.azurewebsites.net/home/rig/758b6495-8cb9-4705-a17a-2836b5fea42d\ntest 1@ ndh ><?";ơp[pôp[opopiiru8t á à ả ã ạ',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1712216040000',
    rigTimestamps: '1712212440000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: '2024-04-04T08:36:55.276Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: false,
    auditTrailUrl: null,
    auditTrail: [
      {
        logId: '4e010f54-db51-4a11-85a1-4c03aae031f1',
        interventionId: '2464518f-65ee-45b2-aaaf-dbe326a1112c',
        action: 'Sent',
        timestamps: '2024-04-04T07:51:55.277Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: '96553373-37f2-46da-b096-9973b1561dab',
        interventionId: '2464518f-65ee-45b2-aaaf-dbe326a1112c',
        action: 'Saved',
        timestamps: '2024-04-04T07:51:28.632Z',
        isFirstSent: false,
        status: 'Initiate (Draft)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'eb086ee9-28cb-4acd-ad6c-f520cd310261',
        interventionId: '2464518f-65ee-45b2-aaaf-dbe326a1112c',
        action: 'Saved',
        timestamps: '2024-04-04T07:51:08.614Z',
        isFirstSent: false,
        status: 'Initiate (Draft)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'f6d9fd90-0b2b-42c3-9801-154098a13a40',
        interventionId: '2464518f-65ee-45b2-aaaf-dbe326a1112c',
        action: 'Created',
        timestamps: '2024-04-04T07:38:07.705Z',
        isFirstSent: false,
        status: 'Initiate (Draft)',
        userDiscipline: null,
        remoteCenterId: null,
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: null,
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Completed)',
    stateCode: 'I_1_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-04-04T07:51:55.277Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-04-04T07:38:07.650Z',
    updatedAt: '2024-04-04T07:51:55.276Z',
    eventType: 'intervention',
    interval: {
      intervalId: '417ebcbc-19d1-4a3f-9e89-0308563c8a91',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: '12/8" ½" in x 37" 81/2 in122222 3242',
      unit: 'SI',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: 'null',
      openHoleDiameter: '2,222,222,222',
      lastShoeMeasuredDepth: 'null',
      isNaCaseHoleLastShoe: true,
      isNaOpenHole: false,
    },
    run: null,
    isComplete: null,
  },
  {
    interventionId: '61e5a4dc-5742-44be-8232-320dc60333fb',
    interventionUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/intervention/1712223043616_N_TestJh_AlertIntervention.pdf',
    holeDiameter: '0',
    runNumber: '0',
    bitMD: '122',
    holeMD: '333,333',
    currentOperation:
      '{"operation_id":"OP06","data":"Tripping in with Drilling Assembly","label":"Tripping in with Drilling Assembly"}',
    event: {
      event_id: 'EV54',
      label: 'Weight on Bit',
      data: 'Weight on Bit',
      styleClass: 'lowestChild',
      eventType: 'intervention',
      template: ['default'],
      leaf: false,
      hierarchy: 'BHA/Operating Limits/Weight on Bit',
    },
    originalRisk:
      '[{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}]',
    risk: [
      {
        risk_id: 'ETSE01',
        label: 'BOP/BOP systems test failure(Rented)',
        data: 'BOP/BOP systems test failure(Rented)',
      },
    ],
    contactMethod: null,
    eventDescription: 'test',
    effectiveDiameter: '',
    flag: 'purple',
    unit: 'SI',
    recommendation: 'ttttttttttttt',
    isRequiredRigBasedValidation: false,
    isRequiredFutherAction: true,
    isNotificationOfResolutionRequired: true,
    isRecommendedActionTaken: null,
    resolution: null,
    sendNotificationOfResolutionTotheSameDistribution: null,
    rigBasedValidationDetail: '',
    timeSaved: null,
    localTimestamps: '1712217360000',
    rigTimestamps: '1712213760000',
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    updateUserId: null,
    createUserId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    status: 'INITIATE',
    hasSent: true,
    timeExpire: '2024-04-04T10:15:55.061Z',
    isOverrideTime: false,
    isOverrideNumber: false,
    isCustomOperation: true,
    isCustomRisk: false,
    auditTrailUrl: null,
    auditTrail: [
      {
        logId: '848feaa7-1f5e-4914-9f72-07110f71ce6b',
        interventionId: '61e5a4dc-5742-44be-8232-320dc60333fb',
        action: 'Created',
        timestamps: '2024-04-04T09:30:40.970Z',
        isFirstSent: false,
        status: 'Initiate (Draft)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
      {
        logId: 'df804669-9349-4d14-ab58-99cbdeca613a',
        interventionId: '61e5a4dc-5742-44be-8232-320dc60333fb',
        action: 'Sent',
        timestamps: '2024-04-04T09:30:55.062Z',
        isFirstSent: true,
        status: 'Initiate (Completed)',
        userDiscipline: 'TEST',
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        userDisplayName: 'Nga Tran Thi Kieu',
        userId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
        userRemoteCenter: 'VRO Testing',
      },
    ],
    additionalData: {
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      isComplete: false,
      wellbore: {
        wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        wellboreOriginalName: 'AC903#1 ST00 BP00',
        wellboreCurrentName: null,
      },
      project: {
        projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        projectOriginalName: 'Alaminos Canyon',
        projectCurrentName: 'Alaminos Canyon 2',
        preUnit: 'API',
        curUnit: 'SI',
      },
      rig: {
        rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
        rigName: 'N_TestJh',
      },
      policy: {
        policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
        policyOriginalName: 'Atlantia Resources',
        policyCurrentName: null,
        edmSource: 'IDAHO',
      },
      well: {
        wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
        siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
        wellOriginalName: 'Unocal AC903 #1 - TEST',
        wellCurrentName: null,
      },
      createdAt: '2023-10-20T11:23:47.889Z',
      updatedAt: '2024-04-01T09:02:26.365Z',
    },
    highestNomination: null,
    currentState: 'Initiate (Completed)',
    stateCode: 'I_1_1',
    engineer: 'Nga Tran Thi Kieu (TEST)',
    engineerId: '5bdea5c0-1d00-4dde-ba42-929368c9b156',
    initiated: '2024-04-04T09:30:55.062Z',
    resolved: null,
    avatarLink:
      'https://hpmvrodevscusstapp.blob.core.windows.net/useravatar/1711102451181_1711078186964_002_Hole%25252520Cleaning%25252520at%25252520749mMD_2024-03-21_22-21-14.png',
    remoteCenter: 'VRO Testing',
    remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
    disciplineName: 'TEST',
    createdAt: '2024-04-04T09:30:40.928Z',
    updatedAt: '2024-04-04T09:30:55.061Z',
    eventType: 'intervention',
    interval: {
      intervalId: '417ebcbc-19d1-4a3f-9e89-0308563c8a91',
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
      intervalName: '12/8" ½" in x 37" 81/2 in122222 3242',
      unit: 'SI',
      operation: 'completion',
      designOfServiceUrl: null,
      casedHoleId: 'null',
      openHoleDiameter: '2,222,222,222',
      lastShoeMeasuredDepth: 'null',
      isNaCaseHoleLastShoe: true,
      isNaOpenHole: false,
    },
    run: null,
    isComplete: null,
  },
];

export const COUNTRY = [
  {
    name: 'Portugal', // Duplicates data
    code: 'PT',
    region: 'EESSA',
  },
  {
    name: 'Portugal',
    code: 'PT',
    region: 'EESSA',
  },
  {
    name: 'Albania',
    code: 'AL',
    region: 'EESSA',
  },
  {
    name: 'Germany',
    code: 'DE',
    region: 'EESSA',
  },
  {
    name: 'Viet Nam',
    code: 'VN',
    region: 'AP',
  },
  {
    name: 'Georgia',
    code: 'GE',
    region: 'EESSA',
  },
  {
    name: 'Dominica',
    code: 'DM',
    region: 'LA',
  },
  {
    name: 'Virgin Islands, U.S.',
    code: 'VI',
    region: 'NAM',
  },
  {
    name: 'Tunisia',
    code: 'TN',
    region: 'MENA',
  },
  {
    name: 'Canada',
    code: 'CA',
    region: 'NAM',
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
    region: 'NAM',
  },
  {
    name: 'Gabon',
    code: 'GA',
    region: 'EESSA',
  },
  {
    name: 'Algeria',
    code: 'DZ',
    region: 'MENA',
  },
];

export const PROJECT = [
  {
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    distributionListUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/rigdistributionlist/1711960018351_1711421058491_1697769477168_disN.xlsx',
    dataRoutingUrl: null,
    communicateProtocolUrl: null,
    isComplete: false,
    wellbore: {
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
      wellboreOriginalName: 'AC903#1 ST00 BP00',
      wellboreCurrentName: null,
      uwiCurrentName: '',
      guid: '',
      surfaceLatitude: '26.06030384472165',
      surfaceLongitude: '-94.78047312794493',
      spudDate: '10/19/2023, 06:16 PM',
      EDMsurfaceLatitude: 26.06030384472165,
      EDMsurfaceLongitude: -94.78047312794493,
      EDMspudDate: null,
      wellboreName: 'AC903#1 ST00 BP00',
    },
    project: {
      projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
      policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
      projectOriginalName: 'Alaminos Canyon',
      projectCurrentName: 'Alaminos Canyon 2',
      timezone: '+06:00',
      country: 'PT',
      countryName: 'Portugal',
      block: 'Test09N',
      region: 'EESSA',
      lease: 'Test09NJh',
      field: 'Test09N12',
      preUnit: 'API',
      curUnit: 'SI',
      projectName: 'Alaminos Canyon 2',
      countryObject: {
        name: 'Portugal',
        code: 'PT',
        region: 'EESSA',
      },
    },
    rig: {
      rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
      rigName: 'N_TestJh',
      rigTypeName:
        'eyJyaWdUeXBlTmFtZSI6Ik90aGVyIiwicmlnVHlwZUhpZXJhcmNoeSI6Ik90aGVyIiwicmlnRGVmYXVsdEltYWdlIjoiT3RoZXIuanBnIn0=',
      contractorId: '1ab70405-5806-48c7-80d1-2e6891796ae9',
      contractorName: 'N_TestContractor',
      imageUrl:
        'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1693278510880_7iyVJmKTbhTvAhSn_1080xauto_high.png',
      specUrl: null,
      rigImageBlob:
        'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1693278510880_7iyVJmKTbhTvAhSn_1080xauto_high.png?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-02-10T17:09:31Z&st=2023-02-10T09:09:31Z&spr=https&sig=N5GYQRNxDShfXF9Ntz1XBgEBCQPiz2reU3OgFSTJM5Q%3D',
      rigTypeDecoded: {
        rigTypeName: 'Other',
        rigTypeHierarchy: 'Other',
        rigDefaultImage: 'Other.jpg',
      },
      rigDefaultImage: '../../../../assets/images/default-rigs/Other.jpg',
    },
    policy: {
      policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
      policyOriginalName: 'Atlantia Resources',
      policyCurrentName: null,
      edmSource: 'IDAHO',
      dataSource: {
        sourceName: 'IDAHO',
        displaySourceName: 'IDAHO',
        displaySourceDropdown: 'GOM IDAHO EDM',
        databaseSource: 'EDM',
      },
    },
    well: {
      wellName: 'Unocal AC903 #1 - TEST',
    },
    site: {
      siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
      projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
      siteOriginalName: 'Test',
      siteCurrentName: null,
    },
    interval: null,
    remoteCenterList: [
      {
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        remoteCenterName: 'VRO Testing',
      },
    ],
    mailboxList: [
      {
        mailboxId: '24ad277c-9032-4e09-b4f7-059ff95ba374',
        mailboxName: 'SVC_BRBUZIOSVRO@halliburton.com',
        isActive: true,
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
      },
      {
        mailboxId: '49e66a2b-1b1d-4d4c-aebe-7bcd9f4941fe',
        mailboxName: 'VROALERTtesting@halliburton.com',
        isActive: true,
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
      },
      {
        mailboxId: 'f8c50ce0-8fe2-4025-a4d3-fed7ce16ace2',
        mailboxName: 'WellCareTestA@halliburton.com',
        isActive: true,
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
      },
      {
        mailboxId: '6124aaa7-191d-4dc4-8af1-5e8fc18fbc5a',
        mailboxName: 'WellCareTestB@halliburton.com',
        isActive: true,
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
      },
      {
        mailboxId: 'eac8a337-76d3-4a8e-b531-3b52edd2d958',
        mailboxName: 'WellCareTestC@halliburton.com',
        isActive: true,
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
      },
      {
        mailboxId: 'cb62438d-f820-4657-8ae6-3a26e059b9ff',
        mailboxName: 'WellCareTestD@halliburton.com',
        isActive: true,
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
      },
    ],
    run: null,
    interventionFlag: {
      green: 22,
      yellow: 1,
      purple: 38,
    },
    updatedAt: '2024-04-01T09:02:26.365Z',
    flagStatistic: [
      {
        key: 'green',
        value: 22,
      },
      {
        key: 'yellow',
        value: 1,
      },
      {
        key: 'purple',
        value: 38,
      },
    ],
  },
  {
    rigJournalId: 'ea418643-9901-40aa-a114-ffb4a6d44055',
    distributionListUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/rigdistributionlist/1711421058491_1697769477168_disN.xlsx',
    dataRoutingUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/rigdatarouting/1711080069797_WellCare-Release-Notes-v1.1.0.pdf',
    communicateProtocolUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/rigcommunicateprotocal/1711080069843_Report_Alert_Management_export_1710229532074.pdf',
    isComplete: false,
    wellbore: {
      wellboreId: 'BFA0D743-EF80-4B16-A497-9099C32197FB',
      wellId: '9BF25524-4641-4265-8143-1A1DC3C1EBBD',
      wellboreOriginalName: 'ST00',
      wellboreCurrentName: null,
      uwiCurrentName: '',
      guid: '',
      surfaceLatitude: '26.08370766130537',
      surfaceLongitude: '-94.71976780810463',
      spudDate: null,
      EDMsurfaceLatitude: 26.08370766130537,
      EDMsurfaceLongitude: -94.71976780810463,
      EDMspudDate: null,
      wellboreName: 'ST00',
    },
    project: {
      projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
      policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
      projectOriginalName: 'Alaminos Canyon',
      projectCurrentName: 'Alaminos Canyon 2',
      timezone: '+06:00',
      country: 'PT',
      countryName: 'Portugal',
      block: 'Test09N',
      region: 'EESSA',
      lease: 'Test09NJh',
      field: 'Test09N12',
      preUnit: 'API',
      curUnit: 'SI',
      projectName: 'Alaminos Canyon 2',
      countryObject: {
        name: 'Portugal',
        code: 'PT',
        region: 'EESSA',
      },
    },
    rig: {
      rigId: '956348ff-838b-4d45-a7bb-7c7117d9c171',
      rigName: 'S_N_TestABC',
      rigTypeName:
        'eyJyaWdUeXBlTmFtZSI6IlRZUEUxOSIsInJpZ1R5cGVIaWVyYXJjaHkiOiJMYW5kIHwgQ29udmVudGlvbmFsIHwgU2xpbmdzaG90IiwicmlnRGVmYXVsdEltYWdlIjoiTGFuZF9Db252ZW50aW9uYWwuanBnIn0=',
      contractorId: '5502fb10-39f3-4356-82b5-2ab2f8d5a6b8',
      contractorName: 'Rig Contractor Demo Audit Trail',
      imageUrl:
        'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1703489978436_Hello%2520Vi%25E1%25BB%2587t%2520Nam.png',
      specUrl:
        'https://hpmvrodevscusstapp.blob.core.windows.net/rigspec/1703490073155_Hi%2520Vi%25E1%25BB%2587t%2520Nam%2520%2540%2523%2540%2523%2540.pdf',
      rigImageBlob:
        'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1703489978436_Hello%2520Vi%25E1%25BB%2587t%2520Nam.png?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-02-10T17:09:31Z&st=2023-02-10T09:09:31Z&spr=https&sig=N5GYQRNxDShfXF9Ntz1XBgEBCQPiz2reU3OgFSTJM5Q%3D',
      rigTypeDecoded: {
        rigTypeName: 'TYPE19',
        rigTypeHierarchy: 'Land | Conventional | Slingshot',
        rigDefaultImage: 'Land_Conventional.jpg',
      },
      rigDefaultImage:
        '../../../../assets/images/default-rigs/Land_Conventional.jpg',
    },
    policy: {
      policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
      policyOriginalName: 'Atlantia Resources',
      policyCurrentName: null,
      edmSource: 'IDAHO',
      dataSource: {
        sourceName: 'IDAHO',
        displaySourceName: 'IDAHO',
        displaySourceDropdown: 'GOM IDAHO EDM',
        databaseSource: 'EDM',
      },
    },
    well: {
      wellName: 'AC 904 - Site_East_1',
    },
    site: {
      siteId: 'D41EEDC3-E052-4B46-BD4F-92F22FD6C4D2',
      projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
      siteOriginalName: 'Prometheus (Well Construction)',
      siteCurrentName: null,
    },
    interval: null,
    remoteCenterList: [
      {
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        remoteCenterName: 'VRO Testing',
      },
    ],
    mailboxList: [
      {
        mailboxId: '49e66a2b-1b1d-4d4c-aebe-7bcd9f4941fe',
        mailboxName: 'VROALERTtesting@halliburton.com',
        isActive: true,
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
      },
    ],
    run: null,
    interventionFlag: {},
    updatedAt: '2024-03-26T09:49:50.247Z',
    flagStatistic: [],
  },
  {
    // duplicate
    rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
    distributionListUrl:
      'https://hpmvrodevscusstapp.blob.core.windows.net/rigdistributionlist/1711960018351_1711421058491_1697769477168_disN.xlsx',
    dataRoutingUrl: null,
    communicateProtocolUrl: null,
    isComplete: false,
    wellbore: {
      wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
      wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
      wellboreOriginalName: 'AC903#1 ST00 BP00',
      wellboreCurrentName: null,
      uwiCurrentName: '',
      guid: '',
      surfaceLatitude: '26.06030384472165',
      surfaceLongitude: '-94.78047312794493',
      spudDate: '10/19/2023, 06:16 PM',
      EDMsurfaceLatitude: 26.06030384472165,
      EDMsurfaceLongitude: -94.78047312794493,
      EDMspudDate: null,
      wellboreName: 'AC903#1 ST00 BP00',
    },
    project: {
      projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
      policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
      projectOriginalName: 'Alaminos Canyon',
      projectCurrentName: 'Alaminos Canyon 2',
      timezone: '+06:00',
      country: 'PT',
      countryName: 'Portugal',
      block: 'Test09N',
      region: 'EESSA',
      lease: 'Test09NJh',
      field: 'Test09N12',
      preUnit: 'API',
      curUnit: 'SI',
      projectName: 'Alaminos Canyon 2',
      countryObject: {
        name: 'Portugal',
        code: 'PT',
        region: 'EESSA',
      },
    },
    rig: {
      rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
      rigName: 'N_TestJh',
      rigTypeName:
        'eyJyaWdUeXBlTmFtZSI6Ik90aGVyIiwicmlnVHlwZUhpZXJhcmNoeSI6Ik90aGVyIiwicmlnRGVmYXVsdEltYWdlIjoiT3RoZXIuanBnIn0=',
      contractorId: '1ab70405-5806-48c7-80d1-2e6891796ae9',
      contractorName: 'N_TestContractor',
      imageUrl:
        'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1693278510880_7iyVJmKTbhTvAhSn_1080xauto_high.png',
      specUrl: null,
      rigImageBlob:
        'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1693278510880_7iyVJmKTbhTvAhSn_1080xauto_high.png?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-02-10T17:09:31Z&st=2023-02-10T09:09:31Z&spr=https&sig=N5GYQRNxDShfXF9Ntz1XBgEBCQPiz2reU3OgFSTJM5Q%3D',
      rigTypeDecoded: {
        rigTypeName: 'Other',
        rigTypeHierarchy: 'Other',
        rigDefaultImage: 'Other.jpg',
      },
      rigDefaultImage: '../../../../assets/images/default-rigs/Other.jpg',
    },
    policy: {
      policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
      policyOriginalName: 'Atlantia Resources',
      policyCurrentName: null,
      edmSource: 'IDAHO',
      dataSource: {
        sourceName: 'IDAHO',
        displaySourceName: 'IDAHO',
        displaySourceDropdown: 'GOM IDAHO EDM',
        databaseSource: 'EDM',
      },
    },
    well: {
      wellName: 'Unocal AC903 #1 - TEST',
    },
    site: {
      siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
      projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
      siteOriginalName: 'Test',
      siteCurrentName: null,
    },
    interval: null,
    remoteCenterList: [
      {
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
        remoteCenterName: 'VRO Testing',
      },
    ],
    mailboxList: [
      {
        mailboxId: '24ad277c-9032-4e09-b4f7-059ff95ba374',
        mailboxName: 'SVC_BRBUZIOSVRO@halliburton.com',
        isActive: true,
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
      },
      {
        mailboxId: '49e66a2b-1b1d-4d4c-aebe-7bcd9f4941fe',
        mailboxName: 'VROALERTtesting@halliburton.com',
        isActive: true,
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
      },
      {
        mailboxId: 'f8c50ce0-8fe2-4025-a4d3-fed7ce16ace2',
        mailboxName: 'WellCareTestA@halliburton.com',
        isActive: true,
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
      },
      {
        mailboxId: '6124aaa7-191d-4dc4-8af1-5e8fc18fbc5a',
        mailboxName: 'WellCareTestB@halliburton.com',
        isActive: true,
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
      },
      {
        mailboxId: 'eac8a337-76d3-4a8e-b531-3b52edd2d958',
        mailboxName: 'WellCareTestC@halliburton.com',
        isActive: true,
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
      },
      {
        mailboxId: 'cb62438d-f820-4657-8ae6-3a26e059b9ff',
        mailboxName: 'WellCareTestD@halliburton.com',
        isActive: true,
        remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
      },
    ],
    run: null,
    interventionFlag: {
      green: 22,
      yellow: 1,
      purple: 38,
    },
    updatedAt: '2024-04-01T09:02:26.365Z',
    flagStatistic: [
      {
        key: 'green',
        value: 22,
      },
      {
        key: 'yellow',
        value: 1,
      },
      {
        key: 'purple',
        value: 38,
      },
    ],
  },
];

export const PROJECT_GROUP = [
  [
    '20E55D14-A630-4D2E-BE77-BD381C629785',
    [
      {
        rigJournalId: '88a4ba27-d2f3-4658-b619-38353cddcc81',
        distributionListUrl:
          'https://hpmvrodevscusstapp.blob.core.windows.net/rigdistributionlist/1712224360497_VRO_PROJECT_DISTRIBUTIONS.xlsx',
        dataRoutingUrl:
          'https://hpmvrodevscusstapp.blob.core.windows.net/rigdatarouting/1709088741259_rigSpec001.pdf',
        communicateProtocolUrl:
          'https://hpmvrodevscusstapp.blob.core.windows.net/rigcommunicateprotocal/1709088741269_rigSpec001.pdf',
        isComplete: false,
        wellbore: {
          wellboreId: '909EA621-370F-4F2F-8B51-0A7AEA5077D2',
          wellId: '56A96488-757D-4B7C-9DC4-0E054602066E',
          wellboreOriginalName: 'RDA.x-2000 (p+h)',
          wellboreCurrentName: 'AutoWellbore',
          uwiCurrentName: 'Auto1',
          guid: 'GUID',
          surfaceLatitude: '12',
          surfaceLongitude: '12',
          spudDate: 'Jul 20 2023, 20:00',
          EDMsurfaceLatitude: null,
          EDMsurfaceLongitude: null,
          EDMspudDate: '2019-07-20T20:00:00',
          wellboreName: 'AutoWellbore',
        },
        project: {
          projectId: '20E55D14-A630-4D2E-BE77-BD381C629785',
          policyId: '05472721-52C5-4CEA-864A-98147BD3677F',
          projectOriginalName: 'Rincon de Aranda',
          projectCurrentName: 'Automation (Do Not Touch)',
          timezone: '+05:45',
          country: 'VN',
          countryName: 'Viet Nam',
          block: 'VN-84',
          region: 'AP',
          lease: 'VN-84',
          field: 'VN-84',
          preUnit: 'API - US Survey Feet',
          curUnit: 'API',
          projectName: 'Automation (Do Not Touch)',
        },
        rig: {
          rigId: 'e727bf8b-996b-4cae-beea-46ec4480b734',
          rigName: 'AutoRig',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUxOSIsInJpZ1R5cGVIaWVyYXJjaHkiOiJMYW5kIHwgQ29udmVudGlvbmFsIHwgU2xpbmdzaG90IiwicmlnRGVmYXVsdEltYWdlIjoiTGFuZF9Db252ZW50aW9uYWwuanBnIn0=',
          contractorId: 'ce589db8-33f2-4f57-b7d3-70ed5d48e9ec',
          contractorName: 'AutoRigContractor',
          imageUrl: null,
          specUrl: null,
        },
        policy: {
          policyId: '05472721-52C5-4CEA-864A-98147BD3677F',
          policyOriginalName: 'AR - Pampa Energia',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'AutoWell',
        },
        site: {
          siteId: 'D0002E12-68A4-4D64-92FD-949545F2F05D',
          projectId: '20E55D14-A630-4D2E-BE77-BD381C629785',
          siteOriginalName: 'Rincon de Aranda',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [
          {
            remoteCenterId: '6f73ae2d-76fb-4c78-ba2c-ba0a2feba224',
            remoteCenterName: 'EH VRO',
          },
          {
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
            remoteCenterName: 'VRO Testing',
          },
        ],
        mailboxList: [
          {
            mailboxId: '232dd0bb-2185-464e-b5c5-7d0cf6ce5fe4',
            mailboxName: 'EHiVROGMECH@halliburton.com',
            isActive: true,
            remoteCenterId: '6f73ae2d-76fb-4c78-ba2c-ba0a2feba224',
          },
          {
            mailboxId: '49e66a2b-1b1d-4d4c-aebe-7bcd9f4941fe',
            mailboxName: 'VROALERTtesting@halliburton.com',
            isActive: true,
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
          },
        ],
        run: null,
        interventionFlag: {
          purple: 1,
        },
        updatedAt: '2024-04-04T09:52:40.552Z',
      },
    ],
  ],
  [
    '7900F5EA-A63D-4FBD-BF2F-D0359101DA56',
    [
      {
        rigJournalId: '8e065c84-969e-45b2-ac8b-c31489c7904b',
        distributionListUrl:
          'https://hpmvrodevscusstapp.blob.core.windows.net/rigdistributionlist/1712213460469_Distribution-QA-Vi%25E1%25BB%2587t-Nam%2523%2540%2523%2540%2523%2540.xlsx',
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: 'C12D0C1F-DEB8-4FF3-95C7-214AB7CA153D',
          wellId: '02513144-5171-486A-B9F7-731B1F0DCFE4',
          wellboreOriginalName: 'BdC-13h',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: -37.71124956962985,
          EDMsurfaceLongitude: -69.16593728475588,
          EDMspudDate: null,
          wellboreName: 'BdC-13h',
        },
        project: {
          projectId: '7900F5EA-A63D-4FBD-BF2F-D0359101DA56',
          policyId: 'DC5B1F7E-8A86-4B40-AAE8-8214A613DC7B',
          projectOriginalName: 'BAJO DEL CHOIQUE',
          projectCurrentName: null,
          timezone: '+02:00',
          country: 'GE',
          countryName: 'Georgia',
          block: 'BLOCK  - 221 edit BLOCK  - 221 editBLOCK  - 221 BL',
          region: 'EESSA',
          lease: 'BLOCK  - 221 edit BLOCK  - 221 editBLOCK  - 221BL',
          field: 'BLOCK  - 221 edit BLOCK  - 221 editBLOCK  - 221BL',
          preUnit: 'SI',
          curUnit: 'API',
          projectName: 'BAJO DEL CHOIQUE',
        },
        rig: {
          rigId: '08523881-b961-419a-80be-796e28e0b344',
          rigName: 'Test Rig A4',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUxMSIsInJpZ1R5cGVIaWVyYXJjaHkiOiJNYXJpbmUgfCBGbG9hdGluZyB8IERyaWxsc2hpcCB8IE1pZHdhdGVyIiwicmlnRGVmYXVsdEltYWdlIjoiTWFyaW5lX0Zsb2F0aW5nX0RyaWxsc2hpcC5qcGcifQ==',
          contractorId: '01cf9085-a47c-4cbc-a706-60a1200dd2b0',
          contractorName: 'Rig Test',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1690252407037_rig.png',
          specUrl: null,
        },
        policy: {
          policyId: 'DC5B1F7E-8A86-4B40-AAE8-8214A613DC7B',
          policyOriginalName: 'AR - ExxonMobil Exploration Argentina S.R.L.',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'BdC-13h',
        },
        site: {
          siteId: '843D0A96-0515-4666-932B-44FD49BC7028',
          projectId: '7900F5EA-A63D-4FBD-BF2F-D0359101DA56',
          siteOriginalName: 'BdC Pad 1A',
          siteCurrentName: 'BdC Pad 1A Edit',
        },
        interval: {
          intervalId: 'b75941d6-2eb3-4d0b-80fd-d688151aa61b',
          wellboreId: 'C12D0C1F-DEB8-4FF3-95C7-214AB7CA153D',
          rigJournalId: '8e065c84-969e-45b2-ac8b-c31489c7904b',
          intervalName: '124',
          unit: 'API',
          operation: 'completion',
          designOfServiceUrl: null,
          casedHoleId: 'null',
          openHoleDiameter: '12,435',
          lastShoeMeasuredDepth: 'null',
          isNaCaseHoleLastShoe: true,
          isNaOpenHole: false,
        },
        remoteCenterList: [
          {
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
            remoteCenterName: 'VRO Testing',
          },
        ],
        mailboxList: [
          {
            mailboxId: '49e66a2b-1b1d-4d4c-aebe-7bcd9f4941fe',
            mailboxName: 'VROALERTtesting@halliburton.com',
            isActive: true,
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
          },
          {
            mailboxId: 'eac8a337-76d3-4a8e-b531-3b52edd2d958',
            mailboxName: 'WellCareTestC@halliburton.com',
            isActive: true,
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
          },
          {
            mailboxId: 'cb62438d-f820-4657-8ae6-3a26e059b9ff',
            mailboxName: 'WellCareTestD@halliburton.com',
            isActive: true,
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
          },
        ],
        run: {
          run_id: '9e1b9e6d-5382-4be4-9a26-6e8e0cec9a8e',
          rig_journal_id: '8e065c84-969e-45b2-ac8b-c31489c7904b',
          interval_id: 'b75941d6-2eb3-4d0b-80fd-d688151aa61b',
          description: '1',
          type: 'TYPE15',
          run_no: '1',
          rig_bha_no: '',
          bit_size: '',
          reamer_size: '',
          casing_size: '3',
          start_measured_depth: '',
          td_measured_depth: '23',
          below_rotary_timestamp: '03/21/2024, 09:41 AM',
          run_td_timestamp: '03/27/2024, 09:42 AM',
          above_rotary_timestamp: null,
          tally_url: null,
          unit: 'API',
          createdAt: '2024-03-29T02:40:38.342Z',
          updatedAt: '2024-03-29T02:42:12.916Z',
        },
        interventionFlag: {},
        updatedAt: '2024-04-04T06:51:00.529Z',
      },
      {
        rigJournalId: '79e76eb2-6a34-4899-9b2f-878ef08d166a',
        distributionListUrl:
          'https://hpmvrodevscusstapp.blob.core.windows.net/rigdistributionlist/1706758887779_1689757558171_VRO_PROJECT_DISTRIBUTIONS.xlsx',
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: 'B3BBF0BC-5DB0-4FF7-80FB-1B47AA792740',
          wellId: '459E5E36-7EAF-4BFC-A861-11B87C78355A',
          wellboreOriginalName: 'BdC-12h',
          wellboreCurrentName: null,
          uwiCurrentName: '',
          guid: '',
          surfaceLatitude: '2',
          surfaceLongitude: '2',
          spudDate: '08/29/2023, 10:00 AM',
          EDMsurfaceLatitude: null,
          EDMsurfaceLongitude: null,
          EDMspudDate: '2019-05-22T00:00:00',
          wellboreName: 'BdC-12h',
        },
        project: {
          projectId: '7900F5EA-A63D-4FBD-BF2F-D0359101DA56',
          policyId: 'DC5B1F7E-8A86-4B40-AAE8-8214A613DC7B',
          projectOriginalName: 'BAJO DEL CHOIQUE',
          projectCurrentName: null,
          timezone: '+02:00',
          country: 'GE',
          countryName: 'Georgia',
          block: 'BLOCK  - 221 edit BLOCK  - 221 editBLOCK  - 221 BL',
          region: 'EESSA',
          lease: 'BLOCK  - 221 edit BLOCK  - 221 editBLOCK  - 221BL',
          field: 'BLOCK  - 221 edit BLOCK  - 221 editBLOCK  - 221BL',
          preUnit: 'SI',
          curUnit: 'API',
          projectName: 'BAJO DEL CHOIQUE',
        },
        rig: {
          rigId: '8fcf7803-56b4-4f74-bcc8-01c3ac519615',
          rigName: 'GF_test',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUwMiIsInJpZ1R5cGVIaWVyYXJjaHkiOiJNYXJpbmUgfCBCb3R0b20gU3VwcG9ydGVkIHwgSmFja3VwIHwgS2V5d2F5IiwicmlnRGVmYXVsdEltYWdlIjoiTWFyaW5lX0phY2t1cF9LZXl3YXkuanBnIn0=',
          contractorId: '482bc6d3-e094-4fe3-b3bc-c0917feac86e',
          contractorName: 'gf_test',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1699472802393_funny_cat_face_pictures.png',
          specUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigspec/1699558839616_VRO.Platform_Intervention.Categories_v3.1-efdad236-08a7-4aa2-bf5c-ffd7692d91ec.pdf',
        },
        policy: {
          policyId: 'DC5B1F7E-8A86-4B40-AAE8-8214A613DC7B',
          policyOriginalName: 'AR - ExxonMobil Exploration Argentina S.R.L.',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'BdC-12h',
        },
        site: {
          siteId: '843D0A96-0515-4666-932B-44FD49BC7028',
          projectId: '7900F5EA-A63D-4FBD-BF2F-D0359101DA56',
          siteOriginalName: 'BdC Pad 1A',
          siteCurrentName: 'BdC Pad 1A Edit',
        },
        interval: null,
        remoteCenterList: [
          {
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
            remoteCenterName: 'VRO Testing',
          },
        ],
        mailboxList: [
          {
            mailboxId: 'f8c50ce0-8fe2-4025-a4d3-fed7ce16ace2',
            mailboxName: 'WellCareTestA@halliburton.com',
            isActive: true,
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
          },
        ],
        run: null,
        interventionFlag: {
          green: 1,
        },
        updatedAt: '2024-02-01T03:53:02.920Z',
      },
    ],
  ],
  [
    '128724BC-3915-47D2-B40A-7EA8C1AC2942',
    [
      {
        rigJournalId: '758b6495-8cb9-4705-a17a-2836b5fea42d',
        distributionListUrl:
          'https://hpmvrodevscusstapp.blob.core.windows.net/rigdistributionlist/1711960018351_1711421058491_1697769477168_disN.xlsx',
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: '63D13BA7-EC38-42EA-A7C3-3BB68364DE58',
          wellId: 'C1313052-FED5-4ABF-9E26-AA127E671D98',
          wellboreOriginalName: 'AC903#1 ST00 BP00',
          wellboreCurrentName: null,
          uwiCurrentName: '',
          guid: '',
          surfaceLatitude: '26.06030384472165',
          surfaceLongitude: '-94.78047312794493',
          spudDate: '10/19/2023, 06:16 PM',
          EDMsurfaceLatitude: 26.06030384472165,
          EDMsurfaceLongitude: -94.78047312794493,
          EDMspudDate: null,
          wellboreName: 'AC903#1 ST00 BP00',
        },
        project: {
          projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
          policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
          projectOriginalName: 'Alaminos Canyon',
          projectCurrentName: 'Alaminos Canyon 2',
          timezone: '+06:00',
          country: 'PT',
          countryName: 'Portugal',
          block: 'Test09N',
          region: 'EESSA',
          lease: 'Test09NJh',
          field: 'Test09N12',
          preUnit: 'API',
          curUnit: 'SI',
          projectName: 'Alaminos Canyon 2',
        },
        rig: {
          rigId: 'efaf8717-f493-4367-80be-166f78e84ce1',
          rigName: 'N_TestJh',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6Ik90aGVyIiwicmlnVHlwZUhpZXJhcmNoeSI6Ik90aGVyIiwicmlnRGVmYXVsdEltYWdlIjoiT3RoZXIuanBnIn0=',
          contractorId: '1ab70405-5806-48c7-80d1-2e6891796ae9',
          contractorName: 'N_TestContractor',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1693278510880_7iyVJmKTbhTvAhSn_1080xauto_high.png',
          specUrl: null,
        },
        policy: {
          policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
          policyOriginalName: 'Atlantia Resources',
          policyCurrentName: null,
          edmSource: 'IDAHO',
        },
        well: {
          wellName: 'Unocal AC903 #1 - TEST',
        },
        site: {
          siteId: 'E0266BBC-BBFC-4640-8DA5-998674BDE36D',
          projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
          siteOriginalName: 'Test',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [
          {
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
            remoteCenterName: 'VRO Testing',
          },
        ],
        mailboxList: [
          {
            mailboxId: '24ad277c-9032-4e09-b4f7-059ff95ba374',
            mailboxName: 'SVC_BRBUZIOSVRO@halliburton.com',
            isActive: true,
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
          },
          {
            mailboxId: '49e66a2b-1b1d-4d4c-aebe-7bcd9f4941fe',
            mailboxName: 'VROALERTtesting@halliburton.com',
            isActive: true,
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
          },
          {
            mailboxId: 'f8c50ce0-8fe2-4025-a4d3-fed7ce16ace2',
            mailboxName: 'WellCareTestA@halliburton.com',
            isActive: true,
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
          },
          {
            mailboxId: '6124aaa7-191d-4dc4-8af1-5e8fc18fbc5a',
            mailboxName: 'WellCareTestB@halliburton.com',
            isActive: true,
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
          },
          {
            mailboxId: 'eac8a337-76d3-4a8e-b531-3b52edd2d958',
            mailboxName: 'WellCareTestC@halliburton.com',
            isActive: true,
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
          },
          {
            mailboxId: 'cb62438d-f820-4657-8ae6-3a26e059b9ff',
            mailboxName: 'WellCareTestD@halliburton.com',
            isActive: true,
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
          },
        ],
        run: null,
        interventionFlag: {
          green: 22,
          yellow: 1,
          purple: 38,
        },
        updatedAt: '2024-04-01T09:02:26.365Z',
      },
      {
        rigJournalId: 'ea418643-9901-40aa-a114-ffb4a6d44055',
        distributionListUrl:
          'https://hpmvrodevscusstapp.blob.core.windows.net/rigdistributionlist/1711421058491_1697769477168_disN.xlsx',
        dataRoutingUrl:
          'https://hpmvrodevscusstapp.blob.core.windows.net/rigdatarouting/1711080069797_WellCare-Release-Notes-v1.1.0.pdf',
        communicateProtocolUrl:
          'https://hpmvrodevscusstapp.blob.core.windows.net/rigcommunicateprotocal/1711080069843_Report_Alert_Management_export_1710229532074.pdf',
        isComplete: false,
        wellbore: {
          wellboreId: 'BFA0D743-EF80-4B16-A497-9099C32197FB',
          wellId: '9BF25524-4641-4265-8143-1A1DC3C1EBBD',
          wellboreOriginalName: 'ST00',
          wellboreCurrentName: null,
          uwiCurrentName: '',
          guid: '',
          surfaceLatitude: '26.08370766130537',
          surfaceLongitude: '-94.71976780810463',
          spudDate: null,
          EDMsurfaceLatitude: 26.08370766130537,
          EDMsurfaceLongitude: -94.71976780810463,
          EDMspudDate: null,
          wellboreName: 'ST00',
        },
        project: {
          projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
          policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
          projectOriginalName: 'Alaminos Canyon',
          projectCurrentName: 'Alaminos Canyon 2',
          timezone: '+06:00',
          country: 'PT',
          countryName: 'Portugal',
          block: 'Test09N',
          region: 'EESSA',
          lease: 'Test09NJh',
          field: 'Test09N12',
          preUnit: 'API',
          curUnit: 'SI',
          projectName: 'Alaminos Canyon 2',
        },
        rig: {
          rigId: '956348ff-838b-4d45-a7bb-7c7117d9c171',
          rigName: 'S_N_TestABC',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUxOSIsInJpZ1R5cGVIaWVyYXJjaHkiOiJMYW5kIHwgQ29udmVudGlvbmFsIHwgU2xpbmdzaG90IiwicmlnRGVmYXVsdEltYWdlIjoiTGFuZF9Db252ZW50aW9uYWwuanBnIn0=',
          contractorId: '5502fb10-39f3-4356-82b5-2ab2f8d5a6b8',
          contractorName: 'Rig Contractor Demo Audit Trail',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1703489978436_Hello%2520Vi%25E1%25BB%2587t%2520Nam.png',
          specUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigspec/1703490073155_Hi%2520Vi%25E1%25BB%2587t%2520Nam%2520%2540%2523%2540%2523%2540.pdf',
        },
        policy: {
          policyId: '706940C3-B79B-47BC-9EC4-215CC7D5D29B',
          policyOriginalName: 'Atlantia Resources',
          policyCurrentName: null,
          edmSource: 'IDAHO',
        },
        well: {
          wellName: 'AC 904 - Site_East_1',
        },
        site: {
          siteId: 'D41EEDC3-E052-4B46-BD4F-92F22FD6C4D2',
          projectId: '128724BC-3915-47D2-B40A-7EA8C1AC2942',
          siteOriginalName: 'Prometheus (Well Construction)',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [
          {
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
            remoteCenterName: 'VRO Testing',
          },
        ],
        mailboxList: [
          {
            mailboxId: '49e66a2b-1b1d-4d4c-aebe-7bcd9f4941fe',
            mailboxName: 'VROALERTtesting@halliburton.com',
            isActive: true,
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
          },
        ],
        run: null,
        interventionFlag: {},
        updatedAt: '2024-03-26T09:49:50.247Z',
      },
    ],
  ],
  [
    '53F56465-A118-4E3F-A4F3-28DB6BC7C2DF',
    [
      {
        rigJournalId: '73570b88-d90c-448e-b9de-5ff8d3b2cf31',
        distributionListUrl:
          'https://hpmvrodevscusstapp.blob.core.windows.net/rigdistributionlist/1711519826952_1711421058491_1697769477168_disN.xlsx',
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: '468E3359-D8BC-44EE-9C39-943A9083D6AB',
          wellId: 'FD7FEAFF-BDDE-4D54-9254-8D0B28602491',
          wellboreOriginalName: '1-ALV-13-BA',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: -11.96491517653762,
          EDMsurfaceLongitude: -38.21495078783794,
          EDMspudDate: '2019-08-13T00:00:00',
          wellboreName: '1-ALV-13-BA',
        },
        project: {
          projectId: '53F56465-A118-4E3F-A4F3-28DB6BC7C2DF',
          policyId: '97B1C306-CC4E-4386-93CC-91D60B1ADF9F',
          projectOriginalName: 'Alvopetro',
          projectCurrentName: null,
          timezone: '-11:00',
          country: 'AL',
          countryName: 'Albania',
          block: '321',
          region: 'EESSA',
          lease: '312',
          field: '312',
          preUnit: '',
          curUnit: 'API',
          projectName: 'Alvopetro',
        },
        rig: {
          rigId: '0eb8ad4b-ba66-47d9-b578-7e86cd4bcd50',
          rigName: 'N_Test Demo',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUyNyIsInJpZ1R5cGVIaWVyYXJjaHkiOiJSaWdsZXNzIHwgQ29pbGVkIFR1YmluZyIsInJpZ0RlZmF1bHRJbWFnZSI6IkxhbmRfUmlnbGVzc19Db2lsZWQgVHViaW5nLmpwZyJ9',
          contractorId: '5502fb10-39f3-4356-82b5-2ab2f8d5a6b8',
          contractorName: 'Rig Contractor Demo Audit Trail',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1710729533575_1690252407037_rig.png',
          specUrl: null,
        },
        policy: {
          policyId: '97B1C306-CC4E-4386-93CC-91D60B1ADF9F',
          policyOriginalName: 'BR - ALVOPETRO',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: '1-ALV-13-BA',
        },
        site: {
          siteId: '3AE3AD80-DC97-4631-8A13-17A274FAECB3',
          projectId: '53F56465-A118-4E3F-A4F3-28DB6BC7C2DF',
          siteOriginalName: '1-ALV-13-BA',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [],
        mailboxList: [],
        run: null,
        interventionFlag: {},
        updatedAt: '2024-03-27T06:10:27.036Z',
      },
    ],
  ],
  [
    'AA80B181-966E-47EF-8C60-4215BA6A8CEE',
    [
      {
        rigJournalId: 'e28be639-7037-4663-847e-767ad87b1cb0',
        distributionListUrl:
          'https://hpmvrodevscusstapp.blob.core.windows.net/rigdistributionlist/1711442481938_1697713341955_1696506902811_Tuyet_DistributionList.xlsx',
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: '5B46BBCA-4D02-41E5-9DD9-69E0E9955873',
          wellId: 'A2053711-5AC6-467B-984B-2CCFF6D24858',
          wellboreOriginalName: 'LCA-3002',
          wellboreCurrentName: 'LCA-3002A Test',
          uwiCurrentName: '',
          guid: '',
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: null,
          EDMsurfaceLongitude: null,
          EDMspudDate: '2021-11-14T00:00:00',
          wellboreName: 'LCA-3002A Test',
        },
        project: {
          projectId: 'AA80B181-966E-47EF-8C60-4215BA6A8CEE',
          policyId: '6D026303-07DD-46EE-AF2D-35DBB83EC961',
          projectOriginalName: 'La Calera',
          projectCurrentName: null,
          timezone: '+06:00',
          country: 'GA',
          countryName: 'Gabon',
          block: 'VN0112',
          region: 'EESSA',
          lease: 'VN00112',
          field: 'VN101112',
          preUnit: 'API - US Survey Feet',
          curUnit: 'API',
          projectName: 'La Calera',
        },
        rig: {
          rigId: 'ba9aaf83-ae6f-4662-b11a-1cd00a4c7f98',
          rigName: 'Rig Test',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUxOCIsInJpZ1R5cGVIaWVyYXJjaHkiOiJMYW5kIHwgQ29udmVudGlvbmFsIHwgQm94LW9uLUJveCIsInJpZ0RlZmF1bHRJbWFnZSI6IkxhbmRfQ29udmVudGlvbmFsLmpwZyJ9',
          contractorId: 'e829a54c-8746-40c6-9774-db2c9d1afb29',
          contractorName: 'Test',
          imageUrl: null,
          specUrl: null,
        },
        policy: {
          policyId: '6D026303-07DD-46EE-AF2D-35DBB83EC961',
          policyOriginalName: 'AR - Pluspetrol',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'LCA-3002A Test',
        },
        site: {
          siteId: '2E72F4B5-420D-4038-A5AA-F3F0B30E7DF5',
          projectId: 'AA80B181-966E-47EF-8C60-4215BA6A8CEE',
          siteOriginalName: 'PAD 3001',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [
          {
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
            remoteCenterName: 'VRO Testing',
          },
        ],
        mailboxList: [
          {
            mailboxId: '49e66a2b-1b1d-4d4c-aebe-7bcd9f4941fe',
            mailboxName: 'VROALERTtesting@halliburton.com',
            isActive: true,
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
          },
          {
            mailboxId: '6124aaa7-191d-4dc4-8af1-5e8fc18fbc5a',
            mailboxName: 'WellCareTestB@halliburton.com',
            isActive: true,
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
          },
          {
            mailboxId: 'eac8a337-76d3-4a8e-b531-3b52edd2d958',
            mailboxName: 'WellCareTestC@halliburton.com',
            isActive: true,
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
          },
          {
            mailboxId: 'cb62438d-f820-4657-8ae6-3a26e059b9ff',
            mailboxName: 'WellCareTestD@halliburton.com',
            isActive: true,
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
          },
        ],
        run: {
          run_id: 'e87e961f-a716-4bc6-9dbd-407ff2c503c4',
          rig_journal_id: 'e28be639-7037-4663-847e-767ad87b1cb0',
          interval_id: '5b518a97-3553-44fd-b9d8-a991e65b29fd',
          description: 'g',
          type: 'TYPE10',
          run_no: '1',
          rig_bha_no: '',
          bit_size: '',
          reamer_size: '',
          casing_size: '1',
          start_measured_depth: '',
          td_measured_depth: '',
          below_rotary_timestamp: null,
          run_td_timestamp: null,
          above_rotary_timestamp: null,
          tally_url: null,
          unit: 'API - US Survey Feet',
          createdAt: '2024-02-06T08:44:38.010Z',
          updatedAt: '2024-02-06T08:44:38.010Z',
        },
        interventionFlag: {
          green: 4,
          yellow: 4,
          red: 26,
          purple: 6,
        },
        updatedAt: '2024-03-26T08:41:22.837Z',
      },
    ],
  ],
  [
    '65244C0D-B979-4FBC-A3CB-F9842E960F22',
    [
      {
        rigJournalId: '6aea1e17-9a6f-4ea3-b78d-989eeaaa31ae',
        distributionListUrl:
          'https://hpmvrodevscusstapp.blob.core.windows.net/rigdistributionlist/1711428320391_1710761522013_TEMPLATE_WELLCARE_DISTRIBUTION.xlsx',
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: '0595C8F9-AED6-436C-BF7D-899A5852993E',
          wellId: '05BC1E43-A805-4ADC-B68C-7CDA247DAB71',
          wellboreOriginalName: 'Enin-1',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: 0.9607418247702764,
          EDMsurfaceLongitude: 10.098419520628838,
          EDMspudDate: null,
          wellboreName: 'Enin-1',
        },
        project: {
          projectId: '65244C0D-B979-4FBC-A3CB-F9842E960F22',
          policyId: '6304A347-AB51-45A4-A1B9-BBD6009D665C',
          projectOriginalName: 'OPL231 - Exploration',
          projectCurrentName: null,
          timezone: null,
          country: null,
          block: null,
          region: null,
          lease: null,
          field: null,
          preUnit: null,
          curUnit: null,
          projectName: 'OPL231 - Exploration',
        },
        rig: {
          rigId: '79ad366a-c5b6-4284-92a7-8e1626690fcc',
          rigName: '0_JF_0',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUyMyIsInJpZ1R5cGVIaWVyYXJjaHkiOiJSaWdsZXNzIHwgU3VwcG9ydCBWZXNzZWwgfCBXZWxsIEludGVydmVudGlvbiBWZXNzZWwiLCJyaWdEZWZhdWx0SW1hZ2UiOiJSaWdsZXNzX1N1cHBvcnQgVmVzc2VsX1dlbGwgSW50ZXJ2ZW50aW9uIFZlc3NlbC5qcGcifQ==',
          contractorId: '51d3c409-d483-4f3f-9260-8ca24654d697',
          contractorName: 'N_Test1',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1703049732168_chu%25E1%25BB%2599t.png',
          specUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigspec/1703049732160_%25C4%2591%25C3%25B3c%2520cu%2520m%25C3%25A8n.pdf',
        },
        policy: {
          policyId: '6304A347-AB51-45A4-A1B9-BBD6009D665C',
          policyOriginalName: 'NG - Moni Pulo Petroleum Development Limited',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'Enin-1',
        },
        site: {
          siteId: 'E5936228-3128-47E2-B820-6E6E17832C58',
          projectId: '65244C0D-B979-4FBC-A3CB-F9842E960F22',
          siteOriginalName: 'Enin-1',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [],
        mailboxList: [],
        run: null,
        interventionFlag: {},
        updatedAt: '2024-03-26T04:45:20.456Z',
      },
    ],
  ],
  [
    '8AC2866B-9B47-49E7-B9B6-6D33EF3876FC',
    [
      {
        rigJournalId: 'b06c578c-45f7-4e34-826a-26440ff89bc2',
        distributionListUrl: null,
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: 'FA4A8255-30EA-4CA3-96D1-9C04D9086847',
          wellId: '8E1314AF-AB0D-4FED-8B4D-759C7B4B4A9C',
          wellboreOriginalName: 'Jacana 41',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: null,
          EDMsurfaceLongitude: null,
          EDMspudDate: '2020-10-11T00:00:00',
          wellboreName: 'Jacana 41',
        },
        project: {
          projectId: '8AC2866B-9B47-49E7-B9B6-6D33EF3876FC',
          policyId: 'D7DBA003-F59B-43DF-B4CD-DE2299ADAE27',
          projectOriginalName: 'Llanos 34',
          projectCurrentName: 'Llanos 346',
          timezone: '+00:00',
          country: 'DE',
          countryName: 'Germany',
          block: 'SA',
          region: 'EESSA',
          lease: 'SA',
          field: 'SA',
          preUnit: '',
          curUnit: 'API',
          projectName: 'Llanos 346',
        },
        rig: {
          rigId: 'a66f5567-4e9f-4ba0-95d8-82114a34402e',
          rigName: 'Another Space2',
          rigTypeName: 'eyJyaWdUeXBlSGllcmFyY2h5IjoiTWFyaW5lIn0=',
          contractorId: '98a1a70f-dcaa-4a3f-b75c-da794dd617ad',
          contractorName: 'Test Contractor Unit',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1693293722717_iyVJmKTbhTvAhSn_1080%27%2B~xauto_high.png',
          specUrl: null,
        },
        policy: {
          policyId: 'D7DBA003-F59B-43DF-B4CD-DE2299ADAE27',
          policyOriginalName: 'CO - Geopark',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'Jacana 41',
        },
        site: {
          siteId: 'DAB4C166-BC27-4F1C-8DBA-37F4AA526915',
          projectId: '8AC2866B-9B47-49E7-B9B6-6D33EF3876FC',
          siteOriginalName: 'Jacana 41',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [
          {
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
            remoteCenterName: 'VRO Testing',
          },
        ],
        mailboxList: [],
        run: null,
        interventionFlag: {},
        updatedAt: '2024-03-26T04:09:31.910Z',
      },
    ],
  ],
  [
    'FEFB765B-1F9F-48A1-AEB6-9350DEA8D234',
    [
      {
        rigJournalId: 'ec5cc97b-f90b-4f03-bc83-de492dab3c18',
        distributionListUrl:
          'https://hpmvrodevscusstapp.blob.core.windows.net/rigdistributionlist/1710244604748_1697769477168_disN.xlsx',
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: '31553D21-AD86-4142-A37A-FFE986F73626',
          wellId: 'EDBDA449-441F-4CA5-86D9-3F8310722ECC',
          wellboreOriginalName: ' 25/10 A-01 AH',
          wellboreCurrentName: null,
          uwiCurrentName: '',
          guid: '',
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: '03/27/2024, 09:07 AM',
          EDMsurfaceLatitude: null,
          EDMsurfaceLongitude: null,
          EDMspudDate: null,
          wellboreName: ' 25/10 A-01 AH',
        },
        project: {
          projectId: 'FEFB765B-1F9F-48A1-AEB6-9350DEA8D234',
          policyId: 'B7EA2BC6-C4C2-4287-A94F-9C935FB7D761',
          projectOriginalName: 'Hanz Producer 25/10 A-01 AH',
          projectCurrentName: null,
          timezone: '-06:00',
          country: 'PR',
          countryName: 'Puerto Rico',
          block: '12',
          region: 'NAM',
          lease: '12',
          field: '2',
          preUnit: '',
          curUnit: 'API - US Survey Feet',
          projectName: 'Hanz Producer 25/10 A-01 AH',
        },
        rig: {
          rigId: '21d1591c-b4f1-4ab2-bf63-d2226cc3d6dc',
          rigName: 'N_Test Rig',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUyOSIsInJpZ1R5cGVIaWVyYXJjaHkiOiJNYXJpbmUgfCBCb3R0b20gU3VwcG9ydGVkIHwgUGxhdGZvcm0gfCBNb2R1bGFyIERyaWxsaW5nIFJpZyIsInJpZ0RlZmF1bHRJbWFnZSI6IkdFTkVSSUMtUklHLUlNQUdFX01PRFVMQVItRFJJTExJTkctUklHLnBuZyJ9',
          contractorId: 'cd01b508-26c6-4186-8457-e231464352ca',
          contractorName: 'B',
          imageUrl: null,
          specUrl: null,
        },
        policy: {
          policyId: 'B7EA2BC6-C4C2-4287-A94F-9C935FB7D761',
          policyOriginalName: 'AkerBP Lower Completion Space',
          policyCurrentName: null,
          edmSource: 'OHIO(ConocoPhillips)',
        },
        well: {
          wellName: ' 25/10 A-01 AH',
        },
        site: {
          siteId: '35492357-87F4-40F0-A7D7-6FDA599F273C',
          projectId: 'FEFB765B-1F9F-48A1-AEB6-9350DEA8D234',
          siteOriginalName: ' 25/10 A-01 AH',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [
          {
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
            remoteCenterName: 'VRO Testing',
          },
        ],
        mailboxList: [
          {
            mailboxId: '49e66a2b-1b1d-4d4c-aebe-7bcd9f4941fe',
            mailboxName: 'VROALERTtesting@halliburton.com',
            isActive: true,
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
          },
        ],
        run: null,
        interventionFlag: {
          purple: 1,
        },
        updatedAt: '2024-03-26T02:08:08.210Z',
      },
    ],
  ],
  [
    '84251EE7-0337-4222-A5D1-D4F8CA965A8E',
    [
      {
        rigJournalId: '102473ed-c1c5-4f49-a585-5317900e7bb1',
        distributionListUrl: null,
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: '2B17AA2F-65EF-41D9-8E9B-386AF651CD3F',
          wellId: '28A212D9-670F-44F2-8524-618DFA11B420',
          wellboreOriginalName: 'M-1104',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: null,
          EDMsurfaceLongitude: null,
          EDMspudDate: '2019-02-04T00:00:00',
          wellboreName: 'M-1104',
        },
        project: {
          projectId: '84251EE7-0337-4222-A5D1-D4F8CA965A8E',
          policyId: '05472721-52C5-4CEA-864A-98147BD3677F',
          projectOriginalName: 'El Mangrullo',
          projectCurrentName: null,
          timezone: '+07:00',
          country: 'DE',
          countryName: 'Germany',
          block: 'BL098',
          region: 'EESSA',
          lease: 'Bl098',
          field: '123',
          preUnit: 'SI',
          curUnit: 'API',
          projectName: 'El Mangrullo',
        },
        rig: {
          rigId: '757c0591-598c-4fa8-b83c-44cd5ffab73e',
          rigName: 'Rig Modula',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUyOSIsInJpZ1R5cGVIaWVyYXJjaHkiOiJNYXJpbmUgfCBCb3R0b20gU3VwcG9ydGVkIHwgUGxhdGZvcm0gfCBNb2R1bGFyIERyaWxsaW5nIFJpZyIsInJpZ0RlZmF1bHRJbWFnZSI6IkdFTkVSSUMtUklHLUlNQUdFX01PRFVMQVItRFJJTExJTkctUklHLnBuZyJ9',
          contractorId: '01cf9085-a47c-4cbc-a706-60a1200dd2b0',
          contractorName: 'Rig Test',
          imageUrl: null,
          specUrl: null,
        },
        policy: {
          policyId: '05472721-52C5-4CEA-864A-98147BD3677F',
          policyOriginalName: 'AR - Pampa Energia',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'M-1104(h)',
        },
        site: {
          siteId: '816CFAB1-C640-4935-9927-DB7705B79062',
          projectId: '84251EE7-0337-4222-A5D1-D4F8CA965A8E',
          siteOriginalName: 'El Mangrullo',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [],
        mailboxList: [],
        run: null,
        interventionFlag: {},
        updatedAt: '2024-03-12T10:13:32.000Z',
      },
    ],
  ],
  [
    'A842FF23-0D26-4BAC-A760-44F29C099AA1',
    [
      {
        rigJournalId: 'eede93b4-8c38-4146-8931-8a34677ce384',
        distributionListUrl:
          'https://hpmvrodevscusstapp.blob.core.windows.net/rigdistributionlist/1694168812096_VRO_Check_Normal.xlsx',
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: '639FDDB2-6A4D-491A-B9AA-353B9ED38A11',
          wellId: '516DD301-EB06-41DA-8233-157DA347CDB4',
          wellboreOriginalName: 'ADC-1052',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: null,
          EDMsurfaceLongitude: null,
          EDMspudDate: '2023-06-29T15:00:00',
          wellboreName: 'ADC-1052',
        },
        project: {
          projectId: 'A842FF23-0D26-4BAC-A760-44F29C099AA1',
          policyId: '59DC7167-0EAF-4E13-B462-A84DFD4BF19E',
          projectOriginalName: 'PAD-1050',
          projectCurrentName: null,
          timezone: '+01:00',
          country: 'VN',
          countryName: 'Viet Nam',
          block: 'testdawd',
          region: 'AP',
          lease: 'testawda',
          field: 'testdawda X',
          preUnit: '',
          curUnit: 'API',
          projectName: 'PAD-1050',
        },
        rig: {
          rigId: 'f81ad768-ef5b-4a2e-a3af-48d3f86021a4',
          rigName: '1405_MT_Rig12',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUwMyIsInJpZ1R5cGVIaWVyYXJjaHkiOiJNYXJpbmUgfCBCb3R0b20gU3VwcG9ydGVkIHwgU3VibWVyc2libGUgfCBQb3N0ZWQgQmFyZ2UiLCJyaWdEZWZhdWx0SW1hZ2UiOiJNYXJpbmVfU3VibWVyc2libGVfUG9zdGVkIEJhcmdlLmpwZyJ9',
          contractorId: 'ab6c9b6d-d940-4b12-a8c4-227ff47e208a',
          contractorName: 'RigTest12',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1690813794882_A-Rig4.png',
          specUrl: null,
        },
        policy: {
          policyId: '59DC7167-0EAF-4E13-B462-A84DFD4BF19E',
          policyOriginalName: 'AR - CAPEX',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'ADC-1052',
        },
        site: {
          siteId: '71E22A7A-DB39-49F9-B3B3-8BF14C587C19',
          projectId: 'A842FF23-0D26-4BAC-A760-44F29C099AA1',
          siteOriginalName: 'ADC-1052',
          siteCurrentName: null,
        },
        interval: {
          intervalId: '7daffd53-5a00-4332-83d2-7170a734d22c',
          wellboreId: '639FDDB2-6A4D-491A-B9AA-353B9ED38A11',
          rigJournalId: 'eede93b4-8c38-4146-8931-8a34677ce384',
          intervalName: 'ABC',
          unit: 'API',
          operation: 'completion',
          designOfServiceUrl: null,
          casedHoleId: '12',
          openHoleDiameter: '12',
          lastShoeMeasuredDepth: '12',
          isNaCaseHoleLastShoe: false,
          isNaOpenHole: false,
        },
        remoteCenterList: [
          {
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
            remoteCenterName: 'VRO Testing',
          },
        ],
        mailboxList: [
          {
            mailboxId: 'f8c50ce0-8fe2-4025-a4d3-fed7ce16ace2',
            mailboxName: 'WellCareTestA@halliburton.com',
            isActive: true,
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
          },
        ],
        run: {
          run_id: 'f23c334c-07bb-4dab-9ea6-ca7579495f4e',
          rig_journal_id: 'eede93b4-8c38-4146-8931-8a34677ce384',
          interval_id: '7daffd53-5a00-4332-83d2-7170a734d22c',
          description: '13',
          type: 'TYPE15',
          run_no: '14',
          rig_bha_no: '15',
          bit_size: '',
          reamer_size: '',
          casing_size: '14',
          start_measured_depth: '',
          td_measured_depth: '',
          below_rotary_timestamp: null,
          run_td_timestamp: null,
          above_rotary_timestamp: null,
          tally_url: null,
          unit: 'API',
          createdAt: '2023-07-25T03:26:38.609Z',
          updatedAt: '2023-07-25T03:26:38.609Z',
        },
        interventionFlag: {
          blue: 1,
          purple: 1,
        },
        updatedAt: '2024-02-27T13:53:07.702Z',
      },
      {
        rigJournalId: 'cc118a3d-02e2-4815-b29b-6c5419e0e8ed',
        distributionListUrl:
          'https://hpmvrodevscusstapp.blob.core.windows.net/rigdistributionlist/1696837834880_1696506902811_Tuyet_DistributionList.xlsx',
        dataRoutingUrl:
          'https://hpmvrodevscusstapp.blob.core.windows.net/rigdatarouting/1696824954275_20230427-0502_VRO_RESOLUTION_GREEN_FlowbackVolume_NewRig_BAJODELCHOIQUEEDIT_AR-ExxonMobilExplorationArgentinaS.R.L.edit.pdf',
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: 'EBBCA916-9FE6-48BE-AF14-BC2832B9E481',
          wellId: '436D22F5-B00A-4227-AEE2-E9830808A9CD',
          wellboreOriginalName: 'ADC-1050',
          wellboreCurrentName: null,
          uwiCurrentName: 'null',
          guid: 'null',
          surfaceLatitude: '12',
          surfaceLongitude: '23',
          spudDate: '07/25/2023, 02:55 PM',
          EDMsurfaceLatitude: null,
          EDMsurfaceLongitude: null,
          EDMspudDate: '2023-06-22T00:00:00',
          wellboreName: 'ADC-1050',
        },
        project: {
          projectId: 'A842FF23-0D26-4BAC-A760-44F29C099AA1',
          policyId: '59DC7167-0EAF-4E13-B462-A84DFD4BF19E',
          projectOriginalName: 'PAD-1050',
          projectCurrentName: null,
          timezone: '+01:00',
          country: 'VN',
          countryName: 'Viet Nam',
          block: 'testdawd',
          region: 'AP',
          lease: 'testawda',
          field: 'testdawda X',
          preUnit: '',
          curUnit: 'API',
          projectName: 'PAD-1050',
        },
        rig: {
          rigId: '4c93230e-fd6c-45cb-9cf5-aa44b8a0dfcd',
          rigName: '1406_MT_Test6',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUwMSIsInJpZ1R5cGVIaWVyYXJjaHkiOiJNYXJpbmUgfCBCb3R0b20gU3VwcG9ydGVkIHwgSmFja3VwIHwgQ2FudGlsZXZlcmVkIiwicmlnRGVmYXVsdEltYWdlIjoiTWFyaW5lX0phY2t1cF9DYW50aWxldmVyZWQuanBnIn0=',
          contractorId: '01cf9085-a47c-4cbc-a706-60a1200dd2b0',
          contractorName: 'Rig Test',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1691398901004_MicrosoftTeams-image(1).png',
          specUrl: null,
        },
        policy: {
          policyId: '59DC7167-0EAF-4E13-B462-A84DFD4BF19E',
          policyOriginalName: 'AR - CAPEX',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'ADC-1050',
        },
        site: {
          siteId: '532E524A-9E96-47C8-8489-3CBA0B791697',
          projectId: 'A842FF23-0D26-4BAC-A760-44F29C099AA1',
          siteOriginalName: 'ADC-1050',
          siteCurrentName: null,
        },
        interval: {
          intervalId: 'a7fd8bac-7fed-4965-9975-8fd18a8e90c0',
          wellboreId: 'EBBCA916-9FE6-48BE-AF14-BC2832B9E481',
          rigJournalId: 'cc118a3d-02e2-4815-b29b-6c5419e0e8ed',
          intervalName: 'Test',
          unit: 'API',
          operation: 'intervention',
          designOfServiceUrl: null,
          casedHoleId: '123',
          openHoleDiameter: '314',
          lastShoeMeasuredDepth: '123',
          isNaCaseHoleLastShoe: false,
          isNaOpenHole: false,
        },
        remoteCenterList: [
          {
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
            remoteCenterName: 'VRO Testing',
          },
        ],
        mailboxList: [],
        run: {
          run_id: 'd13e57e8-d076-489e-af11-3514468b1186',
          rig_journal_id: 'cc118a3d-02e2-4815-b29b-6c5419e0e8ed',
          interval_id: 'a7fd8bac-7fed-4965-9975-8fd18a8e90c0',
          description: '123',
          type: 'TYPE15',
          run_no: '123',
          rig_bha_no: '123',
          bit_size: '',
          reamer_size: '',
          casing_size: '12',
          start_measured_depth: '',
          td_measured_depth: '',
          below_rotary_timestamp: null,
          run_td_timestamp: null,
          above_rotary_timestamp: null,
          tally_url: null,
          unit: 'API',
          createdAt: '2023-07-24T13:57:02.239Z',
          updatedAt: '2023-07-24T13:57:02.239Z',
        },
        interventionFlag: {},
        updatedAt: '2023-10-09T08:29:27.599Z',
      },
      {
        rigJournalId: 'd96f66bc-9ffe-47cc-9ab0-30e4264a7158',
        distributionListUrl: null,
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: '75DB15BB-3BB6-4995-B071-BD95DFF7E31F',
          wellId: 'E4351304-A90A-470F-AA9E-1FD84D1C8669',
          wellboreOriginalName: 'ADC-1053',
          wellboreCurrentName: 'ADC-1053a',
          uwiCurrentName: '',
          guid: '',
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: null,
          EDMsurfaceLongitude: null,
          EDMspudDate: '2023-07-03T00:00:00',
          wellboreName: 'ADC-1053a',
        },
        project: {
          projectId: 'A842FF23-0D26-4BAC-A760-44F29C099AA1',
          policyId: '59DC7167-0EAF-4E13-B462-A84DFD4BF19E',
          projectOriginalName: 'PAD-1050',
          projectCurrentName: null,
          timezone: '+01:00',
          country: 'VN',
          countryName: 'Viet Nam',
          block: 'testdawd',
          region: 'AP',
          lease: 'testawda',
          field: 'testdawda X',
          preUnit: '',
          curUnit: 'API',
          projectName: 'PAD-1050',
        },
        rig: {
          rigId: 'f408ae5f-af7f-4f6b-971f-8ab958404496',
          rigName: 'NCTRig',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6Ik90aGVyIiwicmlnVHlwZUhpZXJhcmNoeSI6Ik90aGVyIiwicmlnRGVmYXVsdEltYWdlIjoiT3RoZXIuanBnIn0=',
          contractorId: '98a1a70f-dcaa-4a3f-b75c-da794dd617ad',
          contractorName: 'Test Contractor Unit',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1694698670291_rig03.png',
          specUrl: null,
        },
        policy: {
          policyId: '59DC7167-0EAF-4E13-B462-A84DFD4BF19E',
          policyOriginalName: 'AR - CAPEX',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'ADC-1053',
        },
        site: {
          siteId: '9B2DF22F-4BAB-46F2-B5C9-8DC7C2BF3343',
          projectId: 'A842FF23-0D26-4BAC-A760-44F29C099AA1',
          siteOriginalName: 'ADC-1053',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [],
        mailboxList: [],
        run: null,
        interventionFlag: {},
        updatedAt: '2023-10-03T02:13:17.075Z',
      },
    ],
  ],
  [
    '49B0C04C-D199-43BF-A50E-121818D7FA48',
    [
      {
        rigJournalId: '100853d8-f7a7-4872-936c-a52b38825107',
        distributionListUrl:
          'https://hpmvrodevscusstapp.blob.core.windows.net/rigdistributionlist/1689825707523_1683531332986_TestDistribution%20name.xlsx',
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: 'D2200155-DBBF-4A38-B883-2F84CEC48B1A',
          wellId: 'AAA2E07B-973F-4FCA-AAB1-E8819A95A396',
          wellboreOriginalName: 'LA.e-306',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: '23',
          surfaceLongitude: '54',
          spudDate: '06/22/2023, 03:34 PM',
          EDMsurfaceLatitude: null,
          EDMsurfaceLongitude: null,
          EDMspudDate: '2019-12-19T10:00:00',
          wellboreName: 'LA.e-306',
        },
        project: {
          projectId: '49B0C04C-D199-43BF-A50E-121818D7FA48',
          policyId: '4D93C1F1-DE0A-4845-AF71-5E0575E19914',
          projectOriginalName: 'Lindero Atravesado',
          projectCurrentName: null,
          timezone: '+01:00',
          country: 'DE',
          countryName: 'Germany',
          block: 'Test',
          region: 'EESSA',
          lease: 'Test',
          field: 'Test',
          preUnit: 'SI',
          curUnit: 'API - US Survey Feet',
          projectName: 'Lindero Atravesado',
        },
        rig: {
          rigId: '6c5f09ab-7d72-4f25-8a3c-cf51f66be63c',
          rigName: "';:",
          rigTypeName: 'eyJyaWdUeXBlSGllcmFyY2h5IjoiTWFyaW5lIn0=',
          contractorId: '06a33aa5-66f0-49d5-b6e0-121f5693b5be',
          contractorName: 'Rig Test 5',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1691391336811_A-Rig4.png',
          specUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigspec/1691377487876_Report_Alert_Management_export_1690812361709.pdf',
        },
        policy: {
          policyId: '4D93C1F1-DE0A-4845-AF71-5E0575E19914',
          policyOriginalName: 'AR - Pan American Energy',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'LA.e-306',
        },
        site: {
          siteId: 'E8E37E14-0D51-422B-9D8E-FD420A7175A7',
          projectId: '49B0C04C-D199-43BF-A50E-121818D7FA48',
          siteOriginalName: 'LA-306',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [
          {
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
            remoteCenterName: 'VRO Testing',
          },
        ],
        mailboxList: [
          {
            mailboxId: 'f8c50ce0-8fe2-4025-a4d3-fed7ce16ace2',
            mailboxName: 'WellCareTestA@halliburton.com',
            isActive: true,
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
          },
        ],
        run: null,
        interventionFlag: {
          green: 1,
        },
        updatedAt: '2024-02-27T13:52:41.360Z',
      },
    ],
  ],
  [
    '21DD399E-2FD2-4D7B-823C-25B556394047',
    [
      {
        rigJournalId: '46754b92-e2d7-49a2-ba35-735f7c271c29',
        distributionListUrl:
          'https://hpmvrodevscusstapp.blob.core.windows.net/rigdistributionlist/1693297581154_dis_tue.xlsx',
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: '42DFF61E-286D-46FA-B1F2-3606AADDB125',
          wellId: '9470C670-7FAA-4FA0-B123-14E19DFC547C',
          wellboreOriginalName: 'A24-4',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: 66.35647944444447,
          EDMsurfaceLongitude: 77.1689398888853,
          EDMspudDate: null,
          wellboreName: 'A24-4',
        },
        project: {
          projectId: '21DD399E-2FD2-4D7B-823C-25B556394047',
          policyId: '7F029402-6722-46D7-AEB1-F9059D07BDFC',
          projectOriginalName: 'Achimgaz TSA',
          projectCurrentName: 'Champion',
          timezone: '+00:00',
          country: 'DE',
          countryName: 'Germany',
          block: '1234',
          region: 'EESSA',
          lease: '1234',
          field: '2345',
          preUnit: '',
          curUnit: 'API',
          projectName: 'Champion',
        },
        rig: {
          rigId: 'f9d4ba9e-d579-4601-b987-60e222b3367e',
          rigName: 'Change Name 1',
          rigTypeName: 'eyJyaWdUeXBlSGllcmFyY2h5IjoiTWFyaW5lIn0=',
          contractorId: '98a1a70f-dcaa-4a3f-b75c-da794dd617ad',
          contractorName: 'Test Contractor Unit',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1693189556943_rig01.png',
          specUrl: null,
        },
        policy: {
          policyId: '7F029402-6722-46D7-AEB1-F9059D07BDFC',
          policyOriginalName: 'Achimgaz Russsia',
          policyCurrentName: null,
          edmSource: 'IDAHO',
        },
        well: {
          wellName: 'A24-4',
        },
        site: {
          siteId: '35DAECE5-FCED-4B9A-8994-41FF06B221F5',
          projectId: '21DD399E-2FD2-4D7B-823C-25B556394047',
          siteOriginalName: 'Achimgaz TSA',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [
          {
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
            remoteCenterName: 'VRO Testing',
          },
        ],
        mailboxList: [
          {
            mailboxId: 'f8c50ce0-8fe2-4025-a4d3-fed7ce16ace2',
            mailboxName: 'WellCareTestA@halliburton.com',
            isActive: true,
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
          },
        ],
        run: null,
        interventionFlag: {},
        updatedAt: '2024-02-27T13:52:22.453Z',
      },
    ],
  ],
  [
    '688D6981-A702-4A10-9018-495167E3A9D7',
    [
      {
        rigJournalId: '06247aba-0743-4a0d-b2d2-2d01139ca851',
        distributionListUrl:
          'https://hpmvrodevscusstapp.blob.core.windows.net/rigdistributionlist/1708417122037_1697769477168_disN.xlsx',
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: 'B21A3023-EF65-494B-94B1-ACE571865A87',
          wellId: '555648BD-2458-488D-93DE-CCF582908D2F',
          wellboreOriginalName: '6604/5-1',
          wellboreCurrentName: null,
          uwiCurrentName: '',
          guid: '',
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: 66.59373045171681,
          EDMsurfaceLongitude: 4.54904413024317,
          EDMspudDate: null,
          wellboreName: '6604/5-1',
        },
        project: {
          projectId: '688D6981-A702-4A10-9018-495167E3A9D7',
          policyId: '96AC164A-8A47-4B46-A6F2-885397A2FB34',
          projectOriginalName: 'Exploration UTM 31N',
          projectCurrentName: 'Exploration UTM 31N Edit',
          timezone: '-12:00',
          country: 'CA',
          countryName: 'Canada',
          block: '123',
          region: 'NAM',
          lease: '425',
          field: '124',
          preUnit: '',
          curUnit: 'API',
          projectName: 'Exploration UTM 31N Edit',
        },
        rig: {
          rigId: '990d21fd-b762-4584-9cd8-bc130742df11',
          rigName: 'Z_TestOHIO1',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUyNiIsInJpZ1R5cGVIaWVyYXJjaHkiOiJSaWdsZXNzIHwgSHlkcmF1bGljIFdvcmtvdmVyIFVuaXQiLCJyaWdEZWZhdWx0SW1hZ2UiOiJSaWdsZXNzX0h5ZHJhdWxpYyBXb3Jrb3ZlciBVbml0LmpwZyJ9',
          contractorId: '5502fb10-39f3-4356-82b5-2ab2f8d5a6b8',
          contractorName: 'Rig Contractor Demo Audit Trail',
          imageUrl: null,
          specUrl: null,
        },
        policy: {
          policyId: '96AC164A-8A47-4B46-A6F2-885397A2FB34',
          policyOriginalName: 'Wintershall Dea Norge AS',
          policyCurrentName: 'Wintershall Dea Norge AS[Test/123"]',
          edmSource: 'OHIO(Wintershall)',
        },
        well: {
          wellName: '6604/5-1_Balderbrå',
        },
        site: {
          siteId: 'F2F18B60-4EDF-4A87-8396-26B37E064606',
          projectId: '688D6981-A702-4A10-9018-495167E3A9D7',
          siteOriginalName: 'PL894 Balderbrå',
          siteCurrentName: null,
        },
        interval: {
          intervalId: '554e7a2d-a86b-409b-a0df-0414c6d413c3',
          wellboreId: 'B21A3023-EF65-494B-94B1-ACE571865A87',
          rigJournalId: '06247aba-0743-4a0d-b2d2-2d01139ca851',
          intervalName: '123',
          unit: 'API',
          operation: 'drilling',
          designOfServiceUrl: null,
          casedHoleId: 'null',
          openHoleDiameter: 'null',
          lastShoeMeasuredDepth: 'null',
          isNaCaseHoleLastShoe: true,
          isNaOpenHole: true,
        },
        remoteCenterList: [
          {
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
            remoteCenterName: 'VRO Testing',
          },
        ],
        mailboxList: [
          {
            mailboxId: '49e66a2b-1b1d-4d4c-aebe-7bcd9f4941fe',
            mailboxName: 'VROALERTtesting@halliburton.com',
            isActive: true,
            remoteCenterId: '8151634f-c813-4eab-8ad1-960dd4fa2d92',
          },
        ],
        run: {
          run_id: '600969c2-2622-462f-8966-806f0e5a1626',
          rig_journal_id: '06247aba-0743-4a0d-b2d2-2d01139ca851',
          interval_id: '554e7a2d-a86b-409b-a0df-0414c6d413c3',
          description: '132345',
          type: 'TYPE10',
          run_no: '23,435',
          rig_bha_no: '',
          bit_size: '',
          reamer_size: '',
          casing_size: '124,235',
          start_measured_depth: '',
          td_measured_depth: '',
          below_rotary_timestamp: '11/22/2023, 03:22 PM',
          run_td_timestamp: null,
          above_rotary_timestamp: null,
          tally_url: null,
          unit: 'API',
          createdAt: '2023-11-28T08:22:28.231Z',
          updatedAt: '2023-11-28T08:22:28.231Z',
        },
        interventionFlag: {
          green: 3,
          blue: 1,
          purple: 2,
        },
        updatedAt: '2024-02-20T08:18:42.125Z',
      },
    ],
  ],
  [
    '92D49DEA-1082-4959-AD55-3CD251D4059F',
    [
      {
        rigJournalId: '6e6be00c-c49d-41ae-8e30-b8219219ce77',
        distributionListUrl:
          'https://hpmvrodevscusstapp.blob.core.windows.net/rigdistributionlist/1706776865808_disN.xlsx',
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: 'A2AAAA0B-FCA3-4696-8165-A87851BD0BCA',
          wellId: 'ADD91014-19CF-4BBC-A05F-40D3207FBDF1',
          wellboreOriginalName: 'MUHAMIR_OP2',
          wellboreCurrentName: null,
          uwiCurrentName: '',
          guid: '',
          surfaceLatitude: '2',
          surfaceLongitude: '34',
          spudDate: '08/15/2023, 02:15 PM',
          EDMsurfaceLatitude: 23.24503826584185,
          EDMsurfaceLongitude: 55.68376426298935,
          EDMspudDate: null,
          wellboreName: 'MUHAMIR_OP2',
        },
        project: {
          projectId: '92D49DEA-1082-4959-AD55-3CD251D4059F',
          policyId: 'DFED6260-C317-4003-9D96-E48BCA937403',
          projectOriginalName: 'ARA 2018-001',
          projectCurrentName: 'ARA 2018-001-JF',
          timezone: '+00:00',
          country: 'DE',
          countryName: 'Germany',
          block: 'ac',
          region: 'EESSA',
          lease: 'ac',
          field: 'ac',
          preUnit: '',
          curUnit: 'API',
          projectName: 'ARA 2018-001-JF',
        },
        rig: {
          rigId: '17d4ff80-d81d-4b19-b687-6fc04b493e1d',
          rigName: '0_JF_1',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUyMCIsInJpZ1R5cGVIaWVyYXJjaHkiOiJMYW5kIHwgQ29udmVudGlvbmFsIHwgU3dpbmctTGlmdCIsInJpZ0RlZmF1bHRJbWFnZSI6IkxhbmRfQ29udmVudGlvbmFsLmpwZyJ9',
          contractorId: '857748ce-f8c5-41a1-a003-9159412c8ee1',
          contractorName: 'JF_Contractor',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1691631971915_IMG_1646.png',
          specUrl: null,
        },
        policy: {
          policyId: 'DFED6260-C317-4003-9D96-E48BCA937403',
          policyOriginalName: 'OM - ARA',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'MUNHAMIR-OP2',
        },
        site: {
          siteId: '3288F07F-B2F6-4A9D-A13B-76E033805B4E',
          projectId: '92D49DEA-1082-4959-AD55-3CD251D4059F',
          siteOriginalName: 'BLOCK44',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [],
        mailboxList: [],
        run: null,
        interventionFlag: {},
        updatedAt: '2024-02-01T08:41:05.868Z',
      },
      {
        rigJournalId: '8a2182c4-babc-4e46-8d8f-6e8b3d96ed96',
        distributionListUrl:
          'https://hpmvrodevscusstapp.blob.core.windows.net/rigdistributionlist/1706776823000_disN.xlsx',
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: '5D21BE7F-20DA-4F1E-A321-A26B0D6AA34D',
          wellId: '20DA8584-F789-4985-ADED-9A64AA72E583',
          wellboreOriginalName: 'AFLAK-1H1',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: 23.135453531392653,
          EDMsurfaceLongitude: 55.94737795517657,
          EDMspudDate: '2019-10-20T20:30:00',
          wellboreName: 'AFLAK-1H1',
        },
        project: {
          projectId: '92D49DEA-1082-4959-AD55-3CD251D4059F',
          policyId: 'DFED6260-C317-4003-9D96-E48BCA937403',
          projectOriginalName: 'ARA 2018-001',
          projectCurrentName: 'ARA 2018-001-JF',
          timezone: '+00:00',
          country: 'DE',
          countryName: 'Germany',
          block: 'ac',
          region: 'EESSA',
          lease: 'ac',
          field: 'ac',
          preUnit: '',
          curUnit: 'API',
          projectName: 'ARA 2018-001-JF',
        },
        rig: {
          rigId: 'b6568a6b-398c-4806-85c2-1a7bf3f2c0ea',
          rigName: '0_JF_2',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUwMiIsInJpZ1R5cGVIaWVyYXJjaHkiOiJNYXJpbmUgfCBCb3R0b20gU3VwcG9ydGVkIHwgSmFja3VwIHwgS2V5d2F5IiwicmlnRGVmYXVsdEltYWdlIjoiTWFyaW5lX0phY2t1cF9LZXl3YXkuanBnIn0=',
          contractorId: '35c278de-b663-4ff0-8142-7f62ee766830',
          contractorName: 'JF_Contractor_Bug',
          imageUrl: null,
          specUrl: null,
        },
        policy: {
          policyId: 'DFED6260-C317-4003-9D96-E48BCA937403',
          policyOriginalName: 'OM - ARA',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'AFLAK-1H1',
        },
        site: {
          siteId: '0A4B93E0-270F-45C7-B035-145DEDD6992D',
          projectId: '92D49DEA-1082-4959-AD55-3CD251D4059F',
          siteOriginalName: 'BLOCK-31',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [],
        mailboxList: [],
        run: null,
        interventionFlag: {},
        updatedAt: '2024-02-01T08:40:23.062Z',
      },
      {
        rigJournalId: '9f8e5909-8305-4d54-9dcd-dbb3444a62cf',
        distributionListUrl: null,
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: '68F318E4-1D85-4C17-BCF8-0274ABDBD95D',
          wellId: '710EB625-1FCD-465D-8499-D40AC24C6012',
          wellboreOriginalName: 'SHAMS-23-ST02',
          wellboreCurrentName: null,
          uwiCurrentName: '',
          guid: '',
          surfaceLatitude: '12',
          surfaceLongitude: '12.123',
          spudDate: null,
          EDMsurfaceLatitude: 23.49395075475102,
          EDMsurfaceLongitude: 55.81540596435888,
          EDMspudDate: '2018-12-05T00:00:00',
          wellboreName: 'SHAMS-23-ST02',
        },
        project: {
          projectId: '92D49DEA-1082-4959-AD55-3CD251D4059F',
          policyId: 'DFED6260-C317-4003-9D96-E48BCA937403',
          projectOriginalName: 'ARA 2018-001',
          projectCurrentName: 'ARA 2018-001-JF',
          timezone: '+00:00',
          country: 'DE',
          countryName: 'Germany',
          block: 'ac',
          region: 'EESSA',
          lease: 'ac',
          field: 'ac',
          preUnit: '',
          curUnit: 'API',
          projectName: 'ARA 2018-001-JF',
        },
        rig: {
          rigId: 'f97aea48-12a8-48f2-a0b2-5c5b002188b2',
          rigName: 'Z_JF_3',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUwNCIsInJpZ1R5cGVIaWVyYXJjaHkiOiJNYXJpbmUgfCBCb3R0b20gU3VwcG9ydGVkIHwgU3VibWVyc2libGUgfCBJbmxhbmQgQmFyZ2UiLCJyaWdEZWZhdWx0SW1hZ2UiOiJNYXJpbmVfU3VibWVyc2libGVfSW5sYW5kIEJhcmdlLmpwZyJ9',
          contractorId: '35c278de-b663-4ff0-8142-7f62ee766830',
          contractorName: 'JF_Contractor_Bug',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1692137457287_70465147220__989F6494-DC1A-4C9B-883F-0F37E50697F0.png',
          specUrl: null,
        },
        policy: {
          policyId: 'DFED6260-C317-4003-9D96-E48BCA937403',
          policyOriginalName: 'OM - ARA',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'SHAMS-23',
        },
        site: {
          siteId: '3288F07F-B2F6-4A9D-A13B-76E033805B4E',
          projectId: '92D49DEA-1082-4959-AD55-3CD251D4059F',
          siteOriginalName: 'BLOCK44',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [],
        mailboxList: [],
        run: null,
        interventionFlag: {},
        updatedAt: '2023-10-06T07:16:56.545Z',
      },
      {
        rigJournalId: 'd438cb7b-1c19-4f67-a9eb-3d430f44bfd0',
        distributionListUrl: null,
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: '1A439E98-28EC-4217-8B8D-D3A273ECDDCC',
          wellId: '3E8955BF-BCE8-4FA0-BF7E-C0F8EB75FCCB',
          wellboreOriginalName: 'MUNHAMIR-4-H2-ST01',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: 23.258137139525644,
          EDMsurfaceLongitude: 55.68564794144138,
          EDMspudDate: '2018-08-13T18:00:00',
          wellboreName: 'MUNHAMIR-4-H2-ST01',
        },
        project: {
          projectId: '92D49DEA-1082-4959-AD55-3CD251D4059F',
          policyId: 'DFED6260-C317-4003-9D96-E48BCA937403',
          projectOriginalName: 'ARA 2018-001',
          projectCurrentName: 'ARA 2018-001-JF',
          timezone: '+00:00',
          country: 'DE',
          countryName: 'Germany',
          block: 'ac',
          region: 'EESSA',
          lease: 'ac',
          field: 'ac',
          preUnit: '',
          curUnit: 'API',
          projectName: 'ARA 2018-001-JF',
        },
        rig: {
          rigId: '406dba40-31df-4bcb-94e4-2c6a17e6d9fa',
          rigName: 'Z_JF_9',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6Ik90aGVyIiwicmlnVHlwZUhpZXJhcmNoeSI6Ik90aGVyIiwicmlnRGVmYXVsdEltYWdlIjoiT3RoZXIuanBnIn0=',
          contractorId: '35c278de-b663-4ff0-8142-7f62ee766830',
          contractorName: 'JF_Contractor_Bug',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1692138851691_IMG_1646.png',
          specUrl: null,
        },
        policy: {
          policyId: 'DFED6260-C317-4003-9D96-E48BCA937403',
          policyOriginalName: 'OM - ARA',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'MUNHAMIR-4',
        },
        site: {
          siteId: '3288F07F-B2F6-4A9D-A13B-76E033805B4E',
          projectId: '92D49DEA-1082-4959-AD55-3CD251D4059F',
          siteOriginalName: 'BLOCK44',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [],
        mailboxList: [],
        run: null,
        interventionFlag: {},
        updatedAt: '2023-08-15T22:32:21.803Z',
      },
      {
        rigJournalId: '48127e4f-12bf-47e1-aede-5c2bde4e5f4a',
        distributionListUrl: null,
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: '988C2484-0B4D-45AE-94D0-83A0EF3933B1',
          wellId: '3E8955BF-BCE8-4FA0-BF7E-C0F8EB75FCCB',
          wellboreOriginalName: 'MUNHAMIR-4-H4-ST03',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: 23.258137139525644,
          EDMsurfaceLongitude: 55.68564794144138,
          EDMspudDate: '2018-08-13T18:00:00',
          wellboreName: 'MUNHAMIR-4-H4-ST03',
        },
        project: {
          projectId: '92D49DEA-1082-4959-AD55-3CD251D4059F',
          policyId: 'DFED6260-C317-4003-9D96-E48BCA937403',
          projectOriginalName: 'ARA 2018-001',
          projectCurrentName: 'ARA 2018-001-JF',
          timezone: '+00:00',
          country: 'DE',
          countryName: 'Germany',
          block: 'ac',
          region: 'EESSA',
          lease: 'ac',
          field: 'ac',
          preUnit: '',
          curUnit: 'API',
          projectName: 'ARA 2018-001-JF',
        },
        rig: {
          rigId: 'b6b55e87-62ee-46af-96bb-ac172be788ec',
          rigName: 'Z_JF_8',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUwNSIsInJpZ1R5cGVIaWVyYXJjaHkiOiJNYXJpbmUgfCBCb3R0b20gU3VwcG9ydGVkIHwgU3VibWVyc2libGUgfCBCb3R0bGUtVHlwZSIsInJpZ0RlZmF1bHRJbWFnZSI6Ik1hcmluZV9TdWJtZXJzaWJsZV9Cb3R0bGUtVHlwZS5qcGcifQ==',
          contractorId: '35c278de-b663-4ff0-8142-7f62ee766830',
          contractorName: 'JF_Contractor_Bug',
          imageUrl: null,
          specUrl: null,
        },
        policy: {
          policyId: 'DFED6260-C317-4003-9D96-E48BCA937403',
          policyOriginalName: 'OM - ARA',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'MUNHAMIR-4',
        },
        site: {
          siteId: '3288F07F-B2F6-4A9D-A13B-76E033805B4E',
          projectId: '92D49DEA-1082-4959-AD55-3CD251D4059F',
          siteOriginalName: 'BLOCK44',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [],
        mailboxList: [],
        run: null,
        interventionFlag: {},
        updatedAt: '2023-08-15T22:31:19.561Z',
      },
      {
        rigJournalId: '2f3018ee-adc7-48dc-a21e-768010b261e5',
        distributionListUrl: null,
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: '0C2D5E71-14B4-4E84-8EC4-75FC6B283119',
          wellId: 'BCB3959A-3103-468C-8E16-5D0DC7B4FC6C',
          wellboreOriginalName: 'MUNHAMIR SOUTH-1',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: 23.221440738731772,
          EDMsurfaceLongitude: 55.68578251881803,
          EDMspudDate: '2019-04-27T04:45:00',
          wellboreName: 'MUNHAMIR SOUTH-1',
        },
        project: {
          projectId: '92D49DEA-1082-4959-AD55-3CD251D4059F',
          policyId: 'DFED6260-C317-4003-9D96-E48BCA937403',
          projectOriginalName: 'ARA 2018-001',
          projectCurrentName: 'ARA 2018-001-JF',
          timezone: '+00:00',
          country: 'DE',
          countryName: 'Germany',
          block: 'ac',
          region: 'EESSA',
          lease: 'ac',
          field: 'ac',
          preUnit: '',
          curUnit: 'API',
          projectName: 'ARA 2018-001-JF',
        },
        rig: {
          rigId: 'c6c63237-58cc-4156-bdec-bb73a56f6ccd',
          rigName: 'Z_JF_1',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUwMiIsInJpZ1R5cGVIaWVyYXJjaHkiOiJNYXJpbmUgfCBCb3R0b20gU3VwcG9ydGVkIHwgSmFja3VwIHwgS2V5d2F5IiwicmlnRGVmYXVsdEltYWdlIjoiTWFyaW5lX0phY2t1cF9LZXl3YXkuanBnIn0=',
          contractorId: '35c278de-b663-4ff0-8142-7f62ee766830',
          contractorName: 'JF_Contractor_Bug',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1692137381891_9e1f79518f1c330bfc7e41c6a538580d.png',
          specUrl: null,
        },
        policy: {
          policyId: 'DFED6260-C317-4003-9D96-E48BCA937403',
          policyOriginalName: 'OM - ARA',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'MUNHAMIR SOUTH-1',
        },
        site: {
          siteId: '3288F07F-B2F6-4A9D-A13B-76E033805B4E',
          projectId: '92D49DEA-1082-4959-AD55-3CD251D4059F',
          siteOriginalName: 'BLOCK44',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [],
        mailboxList: [],
        run: null,
        interventionFlag: {},
        updatedAt: '2023-08-15T22:19:51.905Z',
      },
      {
        rigJournalId: 'ba15e997-9eeb-4dbf-9db4-9bff789a3918',
        distributionListUrl: null,
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: '07AEA300-6A0B-46DA-9522-A83889A77143',
          wellId: '20DA8584-F789-4985-ADED-9A64AA72E583',
          wellboreOriginalName: 'AFLAK-1H2',
          wellboreCurrentName: null,
          uwiCurrentName: '',
          guid: '',
          surfaceLatitude: '2',
          surfaceLongitude: '3',
          spudDate: '08/23/2023, 01:35 PM',
          EDMsurfaceLatitude: 23.135453531392653,
          EDMsurfaceLongitude: 55.94737795517657,
          EDMspudDate: '2019-10-20T20:30:00',
          wellboreName: 'AFLAK-1H2',
        },
        project: {
          projectId: '92D49DEA-1082-4959-AD55-3CD251D4059F',
          policyId: 'DFED6260-C317-4003-9D96-E48BCA937403',
          projectOriginalName: 'ARA 2018-001',
          projectCurrentName: 'ARA 2018-001-JF',
          timezone: '+00:00',
          country: 'DE',
          countryName: 'Germany',
          block: 'ac',
          region: 'EESSA',
          lease: 'ac',
          field: 'ac',
          preUnit: '',
          curUnit: 'API',
          projectName: 'ARA 2018-001-JF',
        },
        rig: {
          rigId: 'ee1c9823-2aff-4c18-90e1-2a3a3b450970',
          rigName: 'Z_JF_0',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUwMSIsInJpZ1R5cGVIaWVyYXJjaHkiOiJNYXJpbmUgfCBCb3R0b20gU3VwcG9ydGVkIHwgSmFja3VwIHwgQ2FudGlsZXZlcmVkIiwicmlnRGVmYXVsdEltYWdlIjoiTWFyaW5lX0phY2t1cF9DYW50aWxldmVyZWQuanBnIn0=',
          contractorId: '35c278de-b663-4ff0-8142-7f62ee766830',
          contractorName: 'JF_Contractor_Bug',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1692137285377_b656be96aaf5ad2d8fc0f6f0216b0e23.png',
          specUrl: null,
        },
        policy: {
          policyId: 'DFED6260-C317-4003-9D96-E48BCA937403',
          policyOriginalName: 'OM - ARA',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'AFLAK-1H1',
        },
        site: {
          siteId: '0A4B93E0-270F-45C7-B035-145DEDD6992D',
          projectId: '92D49DEA-1082-4959-AD55-3CD251D4059F',
          siteOriginalName: 'BLOCK-31',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [],
        mailboxList: [],
        run: null,
        interventionFlag: {},
        updatedAt: '2023-08-15T22:18:32.500Z',
      },
    ],
  ],
  [
    '07EAD96E-A814-4EC0-9427-B3E58A7F4BAF',
    [
      {
        rigJournalId: 'fdffee7f-ae98-4f31-91c2-796dbdd2d221',
        distributionListUrl: null,
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: 'B8F53C43-2B21-494D-907B-43C2F33697BF',
          wellId: '4DA38D95-35E6-4282-B1DE-2B69FD284FF2',
          wellboreOriginalName: 'Plan_25/11-BT-1 H [Paleo 6.2]',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: 59.17596694227809,
          EDMsurfaceLongitude: 2.377152009744655,
          EDMspudDate: null,
          wellboreName: 'Plan_25/11-BT-1 H [Paleo 6.2]',
        },
        project: {
          projectId: '07EAD96E-A814-4EC0-9427-B3E58A7F4BAF',
          policyId: 'A80FB6EC-3AFD-4FB9-A577-94C5B05E59BE',
          projectOriginalName: 'BALDER',
          projectCurrentName: null,
          timezone: null,
          country: null,
          block: null,
          region: null,
          lease: null,
          field: null,
          preUnit: null,
          curUnit: null,
          projectName: 'BALDER',
        },
        rig: {
          rigId: 'a2ec1a6b-1bae-426d-84c3-7916833e48a8',
          rigName: 'Z_TestOHI0003',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUwNyIsInJpZ1R5cGVIaWVyYXJjaHkiOiJNYXJpbmUgfCBCb3R0b20gU3VwcG9ydGVkIHwgUGxhdGZvcm0gfCBUZW5zaW9uLUxlZyIsInJpZ0RlZmF1bHRJbWFnZSI6Ik1hcmluZV9QbGF0Zm9ybV9UZW5zaW9uLUxlZy5qcGcifQ==',
          contractorId: '5502fb10-39f3-4356-82b5-2ab2f8d5a6b8',
          contractorName: 'Rig Contractor Demo Audit Trail',
          imageUrl: null,
          specUrl: null,
        },
        policy: {
          policyId: 'A80FB6EC-3AFD-4FB9-A577-94C5B05E59BE',
          policyOriginalName: 'NORWAY_Vår Energi (Import from Vår Compass)',
          policyCurrentName: null,
          edmSource: 'OHIO(VarEnergi)',
        },
        well: {
          wellName: '25/11-BT-1',
        },
        site: {
          siteId: '763D1603-F0D1-4436-9E16-90C6E6621ECE',
          projectId: '07EAD96E-A814-4EC0-9427-B3E58A7F4BAF',
          siteOriginalName: '25/11-BT',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [],
        mailboxList: [],
        run: null,
        interventionFlag: {},
        updatedAt: '2023-11-28T07:47:01.074Z',
      },
    ],
  ],
  [
    '466B842C-7E28-4738-A004-0EF784FEEA6C',
    [
      {
        rigJournalId: 'ffd22951-496f-4907-b81c-42c8f45bf14d',
        distributionListUrl: null,
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: '425C1CC9-8CF9-4C1E-A668-16ABBCC6609A',
          wellId: 'DE95639C-1EE5-4D16-82B4-9BD5DDC39AD0',
          wellboreOriginalName: 'Plan_25/7-12 A [Magellan]',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: 59.27081843611113,
          EDMsurfaceLongitude: 2.3150769777777773,
          EDMspudDate: null,
          wellboreName: 'Plan_25/7-12 A [Magellan]',
        },
        project: {
          projectId: '466B842C-7E28-4738-A004-0EF784FEEA6C',
          policyId: '06C63C7E-AF6E-4B2D-9B44-2208C63BD049',
          projectOriginalName: 'Quadrant 0025',
          projectCurrentName: null,
          timezone: null,
          country: null,
          block: null,
          region: null,
          lease: null,
          field: null,
          preUnit: null,
          curUnit: null,
          projectName: 'Quadrant 0025',
        },
        rig: {
          rigId: '98d2b56c-1076-40cc-99c7-e8f97c0f866a',
          rigName: 'Z_TestOHI002',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUxMCIsInJpZ1R5cGVIaWVyYXJjaHkiOiJNYXJpbmUgfCBCb3R0b20gU3VwcG9ydGVkIHwgUGxhdGZvcm0gfCBDb21wbGlhbnQgVG93ZXIiLCJyaWdEZWZhdWx0SW1hZ2UiOiJNYXJpbmVfUGxhdGZvcm1fQ29tcGxpYW50IFRvd2VyLmpwZyJ9',
          contractorId: '5502fb10-39f3-4356-82b5-2ab2f8d5a6b8',
          contractorName: 'Rig Contractor Demo Audit Trail',
          imageUrl: null,
          specUrl: null,
        },
        policy: {
          policyId: '06C63C7E-AF6E-4B2D-9B44-2208C63BD049',
          policyOriginalName: 'NORWAY_Vår Energi [HMS]',
          policyCurrentName: null,
          edmSource: 'OHIO(Equinor)',
        },
        well: {
          wellName: '25/7-12',
        },
        site: {
          siteId: 'D2CA218D-FBDD-4867-964B-85D402F8A3ED',
          projectId: '466B842C-7E28-4738-A004-0EF784FEEA6C',
          siteOriginalName: '25/07-12',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [],
        mailboxList: [],
        run: null,
        interventionFlag: {},
        updatedAt: '2023-11-28T07:45:57.557Z',
      },
    ],
  ],
  [
    'F4549FBB-2C23-4A87-B61F-126C6B09A9DB',
    [
      {
        rigJournalId: '66c8ddca-d0ae-41c1-870a-0330c6233a9c',
        distributionListUrl: null,
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: '92B1E70A-E277-490E-8C25-0023F6448DE8',
          wellId: 'E1501C88-CB2D-430F-8404-AD01BB2D00DD',
          wellboreOriginalName: '25/7-7\\8 Enniberg',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: null,
          EDMsurfaceLongitude: null,
          EDMspudDate: null,
          wellboreName: '25/7-7\\8 Enniberg',
        },
        project: {
          projectId: 'F4549FBB-2C23-4A87-B61F-126C6B09A9DB',
          policyId: '9D896225-7834-49E9-B941-402534F7EC72',
          projectOriginalName: 'Engineering Cases Only',
          projectCurrentName: null,
          timezone: null,
          country: null,
          block: null,
          region: null,
          lease: null,
          field: null,
          preUnit: null,
          curUnit: null,
          projectName: 'Engineering Cases Only',
        },
        rig: {
          rigId: '921b3865-3418-40cd-b714-844611333612',
          rigName: 'Z_TestOh0001',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUxNSIsInJpZ1R5cGVIaWVyYXJjaHkiOiJNYXJpbmUgfCBGbG9hdGluZyB8IFNlbWktU3VibWVyc2libGUiLCJyaWdEZWZhdWx0SW1hZ2UiOiJNYXJpbmVfU2VtaS1TdWJtZXJzaWJsZS5qcGcifQ==',
          contractorId: '5502fb10-39f3-4356-82b5-2ab2f8d5a6b8',
          contractorName: 'Rig Contractor Demo Audit Trail',
          imageUrl: null,
          specUrl: null,
        },
        policy: {
          policyId: '9D896225-7834-49E9-B941-402534F7EC72',
          policyOriginalName: 'ConocoPhillips Engineering',
          policyCurrentName: null,
          edmSource: 'OHIO(ConocoPhillips)',
        },
        well: {
          wellName: '25/7-8 Enniberg',
        },
        site: {
          siteId: 'BCE8DDD4-67B3-44FE-BF7E-1940E0D6D903',
          projectId: 'F4549FBB-2C23-4A87-B61F-126C6B09A9DB',
          siteOriginalName: 'Enniberg, Leiv Eiriksson, Versaflex',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [],
        mailboxList: [],
        run: null,
        interventionFlag: {},
        updatedAt: '2023-11-28T07:45:17.188Z',
      },
    ],
  ],
  [
    '297D5DBE-35BB-49BE-84CD-7ACE22DD6555',
    [
      {
        rigJournalId: '2f4f8e51-ce71-45b9-9828-35592a8ceaf1',
        distributionListUrl: null,
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: 'B38EC017-B621-43CD-BBA2-5A4EE6210327',
          wellId: '9198634E-317F-4506-B4A6-36780E2AA8ED',
          wellboreOriginalName: '6604/5-1',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: 66.59373045171681,
          EDMsurfaceLongitude: 4.54904413024317,
          EDMspudDate: null,
          wellboreName: '6604/5-1',
        },
        project: {
          projectId: '297D5DBE-35BB-49BE-84CD-7ACE22DD6555',
          policyId: '0A837156-8DED-414B-90C1-6C875304C340',
          projectOriginalName: 'Exploration UTM 31N',
          projectCurrentName: null,
          timezone: null,
          country: null,
          block: null,
          region: null,
          lease: null,
          field: null,
          preUnit: null,
          curUnit: null,
          projectName: 'Exploration UTM 31N',
        },
        rig: {
          rigId: '0bf2e977-0ff1-468f-83a0-2a5e078dcd38',
          rigName: 'Z_testEDM2',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUwMyIsInJpZ1R5cGVIaWVyYXJjaHkiOiJNYXJpbmUgfCBCb3R0b20gU3VwcG9ydGVkIHwgU3VibWVyc2libGUgfCBQb3N0ZWQgQmFyZ2UiLCJyaWdEZWZhdWx0SW1hZ2UiOiJNYXJpbmVfU3VibWVyc2libGVfUG9zdGVkIEJhcmdlLmpwZyJ9',
          contractorId: '5502fb10-39f3-4356-82b5-2ab2f8d5a6b8',
          contractorName: 'Rig Contractor Demo Audit Trail',
          imageUrl: null,
          specUrl: null,
        },
        policy: {
          policyId: '0A837156-8DED-414B-90C1-6C875304C340',
          policyOriginalName: 'NO - Wintershall DEA',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: '6604/5-1_Balderbrå',
        },
        site: {
          siteId: '89FA073F-5921-4961-8EAD-534C870A5963',
          projectId: '297D5DBE-35BB-49BE-84CD-7ACE22DD6555',
          siteOriginalName: 'PL894 Balderbrå',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [],
        mailboxList: [],
        run: null,
        interventionFlag: {},
        updatedAt: '2023-11-28T06:21:48.802Z',
      },
    ],
  ],
  [
    'A5CC2DAC-EBDD-48CE-B17C-84179742847A',
    [
      {
        rigJournalId: 'c60ad420-052f-4f34-a463-f9f5addedf99',
        distributionListUrl: null,
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: '39065432-7A5B-40DC-BAE1-E3F2616A615D',
          wellId: '602ED2C5-4FDE-421E-95BC-B20180093C86',
          wellboreOriginalName: '2/8-V-2 [Lateral]',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: 56.25125204167356,
          EDMsurfaceLongitude: 3.3490891394168325,
          EDMspudDate: '2019-07-14T16:45:00.000Z',
          wellboreName: '2/8-V-2 [Lateral]',
        },
        project: {
          projectId: 'A5CC2DAC-EBDD-48CE-B17C-84179742847A',
          policyId: '16397EAD-88DB-4FF7-89A0-A44F3C2FC971',
          projectOriginalName: 'Quadrant 0002 [VALHALL_FENRIS]',
          projectCurrentName: null,
          timezone: null,
          country: null,
          block: null,
          region: null,
          lease: null,
          field: null,
          preUnit: null,
          curUnit: null,
          projectName: 'Quadrant 0002 [VALHALL_FENRIS]',
        },
        rig: {
          rigId: '387a8956-8d44-4152-8992-0919276a99a4',
          rigName: 'Z_TestEDM1',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUwMSIsInJpZ1R5cGVIaWVyYXJjaHkiOiJNYXJpbmUgfCBCb3R0b20gU3VwcG9ydGVkIHwgSmFja3VwIHwgQ2FudGlsZXZlcmVkIiwicmlnRGVmYXVsdEltYWdlIjoiTWFyaW5lX0phY2t1cF9DYW50aWxldmVyZWQuanBnIn0=',
          contractorId: '5502fb10-39f3-4356-82b5-2ab2f8d5a6b8',
          contractorName: 'Rig Contractor Demo Audit Trail',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1693277891150_intervention.png',
          specUrl: null,
        },
        policy: {
          policyId: '16397EAD-88DB-4FF7-89A0-A44F3C2FC971',
          policyOriginalName: 'AkerBP [Well Planning]',
          policyCurrentName: null,
          edmSource: 'OHIO(AkerBP)',
        },
        well: {
          wellName: '2/8-V-2',
        },
        site: {
          siteId: '1BDEE321-91DC-4D02-91BB-8717308F388A',
          projectId: 'A5CC2DAC-EBDD-48CE-B17C-84179742847A',
          siteOriginalName: '2/08-V [VALHALL FLANKE VEST]',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [],
        mailboxList: [],
        run: null,
        interventionFlag: {},
        updatedAt: '2023-11-28T06:14:34.296Z',
      },
    ],
  ],
  [
    '016B0F11-A208-4628-895D-5C3FCDDDAD3C',
    [
      {
        rigJournalId: 'd60371f2-b597-4845-b343-f35c0cb39330',
        distributionListUrl: null,
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: '56068409-3B9B-4AA0-8A3E-5E0809FE2634',
          wellId: '6872A261-0F7B-49B3-9D30-1839F35C9769',
          wellboreOriginalName: 'AYATSIL-139',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: 19.637396271101366,
          EDMsurfaceLongitude: -92.33238086601862,
          EDMspudDate: '2023-09-20T16:24:00',
          wellboreName: 'AYATSIL-139',
        },
        project: {
          projectId: '016B0F11-A208-4628-895D-5C3FCDDDAD3C',
          policyId: 'DF757E67-570D-431C-AED3-11944DB4D04A',
          projectOriginalName: 'CAMPO AYATSIL',
          projectCurrentName:
            'BAJO DEL CHOIQUE  EDITBAJO DEL CHOIQUE  EDITBAJO D',
          timezone: '-12:00',
          country: 'DM',
          countryName: 'Dominica',
          block: 'MX - Pemex Exploracion y Produccion',
          region: 'LA',
          lease: 'MX - Pemex Exploracion y Produccion',
          field: 'MX - Pemex Exploracion y Produccion',
          preUnit: '',
          curUnit: 'API',
          projectName: 'BAJO DEL CHOIQUE  EDITBAJO DEL CHOIQUE  EDITBAJO D',
        },
        rig: {
          rigId: 'a39c520e-8379-4eed-b8cb-91bf37031f9f',
          rigName: 'MT1511_Katy_2',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUyMyIsInJpZ1R5cGVIaWVyYXJjaHkiOiJSaWdsZXNzIHwgU3VwcG9ydCBWZXNzZWwgfCBXZWxsIEludGVydmVudGlvbiBWZXNzZWwiLCJyaWdEZWZhdWx0SW1hZ2UiOiJSaWdsZXNzX1N1cHBvcnQgVmVzc2VsX1dlbGwgSW50ZXJ2ZW50aW9uIFZlc3NlbC5qcGcifQ==',
          contractorId: 'a7f74c81-47e9-4f08-b27f-eb4563662cff',
          contractorName: 'MT1511_Katy_Contractor',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1700025158049_tom-and-jerry-29-817x1024.png',
          specUrl: null,
        },
        policy: {
          policyId: 'DF757E67-570D-431C-AED3-11944DB4D04A',
          policyOriginalName: 'MX - Pemex Exploracion y Produccion',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'AYATSIL-139',
        },
        site: {
          siteId: 'D94E1C73-FEAA-4941-A08D-859CE3BD9C9E',
          projectId: '016B0F11-A208-4628-895D-5C3FCDDDAD3C',
          siteOriginalName: 'AYATSIL-D',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [],
        mailboxList: [],
        run: null,
        interventionFlag: {},
        updatedAt: '2023-11-15T05:20:55.125Z',
      },
    ],
  ],
  [
    '6D3C8C7E-20DC-4A70-9372-6E21B22D0DAB',
    [
      {
        rigJournalId: 'fc2d1290-cf5a-4a3b-9159-3e2ed460deae',
        distributionListUrl: null,
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: '93E8321A-5ED1-4667-9F71-66E5F19067E1',
          wellId: 'E2E95184-DB82-43D7-87E4-5B1BA55A6135',
          wellboreOriginalName: 'PRINCIPAL T-1EXP',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: 17.84295276958224,
          EDMsurfaceLongitude: -92.6369453344005,
          EDMspudDate: '2023-08-01T15:35:00',
          wellboreName: 'PRINCIPAL T-1EXP',
        },
        project: {
          projectId: '6D3C8C7E-20DC-4A70-9372-6E21B22D0DAB',
          policyId: 'DF757E67-570D-431C-AED3-11944DB4D04A',
          projectOriginalName: 'CAMPO TECHIAKTLI',
          projectCurrentName: null,
          timezone: '-12:00',
          country: 'VI',
          countryName: 'Virgin Islands, U.S.',
          block: 'MX - Pemex Exploracion y Produccion',
          region: 'NAM',
          lease: 'MX - Pemex Exploracion y Produccion',
          field: 'MX - Pemex Exploracion y Produccion',
          preUnit: '',
          curUnit: 'API',
          projectName: 'CAMPO TECHIAKTLI',
        },
        rig: {
          rigId: '521023a3-9284-4df4-9d82-c7f8dcc94a9e',
          rigName: 'MT1511_Katy_4',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUwMSIsInJpZ1R5cGVIaWVyYXJjaHkiOiJNYXJpbmUgfCBCb3R0b20gU3VwcG9ydGVkIHwgSmFja3VwIHwgQ2FudGlsZXZlcmVkIiwicmlnRGVmYXVsdEltYWdlIjoiTWFyaW5lX0phY2t1cF9DYW50aWxldmVyZWQuanBnIn0=',
          contractorId: 'fa999adb-94bd-4328-8021-36c3acf1a661',
          contractorName: 'MT1511_Katy_Contractor_1',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1700025231304_pngtree-facebook-social-media-icon-facebook-logo-png-image_3654772.png',
          specUrl: null,
        },
        policy: {
          policyId: 'DF757E67-570D-431C-AED3-11944DB4D04A',
          policyOriginalName: 'MX - Pemex Exploracion y Produccion',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'TECHIAKTLI -1EXP',
        },
        site: {
          siteId: '1087B702-0186-4188-A706-A967DB27F110',
          projectId: '6D3C8C7E-20DC-4A70-9372-6E21B22D0DAB',
          siteOriginalName: 'TECHIAKTLI -1EXP',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [],
        mailboxList: [],
        run: null,
        interventionFlag: {},
        updatedAt: '2023-11-15T05:19:14.799Z',
      },
    ],
  ],
  [
    'CEC708C6-FBBE-4566-A7A2-74798D0B456D',
    [
      {
        rigJournalId: '058d782a-9903-4678-8e87-ae1a0012b62c',
        distributionListUrl: null,
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: '75E73737-BBB7-4EA8-AF8C-7C071B9FC4EC',
          wellId: '02D3E195-20D2-40E4-917E-4D1ABD9CA4D1',
          wellboreOriginalName: 'PRINCIPAL',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: 18.398594320832906,
          EDMsurfaceLongitude: -94.26744935143557,
          EDMspudDate: '2023-10-13T19:45:00',
          wellboreName: 'PRINCIPAL',
        },
        project: {
          projectId: 'CEC708C6-FBBE-4566-A7A2-74798D0B456D',
          policyId: 'DF757E67-570D-431C-AED3-11944DB4D04A',
          projectOriginalName: 'CAMPO TEPETLA',
          projectCurrentName: null,
          timezone: '-12:00',
          country: 'TN',
          countryName: 'Tunisia',
          block: 'MX - Pemex Exploracion y Produccion',
          region: 'MENA',
          lease: 'MX - Pemex Exploracion y Produccion',
          field: 'MX - Pemex Exploracion y Produccion',
          preUnit: '',
          curUnit: 'API - US Survey Feet',
          projectName: 'CAMPO TEPETLA',
        },
        rig: {
          rigId: '21167c19-af77-42e8-8fba-703fc161ea29',
          rigName: 'MT1511_Katy_1',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUyNSIsInJpZ1R5cGVIaWVyYXJjaHkiOiJSaWdsZXNzIHwgRnJhYyBTcHJlYWQiLCJyaWdEZWZhdWx0SW1hZ2UiOiJMYW5kX1JpZ2xlc3NfRnJhYyBTcHJlYWQuanBnIn0=',
          contractorId: 'a7f74c81-47e9-4f08-b27f-eb4563662cff',
          contractorName: 'MT1511_Katy_Contractor',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1700025133037_free-apple-icon-png-13.png',
          specUrl: null,
        },
        policy: {
          policyId: 'DF757E67-570D-431C-AED3-11944DB4D04A',
          policyOriginalName: 'MX - Pemex Exploracion y Produccion',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'TEPETLA 1EXP',
        },
        site: {
          siteId: '5228EDF0-DB31-4DB8-9D1B-EE88B67688E0',
          projectId: 'CEC708C6-FBBE-4566-A7A2-74798D0B456D',
          siteOriginalName: 'PLATAFORMA COATZACOALCOS',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [],
        mailboxList: [],
        run: null,
        interventionFlag: {},
        updatedAt: '2023-11-15T05:16:48.044Z',
      },
    ],
  ],
  [
    '12752334-41A6-4736-97DC-CAAFAD2D55CC',
    [
      {
        rigJournalId: '28d4d713-226d-400c-bb78-94c26e43492a',
        distributionListUrl: null,
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: '2C58BCE0-CD39-43DC-83D3-E2BC27EA742A',
          wellId: '424592AA-9A54-494A-93EE-05F74DF764C3',
          wellboreOriginalName: 'PRINCIPAL M-201EXP',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: 18.15404169081197,
          EDMsurfaceLongitude: -93.26690785607114,
          EDMspudDate: '2023-07-29T15:34:00',
          wellboreName: 'PRINCIPAL M-201EXP',
        },
        project: {
          projectId: '12752334-41A6-4736-97DC-CAAFAD2D55CC',
          policyId: 'DF757E67-570D-431C-AED3-11944DB4D04A',
          projectOriginalName: 'MADREFIL - ITRF08',
          projectCurrentName: null,
          timezone: '-12:00',
          country: 'DM',
          countryName: 'Dominica',
          block: 'MADREFIL - ITRF08',
          region: 'LA',
          lease: 'MADREFIL - ITRF08',
          field: 'MADREFIL - ITRF08',
          preUnit: '',
          curUnit: 'API',
          projectName: 'MADREFIL - ITRF08',
        },
        rig: {
          rigId: '8b89ad10-9a68-4323-a8ed-a4f97ddb79e5',
          rigName: 'MT1411_California_1',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUwMSIsInJpZ1R5cGVIaWVyYXJjaHkiOiJNYXJpbmUgfCBCb3R0b20gU3VwcG9ydGVkIHwgSmFja3VwIHwgQ2FudGlsZXZlcmVkIiwicmlnRGVmYXVsdEltYWdlIjoiTWFyaW5lX0phY2t1cF9DYW50aWxldmVyZWQuanBnIn0=',
          contractorId: 'd43e045a-5122-4650-9d43-afaabcca98c4',
          contractorName: 'MT1411_California_Contractor',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1699929907081_pikachu-image-illustration-clip-art-sleep-png-favpng-KgeGkz2c5GHs2uMvGexdxEPsf.png',
          specUrl: null,
        },
        policy: {
          policyId: 'DF757E67-570D-431C-AED3-11944DB4D04A',
          policyOriginalName: 'MX - Pemex Exploracion y Produccion',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'MADREFIL-201EXP',
        },
        site: {
          siteId: 'A0EBADD1-6BE3-4A59-840C-DC56B13599E5',
          projectId: '12752334-41A6-4736-97DC-CAAFAD2D55CC',
          siteOriginalName: 'PERA MADREFIL 101EXP',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [],
        mailboxList: [],
        run: null,
        interventionFlag: {},
        updatedAt: '2023-11-14T02:57:47.231Z',
      },
      {
        rigJournalId: '447352ce-6ebb-4281-8438-01627d56338a',
        distributionListUrl: null,
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: '61E8E721-3C57-49FF-8D9F-7D5FB88BDD8A',
          wellId: '4B1B1F43-E951-4700-BCD3-939A2A918BF2',
          wellboreOriginalName: 'PRINCIPAL M-101EXP',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: 18.15289456672895,
          EDMsurfaceLongitude: -93.26799011650834,
          EDMspudDate: '2023-08-01T16:24:00',
          wellboreName: 'PRINCIPAL M-101EXP',
        },
        project: {
          projectId: '12752334-41A6-4736-97DC-CAAFAD2D55CC',
          policyId: 'DF757E67-570D-431C-AED3-11944DB4D04A',
          projectOriginalName: 'MADREFIL - ITRF08',
          projectCurrentName: null,
          timezone: '-12:00',
          country: 'DM',
          countryName: 'Dominica',
          block: 'MADREFIL - ITRF08',
          region: 'LA',
          lease: 'MADREFIL - ITRF08',
          field: 'MADREFIL - ITRF08',
          preUnit: '',
          curUnit: 'API',
          projectName: 'MADREFIL - ITRF08',
        },
        rig: {
          rigId: '043174c4-0bad-450e-aa38-ad8837faa375',
          rigName: 'MT1411_California',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6Ik90aGVyIiwicmlnVHlwZUhpZXJhcmNoeSI6Ik90aGVyIiwicmlnRGVmYXVsdEltYWdlIjoiT3RoZXIuanBnIn0=',
          contractorId: 'd43e045a-5122-4650-9d43-afaabcca98c4',
          contractorName: 'MT1411_California_Contractor',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1699929857367_Arctic-fox3.png',
          specUrl: null,
        },
        policy: {
          policyId: 'DF757E67-570D-431C-AED3-11944DB4D04A',
          policyOriginalName: 'MX - Pemex Exploracion y Produccion',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'MADREFIL-101EXP',
        },
        site: {
          siteId: 'A0EBADD1-6BE3-4A59-840C-DC56B13599E5',
          projectId: '12752334-41A6-4736-97DC-CAAFAD2D55CC',
          siteOriginalName: 'PERA MADREFIL 101EXP',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [],
        mailboxList: [],
        run: null,
        interventionFlag: {},
        updatedAt: '2023-11-14T02:56:49.613Z',
      },
    ],
  ],
  [
    'A0BBEB4E-C2FB-48A0-9427-FF65501F7040',
    [
      {
        rigJournalId: '2c9ed665-7433-413d-8955-a4463cf7120e',
        distributionListUrl: null,
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: 'DC7BB836-19F7-496C-AA52-365056AFEB8A',
          wellId: 'E674D10B-0288-46D3-BCB4-1F0B0B28A7E1',
          wellboreOriginalName: 'PRINCIPAL M-1EXP',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: 17.600414121399854,
          EDMsurfaceLongitude: -93.67606285173586,
          EDMspudDate: '2023-09-12T00:01:00',
          wellboreName: 'PRINCIPAL M-1EXP',
        },
        project: {
          projectId: 'A0BBEB4E-C2FB-48A0-9427-FF65501F7040',
          policyId: 'DF757E67-570D-431C-AED3-11944DB4D04A',
          projectOriginalName: 'CAMPO MACAVIL',
          projectCurrentName: null,
          timezone: '-12:00',
          country: 'DM',
          countryName: 'Dominica',
          block: 'MADREFIL - ITRF08',
          region: 'LA',
          lease: 'MADREFIL - ITRF08',
          field: 'MADREFIL - ITRF08',
          preUnit: '',
          curUnit: 'API - US Survey Feet',
          projectName: 'CAMPO MACAVIL',
        },
        rig: {
          rigId: 'b0a6b9d8-fa87-4e08-945c-f6abf439cc11',
          rigName: 'MT1411_California_3',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUwMyIsInJpZ1R5cGVIaWVyYXJjaHkiOiJNYXJpbmUgfCBCb3R0b20gU3VwcG9ydGVkIHwgU3VibWVyc2libGUgfCBQb3N0ZWQgQmFyZ2UiLCJyaWdEZWZhdWx0SW1hZ2UiOiJNYXJpbmVfU3VibWVyc2libGVfUG9zdGVkIEJhcmdlLmpwZyJ9',
          contractorId: 'a6a10d58-631d-40aa-ad69-b13519240bb1',
          contractorName: 'MT1411_California',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1699930083253_pngtree-two-blue-eggs-are-sitting-in-a-nest-image_2945590.png',
          specUrl: null,
        },
        policy: {
          policyId: 'DF757E67-570D-431C-AED3-11944DB4D04A',
          policyOriginalName: 'MX - Pemex Exploracion y Produccion',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'MACAVIL-1EXP',
        },
        site: {
          siteId: 'CC468CFA-4123-46A6-A183-FFDC3523F384',
          projectId: 'A0BBEB4E-C2FB-48A0-9427-FF65501F7040',
          siteOriginalName: 'MACAVIL-1EXP',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [],
        mailboxList: [],
        run: null,
        interventionFlag: {},
        updatedAt: '2023-11-14T02:55:14.836Z',
      },
    ],
  ],
  [
    'DEF9A38A-46E4-4332-BC04-3F691AF399AA',
    [
      {
        rigJournalId: 'f22d10cc-615d-4b3b-b4bc-2d3241590d43',
        distributionListUrl: null,
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: '43E67770-9128-4FB5-82AE-730194819709',
          wellId: '8673A303-F7F2-4FAA-8070-B3C0BB3180CF',
          wellboreOriginalName: 'OH',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: 50.21808889146664,
          EDMsurfaceLongitude: 33.367844444413414,
          EDMspudDate: null,
          wellboreName: 'OH',
        },
        project: {
          projectId: 'DEF9A38A-46E4-4332-BC04-3F691AF399AA',
          policyId: 'DBD5E626-7C6C-45BA-951E-FCCA47B5294E',
          projectOriginalName: 'Yablunivske GCF',
          projectCurrentName: null,
          timezone: '+00:00',
          country: 'DE',
          countryName: 'Germany',
          block: 'GE',
          region: 'EESSA',
          lease: 'GE',
          field: 'GE',
          preUnit: '',
          curUnit: 'API',
          projectName: 'Yablunivske GCF',
        },
        rig: {
          rigId: '73df7059-40c5-48cb-8eef-bd74870bd63e',
          rigName: 'Super Space',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUyMSIsInJpZ1R5cGVIaWVyYXJjaHkiOiJMYW5kIHwgTW9iaWxlIFJpZyIsInJpZ0RlZmF1bHRJbWFnZSI6IkxhbmRfTW9iaWxlIFJpZy5qcGcifQ==',
          contractorId: '98a1a70f-dcaa-4a3f-b75c-da794dd617ad',
          contractorName: 'Test Contractor Unit',
          imageUrl: null,
          specUrl: null,
        },
        policy: {
          policyId: 'DBD5E626-7C6C-45BA-951E-FCCA47B5294E',
          policyOriginalName: 'UA - UGV',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'Yab 386',
        },
        site: {
          siteId: 'AAD47063-04FD-4513-9028-750357174741',
          projectId: 'DEF9A38A-46E4-4332-BC04-3F691AF399AA',
          siteOriginalName: 'Yablunivska 386',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [
          {
            remoteCenterId: '6f73ae2d-76fb-4c78-ba2c-ba0a2feba224',
            remoteCenterName: 'EH VRO',
          },
        ],
        mailboxList: [
          {
            mailboxId: '0fb68e63-4272-4758-9d04-fc0a3bd66385',
            mailboxName: 'BaraLogixRT1@halliburton.com',
            isActive: true,
            remoteCenterId: '6f73ae2d-76fb-4c78-ba2c-ba0a2feba224',
          },
          {
            mailboxId: '232dd0bb-2185-464e-b5c5-7d0cf6ce5fe4',
            mailboxName: 'EHiVROGMECH@halliburton.com',
            isActive: true,
            remoteCenterId: '6f73ae2d-76fb-4c78-ba2c-ba0a2feba224',
          },
        ],
        run: null,
        interventionFlag: {},
        updatedAt: '2023-11-13T02:44:17.928Z',
      },
    ],
  ],
  [
    'BBC16D7B-2851-4F97-B8B8-27C7687249ED',
    [
      {
        rigJournalId: '4146874b-d64c-4341-a110-9cc5b92b9240',
        distributionListUrl: null,
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: 'D8A3E190-9B5D-434C-9C99-29D175BF8B92',
          wellId: 'A187E197-1D52-4945-8742-6480952B40CC',
          wellboreOriginalName: 'KUKUPEX',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: null,
          EDMsurfaceLongitude: null,
          EDMspudDate: null,
          wellboreName: 'KUKUPEX',
        },
        project: {
          projectId: 'BBC16D7B-2851-4F97-B8B8-27C7687249ED',
          policyId: 'DF757E67-570D-431C-AED3-11944DB4D04A',
          projectOriginalName: 'WER',
          projectCurrentName: null,
          timezone: '+03:30',
          country: 'PR',
          countryName: 'Puerto Rico',
          block: 'Block',
          region: 'NAM',
          lease: 'Lease',
          field: 'Field ABCDE',
          preUnit: '',
          curUnit: 'API',
          projectName: 'WER',
        },
        rig: {
          rigId: '891630e6-79f9-4210-98bd-8c0616055d0f',
          rigName: 'MT1011_California',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUwNyIsInJpZ1R5cGVIaWVyYXJjaHkiOiJNYXJpbmUgfCBCb3R0b20gU3VwcG9ydGVkIHwgUGxhdGZvcm0gfCBUZW5zaW9uLUxlZyIsInJpZ0RlZmF1bHRJbWFnZSI6Ik1hcmluZV9QbGF0Zm9ybV9UZW5zaW9uLUxlZy5qcGcifQ==',
          contractorId: 'f30651f6-4908-4a29-8cff-419e25216278',
          contractorName: 'MT1011_California_Contractor',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1699605844988_pngtree-cute-cartoon-orange-fox-vector-png-image_6686810.png',
          specUrl: null,
        },
        policy: {
          policyId: 'DF757E67-570D-431C-AED3-11944DB4D04A',
          policyOriginalName: 'MX - Pemex Exploracion y Produccion',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'KUKUPEX',
        },
        site: {
          siteId: 'E3939B74-6A3C-448D-992E-601003B0CF51',
          projectId: 'BBC16D7B-2851-4F97-B8B8-27C7687249ED',
          siteOriginalName: 'GULF DRILL',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [],
        mailboxList: [],
        run: null,
        interventionFlag: {},
        updatedAt: '2023-11-10T11:26:26.103Z',
      },
    ],
  ],
  [
    '64313C4E-4492-4F0E-A2EF-8F395B5D24D2',
    [
      {
        rigJournalId: '384efd25-3e36-45c2-92fc-a8f63db98f65',
        distributionListUrl: null,
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: 'F26B90D7-B97C-40C2-AF22-BD572869CC31',
          wellId: '29B5EE04-7738-44FD-9453-E5099EC56783',
          wellboreOriginalName: 'Berezivske-114 MB',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: 49.97451666666667,
          EDMsurfaceLongitude: 34.97630055555556,
          EDMspudDate: '2021-02-19T23:00:00',
          wellboreName: 'Berezivske-114 MB',
        },
        project: {
          projectId: '64313C4E-4492-4F0E-A2EF-8F395B5D24D2',
          policyId: 'DBD5E626-7C6C-45BA-951E-FCCA47B5294E',
          projectOriginalName: 'UGV SIDETRACK',
          projectCurrentName: null,
          timezone: '+12:00',
          country: 'DZ',
          countryName: 'Algeria',
          block: 'SA',
          region: 'MENA',
          lease: 'SA',
          field: 'SA',
          preUnit: '',
          curUnit: 'API',
          projectName: 'UGV SIDETRACK',
        },
        rig: {
          rigId: 'ffcb3790-08e1-47fa-aab5-746a9d4ec9d1',
          rigName: 'Dino Space1',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6Ik90aGVyIiwicmlnVHlwZUhpZXJhcmNoeSI6Ik90aGVyIiwicmlnRGVmYXVsdEltYWdlIjoiT3RoZXIuanBnIn0=',
          contractorId: '98a1a70f-dcaa-4a3f-b75c-da794dd617ad',
          contractorName: 'Test Contractor Unit',
          imageUrl: null,
          specUrl: null,
        },
        policy: {
          policyId: 'DBD5E626-7C6C-45BA-951E-FCCA47B5294E',
          policyOriginalName: 'UA - UGV',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'Berezivske-114 ',
        },
        site: {
          siteId: 'F66396BE-DD2F-4D48-9C25-A417C02AB81F',
          projectId: '64313C4E-4492-4F0E-A2EF-8F395B5D24D2',
          siteOriginalName: 'LOT #2',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [
          {
            remoteCenterId: '6f73ae2d-76fb-4c78-ba2c-ba0a2feba224',
            remoteCenterName: 'EH VRO',
          },
        ],
        mailboxList: [
          {
            mailboxId: '0fb68e63-4272-4758-9d04-fc0a3bd66385',
            mailboxName: 'BaraLogixRT1@halliburton.com',
            isActive: true,
            remoteCenterId: '6f73ae2d-76fb-4c78-ba2c-ba0a2feba224',
          },
          {
            mailboxId: '232dd0bb-2185-464e-b5c5-7d0cf6ce5fe4',
            mailboxName: 'EHiVROGMECH@halliburton.com',
            isActive: true,
            remoteCenterId: '6f73ae2d-76fb-4c78-ba2c-ba0a2feba224',
          },
        ],
        run: null,
        interventionFlag: {},
        updatedAt: '2023-11-09T15:08:20.387Z',
      },
    ],
  ],
  [
    '30C1F52A-041B-4136-8FF5-36C0759D12C3',
    [
      {
        rigJournalId: '26dd5ba0-d074-4db0-9660-9e86ad74b1e3',
        distributionListUrl: null,
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: '6F4F8858-245D-439A-92B1-1347E580AB4E',
          wellId: '0516AFFA-D059-4D41-B767-AC652E6706F8',
          wellboreOriginalName: '4-ENV-45-MA',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: -4.611511159309541,
          EDMsurfaceLongitude: -45.00323493744829,
          EDMspudDate: '2023-08-10T06:00:00',
          wellboreName: '4-ENV-45-MA',
        },
        project: {
          projectId: '30C1F52A-041B-4136-8FF5-36C0759D12C3',
          policyId: '82B0941F-2B7F-4B45-8F6E-881E47C17765',
          projectOriginalName: 'PN-T-66',
          projectCurrentName: null,
          timezone: null,
          country: null,
          block: null,
          region: null,
          lease: null,
          field: null,
          preUnit: null,
          curUnit: null,
          projectName: 'PN-T-66',
        },
        rig: {
          rigId: 'd314fbab-c435-4e6f-8dbe-48c3f9c52c5f',
          rigName: 'California18112023',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUxMSIsInJpZ1R5cGVIaWVyYXJjaHkiOiJNYXJpbmUgfCBGbG9hdGluZyB8IERyaWxsc2hpcCB8IE1pZHdhdGVyIiwicmlnRGVmYXVsdEltYWdlIjoiTWFyaW5lX0Zsb2F0aW5nX0RyaWxsc2hpcC5qcGcifQ==',
          contractorId: 'b24a9a5b-bf80-40b3-a51e-a594084d0318',
          contractorName: 'California18112023_Contractor',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1699030122857_banner-red-fox.png',
          specUrl: null,
        },
        policy: {
          policyId: '82B0941F-2B7F-4B45-8F6E-881E47C17765',
          policyOriginalName: 'BR - ENEVA',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: '4-ENV-45-MA',
        },
        site: {
          siteId: '0D1508FE-6EBD-4531-8FC4-9EEEEC39C5ED',
          projectId: '30C1F52A-041B-4136-8FF5-36C0759D12C3',
          siteOriginalName: '4-ENV-45-MA',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [],
        mailboxList: [],
        run: null,
        interventionFlag: {},
        updatedAt: '2023-11-03T16:50:11.356Z',
      },
    ],
  ],
  [
    '8B31C018-75FC-42B2-905C-7C298F58F7CE',
    [
      {
        rigJournalId: '808b100f-ee98-4e4f-8255-df4e254b1858',
        distributionListUrl: null,
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: '79FAB355-025A-4C40-9A3B-F8DECDF74F01',
          wellId: 'B4882886-8027-4CDC-AB5E-894F7B29CE93',
          wellboreOriginalName: 'Tigana Sur 16',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: null,
          EDMsurfaceLongitude: null,
          EDMspudDate: '2020-11-27T00:00:00',
          wellboreName: 'Tigana Sur 16',
        },
        project: {
          projectId: '8B31C018-75FC-42B2-905C-7C298F58F7CE',
          policyId: 'D7DBA003-F59B-43DF-B4CD-DE2299ADAE27',
          projectOriginalName: 'Tigana Sur 16',
          projectCurrentName: null,
          timezone: null,
          country: null,
          block: null,
          region: null,
          lease: null,
          field: null,
          preUnit: null,
          curUnit: null,
          projectName: 'Tigana Sur 16',
        },
        rig: {
          rigId: '87971a9f-cb91-4cb5-b9e1-0f855f303cc6',
          rigName: '0_JF_10',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUxOCIsInJpZ1R5cGVIaWVyYXJjaHkiOiJMYW5kIHwgQ29udmVudGlvbmFsIHwgQm94LW9uLUJveCIsInJpZ0RlZmF1bHRJbWFnZSI6IkxhbmRfQ29udmVudGlvbmFsLmpwZyJ9',
          contractorId: '857748ce-f8c5-41a1-a003-9159412c8ee1',
          contractorName: 'JF_Contractor',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1694811858317_Lago_Flowers_FD.png',
          specUrl: null,
        },
        policy: {
          policyId: 'D7DBA003-F59B-43DF-B4CD-DE2299ADAE27',
          policyOriginalName: 'CO - Geopark',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'Tigana Sur 16',
        },
        site: {
          siteId: '69816083-8145-4C93-B338-66A27E6369CF',
          projectId: '8B31C018-75FC-42B2-905C-7C298F58F7CE',
          siteOriginalName: 'Tigana Sur 16',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [],
        mailboxList: [],
        run: null,
        interventionFlag: {},
        updatedAt: '2023-09-15T21:05:37.656Z',
      },
    ],
  ],
  [
    // Duplicate
    '8B31C018-75FC-42B2-905C-7C298F58F7CE',
    [
      {
        rigJournalId: '808b100f-ee98-4e4f-8255-df4e254b1858',
        distributionListUrl: null,
        dataRoutingUrl: null,
        communicateProtocolUrl: null,
        isComplete: false,
        wellbore: {
          wellboreId: '79FAB355-025A-4C40-9A3B-F8DECDF74F01',
          wellId: 'B4882886-8027-4CDC-AB5E-894F7B29CE93',
          wellboreOriginalName: 'Tigana Sur 16',
          wellboreCurrentName: null,
          uwiCurrentName: null,
          guid: null,
          surfaceLatitude: null,
          surfaceLongitude: null,
          spudDate: null,
          EDMsurfaceLatitude: null,
          EDMsurfaceLongitude: null,
          EDMspudDate: '2020-11-27T00:00:00',
          wellboreName: 'Tigana Sur 16',
        },
        project: {
          projectId: '8B31C018-75FC-42B2-905C-7C298F58F7CE',
          policyId: 'D7DBA003-F59B-43DF-B4CD-DE2299ADAE27',
          projectOriginalName: 'Tigana Sur 16',
          projectCurrentName: null,
          timezone: null,
          country: null,
          block: null,
          region: null,
          lease: null,
          field: null,
          preUnit: null,
          curUnit: null,
          projectName: 'Tigana Sur 16',
        },
        rig: {
          rigId: '87971a9f-cb91-4cb5-b9e1-0f855f303cc6',
          rigName: '0_JF_10',
          rigTypeName:
            'eyJyaWdUeXBlTmFtZSI6IlRZUEUxOCIsInJpZ1R5cGVIaWVyYXJjaHkiOiJMYW5kIHwgQ29udmVudGlvbmFsIHwgQm94LW9uLUJveCIsInJpZ0RlZmF1bHRJbWFnZSI6IkxhbmRfQ29udmVudGlvbmFsLmpwZyJ9',
          contractorId: '857748ce-f8c5-41a1-a003-9159412c8ee1',
          contractorName: 'JF_Contractor',
          imageUrl:
            'https://hpmvrodevscusstapp.blob.core.windows.net/rigimage/1694811858317_Lago_Flowers_FD.png',
          specUrl: null,
        },
        policy: {
          policyId: 'D7DBA003-F59B-43DF-B4CD-DE2299ADAE27',
          policyOriginalName: 'CO - Geopark',
          policyCurrentName: null,
          edmSource: 'HPM',
        },
        well: {
          wellName: 'Tigana Sur 16',
        },
        site: {
          siteId: '69816083-8145-4C93-B338-66A27E6369CF',
          projectId: '8B31C018-75FC-42B2-905C-7C298F58F7CE',
          siteOriginalName: 'Tigana Sur 16',
          siteCurrentName: null,
        },
        interval: null,
        remoteCenterList: [],
        mailboxList: [],
        run: null,
        interventionFlag: {},
        updatedAt: '2023-09-15T21:05:37.656Z',
      },
    ],
  ],
];

export const COMPANY_LIST = [
  {
    policyId: '59DC7167-0EAF-4E13-B462-A84DFD4BF19E',
    policyOriginalName: 'AR - CAPEX',
    policyCurrentName: null,
    edmSource: 'HPM',
    policyName: 'AR - CAPEX',
  },
  {
    policyId: 'DC5B1F7E-8A86-4B40-AAE8-8214A613DC7B',
    policyOriginalName: 'AR - ExxonMobil Exploration Argentina S.R.L.',
    policyCurrentName: null,
    edmSource: 'HPM',
    policyName: null,
  },
  {
    policyId: '05472721-52C5-4CEA-864A-98147BD3677F',
    policyOriginalName: 'AR - Pampa Energia',
    policyCurrentName: null,
    edmSource: 'HPM',
    policyName: 'AR - Pampa Energia',
  },
  {
    policyId: '4D93C1F1-DE0A-4845-AF71-5E0575E19914',
    policyOriginalName: 'AR - Pan American Energy',
    policyCurrentName: null,
    edmSource: 'HPM',
    policyName: 'AR - Pan American Energy',
  },
  {
    policyId: '6D026303-07DD-46EE-AF2D-35DBB83EC961',
    policyOriginalName: 'AR - Pluspetrol',
    policyCurrentName: null,
    edmSource: 'HPM',
    policyName: 'AR - Pluspetrol',
  },
  {
    policyId: '68024CAA-279A-4F57-814C-7687F4C99C4D',
    policyOriginalName: 'AR - SHELL',
    policyCurrentName: null,
    edmSource: 'HPM',
    policyName: 'AR - SHELL',
  },
  {
    policyId: 'CDB7ADF3-59B7-47C7-B903-4AE087B68894',
    policyOriginalName: 'AR - YPF',
    policyCurrentName: null,
    edmSource: 'HPM',
    policyName: 'AR - YPF',
  },
];
