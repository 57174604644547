<!-- If isDialogCropperVisible is true then prevent button ESC !isDialogCropperVisible -->
<p-sidebar
  [(visible)]="displaySidebarUserInfomation"
  position="right"
  [showCloseIcon]="false"
  [closeOnEscape]="false"
  [dismissible]="false"
>
  <ng-template pTemplate="content">
    <div
      class="d-flex flex-column justify-content-between"
      style="height: 100%; padding-bottom: 20px"
    >
      <div class="container edit-form panel-form" *ngIf="!!formUserGroup" [formGroup]="formUserGroup">
        <div class="sidebar-header-user-panel sidebar-header">
          <div class="d-flex gap-2">
            <span>User Preferences</span>
          </div>
          <div class="custom-close-icon" (click)="closePanel()">
            <i class="pi pi-times"></i>
          </div>
        </div>

        <!-- Product Version -->
        <div class="sidebar-infor-user-panel">
          <div class="setting-label-light">
            Product Version
          </div>
          <div class="setting-label-light">
            {{ APPLICATION_VERSION }}
          </div>
        </div>

        <!-- Theme setting -->
        <div class="sidebar-infor-user-panel">
          <div class="setting-label">
            Theme
          </div>
          <div class="d-flex align-items-center gap-2">
            <div [ngClass]="theme === 'light' ? 'icon-light-active icon-upper' : 'icon-theme icon-upper'">
              <i class="pi pi-sun"></i>
            </div>
            <app-single-switch
              [classList]="theme"
              [statusValue]="status"
              (onSwitch)="toggle()"
            ></app-single-switch>
            <div [ngClass]="theme === 'dark' ? 'icon-dark-active' : 'icon-theme'">
              <i class="pi pi-moon"></i>
            </div>
          </div>
        </div>

        <!-- Notification Setting -->
        <div class="sidebar-infor-user-panel">
          <div class="setting-label">
            Notification Center
          </div>
          <div>
            <app-customizable-button
              [showButton]="true"
              [label]="'Manage Notifications'"
              [style]="'dark'"
              [isDisabled]="(isOpenSetting$ | async)!"
              (eventButton)="openNotiSetting()"
            ></app-customizable-button>
          </div>
        </div>

        <div class="sidebar-infor-user-panel">
          <div class="d-flex">
            <ng-container *ngIf="!currentUser?.avatar && !isPreviewAvatar">
              <div
                class="avatar"
                [attr.data-label]="ramdomAvatarCharacter"
                [appRandomAvatar]="ramdomAvatarCharacter"
                avatarSize="medium"
              ></div>
            </ng-container>

            <ng-container *ngIf="currentUser?.avatar || isPreviewAvatar">
              <ng-container *ngIf="!currentUser?.avatar">
                <div
                  class="avatar"
                  [attr.data-label]="ramdomAvatarCharacter"
                  [appRandomAvatar]="ramdomAvatarCharacter"
                  avatarSize="medium"
                ></div>
              </ng-container>

              <ng-container *ngIf="currentUser?.avatar">
                <div class="avatar">
                  <img 
                    appBlobImage
                    [imageUrl]="currentUser.avatar || ''"
                    alt="Avatar" 
                  />
                </div>
              </ng-container>
            </ng-container>
            <div class="info">
              <div class="info-name">
                {{ currentUser.displayName || "Loading" }}
              </div>
              <div class="info-email">
                {{ currentUser.mail || "Loading" }}
              </div>
            </div>
          </div>

          <div 
            class="feature"
            [ngClass]="formUserGroup.get('avatar')!.disabled ? '' : 'd-none'"
            (click)="enableEditing()"
          >
            <app-customizable-button
              [showButton]="true"
              [label]="'Edit Profile'"
              [style]="'dark'"
              [isDisabled]="false"
            ></app-customizable-button>
          </div>

          <div
            class="custom-single-upload-btn"
            [ngClass]="formUserGroup.get('avatar')!.disabled ? 'd-none' : ''"
          >
            <div class="d-flex align-items-center">
              <div class="add-input-des me-2">image/*</div>
              <div [pTooltip]="fileLabel" tooltipPosition="bottom">
                <p-fileUpload
                  #uploadAvatar
                  name="avatarUpload[]"
                  url="./upload.php"
                  [multiple]="false"
                  accept=".png, .jpg, .jpeg"
                  (onSelect)="showCropper($event)"
                  (onClear)="onClear_avatar()"
                  [chooseLabel]="fileLabel | shortFileName : 6"
                  chooseIcon="pi pi-upload"
                  cancelLabel=" "
                  cancelStyleClass="cancelStyle"
                  chooseStyleClass="chooseStyle"
                  styleClass="hidePreview"
                  [showUploadButton]="false"
                  [showCancelButton]="showCancelButton"
                ></p-fileUpload>
              </div>
            </div>
          </div>
        </div>
        <!-- accept=".jpeg, .jpg, .jpe, .jfif" -->

        <div class="sidebar-field-user-panel container">
          <div class="row">
            <div class="col-12 mb-2">
              <div
                class="add-input-title"
                [ngClass]="formUserGroup.get('role')!.disabled ? 'label-disabled' : ''"
              >
                Role
              </div>
              <div>
                <div class="add-input p-inputgroup">
                  <input
                    type="text"
                    pInputText
                    class="custom-p-input"
                    placeholder="Enter Role"
                    [formControlName]="fieldNameKeys.role"
                    [innerMsg]="messageErrors"
                  />
                </div>
              </div>
            </div>

            <div class="col-12 mb-2">
              <div
                class="add-input-title"
                [ngClass]="formUserGroup.get('psl')!.disabled ? 'label-disabled' : ''"
              >
                PSL
              </div>
              <div>
                <div class="add-input p-inputgroup">
                  <input
                    type="text"
                    pInputText
                    class="custom-p-input"
                    placeholder="Enter PSL"
                    [formControlName]="fieldNameKeys.psl"
                    [innerMsg]="messageErrors"
                  />
                </div>
              </div>
            </div>

            <div class="col-12 mb-2">
              <div
                class="add-input-title"
                [ngClass]="
                  formUserGroup.get('country')!.disabled ? 'label-disabled' : ''
                "
              >
                Country
              </div>
              <div class="w-100 add-input p-inputgroup">
                <p-dropdown
                  [options]="countries"
                  optionLabel="name"
                  [filter]="true"
                  [showClear]="true"
                  placeholder="Select Country"
                  class="custom-p-drop"
                  scrollHeight="160px"
                  [formControlName]="fieldNameKeys.country"
                  [innerMsg]="messageErrors"
                  [resetFilterOnHide]="true"
                >
                </p-dropdown>
              </div>
            </div>

            <div class="col-12 mb-2">
              <div
                class="add-input-title"
                [ngClass]="
                  formUserGroup.get('country')!.disabled ? 'label-disabled' : ''
                "
              >
                Time Zone
              </div>
              <div class="w-100 add-input p-inputgroup">
                <p-dropdown
                  [options]="timezones"
                  optionLabel="name"
                  [filter]="true"
                  [showClear]="true"
                  placeholder="Select Timezone"
                  class="custom-p-drop"
                  scrollHeight="160px"
                  [formControlName]="fieldNameKeys.timezone"
                  [innerMsg]="messageErrors"
                  [resetFilterOnHide]="true"
                >
                </p-dropdown>
              </div>
            </div>

            <div class="col-12 mb-2">
              <div
                class="add-input-title"
                [ngClass]="
                  formUserGroup.get('phone')!.disabled ? 'label-disabled' : ''
                "
              >
                Phone
              </div>
              <div>
                <div class="add-input p-inputgroup">
                  <div
                    class="form-group form-flag"
                    [ngClass]="
                      isInvalidControl(fieldNameKeys.phone) ? 'form-error' : ''
                    "
                  >
                    <p-dropdown
                      [options]="countries"
                      optionLabel="name"
                      class="custom-p-drop"
                      scrollHeight="160px"
                      [filter]="true"
                      filterBy="combine_name_dial"
                      (onFilter)="customFilterChanged($event)"
                      (onChange)="addPrefixNumber($event)"
                      [formControlName]="fieldNameKeys.flag"
                      [resetFilterOnHide]="true"
                    >
                      <ng-template let-country pTemplate="selectedItem">
                        <div
                          class="country-item country-item-value"
                          style="
                            font-size: 14px;
                            font-weight: 500;
                            color: #c9cfd4;
                          "
                        >
                          <i
                            class="flag flag-{{
                              formUserGroup
                                .get('flag')
                                ?.value?.code?.toLowerCase()
                            }}"
                          ></i>
                        </div>
                      </ng-template>
                      <ng-template let-country pTemplate="item">
                        <div class="country-item">
                          <div>
                            <i
                              class="flag flag-{{ country.code.toLowerCase() }}"
                            ></i>
                            {{ country.name }}
                            <span style="color: #3b4957">
                              {{
                                country.replace_dial_code || country.dial_code
                              }}
                            </span>
                          </div>
                        </div>
                      </ng-template>
                    </p-dropdown>
                    <div class="input-box gap-2">
                      <input
                        type="hidden"
                        [formControlName]="fieldNameKeys.dialCode"
                      />
                      <span
                        class="prefix"
                        [ngClass]="
                          formUserGroup.get('phone')!.disabled ? 'disabled' : ''
                        "
                        >{{ formUserGroup.get("dialCode")?.value }}</span
                      >
                      <input
                        type="text"
                        pInputText
                        placeholder="Enter Phone"
                        NumbersOnly
                        [maxLength]="18"
                        [maxDecimalDigit]="0"
                        [allowDecimals]="false"
                        [allowSign]="false"
                        [isBlurActive]="false"
                        [formControlName]="fieldNameKeys.phone"
                        (paste)="handlePasteInput('phone', $event)"
                      />
                      <!-- (paste)="handlePasteInput('phone', $event)" -->
                      <!-- (keypress)="inputNumber_Clear_e($event)" -->
                    </div>
                  </div>
                </div>
                <div *ngIf="isInvalidControl(fieldNameKeys.phone)">
                  <ng-container
                    *ngFor="let msg of getErrorByField(fieldNameKeys.phone)"
                  >
                    <div class="inner-msg-error">
                      <span>{{ msg }}</span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sidebar-button-user-panel">
          <div
            class="feature d-flex align-items-center"
            (click)="logout_Azure()"
          >
            <svg-icon
              key="signout"
              size="smm"
            ></svg-icon>
            <span>Sign Out</span>
          </div>
        </div>
      </div>
      <div
        class="sidebar-button d-flex align-self-end gap-2"
        [ngClass]="formUserGroup.get('phone')!.disabled ? 'd-none' : ''"
      >
        <p-button
          [attr.data-cy]="'cancel-button'"
          label="Cancel"
          class="custom-p-button custom-p-button--secondary"
          (onClick)="closePanel()"
        ></p-button>
        <p-button
          [attr.data-cy]="'save-button'"
          label="Save"
          class="custom-p-button custom-p-button--primary"
          [ngClass]="
            formUserGroup.status === 'INVALID' || isViewer
              ? 'custom-p-button custom-p-button--disable'
              : ''
          "
          [disabled]="formUserGroup.status === 'INVALID' || isViewer"
          (onClick)="saveUserSetting()"
        ></p-button>
      </div>
    </div>
  </ng-template>

  <!-- Loading -->
  <app-loading [isLoading]="isLoading"></app-loading>
</p-sidebar>
