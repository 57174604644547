/** enum for two Mode types */
export enum Mode {
    /**
     * Light Mode
     */
    LIGHT = "light",
  
    /**
     * Dark Mode
     */
    DARK = "dark",

    /**
     * Third Mode / Future
     */
    MIXED = "mixed",
  }
               