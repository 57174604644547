import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstant } from '../../utilities/app.constant';
import { NavigationExtras } from '@angular/router';
import { HelpCenterService } from 'src/app/pages/help-center/help-center.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['../../../../assets/styles/share/_footer.scss'],
})
export class FooterComponent implements OnInit {
  constructor(
    private _router: Router,
    private _helpCenterService: HelpCenterService
  ) {}

  ngOnInit() {}

  hasRoute(route: string) {
    return this._router.url.includes(route);
  }

  redirectToPage(type: string) {
    switch (type) {
      case 'EMPLOYEE_PRIVACY_NOTICE': {
        window.open(
          AppConstant.HALLIBURTON_POLICY_PAGE,
          '_blank' // <- This is what makes it open in a new window.
        );

        break;
      }
      case 'TERMS_OF_USE': {
        this._router.navigate(['/help-center'], { queryParams: { tab: 'term' } });
      }
    }
  }
}
