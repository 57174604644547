import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { catchError, throwError, take, finalize } from 'rxjs';

import { BaseComponent } from 'src/app/shared/components/base.component';
import { HomeService } from 'src/app/shared/services/home.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { EditProject } from 'src/app/shared/interface/project.interface';
import { EDIT_PROJECT } from '../../../shared/metadata/home-edit-project.metadata';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';
import { IConfirmDialog } from 'src/app/shared/interface/common';
import { DialogAction, OptionButtonType } from 'src/app/shared/type';
import { UNIT_SYSTEM } from 'src/app/shared/utilities/app.helper.data';

@Component({
  selector: 'app-home-edit-project',
  templateUrl: './home-edit-project.component.html',
  styleUrls: ['./home-edit-project.component.scss'],
})
export class HomeEditProjectComponent extends BaseComponent {
  isLoading: boolean = false;

  @Input()
  displayEditProject: boolean = false;

  @Input()
  project!: EditProject;

  @Output()
  hideEditProject: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Variable of Form
  public formEditProjectGroup!: FormGroup;

  isViewer: boolean = false;

  METADATA: any;
  selectedRegion?: any;
  selectedCountry?: any;

  menuItems: any = [];

  public fieldNameKeys = {
    projectId: 'projectId',
    projectName: 'projectName',
    operator: 'operator',
    region: 'region',
    country: 'country',
    block: 'block',
    lease: 'lease',
    field: 'field',
    timezone: 'timezone',
    unit: 'unit',
  };

  public messageErrors: any = {
    required: 'Please fill in the information.',
    whitespace: 'Please fill in the information without white space.',
    maxlength: 'Please enter no more than 50 characters',
  };

  operators: any[] = [];
  filteredOperatorName: any[] = [];
  regions: any[] = [];
  countries: any[] = [];
  countriesDisplay: any[] = [];
  timezones: any[] = [];
  units: { name: string, code: string }[] = [];

  confirmDialog: IConfirmDialog = AppConstant.DEFAULT_DIALOG;

  constructor(
    private _homeService: HomeService,
    private _builder: FormBuilder,
    private _notificationService: NotificationService,
    private _userInfoService: UserInfoService,
    private _confirmService: ConfirmDialogService

  ) {
    super();
  }
  onInit(): void {

    this.METADATA = EDIT_PROJECT;
    this.regions = AppConstant.REGIONS;
    this.countries = AppConstant.COUNTRIES;
    this.countriesDisplay = [...this.countries];
    this.timezones = AppConstant.TIME_ZONES;

    for (const key in UNIT_SYSTEM.unit) {
      if (key) {
        this.units.push({ name: key, code: key });
      }
    }
    // Since this is a popup, there is definitely User data. So we can get form data with .getValue()
    this.isViewer = this._userInfoService.userSubject.getValue().role === AppConstant.ROLES.VIEWER.label;
    this.buildForm();

  }

  buildForm() {
    this.formEditProjectGroup = this._builder.group({
      [this.fieldNameKeys.projectId]: [this.project.projectId],
      [this.fieldNameKeys.projectName]: [
        this.project.projectName,
        [
          Validators.required,
          Validators.maxLength(50),
          this.noWhitespaceValidator,
        ],
      ],
      [this.fieldNameKeys.operator]: [
        this.project.company,
        [
          Validators.required,
          Validators.maxLength(50),
          this.noWhitespaceValidator,
        ],
      ],
      [this.fieldNameKeys.region]: [
        this.regions.find((element) => element.code === this.project.region),
        [Validators.required],
      ],
      [this.fieldNameKeys.country]: [
        this.countries.find((element) => element.code === this.project.country),
        [Validators.required],
      ],
      [this.fieldNameKeys.block]: [
        this.project.block,
        [
          Validators.required,
          Validators.maxLength(50),
          this.noWhitespaceValidator,
        ],
      ],
      [this.fieldNameKeys.lease]: [
        this.project.lease,
        [
          Validators.required,
          Validators.maxLength(50),
          this.noWhitespaceValidator,
        ],
      ],
      [this.fieldNameKeys.field]: [
        this.project.field,
        [
          Validators.required,
          Validators.maxLength(50),
          this.noWhitespaceValidator,
        ],
      ],
      [this.fieldNameKeys.timezone]: [
        this.timezones.find(
          (element) => element.code === this.project.timezone
        ),
        [Validators.required],
      ],
      [this.fieldNameKeys.unit]: [
        this.units.find((element) => element.name === this.project.curUnit),
        [Validators.required],
      ],
    });

    // if (isViewer) this.disableForm();
  }

  disableForm() {
    Object.keys(this.formEditProjectGroup.controls).forEach((controlName) => {
      const control = this.formEditProjectGroup.get(controlName);
      if (control) {
        control.disable();
      }
    });
  }

  onMenuShow(fomrKey: string, project: any) {
    // disabled: this.isViewer,
    this.menuItems = [
      {
        label: 'Reset',
        icon: 'c-icons restore-icon',
        command: () => {
          this.resetValue(fomrKey, project);
        },
      },
    ];
  }

  resetValue(formKey: string, project: any): void {
    switch (formKey) {
      case 'projectName':
        this.formEditProjectGroup
          .get(formKey)
          ?.setValue(project.projectOriginalName);
        break;
      case 'operator':
        this.formEditProjectGroup
          .get(formKey)
          ?.setValue(project.policyOriginalName);
        break;
      case 'region':
        let resetRegionValue = this.regions.find(
          (el) => el.code === project.region
        );
        if (resetRegionValue && resetRegionValue.code) {
          this.formEditProjectGroup.get(formKey)?.setValue(resetRegionValue);
          this.bindingCountry(null, resetRegionValue.code);
        }
        break;
      case 'country':
        if (project.country && project.region) {
          let resetRegionValue = this.regions.find(
            (el) => el.code === project.region
          );
          if (resetRegionValue && resetRegionValue.code) {
            this.formEditProjectGroup.get('region')?.setValue(resetRegionValue);
            this.bindingCountry(null, resetRegionValue.code);
          }

          let resetCountryValue = this.countries.find(
            (el) => el.code === project.country
          );
          if (resetCountryValue && resetCountryValue.code) {
            this.formEditProjectGroup.get(formKey)?.setValue(resetCountryValue);
          }
        }
        break;
      case 'block':
        this.formEditProjectGroup.get(formKey)?.setValue(project.block);
        break;
      case 'lease':
        this.formEditProjectGroup.get(formKey)?.setValue(project.lease);
        break;
      case 'field':
        this.formEditProjectGroup.get(formKey)?.setValue(project.field);
        break;
      case 'timezone':
        this.formEditProjectGroup
          .get(formKey)
          ?.setValue(AppConstant.TIME_ZONES.find(time => time.code === project.timezone) || { name: 'UTC+00:00 (GMT/UTC)', code: '+00:00' });
        break;
      case 'unit':
        let resetUnitValue = this.units.find((el) => el.name === project.units);
        this.formEditProjectGroup.get(formKey)?.setValue(resetUnitValue);
        break;
      default:
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.INFO,
          header: 'Reset',
          content: 'On Developing',
        });
        break;
    }
  }

  closeDialog() {
    if (
      this.formEditProjectGroup.touched ||
      !this.formEditProjectGroup.pristine
    ) {
      this._confirmService.setDialog({
        ...this.confirmDialog,
        isVisible: true,
        header: 'Discard',
        haveDialogMessage: true,
        dialogMessage: 'Do you want to leave without saving information?',
        havePrimaryButton: true,
        primaryButtonLabel: 'Discard',
        isValidPrimaryButton: true,
        disablePrimaryButton: false,
        haveSecondaryButton: true,
        secondaryButtonLabel: 'Cancel',
        buttonEvent: (event: OptionButtonType) =>
          this.onButtonClickDialog(event, 'Draft'),
      });

    } else {
      this.displayEditProject = !this.displayEditProject;
      const isReloadPage = this.displayEditProject;
      this.hideEditProject.emit(isReloadPage);
    }
  }

  closeDialogForm(isReloadPage: boolean) {
    this.hideEditProject.emit(isReloadPage);
  }

  removeEdit() {}

  filterOperatorName(event: any) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.operators.length; i++) {
      let operator = this.operators[i];
      if (operator.operatorNathis.toLowerCase().includes(query.toLowerCase())) {
        filtered.push(operator);
      }
    }

    if (filtered.length > 0 && filtered[0].operatorName === query.trim()) {
    } else {
      filtered.push({ operatorName: query, flag: 'new' });
    }
    this.filteredOperatorName = filtered;
  }
  clearOperator(event: any) {
    this.formEditProjectGroup.get(this.fieldNameKeys.operator)?.setValue('');
  }

  submitForm(value: any): void {
    this.isLoading = true;

    if (this.formEditProjectGroup.status === 'INVALID') {
      this.isLoading = false;
      return;
    } else {
      let newUnit = '';
      let preUnit = '';
      if (
        this.project.curUnit !==
        this.formEditProjectGroup.get('unit')?.value.code
      ) {
        newUnit = this.formEditProjectGroup.get('unit')?.value.code;
        preUnit = this.project.curUnit;
      } else {
        newUnit = this.project.curUnit;
        preUnit = this.project.preUnit;
      }

      let payload = {
        region: this.formEditProjectGroup.get('region')?.value.code,
        country: this.formEditProjectGroup.get('country')?.value.code,
        lease: this.formEditProjectGroup.get('lease')?.value,
        block: this.formEditProjectGroup.get('block')?.value,
        field: this.formEditProjectGroup.get('field')?.value,
        timezone: this.formEditProjectGroup.get('timezone')?.value.code,
        projectName: this.formEditProjectGroup.get('projectName')?.value,
        policyName: this.formEditProjectGroup.get('operator')?.value,
        preUnit: preUnit,
        newUnit: newUnit,
      };

      let projectId = this.formEditProjectGroup.get('projectId')?.value;

      this._homeService
        .editProject(payload, projectId)
        .pipe(catchError(AppHelper.UtileFunctions.handleError))
        .subscribe({
          next: (response: any) => {
            // console.info(response);
            const isReloadPage = true;
            this.hideEditProject.emit(isReloadPage);
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.SUCCESS,
              header: 'Update Project',
              content: 'The project was updated successfully!',
            });
            this.isLoading = false;
          },
          error: (error) => {
            // error &&
            //   this._notificationService.setMessage({
            //     type: AppConstant.MESSAGE_TYPE.WARNING,
            //     header: 'Update project failed',
            //     content: error?.message || error,
            //   });
            console.error(error?.message || error)
            this.isLoading = false;
          },
        });
    }
  }

  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error?.error?.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
          `body was: ${error?.error?.message}`
      );
    }
    return throwError(`${error?.error?.message}`);
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const moreThanOneCharecter = control.value.length === 0;
    const isValid = !isWhitespace || moreThanOneCharecter;
    return isValid ? null : { whitespace: true };
  }

  public onBlurMethod(controlName: string, formGroupName: FormGroup) {
    let control = formGroupName.get(controlName);
    formGroupName.controls[controlName].patchValue(control?.value.trim());
  }

  bindingCountry(event: any, region?: any) {
    if (event?.value?.code || region) {
      let selectedRegion = event?.value?.code || region;
      this.countries = [...this.countriesDisplay];
      this.countries = this.countries.filter(
        (country) => country.region === selectedRegion
      );
    }
  }
  bindingRegion(event: any, country?: any) {
    if (event?.value?.region || country) {
      let selectedRegion = event?.value?.region || country;
      let regionIndex = this.regions.findIndex((region: any) => {
        return region.code === selectedRegion;
      });

      this.formEditProjectGroup
        .get(this.fieldNameKeys.region)
        ?.patchValue(this.regions[regionIndex]);
    }
  }

  selectedOptionClear(clearField: string) {
    switch (clearField) {
      case this.METADATA.REGION.LABEL:
        this.countries = [...this.countriesDisplay];
        this.formEditProjectGroup.get(this.fieldNameKeys.country)?.setValue('');

        break;
      case this.METADATA.COUNTRY.LABEL:
        break;
      default:
        break;
    }
  }

  onButtonClickDialog(option: OptionButtonType, dialogType: DialogAction): void {
    switch (option) {
      case AppConstant.OPTION_BUTTON.YES:
        switch (dialogType) {
          case 'Draft':
            this.displayEditProject = !this.displayEditProject;
            this._confirmService.clearDialog();
            break;
          default:
            this._confirmService.clearDialog();
            break;
        }
        break;
      case AppConstant.OPTION_BUTTON.NO:
        this._confirmService.clearDialog();
        break;
      case AppConstant.OPTION_BUTTON.CANCEL:
        this._confirmService.clearDialog();
        break;
      default:
        break;
    }
  }

  onDestroy(): void {}
}
