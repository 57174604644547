import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { NgControl } from '@angular/forms';

type TypeCheck = 'short' | 'long';

@Directive({ selector: '[TextInputValidate]' })
export class TextInputValidate implements OnInit {
  // set value is'long' or 'short'. 'long' for default
  @Input() typeCheck: TypeCheck = 'long';
  @Input() isCustomPattern: boolean = false;
  @Input() customPattern: string = '';

  pattern!: RegExp;
  /**
  * Regular expression pattern to match short special characters.
  * It prevents the use of certain characters in a string.
  * The pattern includes: @, #, $, %, ^, &, *, \, |, +, <, >, ~, and ` 
  **/
  ruleShortSpecialCharacter: RegExp = /[@#$%^&*\\|+<>~`=]/g;
  /**
  * Regular expression pattern to match special characters.
  * It prevents the use of certain characters in a string.
  * The pattern includes: +, =, <, and > 
  */
  ruleLongSpecialCharacter: RegExp = /[+=<>]/g;

  constructor(private ngControl: NgControl) {}

  ngOnInit() {
    if (this.isCustomPattern && this.customPattern) {
      // If you want to use custom RegEpx, then we use your RegExp
      // with isCustomPattern flag and customPattern value
      try {
        this.pattern = new RegExp(this.customPattern, 'g');
      } catch (error: any) {
        this.pattern = this.ruleLongSpecialCharacter;
      }
    } else {
      // We use RegExp default if there are no customPattern
      switch (this.typeCheck) {
        case 'short':
          this.pattern = this.ruleShortSpecialCharacter;
          break;
        case 'long':
          this.pattern = this.ruleLongSpecialCharacter;
          break;
      }
    }
  }

  @HostListener('input', ['$event']) onInput(_: any) {
    this.validateValue();
  }

  @HostListener('blur', ['$event']) onBlur(_: any) {
    let res = this.ngControl.control!.getRawValue().trim();

    this.storeValue(res);
  }

  validateValue() {
    const str: string = this.ngControl.control!.getRawValue();
    let res = str.replace(this.pattern, '');
  
    this.storeValue(res);
  }

  storeValue(value: string) {
    this.ngControl.control!.setValue(value);
  }
}
