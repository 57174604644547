import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, throwError } from 'rxjs';

import { Injectable } from '@angular/core';
import { NotificationService } from '../services/notification.service';
import { HttpErrorResponseAuth } from '../interface/HttpErrorResponseAuth.interface';
import { AppConstant } from '../utilities/app.constant';
import { UserInfoService } from '../services/user-info.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ResponseInterceptor implements HttpInterceptor {
  constructor(
    private _notificationService: NotificationService,
    private _location: Location
  ) {}

  counterErrorSetting: number = 0;

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponseAuth) => {
        let errorMsg = '';
        if (error.error instanceof Error) {
          console.log('This is client side error');
          errorMsg = `Error: ${error?.error?.message}`;
        } else {
          console.log('This is server side error');
          errorMsg = `Error Code: ${error.status},  Message: ${error?.message}`;

          switch (+error.status) {
            case 0: {
              if (error.url && error.url.includes('/ping')) {
                console.log('Error Status Code (Offline): ' + 0);
              } else if (error.url && error.url.includes('/localhost')) {
                if (this.counterErrorSetting < 1) {
                  // Show this error message ONLY 1 Time
                  this._notificationService.setMessage({
                    type: AppConstant.MESSAGE_TYPE.ERROR,
                    header: 'This message only for development',
                    content: 'Oops! Please check your enviroment settings',
                  });
                  this.counterErrorSetting++;
                }
              } else {
                window.location.reload();
                console.log('Error Status Code: ' + 0);
              }
              break;
            }
            case 403: {
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.ERROR,
                header: 'Blocked',
                content: 'Oops! Something went wrong.',
              });
              console.log('Error Status Code: ' + 403);
              break;
            }
            case 401:
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.ERROR,
                header: '401',
                content:
                  'You currently do not have enough permissions to access the system, please contact admin.',
              });
              console.log('Error Status Code: ' + 401);
              break;
            case 400: {
              if (error.error.errorCode === 'permission_not_found') {
                this._notificationService.setMessage({
                  type: AppConstant.MESSAGE_TYPE.WARNING,
                  header: 'Information',
                  content: 'User is not granted permission!',
                });
              } 
              else if (error.error.errorCode === 'mail_validate_error') {
                this._notificationService.setMessage({
                  type: AppConstant.MESSAGE_TYPE.WARNING,
                  header: 'Information',
                  content: error?.error?.message,
                });
              }
              else if(error.error.errorCode === 'missing_distribution_list'){
                  this._notificationService.setMessage({
                    type: AppConstant.MESSAGE_TYPE.WARNING,
                    header: 'Information',
                    content: error?.error?.message,
                  });
              }
              console.log('Error Status Code: ' + 400);
              break;
            }
            case 404: {
              if (error.error.errorCode === 'not_found') {
                this._notificationService.setMessage({
                  type: AppConstant.MESSAGE_TYPE.WARNING,
                  header: 'Information',
                  content: error?.error?.message,
                })
              }
              console.log('Error Status Code: ' + 404);
              break;
            }
            case 419: {
              console.log('Error Status Code: ' + 419);
              break;
            }
            case 500: {
              const errorMessage = error?.error?.message || JSON.stringify(error || {})      
              const apiUrl = req.url;      
              const path = window.location.pathname;     
              let reloadApp = false;         
              if (path === '/home') {
                const skipUrls = ['/rig-journal/project-list', '/rig-journal/rig-list', '/intervention/log/list', '/user/']
                for (const skipUrl of skipUrls) {
                  if (apiUrl.includes(skipUrl)) {
                    reloadApp = true;
                    break;
                  }
                }
              }

              reloadApp ? window.location.reload() : this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.ERROR,
                header: 'Internal Server Error',
                content:
                  `We are sorry, but we encountered an issue on our server. Our team is working to fix it. In the meantime, please refresh and try again. ${errorMessage || ''}`,
              });              
              break;
            }
            case 503: {
              // Wait to confirm AC

              // In case have error -- Server Down
              if (error?.error?.message) {
                this._notificationService.setMessage({
                  type: AppConstant.MESSAGE_TYPE.ERROR,
                  header: 'Server is unstable',
                  content:
                    'We are sorry, but we encountered an issue on our server. Our team is working to fix it. In the meantime, please refresh and try again.',
                });
              } else {
                // Without Message from server means downtime - Maintenance
                this._notificationService.setMessage({
                  type: AppConstant.MESSAGE_TYPE.ERROR,
                  header: '503',
                  content:
                    'Server is under maintenance please try again later. Thanks you',
                });
                console.log('Error Status Code: ' + 503);
              }

              break;
            }
            default:
              console.log('Error Status Code: ' + error.status);
              break;
          }
        }
        return throwError(error?.error?.message);
      })
    );
  }
}
