<div class="live-template d-flex flex-column align-items-center justify-content-center">
  <ng-container [ngSwitch]="currentStep$ | async">
    
    <div class="h-100 w-100" *ngSwitchCase="'CHAT'">
      <app-chat-box [currentUser]="currentUser" (emitTotalUnread)="emitUnreadMsg($event)"></app-chat-box>
    </div>
    <div *ngSwitchCase="'RETRY'" class="default-template d-flex flex-column align-items-center justify-content-center gap-2 mb-5">
      <div class="d-flex flex-column align-items-center gap-2 mb-2 w-100">
        <div>
          <svg-icon class="icon-info" key="nonSupport" size="xxl"></svg-icon>
        </div>
        <div>No agents are available</div>
        <div class="text-center">
          Please write your message to the support team, and they will respond to you as soon as possible.
        </div>
      </div>
      <div class="w-100">
        <textarea
          pInputTextarea
          placeholder="Your message"
          class="fixed-textarea custom-p-input"
          [rows]="6"
          TextInputValidate
          [autoResize]="false"
          [(ngModel)]="newMessageText"
          (focus)="onInputFocus()" 
          (keypress)="onKeyPress($event)"
        ></textarea>
      </div>
      <div class="w-100">
        <div class="step-button step-button--primary" (click)="onSend()">Send</div>
      </div>
      <div *ngIf="false" class="step-button step-button--secondary" (click)="connectAgent()">Try to connect again</div>
      <div *ngIf="false" class="step-button step-button--secondary" (click)="showForm('REPORT')">Report an issue</div>
    </div>

    <div *ngSwitchCase="'WAIT'" class="d-flex flex-column gap-3">
      <div class="dot-container">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
      <div>
        An agent is on the way
      </div>
    </div>

    <div *ngSwitchDefault></div>

  </ng-container>
</div>