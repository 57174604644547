import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject, filter, map, takeUntil, tap } from 'rxjs';
import { UserInfoService } from '../../services/user-info.service';
import { AppConstant } from '../../utilities/app.constant';
import { InterventionService } from '../../services/intervention.service';
import { TutorialService } from '../../services/tutorial.service';
import { CollapseLayoutService } from '../../services/collapseLayout.service';
import { HomeService } from '../../services/home.service';
import { LiveSupportService } from 'src/app/pages/live-support/live-support.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['../../../../assets/styles/share/_sidebar.scss'],
})
export class SidebarComponent implements OnInit {
  public tabs: any[] = [];
  public subTabs: any[] = [];
  
  currentPath: string = '';
  currentUser: any;
  numberUnreadSupportCases: number = 0;
  destroy$ = new Subject<void>();

  @Input() expandMenu$: boolean = false;

  constructor(
    private _router: Router,
    private _userInfoService: UserInfoService,
    private _interventionService: InterventionService,
    private _tutorialService: TutorialService,
    private _collapseLayoutService: CollapseLayoutService,
    private _homeService: HomeService,
    private liveSupportService: LiveSupportService,
  ) {}

  ngOnInit() {
    this.getUnreadForLiveSuppport();
    this.getCurrentUrl().subscribe((url: string) => {
      this.currentPath = url === '/' ? 'home' : url;

      this._userInfoService.userSubject$.pipe(
        filter((userResponse: any) => !!userResponse),
        filter((userResponse: any) => JSON.stringify(userResponse) != (JSON.stringify(this.currentUser))),
        tap((userResponse: any) => this.currentUser = userResponse),
      ).subscribe((userResponse: any) => {
        const user = userResponse || null;
        if (user && user.id && user.role) {
          if (user.role === 'Engineer') {
            this.tabs = [
              {
                title: 'Home',
                svg: 'home',
                sizeIcon: 'md',
                isActive: false,
                path: 'home',
              },
              {
                title: 'Alerts Management',
                svg: 'alert',
                sizeIcon: 'md',
                isActive: false,
                path: 'alerts/alerts-management',
              },
              {
                title: 'Help Center',
                svg: 'helpCenter',
                sizeIcon: 'md',
                isActive: false,
                path: 'help-center',
              },
            ];

            this.activeModuleTracking();
          } else if (user.role === AppConstant.ROLES.MANAGER.label) {
            this.tabs = [
              {
                title: 'Home',
                svg: 'home',
                sizeIcon: 'md',
                isActive: false,
                path: 'home',
              },
              {
                title: 'Alerts Management',
                svg: 'alert',
                sizeIcon: 'md',
                isActive: false,
                path: 'alerts/alerts-management',
              },
              {
                title: 'Team Management',
                svg: 'user',
                sizeIcon: 'md',
                isActive: false,
                path: 'user/team-management',
              },
              {
                title: 'Help Center',
                svg: 'helpCenter',
                sizeIcon: 'md',
                isActive: false,
                path: 'help-center',
              },
            ];

            this.activeModuleTracking();
          } else if (
            [
              AppConstant.ROLES.ADMIN.label,
              AppConstant.ROLES.SUPPORT.value.data,
              AppConstant.ROLES.VIEWER.label,
            ].includes(user.role)
          ) {
            this.tabs = [
              {
                title: 'Home',
                svg: 'home',
                sizeIcon: 'md',
                isActive: false,
                path: 'home',
              },
              {
                title: 'Alerts Management',
                svg: 'alert',
                sizeIcon: 'md',
                isActive: false,
                path: 'alerts/alerts-management',
              },
              {
                title: 'Rig Management',
                svg: 'rig',
                sizeIcon: 'md',
                isActive: false,
                path: 'rig/rig-management',
              },
              {
                title: 'Team Management',
                svg: 'user',
                sizeIcon: 'md',
                isActive: false,
                path: 'user/team-management',
              },
              {
                title: 'Mailbox Management',
                svg: 'mailbox',
                sizeIcon: 'md',
                isActive: false,
                path: 'mailbox/mailbox-management',
              },
              {
                title: 'Help Center',
                svg: 'helpCenter',
                sizeIcon: 'md',
                isActive: false,
                path: 'help-center',
              },
            ];

            this.activeModuleTracking();
          }

          // simulate live support role
        if ([AppConstant.ROLES.SUPPORT.value.data, AppConstant.ROLES.ADMIN.value.data].includes(user.role)) {
          this.subTabs = [
            {
              title: 'Live Support',
              svg: 'liveSupport',
              sizeIcon: 'lg',
              isActive: false,
              path: 'live-support',
            },
          ];
          this.activeModuleTracking();
        }
        } else {
          // In case Error --> Render Only Home to prevent break UI
          this.tabs = [
            {
              title: 'Home',
              svg: 'home',
              sizeIcon: 'md',
              isActive: false,
              path: 'home',
            },
          ];
          this.activeModuleTracking();
        }
        

        this._tutorialService.setCurrentPathSubject(this.currentPath);
        this._tutorialService.setUserTutorialSubject(this.tabs);
      });


      
      
    });
  }

  getUnreadForLiveSuppport() {
    this.liveSupportService.numberSupportCasesUnread$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((value: any) => {
        this.numberUnreadSupportCases = value || 0;
      }
    )
  }

  activeModuleTracking() {
    this.tabs = this.tabs.map((el) => {
      return this.currentPath.includes(el.path)
        ? { ...el, isActive: true }
        : el;
    });

    this.subTabs = this.subTabs.map((el) => {
      return this.currentPath.includes(el.path)
        ? { ...el, isActive: true }
        : el;
    });
  }

  activateClass(subModule: any) {

    this.tabs = this.tabs.map((el) => {
      let rs = { ...el };
      rs.isActive = false;
      if (subModule.title === el.title) {
        rs.isActive = true;
      }
      return rs;
    });


    this.subTabs = this.subTabs.map((el) => {
      let rs = { ...el };
      rs.isActive = false;
      if (subModule.title === el.title) {
        rs.isActive = true;
      }
      return rs;
    });
  }

  onTab(path: string) {
    if (path.includes('home')) {
      this._interventionService.resetLogFilter();
      // Set Default Filter -- Without CONSTANTS
      this._homeService.setPayloadFilterDefault();
      this._collapseLayoutService.updateCollapseLayout(true);
    }
    this._router.navigate([path.toLocaleLowerCase()])
  }

  hasRoute(route: string) {
    return this._router.url.includes(route);
  }

  getCurrentUrl(): Observable<string> {
    return this._router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url)
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
