import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ShareModule } from '../../shared/shared.module';
import { TestComponent } from './test.component';
const declarations = [TestComponent];

const imports: any = [
  ShareModule,
  FormsModule,
  ReactiveFormsModule,
];

@NgModule({
  imports: [...imports],
  declarations: [...declarations],
  exports: [TestComponent],
  providers: [],
})
export class TestModule {}
