import { Component } from '@angular/core';

import { BaseComponent } from 'src/app/shared/components/base.component';
import { Location } from '@angular/common';
import { OptionButtonType } from 'src/app/shared/type';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { IConfirmDialog } from 'src/app/shared/interface/common';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent extends BaseComponent {

  onInit(): void {
    this._confirmService.setDialog({
      ...this.backDialog,
      isVisible: true,
    });
  }
  onDestroy(): void {}

  constructor(
    private _confirmService: ConfirmDialogService,
    private _location: Location
  ) {
    super();
  }

  backDialog: IConfirmDialog = {
    isVisible: false,
    header: 'Information',
    haveCheckbox: false,
    checkboxLabel: '',
    haveDialogMessage: true,
    dialogMessage:
      'This feature is currently in development, please come back later',
    havePrimaryButton: true,
    primaryButtonLabel: 'Back',
    haveSecondaryButton: false,
    secondaryButtonLabel: '',
    isValidPrimaryButton: true,
    disablePrimaryButton: false,
    disableSecondaryButton: false,
    haveBoxSelection: false,
    dataSelection: [],
    haveSecondaryMessage: false,
    secondaryMessage: '',
    haveTertiaryMessage: false,
    tertiaryDialogMessage: '',
    alignCenterButton: false,
    styleDialog: 'dark-dialog',
    isHeaderIcon: false,
    headerIcon: '',
    isHaveTextarea: false,
    textarePlaceHolder: '',
    textareaSize: 6,
    buttonEvent: (event: OptionButtonType) =>
      this.onButtonClickInbackDialog(event),
    dataDropdownTemplate: [],
    dialogMessageTemplate: '',
    dataDropdownLanguage: [],
    dialogMessageLanguage: '',
    isDisableDropdown: false,
    defaultTemplate: {id: 'default', name:'Default', isSelected: true},
    defaultLanguage: {id: 'en', name:'English', isSelected: true},
  };

  onButtonClickInbackDialog(option: OptionButtonType): void {
    switch (option) {
      case AppConstant.OPTION_BUTTON.YES:
      case AppConstant.OPTION_BUTTON.CANCEL:
        this._confirmService.setDialog({
          ...this.backDialog,
          isVisible: false,
        });
        this.backHistory();
        break;
      case AppConstant.OPTION_BUTTON.NO:
      default:
        break;
    }
  }

  backHistory() {
    const me = this;
    me._location.back();
  }
}
