import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppConstant } from 'src/app/shared/utilities/app.constant';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
})

export class ChatMessageComponent implements OnInit {
  @Input() message: AppConstant.Message;
  @Input() previousMessage: AppConstant.Message | undefined;

  @Input() isMine: boolean;

  @Input() isTyping: boolean = false;
  @Input() template: 'CONTENT' | 'LOADING' = 'CONTENT';
  
  ngOnInit(): void {}
}

