import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatFileSizePipe',
})
export class UtileFormatFileSizePipe implements PipeTransform {
  UNITS = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  /**
   * Formats the input value as a human-readable file size string.
   * If `isUnits` is true, it appends the units to the formatted size.
   * Otherwise, it returns only the formatted size.
   *
   * @param size - The size value to be formatted.
   * @param isUnits - Indicates whether to include units in the result.
   * @returns The formatted file size string.
   */

  formatFileSizePipe(size: any, isUnits: boolean) {
    let l = 0,
      n = parseInt(size, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return isUnits
      ? n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + this.UNITS[l]
      : n.toFixed(n < 10 && l > 0 ? 1 : 0);
  }

  transform(value: any, args?: any): string {
    return this.formatFileSizePipe(value, args);
  }
}
