import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from './avatar/avatar.component';
import { UtilePipeModule } from 'src/app/shared/pipes';
import { ShareModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [
    AvatarComponent
  ],
  imports: [
    CommonModule,
    UtilePipeModule,
    ShareModule,
  ],
  exports: [
    AvatarComponent
  ]
})
export class AvatarModule { }
