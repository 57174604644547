import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstant } from '../utilities/app.constant';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ChatReportService extends BaseService {

  constructor(httpClient: HttpClient) {
    super(httpClient)
  }

  sendReportIssue(payload: FormData): Observable<any> {
    const uri = 'live-support/support-cases';
    return this.post<any, any>(uri, payload);
  }
}