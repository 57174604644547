import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { NotificationEventResponse } from '../models/notification_event.model';

// Constant Value

@Injectable({
  providedIn: 'root',
})
export class NotificationEventService extends BaseService {
  private unreadCountSource: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public unreadCountChanged$ = this.unreadCountSource.asObservable();
  public newEvent$: Subject<any> = new Subject<number>();

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getEvents(type: string, skip: number, limit: number): Observable<NotificationEventResponse> {
    const uri = `notification_events`;
    let types: string[] = [] 
    switch (type) {
      case 'alert':
        types = ['alert', 'mention_chat']
        break;

        case 'help_center':
        types = ['help_center', 'new_video', 'mention_video', 'tutorial']
        break;
    
      default:
        types = [type]
        break;
    }
    return this.get<NotificationEventResponse>(uri, {
      params: {
        type: types,
        skip,
        limit
      },
    });
  }

  public countUnreadEvents(): Observable<any> {
    const uri = 'notification_events/metrics/total-unread';
    return this.get<any>(uri);
  }

  public markEventAsRead(eventId: string): Observable<any[]> {
    const uri = `notification_events/${eventId}/read`;
    return this.put<any, any>(uri);
  }

  public markEventAsDeleted(eventId: string): Observable<any> {
    const uri = `notification_events/${eventId}`;
    return this.delete<any>(uri);
  }

  public deleteAllEvents(type: string): Observable<any> {
    const uri = `notification_events/all`;
    let types: string[] = [] 
    switch (type) {
      case 'alert':
        types = ['alert', 'mention_chat']
        break;

        case 'help_center':
        types = ['help_center', 'new_video', 'mention_video', 'tutorial']
        break;
    
      default:
        types = [type]
        break;
    }
    return this.delete<any>(uri, {
      params: {
        type: types
      },
    });
  }

  public decreaseUnreadCount(quantity: number = 1, event: any = null) {
    this.unreadCountSource.next(-quantity);

    if (event) {
      this.newEvent$.next(event)
    }
  }
}
