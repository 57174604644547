import { isSameDay, isToday, isBefore } from 'date-fns';
import { AppConstant } from './app.constant';

type ExtendedTimeSeriesData<T> = T & AppConstant.TimeSeriesData;
export function appendTimeSeriesFields<T extends { [key: string]: any }>(messages: T[], dateFieldName: keyof T, today: Date = new Date()): ExtendedTimeSeriesData<T>[] {
  return messages.map((message, index) => {
    if (message.isPast || message.isToday) return message;
    
    const messageDate = new Date(message[dateFieldName]);    
    const extendedMessage: ExtendedTimeSeriesData<T> = {
      ...message,
      isToday: isToday(messageDate),
      isPast: isBefore(messageDate, today),
    };

    if (extendedMessage.isToday) {
      const nextMessage = messages[index + 1];
      const nextMessageDate = nextMessage ? new Date(nextMessage[dateFieldName]) : null;
      extendedMessage.isTodayLastItem = !nextMessageDate || !isSameDay(messageDate, nextMessageDate);
    }

    if (extendedMessage.isPast) {
      const prevMessage = messages[index - 1];
      const prevMessageDate = prevMessage ? new Date(prevMessage[dateFieldName]) : null;
      extendedMessage.isPastFirstItem = !prevMessageDate || !isSameDay(messageDate, prevMessageDate);
    }

    // console.log(`time: ${message[dateFieldName]}, isToday: ${extendedMessage.isToday}, isPast: ${extendedMessage.isPast}, isTodayLastItem: ${extendedMessage.isTodayLastItem}, isPastFirstItem: ${extendedMessage.isPastFirstItem}`);
    return extendedMessage;
  });
}