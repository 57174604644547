import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirmation-overlay',
  templateUrl: './confirmation-overlay.component.html',
  styleUrls: ['./confirmation-overlay.component.scss']
})
export class ConfirmationOverlayComponent {

  @Input() confirmMessage: string;
  @Input() primaryText: string;
  @Input() secondaryText: string;
  @Output() confirm = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  isVisible = false;

  show() {
    this.isVisible = true;
  }

  hide() {
    this.isVisible = false;
  }

  confirmClose() {
    this.confirm.emit();
    this.hide();
  }

  cancelClose() {
    this.cancel.emit();
    this.hide();
  }
}