import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  @Input() isLoading: boolean = false;
  @Input() isIconShow: boolean = true;
  @Input() singleCenter: boolean = false; // <- use this only then loading single side like Project List
  @Input() size: string = '75px';
}
