  
<ng-container *ngIf="!(isChatWindowVisible$ | async)">
  <div
    class="chat-bubble"
    (click)="toggleChatWindow()" 
    *ngIf="totalUnreadMsg > 0; else notBadge"
    pBadge
    severity="danger"
    [value]="totalUnreadMsg > 99 ? '99+' : totalUnreadMsg.toString()"
  >
    <svg-icon class="icon-info" key="liveSupport" size="lg"></svg-icon>
    <span class="tooltip-text top">Live support</span>
  </div>

  <ng-template #notBadge>
    <div class="chat-bubble" (click)="toggleChatWindow()" >
      <svg-icon class="icon-info" key="liveSupport" size="lg"></svg-icon>
      <span class="tooltip-text top">Live support</span>
    </div>
  </ng-template>
</ng-container>

<!-- [ngClass]="{ 'd-none': !(isChatWindowVisible$ | async)}" -->
<app-chat-window
  [ngClass]="{'d-none': !(isChatWindowVisible$ | async)}"
  [totalUnreadMsg]="totalUnreadMsg"
  [isShow]="!!(isChatWindowVisible$ | async)"
  (onClose)="onClearEvent($event)"
  (emitTotalUnread)="setTotalUnread($event)"
></app-chat-window>