import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-custom-expand-dialog',
  templateUrl: './custom-expand-dialog.component.html',
  styleUrls: ['./custom-expand-dialog.component.scss'],
})
export class CustomExpandDialogComponent implements OnInit {
  constructor(private _router: Router) {}

  ngOnInit() {}

  isDialogOpen = false;

  toggleDialog() {
    this.isDialogOpen = !this.isDialogOpen;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;

    // Check if the click is outside the component
    if (!targetElement.closest('.custom-dialog')) {
      this.isDialogOpen = false;
    }
  }

  redirectTo(destination: string) {
    this._router.navigate([destination]);
  }
}
