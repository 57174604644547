import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';

@Component({
  template: '',
})
export abstract class BaseComponent implements OnDestroy, OnInit {
  protected destroy$ = new Subject<void>();

  constructor() {}
  abstract onInit(): void;
  abstract onDestroy(): void;

  ngOnInit(): void {
    this.onInit();
  }

  ngOnDestroy(): void {
    const me = this;
    me.destroy$.next();
    me.destroy$.complete();
    me.onDestroy();
  }
}
