<div class="export-form" [formGroup]="formGroupExport">
  <div class="export-grid">
    <div>
      <div class="option-export mb-3">

        <div class="export-label mb-1">
          <div class="d-flex gap-2">
            <div>
              {{ METADATA.TIME_RANGE.LABEL }}
            </div>
            <div>
              <svg-icon
                [pTooltip]="selectableDate"
                tooltipPosition="right"
                class="icon-info custom-svg-icon-down"
                key="contact"
                size="sm"
              ></svg-icon>
            </div>
          </div>
        </div>

        <p-calendar
          styleClass="custom-p-calendar time-range-calendar export"
          selectionMode="range"
          dateFormat="dd-M-yy"
          rangeSeparator="to"
          placeholder="Enter value..."
          icon="pi pi-calendar"
          [showIcon]="true"
          [showClear]="true"
          [readonlyInput]="true"
          [minDate]="minDateValue"
          [maxDate]="maxDateValue"
          [defaultDate]="defaultDateValue"
          formControlName="timeRange"
          [selectOtherMonths]="true"
        ></p-calendar>
      </div>
      <div class="option-export mb-3">
        <div class="export-label mb-1">
          <p>
            {{ METADATA.ALERT_TYPE.LABEL }}
          </p>
          <p class="counter">({{ alertTypeCount || 0 }} selected)</p>
        </div>
        <p-multiSelect
          optionLabel="name"
          optionValue="id"
          [options]="alertTypeOptions"
          class="custom-p-multiselect"
          [showClear]="true"
          [showHeader]="false"
          [placeholder]="METADATA.OPTION.PLACE_HOLDER"
          formControlName="alertType"
          >
        </p-multiSelect>
      </div>

      <div class="option-export mb-3">
        <div class="export-label mb-1">
          <p>
            {{ METADATA.STATUS.LABEL }}
          </p>
          <p class="counter">({{ statusCount || 0 }} selected)</p>
        </div>
        <p-multiSelect
          optionLabel="statusName"
          optionValue="stateCode"
          [options]="statusesOption"
          [placeholder]="METADATA.OPTION.PLACE_HOLDER"
          class="custom-p-multiselect"
          [style]="{ width: '100%' }"
          [showClear]="true"
          [showHeader]="false"
          [resetFilterOnHide]="true"
          formControlName="status"
          >
        </p-multiSelect>
      </div>

      <div class="option-export mb-3">
        <div class="export-label mb-1">
          <p>
            {{ METADATA.EVENT.LABEL }}
          </p>
          <p class="counter">({{ eventCount || 0 }} selected)</p>
        </div>
        <p-treeSelect
          #treeEvent
          [options]="eventsOption"
          [placeholder]="METADATA.OPTION.PLACE_HOLDER"
          selectionMode="checkbox"
          optionLabel="eventName"
          optionValue="event_id"
          [showClear]="true"
          scrollHeight="182px"
          [propagateSelectionDown]="true"
          class="any-level custom-multiple-level"
          formControlName="event"
          >
          <ng-template pTemplate="value">
            {{ selectedEventString }}
          </ng-template>
        </p-treeSelect>
      </div>

      <div class="option-export mb-3">
        <div class="export-label mb-1">
          <p>
            {{ METADATA.DISCIPLINE.LABEL }}
          </p>
          <p class="counter">({{ disciplineCount || 0 }} selected)</p>
        </div>
        <p-multiSelect
          optionLabel="disciplineName"
          [options]="disciplineOptions"
          [placeholder]="METADATA.OPTION.PLACE_HOLDER"
          class="custom-p-multiselect"
          [style]="{ width: '100%' }"
          [showClear]="true"
          [showHeader]="false"
          [resetFilterOnHide]="true"
          formControlName="discipline"
        >
        </p-multiSelect>
      </div>

      <div class="option-export mb-3">
        <div class="export-label mb-1">
          <p>
            {{ METADATA.WELLBORE.LABEL }}
          </p>
          <p class="counter">({{ wellboreCount || 0 }} selected)</p>
        </div>
        <p-multiSelect
        optionLabel="name"
        [options]="wellboreOptions"
        [placeholder]="METADATA.OPTION.PLACE_HOLDER"
        class="custom-p-multiselect"
        [style]="{ width: '100%' }"
        [showClear]="true"
        [showHeader]="false"
        [resetFilterOnHide]="true"
        formControlName="wellbore"
        >
      </p-multiSelect>
      </div>

      <div class="option-export mb-3">
        <div class="export-label mb-1">
          <p>
            {{ METADATA.INTERVAL.LABEL }}
          </p>
          <p class="counter">({{ intervalCount || 0 }} selected)</p>
        </div>
        <p-multiSelect
          optionLabel="name"
          [options]="intervalOptions"
          [placeholder]="METADATA.OPTION.PLACE_HOLDER"
          class="custom-p-multiselect"
          [style]="{ width: '100%' }"
          [showClear]="true"
          [showHeader]="false"
          [resetFilterOnHide]="true"
          formControlName="interval"
        >
        </p-multiSelect>
      </div>

      <div class="option-export mb-3">
        <div class="export-label mb-1">
          <p>
            {{ METADATA.RUN.LABEL }}
          </p>
          <p class="counter">({{ runCount || 0 }} selected)</p>
        </div>
        <p-multiSelect
          optionLabel="name"
          [options]="runOptions"
          [placeholder]="METADATA.OPTION.PLACE_HOLDER"
          class="custom-p-multiselect"
          [style]="{ width: '100%' }"
          [showClear]="true"
          [showHeader]="false"
          [resetFilterOnHide]="true"
          formControlName="run"
        >
        </p-multiSelect>
      </div>
    </div>
    <div>
      <div class="option-export mb-3">
        <div class="export-label mb-1">
          <div class="d-flex gap-2">
            <div>
              {{ METADATA.DATA_FIELDS.LABEL }}
            </div>
            <div (click)="clearDataFields()">
              <svg-icon class="custom-svg-icon hover" key="reset" size="md"></svg-icon>
            </div>
          </div>
          <p class="counter">({{ dataFieldsCount || 0 }} selected)</p>
        </div>
        <div class="export-column export-scroll" [class.error-border]="formGroupExport.get('dataFields').invalid && formGroupExport.get('dataFields').touched"  formGroupName="dataFields">
          <div class="export-column-item" [ngClass]="{'active': formGroupExport.get('dataFields.' + field.id).value}" *ngFor="let field of dataFields; let i = index">
            <p-checkbox
              name="export-column-group"
              class="custom-p-checkbox bold"
              [binary]="true"
              [inputId]="field.id"
              [formControlName]="field.id"
            ></p-checkbox>
            <label [for]="field.id" class="export-label-value ms-2">{{ field.name }}</label>
          </div>
        </div>
        <div *ngIf="formGroupExport.get('dataFields').invalid && formGroupExport.get('dataFields').touched" class="inner-msg-error">
          <span>
            At least one Data Fields must be selected
          </span>
        </div>
      </div>
      <div class="option-export mb-3">
        <div class="export-label mb-1">
          <p>
            {{ METADATA.FORMAT.LABEL }}
          </p>
        </div>
        <p-dropdown
          optionLabel="name"
          [options]="formatOptions"
          class="custom-p-drop"
          formControlName="format">
        </p-dropdown>
      </div>

    </div>
  </div>  
</div>


<div class="d-flex justify-content-end mt-4 gap-2">
    <p-button
      [attr.data-cy]="'cancel-button'"
      type="button"
      label="Cancel"
      class="custom-p-button custom-p-button--secondary"
      (onClick)="onCancel()"
    ></p-button>
    <p-button
      [attr.data-cy]="'export-button'"
      type="button"
      label="Export"
      class="custom-p-button custom-p-button--primary"
      (onClick)="onExport()"
      [ngClass]="{ 'custom-p-button--disable' : !this.dataFieldsCount}"
      [disabled]="!this.dataFieldsCount"
    ></p-button>
</div>

<!-- Loading -->
<app-loading [isLoading]="(isLoading$ | async)!"></app-loading>