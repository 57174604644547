import { BehaviorSubject, interval, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { IMessage } from '../interface/common';
import { chain } from 'lodash';

const DEFAULT_MESSAGE: IMessage = {
  type: '',
  header: '',
  content: '',
  time: ''
};

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  // Setting status of loading component When have any request is created
  private messageSubject: BehaviorSubject<IMessage> = new BehaviorSubject<IMessage>(DEFAULT_MESSAGE);
  public messageSubjectAction$ = this.messageSubject.asObservable();

  private messageSubjectClone: IMessage[] = [];

  constructor() {
    interval(1000).pipe(
      map(() => {
        let listMessage = this.messageSubjectClone.splice(0);
        chain(listMessage).uniqBy('content').value().forEach((mess: any) => {
          this.messageSubject.next(mess)
        })
      })
    ).subscribe(() => {})
  };

  public setMessage(msgPack: IMessage) {
    msgPack.time = this.formatTime(new Date());
    this.messageSubjectClone.push(msgPack);
    // this.messageSubject.next(msgPack);
  }

  // Split function to avoid Unit Test Dependencies
  public formatTime(date: Date) {
    const hours = String(date.getHours()).padStart(2, '0'); // Ensure two digits for hours
    const minutes = String(date.getMinutes()).padStart(2, '0'); // Ensure two digits for minutes
    return `Today, ${hours}:${minutes}`;
  }
}
