<div *ngIf="showButton" [attr.data-cy]="label | hyphenCase" [pTooltip]="isToolTip ? toolTipContent : ''" [tooltipPosition]="tooltipPosition" class="button-wrapper"> 
  <p-button
    [label]="label"
    [icon]="icon"
    [disabled]="isDisabled"
    [ngClass]="style"
    class="default-button"
    (onClick)="onClick()"
  ></p-button>
</div>
