export const asMentionVideo = {
  data: `<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_14090_120026)">
  <path d="M8.6665 8.66634V13.9997H1.99984V8.66634H8.6665ZM9.33317 7.33301H1.33317C0.966504 7.33301 0.666504 7.63301 0.666504 7.99967V14.6663C0.666504 15.033 0.966504 15.333 1.33317 15.333H9.33317C9.69984 15.333 9.99984 15.033 9.99984 14.6663V12.333L12.6665 14.9997V7.66634L9.99984 10.333V7.99967C9.99984 7.63301 9.69984 7.33301 9.33317 7.33301Z"/>
  <g clip-path="url(#clip1_14090_120026)">
  <path d="M8.67016 1.33366C8.67016 0.966992 8.96683 0.666992 9.3335 0.666992H14.6668C15.0335 0.666992 15.3335 0.966992 15.3335 1.33366V5.33366C15.3335 5.70033 15.0335 6.00033 14.6668 6.00033H10.0002L8.66683 7.33366L8.67016 1.33366ZM9.3335 1.33366V5.72366L9.7235 5.33366H14.6668V1.33366H9.3335ZM14.0002 4.00033H10.0002V4.66699H14.0002V4.00033ZM14.0002 3.00033H10.0002V3.66699H14.0002V3.00033ZM14.0002 2.00033H10.0002V2.66699H14.0002V2.00033Z"/>
  </g>
  </g>
  <defs>
  <clipPath id="clip0_14090_120026">
  <rect width="16" height="16"/>
  </clipPath>
  <clipPath id="clip1_14090_120026">
  <rect width="8" height="8" transform="matrix(-1 0 0 1 16 0)"/>
  </clipPath>
  </defs>
  </svg>
  
`,
  name: 'mentionvideo',
};
