import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription, finalize } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserInfoService } from '../../services/user-info.service';
import { AppConstant } from '../../utilities/app.constant';
import { KeyValueObject } from './notification-setting.interface';

@Component({
  selector: 'app-notification--setting',
  templateUrl: './notification-setting.component.html',
  styleUrls: ['./notification-setting.component.scss'],
})
export class NotificationSettingComponent implements OnInit, OnDestroy {
  
  constructor(
    private userInfoService: UserInfoService,
    private notificationService: NotificationService,
    private dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig
  ) {}

  private notiSettingSubscription: Subscription = new Subscription();
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  settings: KeyValueObject[] = [
    {
      key: 'notiTeamsChat',
      name: 'Mentions you in a team chat',
      value: true,
    },
    {
      key: 'notiVideo',
      name: 'Mentions you in a video',
      value: true,
    },
    {
      key: 'notiAlert',
      name: 'Sends an alert',
      value: true,
    }
  ];

  settingsInit: KeyValueObject[] = [
    {
      key: 'notiTeamsChat',
      name: 'Mentions you in a team chat',
      value: false,
    },
    {
      key: 'notiVideo',
      name: 'Mentions you in a video',
      value: false,
    },
    {
      key: 'notiAlert',
      name: 'Sends an alert',
      value: false,
    }
  ]

  settingsDefault: KeyValueObject[] = [
    {
      key: 'notiTeamsChat',
      name: 'Mentions you in a team chat',
      value: false,
    },
    {
      key: 'notiVideo',
      name: 'Mentions you in a video',
      value: false,
    },
    {
      key: 'notiAlert',
      name: 'Sends an alert',
      value: false,
    }
  ]

  isViewer: boolean = false;
  isChange: boolean = false;

  ngOnInit(): void {
    this.isLoading$.next(true);
    if (this.dialogConfig.data.userId) {
      // console.log('ID Passing: ', this.dialogConfig.data.userId);
      
      this.loadInitiaData(this.dialogConfig.data.userId);
    } else {
      this.notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Information',
        content: 'Can not find user id',
      });
    }
  }

  loadInitiaData(loginID: any): void {
    this.isLoading$.next(true);
    this.notiSettingSubscription = this.userInfoService
      .getUserInfoInitiate(loginID)
      .pipe(finalize(() => {
        this.settingsInit = JSON.parse(JSON.stringify(this.settings));
      }))
      .subscribe({
        next: (userInfo: any) => {
          // Update Role Variable
          this.isViewer = userInfo.role === AppConstant.ROLES.VIEWER.label;

          if (!userInfo.userSetting) {
            this.settings = JSON.parse(JSON.stringify(this.settingsInit));
            this.isLoading$.next(false);
            // console.log(this.settings);
            return;
          }

          // console.log(userInfo.userSetting);

          this.buildTemplate(userInfo.userSetting);
        },
        error: (err: any) => {
          this.notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Information',
            content: err?.error,
          });
          this.isLoading$.next(false);
        },
      });
  }

  buildTemplate(dataSetting: any): void {
    this.settings.forEach(setting => {
      if (setting.key in dataSetting) {
        setting.value = dataSetting[setting.key];
      }
    });
    // console.log('Data Template: ', this.settings);
    // Stable UI then Turn Off Loading
    this.isLoading$.next(false)
  }

  onToggle(value: boolean, index: number): void {
    this.settings[index].value = value;

    this.isChange = this.checkChange(this.settings, this.settingsInit);
  }
  
  checkChange(settings: KeyValueObject[], settingsInit: KeyValueObject[]): boolean {
    const result = this.compareSetting(settings, settingsInit);
    return result;
  }

  compareSetting(arrA: KeyValueObject[], arrB: KeyValueObject[]): boolean {
    const mapB = new Map(arrB.map(item => [item.key, item.value]));
    for (const objA of arrA) {
      if (mapB.get(objA.key) !== objA.value) {
        return true;
      }
    }
    return false;
  }

  onReset(): void {
    // console.log('Reset');
    this.settings = JSON.parse(JSON.stringify(this.settingsDefault));
    this.isChange = this.checkChange(this.settings, this.settingsInit);
    // console.log('Data Reset: ', this.settings);
  }

  onSave(): void {
    // console.log('Save');
    // console.log(this.settings);
    let payload: any = this.buildPayLoad();
    // console.log('Data Build Payload: ', payload);

    this.isLoading$.next(true);
    this.userInfoService
      .updateSetting(payload)
      .pipe(finalize(() => this.isLoading$.next(false)))
      .subscribe({
        next: (response: any) => {
          const { data, message } = response;
          // console.log('Data Ressponse: ', data.userSetting);

          // if (data.userSetting)  this.buildTemplate(data.userSetting)
          this.onCancel();

          this.notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.SUCCESS,
            header: 'User Preferences',
            content: `Manage Notifications was updated successfully.`,
          });
        },
        error: (err: any) => {
          this.notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Information',
            content: err?.error,
          });
        },
      });
  }

  buildPayLoad(): any {
    // console.log('Before Build Payload: ', this.settings);
    return this.settings.reduce((acc: any, curr: any) => {
      const newKey = `is${curr.key.charAt(0).toUpperCase()}${curr.key.slice(1)}`;
      acc[newKey] = curr.value;
      return acc;
    }, {});
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.isLoading$.next(false);
    this.notiSettingSubscription.unsubscribe();
  }
}