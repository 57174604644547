import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortFileName',
})
export class StringShortFileNamePipe implements PipeTransform {
  /**
   * Transforms the input file name by shortening it to the specified maximum length.
   * If the file name exceeds the maximum length, it will be truncated and appended with '...' along with the file extension.
   *
   * @param value - The input file name to be transformed.
   * @param maxLength - The maximum length of the file name.
   * @returns The transformed file name, shortened to the specified maximum length.
   */

  transform(value: string, maxLength: number): string {
    const fileName = value.slice(0, value.lastIndexOf('.'));
    const fileExtension = value.slice(value.lastIndexOf('.'));
    if (fileName.length > maxLength && fileExtension.length > 0) {
      return fileName.slice(0, maxLength) + '...' + fileExtension;
    } else {
      return value;
    }
  }
}
