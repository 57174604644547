import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { find } from 'lodash';
import { FileUpload } from 'primeng/fileupload';
import { BehaviorSubject, filter, interval, Subject, Subscription, switchMap, tap } from 'rxjs';
import { IMessage } from 'src/app/shared/interface/common';
import { ChatService } from 'src/app/shared/services/chat.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { WebSocketService, WS_MESSAGE_TYPE } from 'src/app/shared/services/web-socket.service';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { transformMessage } from 'src/app/shared/utilities/data.transformer';
import { appendTimeSeriesFields } from 'src/app/shared/utilities/date-utils';

@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.scss']
})
export class ChatBoxComponent implements OnInit, OnDestroy {
  listMessages: AppConstant.Message[] = [];
  messageInput: string = '';
  showError: boolean = false;
  defaultPageSize: number = 30;
  roleList = AppConstant.ROLES;
  showHasNewMessage: boolean = false;

  isTyping: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isTyping$ = this.isTyping.asObservable();

  isScrollingUp: boolean = false;
  isShowConfirm: boolean = true; // default is should is false, pls adding logic to show this confirm box

  currentSupportCaseId: number = -1;
  isAnotherTyping: boolean = false;
  anotherTypingData: any;
  isSelfTyping: boolean = false;
  typingSubject = new Subject<boolean>();
  typingSubcription: Subscription;

  @ViewChild('fileUploader') fileUploader: FileUpload;
  
  @Input()
  currentUser: any;

  @Output()
  emitTotalUnread: EventEmitter<number> = new EventEmitter<number>();

  isLoadingMessage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @ViewChild('infiniteScroll') infiniteScroll?: ElementRef;

  private onReceiveNewMessageBound: Function;
  private onReceiveFlagIsTypingBound: Function;

  constructor(
    private chatService: ChatService,
    private _notificationService: NotificationService,
    private _io: WebSocketService,
  ) {
    this.onReceiveNewMessageBound = this.onReceiveNewMessage.bind(this);
    this.onReceiveFlagIsTypingBound = this.onReceiveFlagIsTyping.bind(this);
  }

  ngOnInit() {
    this._io.registerMessageHanlder(WS_MESSAGE_TYPE.USER__NEW_MESSAGE, this.onReceiveNewMessageBound);
    this._io.registerMessageHanlder(WS_MESSAGE_TYPE.LIVE_SUPPORT_AGENT__TYPING, this.onReceiveFlagIsTypingBound);

    this.loadMessages();
    this.getLastedSupportCase();

    this.typingSubcription = this.typingSubject.pipe(
      switchMap((flag: any) => 
         interval(AppConstant.DELAY_TIME_SEND_STATUS_TYPING).pipe(filter(() => flag))
      )
    ).subscribe(() => {
      this.sendEventTypingStatus(this.isSelfTyping);
    })
  }

  ngOnDestroy(): void {
    this._io.unregisterMessageHanlder(WS_MESSAGE_TYPE.USER__NEW_MESSAGE, this.onReceiveNewMessageBound);
    this._io.unregisterMessageHanlder(WS_MESSAGE_TYPE.LIVE_SUPPORT_AGENT__TYPING, this.onReceiveFlagIsTypingBound);
    
    if (this.isSelfTyping) {
      this.sendEventTypingStatus(false);
    }
    this.isSelfTyping = false;
    this.typingSubject.next(false);
    this.typingSubcription.unsubscribe();
  }

  onReceiveNewMessage(message: AppConstant.Message) {
    this.appendMessage(message);
    this.readNewMessage(message);
    this.scrollToBottomIfNotScrollingUp();
  }

  onReceiveFlagIsTyping(data: any) {
    if (data.toUser == this.currentUser.id)
      this.setIsAnotherTyping(data);
  }

  setIsAnotherTyping(data: any) {
    this.isAnotherTyping = !!data?.status;
    this.anotherTypingData = data.user;
  }

  getLastedSupportCase() {
    this.chatService.getCurrentSupportCase()
      .subscribe((supportCase: any) => {
        if (supportCase) {
          this.setCurrentSupportCaseId(supportCase.id);
        }
      })
  }
  
  loadMessages() {
    this.setLoadingMessageStatus(true);
    this.chatService.getMyMessages(this.defaultPageSize, this.listMessages.length).pipe(
      tap(() => this.readAllMsg()),
    ).subscribe((data: any) => {
      this.listMessages = [...this.listMessages, ...(data.items || []).map((item: any) => transformMessage(item))];
      this.addTimeSeriesFields();
      this.setLoadingMessageStatus(false)
    });
  }
  
  readAllMsg() {
    this.chatService.readAllLiveMessages().subscribe(() => {
      this.emitTotalUnread.emit(0);
    })
  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event: any) {        
    if (!this.infiniteScroll) return;

    // check is scrolling up
    this.isScrollingUp = Math.abs(this.infiniteScroll.nativeElement.scrollTop) > 150;   
    
    if (Math.abs(this.infiniteScroll.nativeElement.scrollTop) < 10) {
      this.showHasNewMessage = false;
    };
      
    if (this.infiniteScroll!.nativeElement.scrollHeight <=
      this.infiniteScroll!.nativeElement.offsetHeight +
        Math.abs(this.infiniteScroll!.nativeElement.scrollTop) +
        1) {
      this.onScrollUp();
    }
  }

  onScrollUp() {
    if (this.isLoadingMessage$.getValue()) {
      return;
    }
    this.loadMessages();
  }

  scrollToBottomIfNotScrollingUp() {
    if (this.isScrollingUp) return;
    this.scrollToBottom();
  }

  scrollToBottom() {    
    if (!this.infiniteScroll) return;
    setTimeout(() => {
      if (this.infiniteScroll) {
        this.infiniteScroll.nativeElement.scrollTop = this.infiniteScroll.nativeElement.scrollHeight;
        this.showHasNewMessage = false;
      }
    }, 100);
  }

  onSend() {
    if (!this.messageInput || this.messageInput.trim() === '') {
      this.showError = true;
      this.messageInput = ''; 
      this.isSelfTyping = false;
      this.sendEventTypingStatus(this.isSelfTyping);
      return;
    }
    this.showError = false;
    this.sendMessageAPI({
      content: this.messageInput,
      type: AppConstant.MessageTypes.TEXT,
      senderType: AppConstant.SenderTypes.USER,
    });
    this.messageInput = '';
    this.isSelfTyping = false;
    this.typingSubject.next(this.isSelfTyping);
    this.sendEventTypingStatus(this.isSelfTyping);
  }

  sendMessageAPI(payload: Partial<AppConstant.Message>) {
    this.chatService.sendMessage(payload).subscribe((data: AppConstant.Message) => {
      this.removeLoadingMsg();

      if (this.currentSupportCaseId != data.supportCaseId)
        this.setCurrentSupportCaseId(data.supportCaseId);

      this.appendMessage(data);
      this.scrollToBottom();
    });
  }

  createLoadingMsg() {    
    this.listMessages.splice(0, 0, {
      id: -1,
      supportCaseId: -1,
      type: AppConstant.MessageTypes.TEXT,
      senderType: AppConstant.SenderTypes.USER,
      content: '',
      userId: this.currentUser.id,
      chatId: '',
      userName: this.currentUser.displayName,
      avatar: '',
      title: '',
      timestamp: new Date(),
      attachments: [],   
      isInterationDisable: false,
    });
    this.scrollToBottom();
  }

  removeLoadingMsg() {
    const idx = this.listMessages.findIndex((m: AppConstant.Message) => m.id == -1);
    if (idx >= 0) 
      this.listMessages.splice(idx, 1);
  }

  appendMessage(message: AppConstant.Message) {
    if (find(this.listMessages, e => e?.id === message?.id)) return;   

    this.listMessages.unshift(transformMessage(message));
    this.addTimeSeriesFields();
    console.log('debug', this.listMessages);

    if (this.isScrollingUp 
      && (message.senderType == AppConstant.SenderTypes.LIVE_AGENT || message.senderType == AppConstant.SenderTypes.BOT)) 
    {
      this.showHasNewMessage = true;
    }
  }

  addTimeSeriesFields() {
    this.listMessages = appendTimeSeriesFields(this.listMessages, "timestamp");
  }

  readNewMessage(message: AppConstant.Message) {
    this.chatService.readLiveMessage(message.id).subscribe(() => {});
  }

  onInputFocus() {
    this.showError = false;
  }

  onKeyPress(event: any) {
    if (event.key === 'Enter' && !event.shiftKey) {
      this.onSend();
      event.preventDefault();
    }
  }

  onChange(event: any) {
    if (event?.target?.value && !this.isSelfTyping) {
      this.isSelfTyping= true; 
      this.sendEventTypingStatus(this.isSelfTyping);
    } else if (event?.target?.value && this.isSelfTyping) {
      this.typingSubject.next(this.isSelfTyping);
    } else if (!event?.target?.value && this.isSelfTyping) {
      this.isSelfTyping= false;
      this.typingSubject.next(this.isSelfTyping);
      this.sendEventTypingStatus(this.isSelfTyping);
    }
  }

  onClickNewMessages() {
    this.scrollToBottom();
  }

  selectFile() {
    this.fileUploader.choose();
  }
  onSelect(event: any) {
    console.log(...event.currentFiles);
    const filesList = event.currentFiles;
    const MAXIMUN_NUMBER_FILE_UPLOAD = 5;
    const MAXIMIN_TOTAL_FILES_SIZE = 10485760; // 10MB = 10485760bytes
    const listErr: IMessage[] = [];

    // Calculate total file size is uploaded
    let currentTotalFileSize = 0;

    for (let i = 0; i < filesList.length; i++) {
      let file = filesList[i];

      // Verify file type
      const mimeTypeAllow = [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'application/pdf',
      ];
      if (!mimeTypeAllow.includes(file.type.toLowerCase())) {
        listErr.push({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: `Invalid File Type`,
          content:
            'Invalid file type. Allowed file types: .pdf,.jpg, .jpeg, .png',
        });
        // Remove Invalid file type from list, decrease i, continue process.
        filesList.splice(i--, 1);
        continue;
      }

      // Verify file name
      let formatSpecialChar = /[!()\[\]{}\\|<>\/?'"]+/;
      if (
        formatSpecialChar.test(
          AppHelper.StringFunctions.extractFileName(file.name)
        )
      ) {
        listErr.push({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Detected: Invalid file name',
          content:
            "A file name can't contain any of the following characters: ! ' \" { } [ ] ( ) \\ / : ? < > | ",
        });
        filesList.splice(i--, 1);
        continue;
      }

      // Verify file size 0 byte
      if (file.size == 0) {
        listErr.push({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Invalid file size',
          content: 'Empty file cannot be uploaded.',
        });
        // Remove Invalid file size from list, decrease i, continue process.
        filesList.splice(i--, 1);
        continue;
      }

      // Verify file size 10000000
      if (file.size > MAXIMIN_TOTAL_FILES_SIZE!) {
        listErr.push({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Invalid file size',
          content: 'Allowed maximum file size: 10MB',
        });
        // Remove Invalid file size from list, decrease i, continue process.
        filesList.splice(i--, 1);
        continue;
      }

      if (currentTotalFileSize + file.size > MAXIMIN_TOTAL_FILES_SIZE) {
        listErr.push({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Invalid total file size',
          content:
            'File make exceeded total allowed file size. Should be removed',
        });

        filesList.splice(i--, 1);
        continue;
      } else {
        currentTotalFileSize += file.size;
      }

    }

    // Verify quantity of file
    const numberFileRemoved = filesList.length - MAXIMUN_NUMBER_FILE_UPLOAD;
    if (numberFileRemoved > 0) {

      listErr.push({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: `Exceeded allowed number of files`,
        content: `Maximum ${MAXIMUN_NUMBER_FILE_UPLOAD} files are allowed`,
      });
      filesList.splice(MAXIMUN_NUMBER_FILE_UPLOAD, numberFileRemoved);
    }

    // notification error
    for (let i = 0; i < listErr.length; i++) {
      this._notificationService.setMessage(listErr[i]);
    }

    console.log("RESOLVE " , filesList);

    if (filesList?.length) {
      this.createLoadingMsg();
      this.sendMessageAPI({
        content: '',
        type: AppConstant.MessageTypes.ATTACHMENT,
        senderType: AppConstant.SenderTypes.USER,
        attachments: filesList
      });
    }
    
    this.fileUploader.clear();
  }

  handleMainButtonClick() {
    console.log('Main button clicked in parent');
    this.isShowConfirm = false;
  }

  handleSubButtonClick() {
    console.log('Sub button clicked in parent');
    this.isShowConfirm = false;
  }

  setCurrentSupportCaseId(newId: number) {
    this.sendEventTypingStatus(false);
    this.currentSupportCaseId = newId;
  }

  sendEventTypingStatus(status: boolean) {
    if (this.currentSupportCaseId < 0) return;

    const data = {
      type: WS_MESSAGE_TYPE.USER__TYPING,
      data: {
        supportCaseId: this.currentSupportCaseId,
        status: status,
        user: this.currentUser
      },
    };
    this._io.emit(`message`, data);
  }
  
  setLoadingMessageStatus(value: boolean) {
    this.isLoadingMessage$.next(value);
  }
}