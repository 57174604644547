import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { finalize } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RequestInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Injest some field to req header
    const clonedRequest = req.clone({ headers: req.headers.set('user-login-id', localStorage.getItem('loginUserId') || `null_${new Date().getTime()}`) });
    return next.handle(clonedRequest).pipe(
      finalize(() => {
        const profilingMsg = `${req.method} -  "${req.urlWithParams}"`;
      })
    );
  }
}
