import { Directive, ElementRef, Renderer2, OnInit, Input, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appExpandableContent]',
})
export class ExpandableContentDirective implements OnInit {
  @Input() userId: string = '';
  @Input() content: string = '';
  @Input() sizeContent: number = 100;
  @Input() sizeBreakLine: number = 3;

  nodeCloneFull: any;
  nodeCloneShort: any;

  isExpand: boolean = false;
  flag: boolean = false;

  constructor(private _el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.nodeCloneShort = this.renderer.createElement('div');
    this.nodeCloneFull = this.renderer.createElement('div');

    this.initDOM();
  }

  /** 
   * Init data
   */
  private initDOM() {
    let restCharacters: number = this.sizeContent;
    let restBreaklines: number = this.sizeBreakLine - 1;
    
    const cloneData = this.renderer.createElement('div');
    this.renderer.setProperty(cloneData, 'innerHTML', this.content);

    cloneData.childNodes?.forEach((nodeChild: any) => {
      // Clone node child seperate
      const child = nodeChild.cloneNode(nodeChild);

      if (restCharacters <= 0 || restBreaklines <= 0) {
        this.flag = true;
      };

      if (child.nodeName.toLowerCase().includes('br')) {
        // Create <br> tag
        const newNodeBr = this.renderer.createElement('br');
        restBreaklines--;
        this.renderer.appendChild(this.nodeCloneFull, newNodeBr);
        if (!this.flag) {
          this.renderer.appendChild(this.nodeCloneShort, child);
        }
      } else if (child.nodeName.includes('text')) {
        // Create text element
        if (!this.flag) {
          if (child.textContent.length <= restCharacters) {
            restCharacters -= child.textContent.length;
            this.nodeCloneShort.innerHTML += child.textContent;

          } else {
            this.nodeCloneShort.innerHTML += child.textContent.slice(0, restCharacters);
            restCharacters = 0;
            this.flag = true;
          }
        }
        this.nodeCloneFull.innerHTML += child.textContent;

      } else {
        // Create <span> tag
        const newNodeSpan = this.renderer.createElement('span');
        const className = this.userId === child.id ? 'mine' : 'another';
        this.renderer.setAttribute(child, 'class', className);
        this.renderer.setAttribute(newNodeSpan, 'class', className);

        if (!this.flag) {
          if (child.textContent.length <= restCharacters) {
            newNodeSpan.textContent += child.textContent;
            restCharacters -= child.textContent.length;
          } else {
            newNodeSpan.textContent += child.textContent.slice(0, restCharacters);
            restCharacters = 0;
            this.flag = true;
          }
      }
        this.renderer.setAttribute(child, 'contenteditable', 'false');
        this.renderer.setAttribute(newNodeSpan, 'contenteditable', 'false');

        this.renderer.appendChild(this.nodeCloneFull, child);
        this.renderer.appendChild(this.nodeCloneShort, newNodeSpan);
      }
    });

    if (this.flag) {
      this.isExpand = false;
      this.cloneForRoot(this.nodeCloneShort);
    } else {      
      this.isExpand = true;
      this.cloneForRoot(this.nodeCloneFull);
    }
  }
  
  /**
   * Change status the content function
   */
  private changeStatus(): void {
    this.isExpand = !this.isExpand;
    if (this.isExpand) {
      // if is collapse status, will be show short Content
      this.cloneForRoot(this.nodeCloneFull);
    } else {
      // if is collapse status, will be show full Content
      this.cloneForRoot(this.nodeCloneShort);
    }
  }

  private cloneForRoot(nodeClone: any) {
    // Remove all child in node Root
    while(this._el.nativeElement.hasChildNodes() ){
      this._el.nativeElement.removeChild(this._el.nativeElement.lastChild);
    }

    // Clone child for Root
    nodeClone.childNodes?.forEach((child: any) => {
      const node = child.cloneNode(child);
      this.renderer.appendChild(this._el.nativeElement, node);
    });

    this.flag && this.addElementExpand();
  }

  /**
   * Create the 'read more' or 'read less' button at tail the content.
   * @param isExpand 
   */
  private addElementExpand(): void {
    const nativeElement = this._el.nativeElement;

    const btn = this.renderer.createElement('span');
    const text = this.renderer.createText(
      this.isExpand ? ' Read less' : '... Read more'
    );
    
    this.renderer.setStyle(btn, 'cursor', 'pointer');
    this.renderer.setStyle(btn, 'color', 'var(--color-pallet-primary-05)');

    this.renderer.appendChild(btn, text);
    this.renderer.appendChild(nativeElement, btn);
    this.renderer.listen(btn, 'click', () => this.changeStatus());
  }
}
