import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-expand-img',
  templateUrl: './expand-img.component.html',
  styleUrls: ['./expand-img.component.scss']
})
export class ExpandImgComponent implements OnInit {
  
  @Input() srcBase64: string = '';

  @ViewChild('myExpand', { read: ElementRef }) myExpand: ElementRef | undefined;

  constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig) {}

  ngOnInit(): void {
    if (this.config.data.srcBase64) {
      this.srcBase64 = this.config.data.srcBase64;
    }
  }

  ngAfterViewInit() {
    if (this.myExpand) {
      // Access the native HTML DOM element
      const imageElement = this.myExpand.nativeElement as HTMLElement;
      const myImage = imageElement.querySelector('.p-image-preview-indicator') as HTMLElement;;
      // Trigger the click event
      myImage && myImage.click();
      }
  }

  onHide() {
    // Also close Dialog
    this.onCancel();
  }

  onCancel(): void {
    // Close Dialog
    this.ref.close();
  }
}
