import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ExpandService } from '../../services/expand.service';

@Component({
  selector: 'app-custom-expand-infor',
  templateUrl: './custom-expand-infor.component.html',
  styleUrls: ['./custom-expand-infor.component.scss'],
})
export class CustomExpandInforComponent implements OnInit {
  expandData: any;
  isDialogOpen = false;
  positionStyle: any;
  previousData = null

  @HostListener('document:mousedown', ['$event'])
  onClickOutside(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;

    // Check if the click is outside the component
    if (
      (!targetElement.closest('.custom-dialog') &&
      !targetElement.closest('.nominate-vote'))
    ) {
      this._expandService.setExpandData({})
    }
  }

  @HostListener('document:wheel', ['$event'])
  onWheel(event: any) {
    const targetElement = event.target as HTMLElement;    

    if (
      (!targetElement.closest('.custom-dialog') &&
      !targetElement.closest('.nominate-vote'))
    ) {
      this.isDialogOpen = false;
    }
  }

  constructor(
    private _expandService: ExpandService,
    private _cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this._expandService.expandData$.subscribe((data: any) => {
      this.expandData = data;
      this.updatePosition();
    });
  }

  updatePosition() {
    this.isDialogOpen = this.expandData.isDialogOpen;
    if (this.expandData) {
      // Trigger UI to get width of expanded element
      this._cdRef.detectChanges();

      // Get width of expanded element
      const expandElement = document.getElementById('expand-content');
      let expandElementWidth = expandElement ? expandElement.offsetWidth : 0;
      expandElementWidth = expandElementWidth >= 320 ? 320 : expandElementWidth;
      let width = expandElementWidth >= 320 ? 320 : expandElementWidth;

      // Set Position to show expand content  
      const expandTrigger = document.getElementById(
        this.expandData.triggerElementId
      );

      if (expandTrigger) {
        const rect = expandTrigger.getBoundingClientRect();
        this.positionStyle = {
          top: rect.bottom + 'px',
          left: rect.left - (expandElementWidth * 0.5) + 'px',
          maxWidth: 320 + 'px',
          height: this.expandData.nominateList.length > 2 ? '120px' : 'auto',
          overflow: 'hidden',
          overflowY: 'scroll',
        };
      }
    }
  }
}
