import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CollapseLayoutService {
  private isCollapse = new BehaviorSubject<boolean>(true);
  private toggleNotificationPanelSource = new BehaviorSubject<boolean>(false);
  private toggleTutorialPanelSource = new BehaviorSubject<boolean>(false);
  private triggerResetTutorial = new BehaviorSubject<boolean>(false); 

  public isCollapse$ = this.isCollapse.asObservable();
  public toggleNotificationPanel$ = this.toggleNotificationPanelSource.asObservable();
  public toggleTutorialPanel$ = this.toggleTutorialPanelSource.asObservable();
  public triggerResetTutorial$ = this.triggerResetTutorial.asObservable();

  constructor() {
    this.updateCollapseLayout(true);
  }

  updateCollapseLayout(nextState: boolean) {
    this.isCollapse.next(nextState);
  }

  getCollapseLayout() {
    return this.isCollapse.getValue();
  }

  toggleNotificationPanel(flag: boolean) {
    if (flag) this.toggleTutorialPanelSource.next(false);
    return this.toggleNotificationPanelSource.next(flag);
  }

  getCollapsePanelLayout() {
    return this.toggleTutorialPanelSource.getValue();
  }

  toggleTutorialPanel(flag: boolean) {
    if (flag) this.toggleNotificationPanelSource.next(false);
    return this.toggleTutorialPanelSource.next(flag);
  }

  triggerResetTutorialPanel() {
    this.triggerResetTutorial.next(true);
  }
}
