<p-dialog
  [(visible)]="displayFormRig"
  [modal]="true"
  [style]="{ width: '480px' }"
  [draggable]="false"
  [resizable]="false"
  [closeOnEscape]="false"
  maskStyleClass="maskStyleDialog"
  styleClass="style-class-dialog"
  [closable]="false"
  [baseZIndex]="0"
>
  <ng-template pTemplate="header">
    <div class="header-dialog">
      <span
        [pTooltip]="rigJournalEdit?.rig.rigName || ''"
        tooltipPosition="bottom"
      >
        Edit Rig Tile: {{ rigJournalEdit?.rig.rigName || "" }}
      </span>
    </div>
    <div class="custom-close-icon cursor-pointer" (click)="closeFormDialog()">
      <i class="pi pi-times"></i>
    </div>
  </ng-template>

  <!-- *ngIf="!!formRigGroup" -->
  <ng-template pTemplate="content">
    <div class="container edit-form" [formGroup]="formRigGroup">
      <div class="row">
        <div class="col-12 mb-2">
          <div class=" label-disabled">Assigned Project</div>
          <div>
            <div class="add-input p-inputgroup">
              <input
                type="text"
                pInputText
                class="custom-p-input"
                (focusout)="onBlurMethod('projectName', formRigGroup)"
                [formControlName]="fieldNameKeys.projectName"
              />
            </div>
          </div>
        </div>
        <div class="col-12 mb-2">
          <div class=" label-disabled">Rig</div>
          <div>
            <div class="add-input p-inputgroup p-input-icon-right">
              <i class="icon pi pi-ellipsis-v cursor-pointer d-none"></i>
              <input
                type="text"
                pInputText
                class="custom-p-input"
                placeholder="Enter Rig Name"
                (focusout)="onBlurMethod('rigName', formRigGroup)"
                [formControlName]="fieldNameKeys.rigName"
                [innerMsg]="messageErrors"
                TextInputValidate
                typeCheck="short"
              />
            </div>
          </div>
        </div>

         <!-- LANGUAGE -->
         <div class="col-12 mb-2">
          <div
          class="form-label"
          [ngClass]="
            formRigGroup.get('routingDiagram')!.disabled
              ? 'label-disabled'
              : ''
          "
          >
            <span class="form-label"> Report Language <span class="p-error"> *</span></span>
          </div>
          <div
          class="dropdown-select"
          tooltipPosition="bottom"
          >
            <p-dropdown
              [options]="languageList"
              scrollHeight="150px"
              optionLabel="name"
              styleClass="dropdown-wapper"
              placeholder="Select language"
              class="custom-p-drop"
              formControlName="language"
              >
            </p-dropdown>
          </div>
        </div>

        <div class="col-12 mb-2">
          <div
            class="d-flex justify-content-between"
            [ngClass]="
              formRigGroup.get('remoteCenter')!.disabled ? 'label-disabled' : ''
            "
          >
            <span class="form-label"> Remote Center <span class="p-error"> *</span></span>
            <span>
              ({{ counterRemoteCenter }}
              {{
                counterRemoteCenter === 0 || counterRemoteCenter === 1
                  ? "option"
                  : "options"
              }}
              selected)
            </span>
          </div>
          <div class="w-100 add-input p-inputgroup non-icon">
            <p-multiSelect
              [options]="remoteCenters"
              [formControlName]="fieldNameKeys.remoteCenter"
              [showHeader]="false"
              [showClear]="true"
              [filter]="true"
              optionLabel="remoteCenterName"
              placeholder="Select Remote Center"
              class="custom-p-multiselect"
              (onChange)="countItemSelected($event, 'remoteCenter')"
              (onClear)="clearItemSelected()"
              [innerMsg]="messageErrors"
              [resetFilterOnHide]="true"
            >
            </p-multiSelect>
            <!-- custom-d-drop custom-multiple-d-drop  -->
          </div>
        </div>

        <div class="col-12 mb-2">
          <div
            class=" d-flex justify-content-between"
            [ngClass]="
              formRigGroup.get('funtionalMailbox')!.disabled
                ? 'label-disabled'
                : ''
            "
          >
            <span class="form-label"> Functional Mailboxes</span>
            <span>
              ({{ counterMailBox }}
              {{
                counterMailBox === 0 || counterMailBox === 1
                  ? "option"
                  : "options"
              }}
              selected)
            </span>
          </div>
          <div class="w-100 add-input p-inputgroup non-icon">
            <div class="multiple-tree">
              <p-treeSelect
                #treeMailBox
                selectionMode="checkbox"
                [options]="mailBoxes"
                class="custom-p-tree"
                placeholder="Select Functional Mailboxes"
                scrollHeight="200px"
                [showClear]="true"
                (onClear)="onClearMailBox($event)"
                (onNodeSelect)="onOptionsSelectedMailBox($event)"
                (onNodeUnselect)="onOptionsUnSelectedMailBox($event)"
                (onNodeExpand)="onNodeExpand($event)"
                [formControlName]="fieldNameKeys.funtionalMailbox"
                [propagateSelectionDown]="false"
                [propagateSelectionUp]="false"
              >
              </p-treeSelect>
            </div>
          </div>
          <div
            *ngIf="
              formRigGroup.controls['funtionalMailbox'].dirty ||
              formRigGroup.controls['funtionalMailbox'].touched
            "
            class="alert"
          >
            <div
              class="inner-msg-warning"
              *ngIf="!formRigGroup.controls['funtionalMailbox'].value"
            >
              <span
                >Recommended to fill value in the functional Mailbox value</span
              >
            </div>
          </div>
        </div>

        <div class="col-12 mb-4">
          <div
            class="form-label"
            [ngClass]="
              formRigGroup.get('routingDiagram')!.disabled
                ? 'label-disabled'
                : ''
            "
          >
            Data Routing Diagram
          </div>
          <div>
            <div class="custom-single-upload-btn">
              <div class="col-4 d-flex align-items-center">
                <div
                  class="add-input-des"
                  [ngClass]="
                    formRigGroup.get('routingDiagram')!.disabled
                      ? 'label-disabled'
                      : ''
                  "
                >
                  *.pdf
                </div>
              </div>
              <div class="col-3 me-1 d-flex align-items-center">
                <div
                  #progressUploadRoutingDiagramPDF
                  style="display: none; width: 100%"
                >
                  <div style="width: 100%">
                    <p-progressBar
                      [value]="valueUploadRoutingDiagramPDF"
                      [showValue]="false"
                      styleClass="progressUpload"
                    ></p-progressBar>
                  </div>
                </div>
              </div>
              <div
                class="col-5"
                [pTooltip]="fileLabelDataRoutingUrl"
                tooltipPosition="bottom"
              >
                <p-fileUpload
                  #uploadRoutingDiagramPDF
                  name="RoutingDiagramPDF[]"
                  url="./upload.php"
                  [multiple]="false"
                  accept=".pdf"
                  (onSelect)="onSelect_Diagram($event)"
                  (onClear)="onClear_Diagram($event)"
                  chooseIcon="pi pi-upload"
                  cancelLabel=" "
                  cancelStyleClass="cancelStyle"
                  chooseStyleClass="chooseStyle"
                  [chooseLabel]="
                    fileLabelDataRoutingUrl === 'Browse file'
                      ? fileLabelDataRoutingUrl
                      : (fileLabelDataRoutingUrl | shortFileName : 6)
                  "
                  styleClass="hidePreview"
                  [showUploadButton]="false"
                  [showCancelButton]="showCancelButtonPDF1"
                ></p-fileUpload>
              </div>
            </div>
          </div>
          <div class="col-12">
            <input
              type="hidden"
              pInputText
              class="custom-p-input"
              name="routingDiagramPDF"
              [formControlName]="fieldNameKeys.routingDiagramPDF"
              [innerMsg]="messageErrors"
            />
          </div>
        </div>

        <div class="col-12 mb-4">
          <div
            class="form-label"
            [ngClass]="
              formRigGroup.get('protocol')!.disabled ? 'label-disabled' : ''
            "
          >
            Communication Protocol
          </div>
          <div>
            <div class="custom-single-upload-btn">
              <div class="col-4 d-flex align-items-center">
                <div
                  class="add-input-des"
                  [ngClass]="
                    formRigGroup.get('protocol')!.disabled
                      ? 'label-disabled'
                      : ''
                  "
                >
                  *.pdf
                </div>
              </div>
              <div class="col-3 me-1 d-flex align-items-center">
                <div
                  #progressUploadProtocolPDF
                  style="display: none; width: 100%"
                >
                  <div style="width: 100%">
                    <p-progressBar
                      [value]="valueUploadprotocolPDF"
                      [showValue]="false"
                      styleClass="progressUpload"
                    ></p-progressBar>
                  </div>
                </div>
              </div>
              <div
                class="col-5"
                [pTooltip]="fileLabelCommunicateProtocolUrl"
                tooltipPosition="bottom"
              >
                <p-fileUpload
                  #uploadProtocolPDF
                  name="protocolPDF[]"
                  url="./upload.php"
                  [multiple]="false"
                  accept=".pdf"
                  (onSelect)="onSelect_Protocol($event)"
                  (onClear)="onClear_Protocol($event)"
                  chooseIcon="pi pi-upload"
                  cancelLabel=" "
                  cancelStyleClass="cancelStyle"
                  chooseStyleClass="chooseStyle"
                  [chooseLabel]="
                    fileLabelCommunicateProtocolUrl === 'Browse file'
                      ? fileLabelCommunicateProtocolUrl
                      : (fileLabelCommunicateProtocolUrl | shortFileName : 6)
                  "
                  styleClass="hidePreview"
                  [showUploadButton]="false"
                  [showCancelButton]="showCancelButtonPDF2"
                ></p-fileUpload>
              </div>
            </div>
          </div>
          <div class="col-12">
            <input
              type="hidden"
              pInputText
              class="custom-p-input"
              name="protocolPDF"
              [formControlName]="fieldNameKeys.protocolPDF"
              [innerMsg]="messageErrors"
            />
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-end gap-2">
      <p-button
        [attr.data-cy]="'cancel-button'"
        label="Cancel"
        (onClick)="closeFormDialog()"
        class="custom-p-button custom-p-button--secondary"
      ></p-button>
      <p-button
        [attr.data-cy]="'save-button'"
        label="Save"
        (onClick)="submitRig(formRigGroup.value)"
        class="custom-p-button custom-p-button--primary"
        [ngClass]="
          formRigGroup.status === 'INVALID' || isViewer
          ? 'custom-p-button custom-p-button--disable'
          : ''
          "
        [disabled]="formRigGroup.status === 'INVALID' || isViewer"
      ></p-button>
    </div>
  </ng-template>

  <!-- Loading -->
  <app-loading [isLoading]="isLoading"></app-loading>
</p-dialog>

