<div class="wrapper flex flex-column" >
  <ng-container *ngIf="!isLoading">
    <div>
      <span class="text">
        Please select the chat or channel you want to connect
      </span >
    </div>
    <div class="flex flex-column gap-2 form-selection" >
      <div class="grid">
        <p-radioButton
          class="radio"
          name="option"
          value="chat"
          [inputId]="'chat'"
          [(ngModel)]="selected"
        ></p-radioButton>
        <div class="d-flex flex-column gap-2" [ngClass]="{disable: selected !== 'chat'}">
          <span class="text" for="'chat'">
            MS Teams Chat
          </span>
          <p-dropdown
            placeholder="Select site"
            class="custom-p-drop"
            for="'chat'"
            [disabled]="selected !== 'chat'"
            [options]="chatOptions"
            [(ngModel)]="selectedChat"
            optionLabel="label"
          >
          </p-dropdown>
        </div>
      </div>
  
        
      <div class="grid">
        <p-radioButton
          class="radio"
          name="option"
          value="channel"
          [inputId]="'channel'"
          [(ngModel)]="selected"
        ></p-radioButton>
        <div class="d-flex flex-column gap-2" [ngClass]="{disable: selected !== 'channel'}">
          <span class="text" for="'channel'">
            MS Teams Channel
          </span>
          <p-treeSelect
            [options]="channelOptions"
            placeholder="Select site"
            class="custom-p-tree"
            scrollHeight="200px"
            for="'channel'"
            [disabled]="selected !== 'channel'"
            [showClear]="true"
            (onNodeExpand)="onNodeExpand($event)"
            [propagateSelectionDown]="false"
            [propagateSelectionUp]="false"
            (onClear)="onClearChannel()"
            (onNodeSelect)="onSelectedChannel($event)"
            [(ngModel)]="selectedChannel"
            >
            <ng-template pTemplate="value">
              {{ selectedChannelString || "Select site" }}
            </ng-template>
          </p-treeSelect>
        </div>
      </div>
    </div>
  
    <div class="bottom d-flex justify-content-end mt-2 gap-2">
      <p-button
        type="button"
        label="Cancel"
        class="custom-p-button custom-p-button--secondary"
        (click)="onCancel()"
      ></p-button>    
      <p-button
        *ngIf="isEditConnection"
        type="button"
        label="Disconnect"
        class="custom-p-button custom-p-button--secondary"
        [disabled]="isViewer"
        (click)="onDisconnect()"
      ></p-button>
      <p-button
        type="button"
        [label]="isEditConnection ? 'Save' : 'Connect'"
        class="custom-p-button custom-p-button--primary"
        [disabled]="isViewer"
        (click)="onConnect()"
      ></p-button>
    </div>
  </ng-container>

</div>
<app-loading [isLoading]="isLoading"></app-loading>