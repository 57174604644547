import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThemeType } from '../../type';

@Component({
  selector: 'app-customizable-button',
  templateUrl: './customizable-button.component.html',
  styleUrls: ['./customizable-button.component.scss']
})
export class CustomizableButtonComponent implements OnInit {

  // Configuration for first button
  @Input() showButton: boolean = true;    // <--  Have primaryButton or not?
  @Input() label: string = 'Button';      // <--  Label display in button 
  @Input() style: ThemeType = 'light';    // <--  Style the button (light or dark)
  @Input() icon: string = '';             // <--  Have icon or not? 
  @Input() isDisabled: boolean = false;   // <--  Have icon or not? isDisabled
  @Input() isToolTip: boolean = false;    // <--   Have tooltip or not? isToolTip
  @Input() toolTipContent: string = '';   // <--  Tooltip Content
  @Input() tooltipPosition: 'top' | 'right' | 'bottom' | 'left' = 'bottom';   // <--  Tooltip Position

  // Suporrt Automation Testing
  @Input() elementId: string = '';
  
  // Control Event
  @Output() eventButton: EventEmitter<void> = new EventEmitter<void>();
  
  constructor() { }
  ngOnInit() {
  }

  onClick() {
    if (!this.isDisabled) {
      this.eventButton.emit();
    }
  }
}
