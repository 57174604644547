<!-- COMMON Loading -->
<div class="d-none" [id]="isLoading ? 'loading' : 'loading-done'"></div>
<div
  class="overlay-progressBar"
  [ngClass]="{ 'single-side-loading': singleCenter }"
  *ngIf="isLoading"
>
  <div class="content-progress-bar" *ngIf="isIconShow">
    <span class="loader" [ngStyle]="{width: size, height: size}"></span>
    <span class="text-progressbar">Loading ... </span>
  </div>
</div>
