// offline-detection.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseService } from './base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class OfflineDetectionService extends BaseService {
  private onlineStatus$ = new BehaviorSubject<boolean>(navigator.onLine);

  constructor(httpClient: HttpClient) {
    super(httpClient);

    window.addEventListener('online', () => this.updateOnlineStatus());
    window.addEventListener('offline', () => this.updateOnlineStatus());
  }

  getOnlineStatus(): Observable<boolean> {
    return this.onlineStatus$.asObservable();
  }

  private updateOnlineStatus() {
    this.onlineStatus$.next(navigator.onLine);
  }

  public getStableConnect(): Observable<any[]> {
    const originSourceUri = 'ping';
    return this.get<any[]>(originSourceUri);
  }

  public checkPipeLine(id: string): any {
    const url = `https://dev.azure.com/HAL-HDS/HPM-OWA/_apis/pipelines/${id}/runs?api-version=7.2-preview.1`;
    return this.get<any[]>(url, {}, true);
  }
}
