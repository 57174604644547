export const formatDateTime = (date: Date, hasTime?: boolean) => {
  if (!date) {
    date = new Date();
  }
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const day = ('0' + date.getDate()).slice(-2);
  const monthIndex = date.getMonth();
  const monthName = monthNames[monthIndex];
  const year = date.getFullYear();

  if (!hasTime) {
    return `${day}-${monthName}-${year}`;
  }

  return `${day}-${monthName}-${year}, ${('0' + date.getHours()).slice(
    -2
  )}:${('0' + date.getMinutes()).slice(-2)}`;
};

export const formatHierarchyString = (value: any): string => {
  if (!value) {
    return 'N/A';
  } else {
    let data = value;
    if (typeof value === 'string') data = JSON.parse(value);

    if (data.hasOwnProperty('hierarchy')) {
      return data.hierarchy;
    } else {
      return 'N/A';
    }
  }
};
