import { Component, Input, OnInit } from '@angular/core';

export const AVATAR_SIZE = {
  SM: 'small',
  SMM: 'extra-small',
  SMMM: 'extra-small-secondary',
  MD: 'medium',
  LG: 'large',
};

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {

  @Input() avatar: string;
  @Input() displayName: string;
  @Input() size: string = '32px';
  @Input() backgroundColor: string = '#f3f3f3';
  
  constructor() { }

  ngOnInit(): void {
  } 

  getFirstLetter(userName: string | undefined): string {
    if (!userName) return 'Unknown User Name';

    let arrySplitName = userName.trim().split(' ');
    let userNameLength = arrySplitName.length;

    let firstLetter = arrySplitName[0].charAt(0);
    let secondLetter = arrySplitName[userNameLength - 1].charAt(0);

    return (firstLetter + secondLetter).toLocaleUpperCase() || userName;
  }
}
