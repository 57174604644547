import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './nonPercentageLoading/loading/loading.component';

const declarations = [LoadingComponent];

const imports: any = [CommonModule];

@NgModule({
  imports: [...imports],
  declarations: [...declarations],
  exports: [LoadingComponent],
  providers: [],
})
export class LoadingModule {}
