import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ChatService } from 'src/app/shared/services/chat.service';

@Component({
  selector: 'app-chat-bubble',
  templateUrl: './chat-bubble.component.html',
  styleUrls: ['./chat-bubble.component.scss'],
})

export class ChatBubbleComponent implements OnInit {
  private isChatWindowVisibleSubject = new BehaviorSubject<boolean>(false);
  isChatWindowVisible$ = this.isChatWindowVisibleSubject.asObservable();

  totalUnreadMsg: number = 0;

  constructor(private chatService: ChatService,) { }

  ngOnInit(): void {
    this.chatService.getUnreadLiveMessages().subscribe((data) => {
      if (data) {
        this.setTotalUnread(Number(data));
      }
    })
  }

  ngOnDestroy(): void { }

  setTotalUnread(event: number) {
    this.totalUnreadMsg = event;
  }
 
  toggleChatWindow() {
    this.isChatWindowVisibleSubject.next(!this.isChatWindowVisibleSubject.value);
  }

  onClearEvent(event: any) {
    this.toggleChatWindow();
  }
}
