import { UnitSystemAPI } from './../models/unit.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

interface layoutListLoadTracking {
  // userLoginAPI: boolean;
  // UnitSystemAPI: boolean;
  InterventionListAPI: boolean;
  ProjectListAPI: boolean;
  userInfoAPI: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LoaderLayOut {
  constructor() {}

  // Process for HomePage Report
  public tasksState: any = {
    InterventionListAPI: false,
    ProjectListAPI: false,
    userInfoAPI: false,
  };
  private loadingSubject: BehaviorSubject<layoutListLoadTracking> =
    new BehaviorSubject<layoutListLoadTracking>(this.tasksState);

  public loadingStatus$ = this.loadingSubject.asObservable();

  public setLoadingStatus(key: string, status: boolean): void {
    this.tasksState[key] = status;
    this.loadingSubject.next(this.tasksState);
  }
  // =============================================

  // Process for Intervention/Resolution Report
  private interventionFormDone: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public interventionFormDone$ = this.interventionFormDone.asObservable();

  public setLoadingInterventionDone(value: boolean): void {
    this.interventionFormDone.next(value);
  }
  // =============================================

  // Process for Alerts Detail page
  private alertsDetailDone: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public alertsDetailDone$ = this.alertsDetailDone.asObservable();

  public setLoadingAlertsDetailDone(value: boolean): void {
    this.alertsDetailDone.next(value);
  }
}
