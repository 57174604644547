import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ShareModule } from '../../shared/shared.module';
import { primeNgModules } from './import/primeNG';
import { ChatBubbleComponent } from './chat-bubble/chat-bubble.component';
import { ChatWindowComponent } from './chat-window/chat-window.component';
import { ChatReportComponent } from './chat-report/chat-report.component';
import { ChatLiveSupportComponent } from './chat-live-support/chat-live-support.component';
import { ChatWindowService } from 'src/app/shared/services/chat-window.service';
import { ChatMessageComponent } from './chat-message/chat-message.component';
import { ChatService } from 'src/app/shared/services/chat.service';
import { ChatBoxComponent } from './chat-box/chat-box.component';
import { ChatReportService } from 'src/app/shared/services/chat-report.service';
import { AvatarModule } from 'src/app/modules/avatar/avatar.module';
import { ChatMessageTextComponent } from './chat-message/chat-message-text/chat-message-text.component';
import { ChatMessageEventComponent } from './chat-message/chat-message-event/chat-message-event.component';
import { ConfirmationOverlayComponent } from './confirmation-overlay/confirmation-overlay.component';
import { ChatMessageTimeComponent } from './chat-message/chat-message-time/chat-message-time.component';
import { ChatMessageConfirmComponent } from './chat-message/chat-message-confirm/chat-message-confirm.component';

const declarations = [
  ChatBubbleComponent,
  ChatWindowComponent,
  ChatReportComponent,
  ChatLiveSupportComponent,
  ChatMessageComponent,
  ChatBoxComponent,
  ChatMessageTextComponent,
  ChatMessageEventComponent,
  ChatMessageConfirmComponent,
  ConfirmationOverlayComponent
];

const imports: any = [
  ShareModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  AvatarModule,
  ...primeNgModules
];

@NgModule({
  imports: [...imports],
  declarations: [...declarations, ChatMessageTimeComponent],
  exports: [ChatBubbleComponent],
  providers: [ChatWindowService, ChatService, ChatReportService],
})
export class ChatModule { }
