import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { TreeSelectModule } from "primeng/treeselect";
import { MultiLevelDropdownComponent } from "./multi-level-dropdown/multi-level-dropdown.component";
import { SingleButtonComponent } from "./single-button/single-button.component";
import { CommonModule } from "@angular/common";
import { InputSwitchModule } from 'primeng/inputswitch';
import { SingleSwitchComponent } from "./single-switch/single-switch.component";
import { CustomRangeInputComponent } from "./custom-range-input/custom-range-input.component";

const declarations = [MultiLevelDropdownComponent, SingleButtonComponent, SingleSwitchComponent, CustomRangeInputComponent];

const imports: any = [
  CommonModule,
  ReactiveFormsModule,
  FormsModule,
  ButtonModule,
  InputTextModule,
  TreeSelectModule,
  InputSwitchModule,
];

@NgModule({
  imports: [...imports],
  declarations: [...declarations],
  exports: [MultiLevelDropdownComponent, SingleButtonComponent, SingleSwitchComponent, CustomRangeInputComponent],
  providers: [],
})
export class FormControlModule {}
