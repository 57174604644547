import { Component, OnInit, Type } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionType,
} from '@azure/msal-browser';
import { filter, Subject, takeUntil, forkJoin, retry, catchError, throwError, of, tap, distinctUntilChanged } from 'rxjs';
import { AzureAdService } from './shared/services/azure-ad.service';
import { UserInfoService } from './shared/services/user-info.service';
import { ConfirmDialogService } from './shared/services/confirm-dialog.service';
import { IConfirmDialog } from './shared/interface/common';
import { DialogActionApp, OptionButtonType } from './shared/type';
import { OfflineDetectionService } from './shared/services/offlineDetection.service';
import { AppConstant } from './shared/utilities/app.constant';
import { NotificationService } from './shared/services/notification.service';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { ApplicationInsightsService } from './shared/services/telemetry.service';
import { User } from './shared/models/user.model';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private unsubscribe = new Subject<void>();

  // Define Default Input of Dialog for this Component
  confirmDialog: IConfirmDialog = AppConstant.DEFAULT_DIALOG;

  // To skip firsttime access app and have internet --> Prevent close dialog
  firstEmitCheckingInternet: boolean = false;

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    public azureAdService: AzureAdService,
    private userInfoService: UserInfoService,
    private confirmService: ConfirmDialogService,
    private offlineDetectionService: OfflineDetectionService,
    private notificationService: NotificationService,
    private router: Router,
    private appInsights: ApplicationInsightsService
  ) {
    this.appInsights = new ApplicationInsightsService(router);
    this.offlineDetectionService
      .getOnlineStatus()
      .subscribe((online: boolean) => {
        if (online) {
          if (!this.firstEmitCheckingInternet) {
            this.firstEmitCheckingInternet = true;
          } else {
            this.confirmService.setDialog({
              ...this.confirmDialog,
              isVisible: true,
              header: 'Warning',
              haveDialogMessage: true,
              dialogMessage:
                'You appear to be disconnected from the internet. Please connect to a network to continue using our app.',
              havePrimaryButton: true,
              primaryButtonLabel: 'Reconnecting ... ',
              isValidPrimaryButton: true,
              disablePrimaryButton: true,
              alignCenterButton: true,
              styleDialog: 'warning-dialog',
              buttonEvent: (event: OptionButtonType) =>
                this.onButtonClickDialog(event, 'CheckConnection'),
            });

            this.notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.SUCCESS,
              header: 'Network Available',
              content: 'Network connection has been restored.',
            });

            setTimeout(() => {
              this.confirmService.clearDialog();
            }, 3000);
          }
        } else {
          this.confirmService.setDialog({
            ...this.confirmDialog,
            isVisible: true,
            header: 'Warning',
            haveDialogMessage: true,
            dialogMessage:
              'You appear to be disconnected from the internet. Please connect to a network to continue using our app.',
            havePrimaryButton: true,
            primaryButtonLabel: 'Reconnect',
            isValidPrimaryButton: true,
            disablePrimaryButton: false,
            alignCenterButton: true,
            styleDialog: 'warning-dialog',
            buttonEvent: (event: OptionButtonType) =>
              this.onButtonClickDialog(event, 'CheckConnection'),
          });
        }
      });
  }

  ngOnInit() {
    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) =>
        [EventType.LOGIN_SUCCESS, EventType.ACQUIRE_TOKEN_SUCCESS].includes(msg.eventType)
      ),
      distinctUntilChanged((prev, curr) => {
        if (prev.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && curr.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
          const prevToken = (prev.payload as AuthenticationResult).accessToken;
          const currToken = (curr.payload as AuthenticationResult).accessToken;
          return prevToken === currToken;
        }
        return false;
      }),
      tap((msg: EventMessage) => {
        if(msg.eventType === EventType.LOGIN_SUCCESS) {
          const { accessToken, account } = msg.payload as AuthenticationResult;
          account && this.msalService.instance.setActiveAccount(account);
          accessToken && localStorage.setItem('token', accessToken );
          
          const localAccountId: string | undefined = this.msalService.instance.getActiveAccount()?.localAccountId; 
          localAccountId && localStorage.setItem( 'loginUserId', localAccountId);
          localAccountId && this.getInitialData(localAccountId);
        }

        // Handling EventType.ACQUIRE_TOKEN_SUCCESS
        if (msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && msg.interactionType === InteractionType.Silent) {
          const { accessToken } = msg.payload as AuthenticationResult;
          accessToken && localStorage.setItem('token', accessToken);
        }
      }),
      takeUntil(this.unsubscribe)
    )
    .subscribe((msg: EventMessage) => {
      // console.log('subscribe: ', msg);
    });


    if (this.msalService.instance.getActiveAccount()?.localAccountId) 
      this.getInitialData( this.msalService.instance.getActiveAccount()?.localAccountId);


    this.confirmService.dataSubjectAction$.subscribe(
      (data: IConfirmDialog) => {
        this.confirmDialog = data;
      }
    );

    this.router.events
      .pipe(filter((event): event is ResolveEnd => event instanceof ResolveEnd))
      .subscribe((event) => {
        const activatedComponent = this.getActivatedComponent(event.state.root);
        if (activatedComponent) {
          this.appInsights.trackPageView({
            name: activatedComponent.name,
            uri: event.urlAfterRedirects,
          });
        }
      });
  }

  getInitialData(loginID?: string, maxRetries: number = 3) {
    // Apply retry to each API call
    if (loginID) {
      this.userInfoService.getUserInfoInitiate(loginID)
        .pipe(retry(maxRetries))
        .subscribe({
          next: (user: User) => {
            this.userInfoService.setUserInfor(user);
          },
          error: (error) => {
            if (!error || error?.includes('authenticate failed')) return;

            setTimeout(() => {
              this.confirmService.initDataErrorDialog(error);
            }, 1000);
          },
      });
    } else {
      setTimeout(() => {
        this.confirmService.messageErrorDialog('Error Missing Login ID');
      }, 1000);
    }
  }

  private getActivatedComponent<T>(snapshot: ActivatedRouteSnapshot): Type<T> {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }
    return snapshot.component as Type<T>;
  }

  onButtonClickDialog(
    option: OptionButtonType,
    dialogType: DialogActionApp
  ): void {
    switch (option) {
    case AppConstant.OPTION_BUTTON.YES:
      switch (dialogType) {
      case 'Reload':
        window.location.reload();
        break;
      case 'CheckConnection':
        this.requestCheckingConnections();
        break;
      default:
        break;
      }
      break;
    case AppConstant.OPTION_BUTTON.CANCEL:
      this.confirmService.setDialog({
        ...this.confirmDialog,
        isVisible: false,
      });
      break;
    default:
      break;
    }
  }

  requestCheckingConnections() {
    this.confirmService.setDialog({
      ...this.confirmDialog,
      havePrimaryButton: true,
      primaryButtonLabel: 'Reconnecting ... ',
      isValidPrimaryButton: true,
      disablePrimaryButton: true,
    });

    setTimeout(() => {
      this.offlineDetectionService.getStableConnect().subscribe({
        next: () => {
          // duplicate
          // Dont need show message
        },
        error: () => {
          this.confirmService.setDialog({
            ...this.confirmDialog,
            havePrimaryButton: true,
            primaryButtonLabel: 'Reconnect',
            isValidPrimaryButton: true,
            disablePrimaryButton: false,
          });

          this.notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.ERROR,
            header: 'Reconnect',
            content:
              'Looks like you are offline; please reconnect and try again.',
          });
        },
      });
    }, 3000);
  }
}
