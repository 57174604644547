<ng-container *ngIf="backPortal">
  <div class="header-back" (click)="backFearture()">
    <mat-icon class="header-back-icon" fontIcon="chevron_left"></mat-icon>
    <span class="header-back-text">{{ backPortalLabel }}</span>
  </div>
</ng-container>
<div
  *ngIf="headerTitle"
  class="header-content"
  [ngClass]="{ 'm-bottom': bottomMargin }"
>
  <div class="header-content__title d-flex">
    <span class="me-3">{{ headerTitle }}</span>
  </div>
  <div
    class="header-button header-button--light"
    [ngClass]="{ 'd-none': !buttonFearture }"
  >
    <p-button
      [attr.data-cy]="buttonFeartureLabel | hyphenCase"
      [label]="buttonFeartureLabel"
      class="custom-p-button custom-p-button--primary"
      (onClick)="openPopup()" 
    >
    </p-button>
  </div>
</div>
