<div class="custom-dialog" [ngClass]="{ active: isDialogOpen }">
  <svg-icon
    class="icon-des"
    key="contact"
    size="md"
    (click)="toggleDialog()"
  ></svg-icon>
  <div class="dialog-content" *ngIf="isDialogOpen">
    The panel displays alerts associated with active wellbores in the last 30 days. For the complete list of alerts, go to
    <span class="link" (click)="redirectTo('alerts/alerts-management')"
      >Alerts Management</span
    >.
  </div>
</div>
