export const EDIT_PROJECT = {
  TITLE: {
    LABEL: 'Edit Project',
    OPTION: {},
  },
  PROJECT: {
    LABEL: 'Project',
    OPTION: {},
  },
  OPERATOR: {
    LABEL: 'Operator',
    OPTION: {},
  },
  REGION: {
    LABEL: 'Region',
    OPTION: {},
  },
  COUNTRY: {
    LABEL: 'Country',
    OPTION: {},
  },
  BLOCK: {
    LABEL: 'Block',
    OPTION: {},
  },
  LEASE: {
    LABEL: 'Lease',
    OPTION: {},
  },
  FIELD: {
    LABEL: 'Field',
    OPTION: {},
  },
  TIMEZONE: {
    LABEL: 'Time Zone',
    OPTION: {},
  },
  UNITS: {
    LABEL: 'Units',
    OPTION: {},
  },
};
