import { AppConstant } from './app.constant';

export const transformMessage = (message: any): AppConstant.Message => {
  return {
    id: message.id,
    supportCaseId: message.supportCaseId,  
    type: message.type,
    content: message.content,    
    senderType: message.senderType,
    userId: message.sender?.id,
    userName: message.sender?.displayName,
    chatId: '',
    title: '',
    avatar: message.sender?.avatar,
    timestamp: new Date(message.createdAt),
    attachments: message.attachments
  } as AppConstant.Message;
}