import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { Socket, connect } from 'socket.io-client'
import { environment } from 'src/environments/environment';
import { ConfirmDialogService } from './confirm-dialog.service';
import { OptionButtonType } from '../type';


@Injectable({
  providedIn: 'root'
})
export class WebSocketService implements OnDestroy {
  socket: Socket;
  socketConnections: Socket[] = [];
  retryCount: number = 0;
  maxRetries: number = 5;
  timerRetry: number = 10000;
  timeOutRetry: any;
  isRestored: boolean = false;

  handlers: { [messageType: string]: Function[] } = {};
  eventListeners: { [eventName: string]: Function[] } = {};

  constructor(
    private _confirmService: ConfirmDialogService,
  ) {
    this.connectSocket();
  }

  ngOnDestroy(): void {
    if (this.socket.connected)
      this.socket.disconnect();
  }

  connectSocket() {
    const token = localStorage.getItem('token');
    if (!token) return;
    if (this.socket && this.socket.connected) return;

    this.socket = connect(environment.apiUrl, {
      auth: { token },
      reconnection: true,
    });

    this.socketConnections.push(this.socket);
    this.socket.on('connect', () => {
      this.retryCount = 0;
      clearTimeout(this.timeOutRetry);

      for (let i=0; i < this.socketConnections.length; i++) {
        const socket = this.socketConnections[i];
        if (socket !== this.socket) {
          socket.disconnect();
          this.socketConnections.splice(i--, 1); 
        }
      }

      this.reRegisterListeners();
    });

    this.socket.on('connect_error', () => {
      // console.log('connect_error WebSocket server');
      this.handleRetryConnectSocket();
    });
    
    this.socket.on('connect_timeout', () => {
      // console.log('connect_timeout WebSocket server');
      this.handleRetryConnectSocket();
    });

    this.socket.on('disconnect', () => {
      // console.log('Disconnected from WebSocket server');
      this.handleRetryConnectSocket();
    });
  }

  handleRetryConnectSocket() {
    this.isRestored = false;
    if (this.retryCount == 0)
      this.retryConnect();
    else
      this.timeOutRetry = setTimeout(() => this.retryConnect(), this.timerRetry);
  }

  retryConnect() {
    if (this.socket.connected) return;
    if (this.retryCount >= this.maxRetries) {
      this.handleMaxRetriesExceeded();
      return;
    }
    
    this.connectSocket();
    this.retryCount++;
  }

  private handleMaxRetriesExceeded() {
    const onButtonEvent = (option: OptionButtonType) => {
      window.location.reload();
    };

    this._confirmService.setDialog({
      isVisible: true,
      header: `Warning`,
      haveCheckbox: false,
      haveDialogMessage: true,
      dialogMessage:
        'Inactive page in long time, Please reload page to refresh page!',
      havePrimaryButton: true,
      primaryButtonLabel: 'Confirm',
      isValidPrimaryButton: true,
      disablePrimaryButton: false,
      haveSecondaryButton: false,
      secondaryButtonLabel: 'Cancel',
      buttonEvent: (event: OptionButtonType) =>
        onButtonEvent(event),
    });
  }

  listen(eventName: string): Observable<any> {
    return new Observable((subscribe: any) => {
      this.socket.on(eventName, (data: any) => {
        subscribe.next(data);
      });
      if (!this.eventListeners[eventName]) {
        this.eventListeners[eventName] = [];
      }
      this.eventListeners[eventName].push(subscribe);
    })
  }

  private reRegisterListeners() {
    // reRegister listeners if stored
    if (this.isRestored && !this.socket.connected) return;

    for (const eventName in this.eventListeners) {
      if (this.eventListeners.hasOwnProperty(eventName)) {
        const listeners = this.eventListeners[eventName];
        listeners.forEach((subscribe: any) => {
          // console.log("Retry Listeners eventName: ", eventName);
          this.socket.on(eventName, (data: any) => subscribe.next(data));
        });
      }
    }
    this.isRestored = true;
  }

  unlisten(eventName: string): Observable<any> {
    return new Observable((subcribe) => {
      // temporarily fix the issue with socket.off
      // console.log(`Unlistening tco event: ${eventName}`);
      this.socket.removeAllListeners(eventName);
    })
  }

  emit(eventName: string, data: any): any {
    this.socket.emit(eventName, data)
  }

  registerMessageHanlder(messageType: WS_MESSAGE_TYPE, handler: Function): void {
    if (!this.handlers[messageType]) {
      this.handlers[messageType] = [];
    }
    this.handlers[messageType].push(handler);
    // console.log(`after registerMessageHanlder: ${messageType} - totalHandlers: ${this.handlers[messageType].length}`);
  }

  unregisterMessageHanlder(messageType: WS_MESSAGE_TYPE, handler: Function): void {
    // console.log(`before unregisterMessageHanlder: ${messageType}`);
    if (this.handlers[messageType]) {
      this.handlers[messageType] = this.handlers[messageType].filter((h) => h !== handler);
      // console.log(`after unregisterMessageHanlder: ${messageType} - totalHandlers: ${this.handlers[messageType].length}`);
    }
  }

  handleMessage(message: WebSocketMessage): void {
    const { type: messageType, data } = message;
    if (this.handlers[messageType]) {
      this.handlers[messageType].forEach((handler) => {
        handler(data);
      });
    }
  }
}

export enum WS_MESSAGE_TYPE {
  LIVE_SUPPORT_AGENT__NEW_MESSAGE = 'liveSupportAgent/newMessage',
  LIVE_SUPPORT_AGENT__NEW_SUPPORT_CASE = 'liveSupportAgent/newSupportCase',
  LIVE_SUPPORT_AGENT__UPDATE_SUPPORT_CASE = 'liveSupportAgent/updateSupportCase',
  LIVE_SUPPORT_AGENT__READ_ALL = 'liveSupportAgent/readall',
  LIVE_SUPPORT_AGENT__TYPING = 'liveSupportAgent/typing',

  USER__NEW_MESSAGE = 'user/newMessage',
  USER__UPDATE_UNREAD_MESSAGE = 'user/updateUnreadMessage',
  USER__READ_ALL = 'user/readall',
  USER__TYPING = 'user/typing',
}


export interface WebSocketMessage {
  type: WS_MESSAGE_TYPE;
  data: any;
}
