import { AppConstant } from './app.constant';

function getLeafEventItems(arr: any[], parentItems: any[] = []): any[] {
  let result: any[] = [];
  arr.forEach((item) => {
    const parents = [...parentItems, item];
  
    if (Array.isArray(item.children) && item.children.length > 0) {
      result = result.concat(getLeafEventItems(item.children, parents));
    } else {
      var hierarchy = [...parentItems, item].map(e => e.label).join('/');
      result.push({...item, hierarchy});
    }
  });
  return result;
}
  
const leftEventsMap = getLeafEventItems(AppConstant.EVENTS).reduce((acc, item) => {
  acc[item.event_id] = item;
  return acc;
}, {});
  
export const getEventById = (eventId: string) => {
  if (!eventId) return null;
  let tempId = eventId;
  try {
    const obj = JSON.parse(eventId);
    tempId = obj?.event_id || tempId;
  } catch {
    return leftEventsMap[tempId];
  }
  
  return leftEventsMap[tempId];
};