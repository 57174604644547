import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-single-switch',
  templateUrl: './single-switch.component.html',
  styleUrls: ['./single-switch.component.scss']
})
export class SingleSwitchComponent implements OnInit {

  @Input()
  disabled: boolean = false;

  @Input()
  statusValue: boolean = false;

  @Input()
  classList: string = '';

  @Output() onSwitch: EventEmitter<boolean> = new EventEmitter();
  
  constructor() { }

  ngOnInit() { }
}
