import {
  Component,
  OnInit,
} from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { GraphApiService } from '../../services/graph-api.service';
import { tap } from 'rxjs';
import { HomeService } from '../../services/home.service';
import { NotificationService } from '../../services/notification.service';
import { AppConstant } from '../../utilities/app.constant';

@Component({
  selector: 'app-connect-ms-team',
  templateUrl: './connect-ms-team.component.html',
  styleUrls: ['./connect-ms-team.component.scss'],
})
export class ConnectMsTeamComponent implements OnInit {
  selected: string = "chat";
  isEditConnection: boolean = false;
  isLoading: boolean = true;
  chatOptions: { name: string; id: string }[] = [];
  channelOptions: any[] = [];

  rigJournalId: string = '';
  selectedChat: any;
  selectedChannel: any;
  selectedChannelString: string = '';

  userRole: string;
  isViewer: boolean = false;
  userMail: string;

  constructor(
    private dialogRef: DynamicDialogRef,    
    public dialogConfig: DynamicDialogConfig,
    private homeService: HomeService,
    private _graphService: GraphApiService,
    private _notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this._graphService.getListData().pipe(
      tap(() => this.isLoading = true),
    ).subscribe((res) => {
      this.userRole = this.dialogConfig?.data?.userRole;
      this.isViewer = this.userRole == AppConstant.ROLES.VIEWER.label;
      this.userMail = this.dialogConfig?.data?.userMail;
      this.rigJournalId = this.dialogConfig?.data?.rigJournalId;

      this.chatOptions = res.chats;
      this.channelOptions = res.channels;
      this.isLoading = false;

      if (this.dialogConfig?.data?.isEdit) {
        this.isEditConnection = this.dialogConfig?.data?.isEdit
        if (this.dialogConfig.data.teamId && this.dialogConfig.data.channelId) {
          this.selected = 'channel';
          this.selectedChannel = this.findNodeChannel(this.channelOptions, this.dialogConfig.data.channelId)
          this.selectedChannelString = this.dialogConfig.data.teamName;
        } else {
          this.selected = 'chat';
          this.selectedChat = this.chatOptions.find((chat: any) => chat.id == this.dialogConfig.data.groupId);
        }
      }
    });
  }

  findNodeChannel(data: any, targetId: string) {
    for (let team of data)
      for (let channel of team.children)
        if (channel.id === targetId)
          return { ...channel, parent: team };
    return null; 
  } 
  
  onCancel(): void {
    this.dialogRef.close({type: "Cancel", data: null});
  }

  onDisconnect(): void {
    if (this.userRole == AppConstant.ROLES.VIEWER.label)
      return;

    const payload = {
      groupId: '',
      groupName: '',
      teamId: '',
      channelId: '',
      teamName: '',
      userEmailConnected: '',
    };

    this.homeService.addConnectionMSTeams(this.rigJournalId, payload).subscribe(() => {
      this.dialogRef.close({type: "Disconnect", data: payload});
    });

  }

  onConnect(): void {
    if (this.userRole == AppConstant.ROLES.VIEWER.label)
      return;
    if (!this.selectedChannel?.id && !this.selectedChat?.id) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Connect MS Team',
        content: 'Please select the Chat/Channel',
      });
      return;
    }
    const payload = {
      groupId: '',
      groupName: '',
      teamId: '',
      channelId: '',
      teamName: '',
      userEmailConnected: this.userMail,
    };
    if (this.selected == 'chat') {
      payload.groupId = this.selectedChat.id;
      payload.groupName = this.selectedChat.label;
    } else {
      payload.teamId = this.selectedChannel.parent.id;
      payload.channelId = this.selectedChannel.id;
      payload.teamName = this.selectedChannelString;
    }


    this.homeService.addConnectionMSTeams(this.rigJournalId, payload).subscribe(() => {
      this.dialogRef.close({type: "Connect", data: payload});
    });
  }

  onNodeExpand(event: any) {
    if (event.node.children === undefined) event.node.expanded = false;
  }
  onClearChannel() {
    this.selectedChannel = undefined;
    this.selectedChannelString = '';
  }

  onSelectedChannel(event: any) {
    const node = event?.node;
    if (node) {
      this.selectedChannelString = `${node.parent.label} | ${node.label}`
    }
  }
}
