import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'timeUpload',
})
export class TimeUploadPipe implements PipeTransform {
  /**
   * Transforms the input value into an estimated remaining time string.
   * If the value is '0.00', it returns 'Estimating remaining time'.
   * Otherwise, it returns a string with the value followed by 'seconds left'.
   *
   * @param value - The value to be transformed.
   * @returns The estimated remaining time string.
   */

  transform(value: string | number | null): string {
    if (value === '0.00') {
      return 'Estimating remaining time';
    } else {
      return `${value} seconds left`;
    }
  }
}
