import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hyphenCase',
})
export class StringHyphansCyPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return '';

    value = value + ' ' + 'Button'; 
    return value.replace(/\s+/g, '-').toLowerCase();
  }
}
