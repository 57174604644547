<div class="VRO-footer">
  <div class="VRO-footer__left-logo">
    <img
      class="Decision-logo"
      srcset="../../../../assets/images/logo/DecisionLogo.png 2x"
      alt="Image of Decision-logo"
    />
    <!-- DecisionLogo -->
    <img
      class="Decision-space"
      srcset="../../../../assets/images/logo/iEnergyLogo.png 2x"
      alt="Image of iEnergyLogo"
    />
  </div>
  <div class="VRO-footer__copyright">
    Copyright © 2024 Halliburton. All Rights Reserved. |
    <span
      class="primary-color cursor-pointer"
      (click)="redirectToPage('TERMS_OF_USE')"
      >Terms of Use</span
    >
    <span> |</span>
    <span
      class="primary-color cursor-pointer"
      (click)="redirectToPage('EMPLOYEE_PRIVACY_NOTICE')"
    >
      Employee Privacy Notice</span
    >
  </div>
  <div class="VRO-footer__right-logo">
    <img
      class="Hal-VRO-Logo"
      srcset="../../../../assets/images/logo/Hal-VRO-Logo.png 2x"
      alt="Image of Hal-VRO-Logo"
    />
  </div>
</div>
