import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BackPortalService {
  private headerDisplayValue: string = '';

  public collapseInterventionLog: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);

  constructor() {}

  setHeaderDisplayValue(value: string) {
    if (value) {
      this.clearCollapseLog();
    } else {
      this.setCollapseLog(true);
    }
    this.headerDisplayValue = value;
  }

  getHeaderDisplayValue(): string {
    return this.headerDisplayValue;
  }

  public collapseInterventionLog$ = this.collapseInterventionLog.asObservable();

  public setCollapseLog(logState: boolean) {
    this.collapseInterventionLog.next(logState);
  }

  public clearCollapseLog(): any {
    this.setCollapseLog(false);
  }
}
