import { Component, Input, OnInit } from '@angular/core';
import { AppConstant } from 'src/app/shared/utilities/app.constant';

@Component({
  selector: 'app-chat-message-event',
  templateUrl: './chat-message-event.component.html',
  styleUrls: ['./chat-message-event.component.scss']
})
export class ChatMessageEventComponent implements OnInit {
  @Input() message: AppConstant.Message;
  @Input() previousMessage: AppConstant.Message | undefined;

  @Input() isMine: boolean;

  @Input() isTyping: boolean = false;
  @Input() template: 'CONTENT' | 'TYPING' = 'CONTENT';
  constructor() { }

  ngOnInit(): void {
  }

}
