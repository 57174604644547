import { FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { throwError } from 'rxjs';
import { AppConstant } from './app.constant';
import { EvidenceMask } from '../interface/intervention.interface';
import { EvidenceProcessType } from '../type';
import { ALERT_TYPE } from '../enum';
import {Jimp, JimpMime} from 'jimp';

export namespace AppHelper {
  // [List helper Functions handle]
  // [Date - Time]
  export namespace DateFunctions {
    export function formatDate(time: Date, format: string): string {
      const currentDate = time;
      const year = currentDate.getFullYear().toString();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const day = currentDate.getDate().toString().padStart(2, '0');
      const hours = currentDate.getHours().toString().padStart(2, '0');
      const minutes = currentDate.getMinutes().toString().padStart(2, '0');
      const seconds = currentDate.getSeconds().toString().padStart(2, '0');
      const milliseconds = currentDate.getMilliseconds().toString().padStart(3, '0');
  
      // Validate input format
      switch (format) {
          case "yyyyMMddHHmmssfff":
              return year + month + day + hours + minutes + seconds + milliseconds;
          case "yyyyMMdd":
              return year + month + day;
          case "HHmmss":
              return hours + minutes + seconds;
          case "yyyymm":
              return year + month;
          default:
            return "N/A";
      }
  }

    export const formatTime = (time: string) => {
      return new Date(Date.parse(time)).toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      });
    };

    export const getTimeUCT = (offset: any) => {
      var d = new Date();
      let localTime = d.getTime();
      let localOffset = d.getTimezoneOffset() * 60000;

      let utc = localTime + localOffset;
      var nd = new Date(utc + 3600000 * offset);
      return nd;
    };

    export const formatDateTime = (date: Date, hasTime?: boolean, monthsDict?: any) => {
      const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      const day = ('0' + date.getDate()).slice(-2);
      const monthIndex = date.getMonth();
      const monthName = monthsDict?.[monthNames[monthIndex]] || monthNames[monthIndex];
      const year = date.getFullYear();

      if (!hasTime) {
        return `${day}-${monthName}-${year}`;
      }

      return `${day}-${monthName}-${year}, ${('0' + date.getHours()).slice(
        -2
      )}:${('0' + date.getMinutes()).slice(-2)}`;
    };

    export const convertToMidnightTimestamp = (timestamp: number, nextMidnight = false) => {
      // Step 2: Create a new Date object using the given timestamp
      const originalDate = new Date(timestamp);
  
      // Step 3: Extract the year, month, and day from the new Date object
      const year = originalDate.getFullYear();
      const month = originalDate.getMonth();
      const day = originalDate.getDate();
  
      // Step 4: Create a new Date object with hours and minutes set to 0
      const midnightDate = nextMidnight
        ? new Date(year, month, day + 1) // Move to the next day
        : new Date(year, month, day);
  
      // Step 5: Get the timestamp of the new Date object (at 00:00)
      const midnightTimestamp = midnightDate.getTime();
  
      return midnightTimestamp;
    }
  }

  // [List helper Functions handle]
  // [Math - Number]
  export namespace MathFunctions {
    export const getDigitUnitSystems = (unit: string): number => {
      switch (unit) {
        case 'ft':
          return 2;
        case 'm':
          return 2;
        case 'mm':
          return 0;
        case 'in':
          return 3;
        default:
          return 0;
      }
    };

    export const findUnit = (unitName: string, unitSystem: any) => {
      const keys = Object.keys(unitSystem);
      for (let i = 0; i < keys.length; i++) {
        if (keys[i] === unitName) {
          return unitSystem[keys[i]];
        }
      }
      return null; // Not found key
    };

    export const financial = (input: any, factor: number) => {
      return Number(Number.parseFloat(input).toFixed(factor));
    };

    export const getColorOfInterventionStatus = (
      stateCode: string,
      timeExpire: any
    ) => {
      if (['I_0_1', 'R_1_1'].includes(stateCode)) return 'green-tag';
      if (['I_0_0', 'I_1_0'].includes(stateCode)) return 'yellow-tag';
      const isRunOutTime =
        new Date(timeExpire).getTime() - new Date().getTime() <= 0;
      if (['I_1_1'].includes(stateCode))
        return isRunOutTime ? 'red-tag' : 'green-tag';
      if (['R_1_0'].includes(stateCode))
        return isRunOutTime ? 'red-tag' : 'yellow-tag';
      else return '';
    };

    export const getActionOfInterventionStatus = (
      stateCode: string,
      timeExpire: any
    ) => {
      if (['I_0_1'].includes(stateCode)) return 'Initiate Completed';
      if (['R_1_1'].includes(stateCode)) return 'Resolution Completed';
      if (['I_0_0', 'I_1_0'].includes(stateCode))
        return 'Initiated but not sent (draft)';
      const isRunOutTime =
        new Date(timeExpire).getTime() - new Date().getTime() <= 0;
      if (['I_1_1'].includes(stateCode))
        return isRunOutTime
          ? 'Initiated and sent (requires immediate resolution)'
          : 'Initiated and sent (awaiting resolution)';
      if (['R_1_0'].includes(stateCode))
        return isRunOutTime
          ? 'Resolution drafted but not sent (requires immediate resolution)'
          : 'Resolution drafted but not sent';
      else return '';
    };

    export const transferData = (
      value: any,
      unitSystem: any,
      referenceUnit: string,
      currentUnit: string,
      unitType: string = 'diameter',
      factorDiameterNumber: number,
      digitDiameterNumber: number,
      factorDepthNumber: number,
      digitDepthNumber: number,
      hasUnit: boolean = true
    ): string => {
      let formUnitOld: any;
      let formUnitNew: any;
      let unitConverterDiameter = 0;
      let unitConverterDepth = 0;
      let formUnit: any;
      let digitDiameter = 0;
      let digitDepth = 0;

      if (currentUnit) {
        if (currentUnit && currentUnit !== referenceUnit) {
          formUnitOld = AppHelper.MathFunctions.findUnit(
            currentUnit,
            unitSystem.unit
          );

          formUnitNew = AppHelper.MathFunctions.findUnit(
            referenceUnit,
            unitSystem.unit
          );

          for (const key in unitSystem.converter) {
            let combineConvertersDiameterKey =
              formUnitOld.Diameter + '2' + formUnitNew.Diameter;
            let combineDepthDiameterKey =
              formUnitOld.Depth + '2' + formUnitNew.Depth;

            if (key === combineConvertersDiameterKey.toLowerCase()) {
              unitConverterDiameter = unitSystem.converter[key];
            }

            if (key === combineDepthDiameterKey.toLowerCase()) {
              unitConverterDepth = unitSystem.converter[key];
            }
          }
        }
      }
      formUnit = AppHelper.MathFunctions.findUnit(
        referenceUnit,
        unitSystem.unit
      );
      digitDiameter = AppHelper.MathFunctions.getDigitUnitSystems(
        formUnit.Diameter
      );
      digitDepth = AppHelper.MathFunctions.getDigitUnitSystems(formUnit.Depth);

      factorDiameterNumber = unitConverterDiameter;
      factorDepthNumber = unitConverterDepth;

      digitDiameterNumber = digitDiameter;
      digitDepthNumber = digitDepth;

      switch (unitType) {
        case 'diameter': {
          let parsedValue = parseFloat(
            value?.toString().replace(/,/g, '')
          );
          let roundedValue = AppHelper.MathFunctions.financial(
            factorDiameterNumber === 0
              ? parsedValue * 1
              : parsedValue * factorDiameterNumber,
            digitDiameterNumber
          );

          if (hasUnit)
            return isNaN(roundedValue)
              ? 'N/A'
              : `${roundedValue.toLocaleString('en-US', {
                  useGrouping: true,
                })} ${formUnit.Diameter}`;
          else
            return isNaN(roundedValue)
              ? 'N/A'
              : `${roundedValue.toLocaleString('en-US', {
                  useGrouping: true,
                })}`;
        }
        case 'depth': {
          let parsedValue = parseFloat(
            value?.toString().replace(/,/g, '')
          );
          let roundedValue = AppHelper.MathFunctions.financial(
            factorDepthNumber === 0
              ? parsedValue * 1
              : parsedValue * factorDepthNumber,
            digitDepthNumber
          );
          if (hasUnit)
            return isNaN(roundedValue)
              ? 'N/A'
              : `${roundedValue.toLocaleString('en-US', {
                  useGrouping: true,
                })} ${formUnit.Depth}`;
          else
            return isNaN(roundedValue)
              ? 'N/A'
              : `${roundedValue.toLocaleString('en-US', {
                  useGrouping: true,
                })}`;
        }
        default:
          return 'N/A';
      }
    };

    /**
     * Generates a random number between 0 and 1 using the Crypto API's getRandomValues() method.
     * @returns A random number between 0 (inclusive) and 1 (exclusive).
     */
    export const getRandomNumber = (): number => {
      const array = new Uint32Array(1);
      window.crypto.getRandomValues(array);
      return array[0] / Math.pow(2, 32); // 2^32
    };
  }

  // [List helper Functions handle]
  // [String]
  export namespace StringFunctions {
    export const sourceName = (sourceName: string): string => {
      switch (sourceName) {
        case 'HPM':
          return 'Global HPM';
        default:
          return sourceName;
      }
    };


    export const getFileName = (fileName: string): string => {
      return fileName ? fileName.split('/')[4] || fileName : fileName;
    };

    export const extractFileName = (fileName: string): string => {
      return fileName.split('.').slice(0, -1).join('.');
    };

    export const getExtension = (fileName: string): string => {
      return fileName.split('.').pop() || '';
    };

    export const shortenFileName = (
      nameFile: string,
      limitLength: number,
      startLength: number
    ): string => {
      return nameFile.length > limitLength
        ? nameFile.slice(0, startLength) +
            '...' +
            AppHelper.StringFunctions.getExtension(nameFile)
        : nameFile;
    };

    export const capitalizeFirstLetter = (string: string): string => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    export const formatHierarchyString = (value: any): string => {
      if (!value) {
        return 'N/A';
      } else {
        let data = value;
        if (typeof value === 'string')
          data = JSON.parse(value);

        if (data.hasOwnProperty('hierarchy')) {
          return data.hierarchy;
        } else {
          return 'N/A';
        }
      }
    };

    export const formatRecommendedActionTaken = (value: string): string => {
      if (!value) {
        return 'N/A';
      } else {
        if (value.length > 1)
          return value;
        else return value.toUpperCase();
      }
    };

    export const formatContactMethodString = (value: string): string => {
      if (!value) {
        return 'N/A';
      } else {
        const data = JSON.parse(value);
        if (data) {
          let labelDisplay = '';
          let labelDisplayChild = '';
          let parentArray: any = [];
          let childArray: any = [];
          data.forEach((el: any) => {
            if (el.id) {
              // seperate with element is group Parent (Mail and Chat) or Group Child (Phone)
              parentArray.push(el);
            } else {
              childArray.push(el);
            }
          });

          childArray = childArray.sort(function (a: any, b: any) {
            var keyA = a.label,
              keyB = b.label;
            // Compare the 2 dates
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });
          //

          let email = parentArray.find((el: any) => el.label === 'Email');
          let chat = parentArray.find((el: any) => el.label === 'Chat');

          childArray.forEach((item: any) => {
            labelDisplayChild = labelDisplayChild + item.contact_id + ', ';
          });
          labelDisplayChild = labelDisplayChild.slice(0, -2);
          labelDisplayChild = `Phone (${labelDisplayChild})`;

          if (childArray.length > 0) {
            labelDisplay = labelDisplayChild + ' | ' + labelDisplay;
          }
          if (email) {
            labelDisplay = labelDisplay + email.label + ' | ';
          }
          if (chat) {
            labelDisplay = labelDisplay + chat.label + ' | ';
          }
          return labelDisplay.slice(0, -2);
        } else {
          return 'N/A';
        }
      }
    };

    export const formatRiskString = (value: any): string => {
      if (!value) {
        return 'N/A';
      } else {
        try {
          let data = value;
          if (typeof value === 'string')
            return value;
  
          if (data.length) {
            let labelValues = data.map((risk: any) => risk.label);
            return labelValues.join(', ');
          } else {
            return 'N/A';
          }
        } catch (error) {
          return value;
        }
      }
    };

    export const descriptionDate = (date: string): string => {
      let dateFormat: String[] = AppHelper.DateFunctions.formatDateTime(
        new Date(date),
        true
      ).split(',');
      let isToday: Boolean = moment(date).isSame(moment(), 'day');
      if (isToday) return dateFormat[1] + ', ' + 'Today';

      let isYesterday: Boolean = moment(date).isSame(
        moment().subtract(1, 'day'),
        'day'
      );
      if (isYesterday) return dateFormat[1] + ', ' + 'Yesterday';
      else return dateFormat[1] + ', ' + dateFormat[0];
    };

    /**
     * Make the text beauty by the way remove whitespace, enterline redundance
     * @param str 
     * @returns 
     */
    export const textTrimming = (str: string): string => {
      if (!str) return '';

      const arrString: Array<string> = str.split('\n');
      str = arrString
        .map((str: string) => {
          return str.trim().replace(/\s{2,}/g, ' ');
        })
        .filter((str: string) => str)
        .join('\n');
      return str;
    };

    export const textTrimmingAdvance = (str: string): string => {
      if (!str) return '';

      const arrString: Array<string> = str.split('<br>');
      str = arrString
        .map((str: string) => {
          return str
                    // non-breaking to white-space
                    .replace(/&nbsp;/g, ' ')
                    // 2 white-space to 1 white-space
                    .replace(/\s{2,}/g, ' ')
                    // Remove white-space at head
                    .replace(/^\s+/, '');
        })
        .filter((str: string) => str)
        .join('<br>');
      return str;
    };
    
    /**
     * Similar to the textTrimming func, but keep only one enterline between paragraph
     * @param str 
     * @returns 
     */
    export const textTrimmingBeauty = (str: string): string => {
      if (!str) return '';

      const a: Array<Number> = [1,2,3,4,5,6]

      const arrString: Array<string> = str.split('\n');
      str = arrString
        .map((str: string) => {
          return str.trim().replace(/\s{2,}/g, ' ');
        })
        .filter((str: string, index: number, arr: Array<string>) => {
          if (str) {
            return Boolean(true);
          } else {
            if (index == 0 || !arr[index - 1])
              return false
            return true
          }
        })
        .join('\n');
      return str;
    };
  }

  // [List helper Functions handle]
  // [Utile - Sort - Orther]
  export namespace UtileFunctions {
    /**
     * Sorts an array of objects based on a specified key, handling various data types and special values.
     *
     * @param arrInput The input array of objects to be sorted.
     * @param key The key based on which the objects will be sorted.
     * @param order The order in which the objects should be sorted. Possible values: "ascending" or "descending". Default: "ascending".
     * @param convertTime Indicates whether time values should be converted before sorting. Default: false.
     * @returns A new sorted array of objects based on the specified key and sorting order.
     */
    export const sortArrayExceptNABykey = (
      arrInput: any[],
      key: string,
      order?: string,
      convertTime?: boolean
    ): any[] => {
      return arrInput.sort(function (a, b) {
        var valueA = a[key];
        var valueB = b[key];

        // Move elements with null values to the bottom
        if (valueA === 'N/A' || valueA === null) return order === 'ascending' ? 1 : -1;
        if (valueB === 'N/A' || valueB === null) return order === 'ascending' ? -1 : 1;

        if (typeof valueA === 'number' && typeof valueB === 'number') {
          if (order === 'ascending') {
            return valueB - valueA;
          } else {
            return valueA - valueB;
          }
        }

        if (typeof valueA === 'boolean' && typeof valueB === 'boolean') {
          if (order === 'ascending') {
            return valueA === valueB ? 0 : valueA ? 1 : -1;
          } else {
            return valueA === valueB ? 0 : valueA ? -1 : 1;
          }
        }
        var nameA = valueA?.toString().toUpperCase().trim();
        var nameB = valueB?.toString().toUpperCase().trim();

        if (convertTime) {
          nameA = new Date(nameA).getTime();
          nameB = new Date(nameB).getTime();
        }

        if (order === 'ascending') {
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }
          return 0;
        } else {
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }
      });
    };

    /**
     * Sorts an array of objects based on a specified key in ascending order.
     *
     * @param arrInput - The input array to be sorted.
     * @param key - The key to sort the objects by.
     * @returns The sorted array.
     */
    export const sortArrayBykey = (arrInput: any[], key: string): any[] => {
      return (arrInput = arrInput.sort(function (a, b) {
        const valueA = a[key] || "";
        const valueB = b[key] || "";
    
        if (typeof valueA === 'boolean' && typeof valueB === 'boolean') {
          // Sort boolean values: true comes before false
          return valueA === valueB ? 0 : valueA ? -1 : 1;
        }
    
        const nameA = valueA?.toString().toUpperCase().trim(); // ignore upper and lowercase
        const nameB = valueB?.toString().toUpperCase().trim(); // ignore upper and lowercase
    
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }));
    };

    export const sortByKeyNestObject = (
      arrInput: any[],
      key_1: string,
      key_2: string
    ): any[] => {
      return arrInput.sort(function (a, b) {
        var nameA = a[key_1][key_2].toUpperCase(); // ignore upper and lowercase
        var nameB = b[key_1][key_2].toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    };

    export const sortByKeyNestObjectCaseGroup = (
      arrInput: any[],
      key_1: string,
      key_2: string
    ): any[] => {
      return arrInput.sort(function (a, b) {
        var nameA = a[1][0][key_1][key_2].toUpperCase(); // ignore upper and lowercase
        var nameB = b[1][0][key_1][key_2].toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    };

    export const sortRoleByOrder = (
      orderArray: string[],
      sortArray: any[],
      keyName: string
    ) => {
      const sortedArray = sortArray.sort((a, b) => {
        return orderArray.indexOf(a[keyName]) - orderArray.indexOf(b[keyName]);
      });
      return sortedArray;
    };
    export const sortPrimaryFlag = (objectFlag: any) => {
      let sortable: any[] = [];
      for (var flag in objectFlag) {
        sortable.push({
          key: flag,
          value: objectFlag[flag],
        });
      }

      sortable.sort(function (a: any, b: any) {
        return b.value - a.value;
      });

      let primaryFlag = [0, 0, 0, 0];
      let secondaryFlag: any[] = [];

      sortable.forEach((flag: any) => {
        switch (flag.key) {
          case 'green':
            primaryFlag[0] = flag;
            break;
          case 'yellow':
            primaryFlag[1] = flag;
            break;
          case 'red':
            primaryFlag[2] = flag;
            break;
          case 'blue':
            primaryFlag[3] = flag;
            break;
          default:
            secondaryFlag.push(flag);
            break;
        }
      });
      primaryFlag
        .slice()
        .reverse()
        .forEach((flag: any) => {
          if (flag.value && flag.value !== 0) {
            secondaryFlag.unshift(flag);
          }
        });
      sortable = [...secondaryFlag];
      return sortable;
    };

    export const replaceAllCharacter = (
      originalString: string,
      replaceCharacterFrom: string,
      replaceCharacterTo: string
    ): string => {
      if(!originalString) return '';
      return ('' + originalString).replace(
        new RegExp(replaceCharacterFrom, 'g'),
        replaceCharacterTo
      );
    };

    export const uniqBy = (a: any[], key: any) => {
      return [...new Map(a.map((x) => [key(x), x])).values()];
    };

    // 4 function have catch click button highlight

    // same level element
    export const showOptionMenu = (elementRef: any) => {
      // el: HTMLElement
      elementRef.el.nativeElement.previousSibling.classList.add('active');
    };
    export const hideOptionMenu = (elementRef: any) => {
      elementRef.el.nativeElement.previousSibling.classList.remove('active');
    };

    // // same level element then child
    // export const showOptionMenuTable = (elementRef: any) => {
    //   // el: HTMLElement
    //   elementRef.el.nativeElement.previousSibling.children[0].classList.add(
    //     'active'
    //   );
    // };
    // export const hideOptionMenuTable = (elementRef: any) => {
    //   elementRef.el.nativeElement.previousSibling.children[0].classList.remove(
    //     'active'
    //   );
    // };
    export const filterArrayNullData = (
      arrInput: any[],
      key: string
    ): any[] => {
      return arrInput.filter((item) => {
        return !!item[key];
      });
    };
    export const handleError = (err: any) => {
      if (err?.error === undefined) {
        return throwError(() => new Error(err));
      } else {
        return throwError(() => new Error(err?.error?.message));
      }
    };
    export const arraysMatch = (arrOld: any[], arrNew: any[]): boolean => {
      // Nếu cả hai mảng đờu rỗng, trả vờ true
      if (!arrOld.length && !arrNew.length) {
        return true;
      }

      if (!arrOld.length || !arrNew.length) {
        return false;
      }

      // Nếu chiờu dài của 2 mang không giống nhau, trả vờ false
      if (arrOld.length !== arrNew.length) {
        return false;
      }

      // Kiểm tra từng phần tử của hai mảng
      const match = arrOld.every((element, index) => {
        return element === arrNew[index];
      });

      return match;
    };

    export const isObjectEmpty = (objectName: any) => {
      return Object.keys(objectName).length === 0;
    };

    export const addToArrIfNotExist = (arr: any[], val: any, key: string) => {
      if (!arr.some((obj) => obj[key] === val[key])) {
        arr.push(val);
      }
    };

    export const isImageFile = (file: File): boolean => {
      return file.type.startsWith('image/');
    };

    export const clickDownloadDocumentWithName = (
      blobData: Blob,
      newName?: string
    ): void => {
      const element = document.createElement('a');
      element.href = window.URL.createObjectURL(blobData);
      element.download = newName ? newName : 'newFile';
      document.body.appendChild(element);
      element.click();
      element.parentElement?.removeChild(element);
    };

    export const isRequired = (
      formGroup: FormGroup,
      controlName: string
    ): boolean => {
      return formGroup.get(controlName)!.hasValidator(Validators.required);
    };

    export const isArrayHaveNABykey = (
      arrInput: any[],
      key: string
    ): boolean => {
      for (const item of arrInput) {
        if (
          !item.hasOwnProperty(key) ||
          (Array.isArray(item[key]) && item[key].length === 0)
        ) {
          return true;
        }
      }
      return false;
    };

    export const getDataFromObject = (obj: any, key: string): any => {
      if (key in obj) {
        return obj[key];
      }
      return obj;
    };

    export const isBase64Encoded = (str: string) => {
      // Check if the string length is a multiple of 4
      if (!str || str.length % 4 !== 0) {
        return false;
      }

      // Check if the string contains valid Base64 characters
      var validChars = /^[A-Za-z0-9+/=]+$/;
      if (!validChars.test(str)) {
        return false;
      }

      return true;
    };

    export const decodeIfIsBase64 = (str: string) => {
      return isBase64Encoded(str) ? atob(str) : str;
    };

    export const encodeBase64 = (str: string) => {
      // Encode the string to Base64
      const encoder = new TextEncoder();
      const data: any = encoder.encode(str);
      return btoa(String.fromCharCode.apply(null, data));
    };
    export const encode64Payload = (payload: any, keys: string[]) => {
      for (let key of keys) {
        if (payload.hasOwnProperty(key)) {
          payload[key] = encodeBase64(payload[key]);
        }
      }
    };

    export const encodeFileName = (fileName: string) => {
      return encodeURIComponent(fileName);
    }

    export const decodeFileNameFromAzureUrl = (fileName: any) => {
      return decodeURIComponent(fileName)?.split('/')?.pop() || 'Unknown file!';
    }

    export const getFileNameFromUrl = (url: string): string => {
      return decodeFileNameFromAzureUrl(StringFunctions.getFileName(url));
    }

    export function filterUniqueAndSortByKey<T>(
      arr: T[],
      key: keyof T,
      isSort: boolean = true
    ): T[] {
      const uniqueValuesMap: Map<T[keyof T], T> = new Map();

      for (const item of arr) {
        if (!uniqueValuesMap.has(item[key])) {
          uniqueValuesMap.set(item[key], item);
        }
      }

      const uniqueValues = Array.from(uniqueValuesMap.values());

      if (isSort) {
        uniqueValues.sort((a, b) => {
          const valueA = a[key];
          const valueB = b[key];

          if (valueA < valueB) {
            return -1;
          } else if (valueA > valueB) {
            return 1;
          } else {
            return 0;
          }
        });
      }
      return uniqueValues;
    }

    export function removeDuplicatesFromArray<T>(arr: T[]): T[] {
      const resultArray: T[] = [];
      for (const item of arr) {
        if (!resultArray.includes(item)) {
          resultArray.push(item);
        }
      }
      resultArray.sort((a, b) => {
        return a < b ? -1 : 1;
      });
      return resultArray;
    }

    export const filterAndMultiply = (arr: any[]): any[] => {
      const result: any[] = [];

      const idMap: { [id: string]: any } = {};

      for (const obj of arr) {
        const { isComplete, rig } = obj;
        const { rigId } = rig;

        if (idMap[rigId]) {
          // Check if isComplete is true, then replace the existing element
          if (!isComplete) {
            idMap[rigId] = obj;
          }
        } else {
          // If id not found, add the object to the map
          idMap[rigId] = obj;
        }
      }

      // Push the values of idMap to the result array
      for (const id in idMap) {
        result.push(idMap[id]);
      }
      return result;
    };

    export const filterDataById = (
      data: any[],
      idArray: string[],
      key: string
    ): any[] => {
      return data.filter((item) => idArray.includes(item[key]));
    };

    export const removeEmptyProjectGroup = (data: any[]): any[] => {
      return data.filter((item) => item[1].length > 0);
    };

    export const extractFileNameWithoutIndex = (string: string): string => {
      let array = string.split('_');
      array.shift();
      let output = array.join('_');
      return output ? output : string;
    };

    export const areArraysEqual = (arr1: any[], arr2: any[]): boolean =>{
      // Check if the arrays have the same length
      if (arr1.length !== arr2.length) {
        return false;
      }
    
      // Iterate through the arrays and compare each element
      for (let i = 0; i < arr1.length; i++) {
        if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
          return false;
        }
      }
      // If all checks pass, the arrays are equal
      return true;
    }
    
    export const areObjectsEqual = (obj1: any, obj2:any):boolean => {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
    
      // Check if the number of keys is the same
      if (keys1.length !== keys2.length) {
        return false;
      }
      // Check if all keys in obj1 exist in obj2 and have the same values
      for (const key of keys1) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
      // If all checks pass, the objects are equal
      return true;
    }
    
    export const getTypeAlert = (event: any) => {  
      if (AppHelper.UtileFunctions.isObjectEmpty(event)) {
        return AppHelper.StringFunctions.capitalizeFirstLetter(ALERT_TYPE.INTERVENTION);
      };
      
      if (event.template === ALERT_TYPE.ROADMAP) 
        return AppHelper.StringFunctions.capitalizeFirstLetter(ALERT_TYPE.ROADMAP);
      if (event?.eventType) {
        return AppHelper.StringFunctions.capitalizeFirstLetter(event.eventType);
      } else {
        const isReport = AppConstant.REPORT_EVENT_ID.includes(event.event_id);
        if (isReport) {
          return AppHelper.StringFunctions.capitalizeFirstLetter(ALERT_TYPE.REPORT);
        }
        return AppHelper.StringFunctions.capitalizeFirstLetter(ALERT_TYPE.INTERVENTION);
      }
    }

    export const filterEvidenceByType = (rawData: EvidenceMask[], type: EvidenceProcessType) => {
      const listEvidence: EvidenceMask[] = [];

      if (rawData && rawData?.length <= 0) return listEvidence;
      for (const evidence of rawData) {
        if (type === evidence?.typeProcess) {
          listEvidence.push(evidence);
        }
      }

      return listEvidence;
    }

    /**
     * Function handle convert file to ArrayBuffer type
     * @param file The file want to convert to ArrayBuffer type
     * @returns ArrayBuffer
     */
    export const fileToArrayBuffer = (file: File): Promise<ArrayBuffer> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
  
        // Set up an event listener for when the file is loaded
        reader.onload = (event: any) => {
          resolve(event.target.result); // The result is the ArrayBuffer
        };
  
        // Set up an event listener for when there is an error reading the file
        reader.onerror = (event) => {
          reject(event);
        };
        // Read the file as an ArrayBuffer
        reader.readAsArrayBuffer(file);
      });
    }

    export const clearStyleDOM = (node: HTMLHeadingElement, listNodeName: string[], styleRemove: string[], forceRemove: boolean = false) => {
      try {
        let willRemove = forceRemove; 
        const {style, nodeName} = node;
  
        if (listNodeName.includes(nodeName))
          willRemove = true;
  
        if (willRemove) {
          styleRemove.forEach((property: string) => {
            if (style) {
              (style as any)[property] = "";
            }
          })
        }
  
        if (node?.childNodes?.length > 0) {
          node.childNodes.forEach((child: any) => {
            AppHelper.UtileFunctions.clearStyleDOM(child, listNodeName, styleRemove, willRemove);
          })
        }
      } catch (e: any) {
        console.log("error : ", e );
      }
    }

    export const sortTimezone = (a: string, b: string, order?: number): number => {
      const specialCases = ['null', 'n/a', '', null, undefined];
      
      
      if (order === 1) {
        if (specialCases.includes(a.toLowerCase())) return -1;
        if (specialCases.includes(b.toLowerCase())) return 1;
      return a.localeCompare(b);
      } else {
        if (specialCases.includes(a.toLowerCase())) return 1;
      if (specialCases.includes(b.toLowerCase())) return -1;
      return b.localeCompare(a);
      }
    }
  }

   // [List helper Functions handle]
  // [Image]
  export namespace ImageFunctions {
    /**
     * Resizes a base64 encoded image to the specified width and height.
     *
     * @param {string} base64 - The base64 encoded image string.
     * @param {number} width - The desired width of the resized image.
     * @param {number} height - The desired height of the resized image.
     * @returns {Promise<string>} - A promise that resolves to the resized image as a base64 encoded string.
     */
      export const resizeBase64Image = async (file: File, width: number, height: number, quality: number = 80): Promise<string> => {
        const image = await Jimp.fromBuffer(await file.arrayBuffer());
        image.resize({w:width, h:height})
        const resizedBuffer = await image.getBase64(JimpMime.png, {quality: 80});
        return resizedBuffer
    };
  }
}
