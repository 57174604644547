<div class="d-flex flex-column justify-content-between h-100">
  <div class="sidebar" [ngClass]="{ 'd-none': hasRoute('login') }">
    <div class="sidebar-item flex flex-grow gap-3" *ngFor="let tab of tabs; let i = index"
      (click)="onTab(tab.path); activateClass(tab)" [ngClass]="{ active: tab.isActive }">
      <div>
        <svg-icon [key]="tab.svg" [size]="tab.sizeIcon"></svg-icon>
      </div>
      <div class="sidebar-title" [ngClass]="expandMenu$ ? '' : 'hide-sidebar'">
        {{ tab.title }}
      </div>
    </div>
  </div>
  <div class="sidebar justify-content-end">
    <div class="sidebar-item flex flex-grow gap-3 sub-sidebar sub-active" *ngFor="let tab of subTabs; let i = index"
      (click)="onTab(tab.path); activateClass(tab)">  <!-- always active -->
      <div 
        *ngIf="numberUnreadSupportCases > 0 && tab.title == 'Live Support'; else notBadge"
        pBadge
        severity="danger"
        [value]="numberUnreadSupportCases > 99 ? '+99' : numberUnreadSupportCases.toString()"
      >
        <svg-icon [key]="tab.svg" [size]="tab.sizeIcon"></svg-icon>
      </div>
      <ng-template #notBadge>
        <div>
          <svg-icon [key]="tab.svg" [size]="tab.sizeIcon"></svg-icon>
        </div>
      </ng-template>
      <div class="sidebar-title" [ngClass]="expandMenu$ ? '' : 'hide-sidebar sub-hide-sidebar'">
        {{ tab.title }}
      </div>
    </div>
  </div>
</div>