import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-module-header',
  templateUrl: './module-header.component.html',
  styleUrls: ['./module-header.component.scss'],
})
export class ModuleHeaderComponent extends BaseComponent {
  @Input()
  backPortal: boolean = false;

  @Input()
  backPortalLabel: string = 'Back to previous page';

  @Input()
  headerTitle: string = '';

  @Input()
  buttonFearture: boolean = false;

  @Input()
  buttonFeartureLabel: string = 'Add Object';

  @Output() onClickBack: EventEmitter<string> = new EventEmitter();

  @Output() onClickButton: EventEmitter<string> = new EventEmitter();

  @Input()
  bottomMargin: boolean = true;

  constructor() {
    super();
  }

  onInit(): void {}

  backFearture() {
    this.onClickBack.emit('Back handle');
  }

  openPopup() {
    this.onClickButton.emit('Popup handle');
  }
  onDestroy(): void {}
}
