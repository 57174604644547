import { NgModule } from '@angular/core';
import { TimeUploadPipe } from './time-upload.pipe';
import { TimeDecimalToHHMMPipe } from './time-decimalToHHMM.pipe';
import { TimeMilisecondToMMSSPipe } from './time-milisecondToMMSS.pipe';

@NgModule({
  declarations: [
    TimeUploadPipe,
    TimeDecimalToHHMMPipe,
    TimeMilisecondToMMSSPipe,
  ],
  exports: [TimeUploadPipe, TimeDecimalToHHMMPipe, TimeMilisecondToMMSSPipe],
})
export class TimePipeModule {}
