import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, delay, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class TutorialService extends BaseService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  private currentPathSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private userTutorialSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  //
  public currentPathSubject$ = this.currentPathSubject.asObservable();
  public userTutorialSubject$ = this.userTutorialSubject.asObservable();

  public getDetailTutorial(id: string): Observable<any> {
    const uri = `topic/${id}`;
    return this.get<any[]>(uri);
  }

  setCurrentPathSubject(user: any): void {
    this.currentPathSubject.next(user);
  }

  getCurrentPathSubject(): any {
    return this.currentPathSubject.getValue();
  }

  setUserTutorialSubject(tutorialList: any[]): void {
    this.userTutorialSubject.next(tutorialList);
  }

  getUserTutorialSubject(): any {
    return this.userTutorialSubject.getValue();
  }

  //  ============= Tutorial =============
  getTutorials(type: string, level: number): Observable<any> {
    switch (level) {
      case 1:
        return this.get<any[]>(`category/?query=${type}`).pipe();
      case 2:
        const id = type;
        return this.get<any[]>(`category/${id}`).pipe();
      default:
        return of([null]);
    }
  }

  getTutorial(type: string): Observable<any[]> {
    const uri = `category/?query=${type}`;
    return this.get<any[]>(uri).pipe();
  }

  getTopic(id: string): Observable<any[]> {
    const uri = `topic/${id}`;
    return this.get<any[]>(uri).pipe();
  }

  createTopic(option: any) {
    const uri = `topic/create`;
    return this.post<any, any>(uri, option).pipe();
  }

  updateTopic(id: string, option: any): Observable<any[]> {
    const uri = `topic/${id}`;
    return this.put<any, any>(uri, option).pipe();
  }

  deleteTopic(id: string) {
    const uri = `topic/${id}`;
    return this.delete<any[]>(uri).pipe();
  }
}