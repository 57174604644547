<div class="chat-container">
  <div class="messages-panel d-flex flex-column-reverse" [ngClass]="{ 'confirm-mode': isShowConfirm }" #infiniteScroll (scroll)="scrollHandler($event)">
    
    <!-- TYPING -->
    <div class="message-container d-flex flex-row gap-1" *ngIf="isAnotherTyping">
      <div class="avatar">
        <app-avatar [avatar]="anotherTypingData.avatar" [displayName]="anotherTypingData.displayName" size="32px"></app-avatar>
      </div>
    
      <div class="d-flex flex-column gap-1">    
        <!-- content -->
        <div class="message-bubble">
          <div class="dot-container">
            {{ anotherTypingData.displayName }} is typing<span class="dot"></span>
          </div>
        </div>
      </div>
    </div>

    <!-- MESSAGE -->

    <app-chat-message *ngFor="let message of listMessages; let i = index"
      [previousMessage]="listMessages[i + 1] || null"
      [message]="message"
      [isMine]="message.senderType === 'user'"
      [template]="message.id == -1 ? 'LOADING' : 'CONTENT'"
      (primaryButtonClick)="handleMainButtonClick()"
      (secondaryButtonClick)="handleSubButtonClick()"
    ></app-chat-message>
    
    <div class="content-progress-bar-notification" *ngIf="(isLoadingMessage$ | async) || false">
      <span class="loader"></span>
    </div>
    <p-button
        *ngIf="showHasNewMessage && isScrollingUp"
        [attr.data-cy]="'new-messages-button'"
        label="New messages"
        class="custom-p-button custom-p-button--secondary btn-new-messages"      
        (onClick)="onClickNewMessages()"
      ></p-button>    
  </div>
  <div class="input-panel d-flex justify-content-end align-items-end pb-2 pt-2 gap-2">
    <div class="inbox-upload">
      <div class="icon cursor-pointer" (click)="selectFile()">
        <i class="pi pi-paperclip"></i>
      </div>
      <p-fileUpload
        #fileUploader
        accept=".pdf,.jpg, .jpeg, .png"
        [showUploadButton]="false"
        [showCancelButton]="false"
        [multiple]="true"
        [customUpload]="true"
        [style]="{display: 'none'}"
        (onSelect)="onSelect($event)"
      ></p-fileUpload>
    </div>
    <textarea
      pInputTextarea
      placeholder="Message..."
      class="inbox-content custom-p-input"
      [rows]="2"
      TextInputValidate
      [autoResize]="true"
      [(ngModel)]="messageInput"
      (focus)="onInputFocus()" 
      (keypress)="onKeyPress($event)"
      (keyup)="onChange($event)"
    ></textarea>
    <div style="padding: 0px; margin-bottom: 4px;">
      <p-button
        [attr.data-cy]="'send-button'"
        label="Send"
        class="custom-p-button custom-p-button--secondary small-button"
        [ngClass]="{'btn-disable' : messageInput.length <= 0 || messageInput.trim().length <=0}"
        [disabled]="messageInput.length <= 0 || messageInput.trim().length <= 0"
        (onClick)="onSend()"
      ></p-button>
    </div>
    <!-- <div *ngIf="showError" class="error-badge">Please type a message to continue</div> -->
  </div>
</div>