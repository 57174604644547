import { ErrorHandler, Injectable } from '@angular/core';
import { ApplicationInsightsService } from './telemetry.service';
import { IExceptionTelemetry } from '@microsoft/applicationinsights-web';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private applicationInsightsService: ApplicationInsightsService) {}

  handleError(error: any): void {
    console.error(error);
    const exception: IExceptionTelemetry = {
      exception: error
    };
    this.applicationInsightsService.trackException(exception);
  }
}
