<div class="chat-message-confirm">
    <div class="chat-message-confirm-title">{{ message.content?.text }}</div>
    <div *ngFor="let button of message.content?.buttons; let i = index" 
         class="step-button"
         [ngClass]="{
             'step-button--primary': message.content?.buttons.length === 1 || (message.content?.buttons.length > 0 && i % 2 !== 0),
             'step-button--secondary': !(message.content?.buttons.length === 1 || (message.content?.buttons.length > 0 && i % 2 !== 0)),
             'step-button--disable': isInteractionDisabled
         }" 
         (click)="onButtonClick(i)">
        {{ button.label }}
    </div>
</div>
