<!-- [formGroup]="formGroupExport" -->
<div class="notification-setting-form">
    <div class="d-flex flex-column gap-3">
      <div class="notification-setting-header">Receive an email when a colleague:</div>

      <div class="d-flex flex-column gap-2">
        <div class="d-flex justify-content-between" *ngFor="let setting of settings; index as i">
          <div class="notification-setting-label">{{ setting.name }}</div>
          <app-single-switch
            [classList]="'class-name'"
            [statusValue]="setting.value"
            (onSwitch)="onToggle(!setting.value, i)"
          ></app-single-switch>
        </div>
      </div>

      <div>
        <app-customizable-button
          [showButton]="true"
          [label]="'Reset Preferences'"
          [style]="'dark'"
          [isDisabled]="false"
          (eventButton)="onReset()"
        ></app-customizable-button>
      </div>
    </div>
</div>

<div class="d-flex justify-content-end mt-4 gap-2">
    <p-button
      [attr.data-cy]="'cancel-button'"
      type="button"
      label="Cancel"
      class="custom-p-button custom-p-button--secondary"
      (onClick)="onCancel()"
    ></p-button>
    <p-button
      [attr.data-cy]="'save-button'"
      type="button"
      label="Save"
      class="custom-p-button custom-p-button--primary"
      (onClick)="onSave()"
      [ngClass]="{ 'custom-p-button--disable' : !isChange || isViewer}"
      [disabled]="!isChange || isViewer"
    ></p-button>
</div>

<!-- Loading -->
<app-loading [isLoading]="(isLoading$ | async)!"></app-loading>