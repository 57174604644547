<p-dialog
  [(visible)]="displayEditProject"
  [modal]="true"
  [style]="{ width: '480px', 'min-height': '720px' }"
  [draggable]="false"
  [resizable]="false"
  [closeOnEscape]="false"
  maskStyleClass="maskStyleDialog"
  styleClass="style-class-dialog"
  [closable]="false"
>
  <ng-template pTemplate="header">
    <div>Edit Project: {{ project.projectName }}</div>
    <div class="custom-close-icon" (click)="closeDialog()">
      <i class="pi pi-times"></i>
    </div>
  </ng-template>

  <ng-template pTemplate="content">
    <div
      class="container editForm popup-project"
      *ngIf="!!formEditProjectGroup"
      [formGroup]="formEditProjectGroup"
    >
      <div class="row">
        <div class="col-12 mb-2">
          <div class="add-input-title">
            Project<span class="p-error"> *</span>
          </div>
          <div>
            <div class="add-input p-inputgroup p-input-icon-right">
              <input
                type="text"
                pInputText
                placeholder="Enter project"
                [formControlName]="fieldNameKeys.projectName"
                [innerMsg]="messageErrors"
                TextInputValidate
                typeCheck="short"
                class="custom-p-input"
              />
              <i
                class="icon pi pi-ellipsis-v cursor-pointer"
                (click)="menuItems_1.toggle($event)"
              ></i>
              <p-menu
                #menuItems_1
                [popup]="true"
                [model]="menuItems"
                (onShow)="onMenuShow(fieldNameKeys.projectName, project)"
                class="menu-table-item"
              ></p-menu>
            </div>
          </div>
        </div>

        <div class="col-12 mb-2">
          <div class="add-input-title">
            Operator<span class="p-error"> *</span>
          </div>
          <div>
            <div class="add-input p-inputgroup p-input-icon-right">
              <input
                type="text"
                pInputText
                placeholder="Enter project"
                [formControlName]="fieldNameKeys.operator"
                [innerMsg]="messageErrors"
                TextInputValidate
                typeCheck="short"
                class="custom-p-input"
              />
              <i
                class="icon pi pi-ellipsis-v cursor-pointer"
                (click)="menuItems_2.toggle($event)"
              ></i>
              <p-menu
                #menuItems_2
                [popup]="true"
                [model]="menuItems"
                (onShow)="onMenuShow(fieldNameKeys.operator, project)"
                class="menu-table-item"
                class="custom-p-input"
              ></p-menu>
            </div>
          </div>
        </div>

        <div class="col-12 mb-2">
          <div class="add-input-title">
            Region<span class="p-error"> *</span>
          </div>
          <div class="w-100 add-input p-inputgroup p-input-icon-right">
            <p-dropdown
              [options]="regions"
              optionLabel="name"
              [filter]="true"
              [showClear]="true"
              placeholder="Select region"
              class="custom-p-drop"
              scrollHeight="160px"
              [formControlName]="fieldNameKeys.region"
              [innerMsg]="messageErrors"
              (onChange)="bindingCountry($event)"
              (onClear)="selectedOptionClear(METADATA.REGION.LABEL)"
              [resetFilterOnHide]="true"
            >
            </p-dropdown>
            <i
              class="icon pi pi-ellipsis-v cursor-pointer"
              (click)="menuItems_3.toggle($event)"
            ></i>
            <p-menu
              #menuItems_3
              [popup]="true"
              [model]="menuItems"
              (onShow)="onMenuShow(fieldNameKeys.region, project)"
              class="menu-table-item"
            ></p-menu>
          </div>
        </div>

        <div class="col-12 mb-2">
          <div class="add-input-title">
            Country<span class="p-error"> *</span>
          </div>
          <div class="w-100 add-input p-inputgroup p-input-icon-right">
            <p-dropdown
              [options]="countries"
              optionLabel="name"
              [filter]="true"
              [showClear]="true"
              placeholder="Select country"
              class="custom-p-drop"
              scrollHeight="160px"
              [formControlName]="fieldNameKeys.country"
              [innerMsg]="messageErrors"
              (onChange)="bindingRegion($event)"
              [resetFilterOnHide]="true"
            >
            </p-dropdown>
            <i
              class="icon pi pi-ellipsis-v cursor-pointer"
              (click)="menuItems_4.toggle($event)"
            ></i>
            <p-menu
              #menuItems_4
              [popup]="true"
              [model]="menuItems"
              (onShow)="onMenuShow(fieldNameKeys.country, project)"
              class="menu-table-item"
            ></p-menu>
          </div>
        </div>

        <div class="col-12 mb-2">
          <div class="add-input-title">
            Block<span class="p-error"> *</span>
          </div>
          <div>
            <div class="add-input p-inputgroup p-input-icon-right">
              <input
                type="text"
                pInputText
                placeholder="Enter block"
                [formControlName]="fieldNameKeys.block"
                [innerMsg]="messageErrors"
                TextInputValidate
                typeCheck="short"
                class="custom-p-input"
              />
              <i
                class="icon pi pi-ellipsis-v cursor-pointer"
                (click)="menuItems_5.toggle($event)"
              ></i>
              <p-menu
                #menuItems_5
                [popup]="true"
                [model]="menuItems"
                (onShow)="onMenuShow(fieldNameKeys.block, project)"
                class="menu-table-item"
              ></p-menu>
            </div>
          </div>
        </div>

        <div class="col-12 mb-2">
          <div class="add-input-title">
            Lease<span class="p-error"> *</span>
          </div>
          <div>
            <div class="add-input p-inputgroup p-input-icon-right">
              <input
                type="text"
                pInputText
                placeholder="Enter lease"
                [formControlName]="fieldNameKeys.lease"
                [innerMsg]="messageErrors"
                TextInputValidate
                typeCheck="short"
                class="custom-p-input"
              />
              <i
                class="icon pi pi-ellipsis-v cursor-pointer"
                (click)="menuItems_6.toggle($event)"
              ></i>
              <p-menu
                #menuItems_6
                [popup]="true"
                [model]="menuItems"
                (onShow)="onMenuShow(fieldNameKeys.lease, project)"
                class="menu-table-item"
              ></p-menu>
            </div>
          </div>
        </div>

        <div class="col-12 mb-2">
          <div class="add-input-title">
            Field<span class="p-error"> *</span>
          </div>
          <div>
            <div class="add-input p-inputgroup p-input-icon-right">
              <input
                type="text"
                pInputText
                placeholder="Enter field"
                [formControlName]="fieldNameKeys.field"
                [innerMsg]="messageErrors"
                TextInputValidate
                typeCheck="short"
                class="custom-p-input"
              />
              <i
                class="icon pi pi-ellipsis-v cursor-pointer"
                (click)="menuItems_7.toggle($event)"
              ></i>
              <p-menu
                #menuItems_7
                [popup]="true"
                [model]="menuItems"
                (onShow)="onMenuShow(fieldNameKeys.field, project)"
                class="menu-table-item"
              ></p-menu>
            </div>
          </div>
        </div>

        <div class="col-12 mb-2">
          <div class="add-input-title">
            Time Zone<span class="p-error"> *</span>
          </div>
          <div class="w-100 add-input p-inputgroup p-input-icon-right">
            <p-dropdown
              [options]="timezones"
              optionLabel="name"
              [filter]="true"
              [showClear]="true"
              placeholder="Select time zone"
              class="custom-p-drop"
              scrollHeight="160px"
              [formControlName]="fieldNameKeys.timezone"
              [innerMsg]="messageErrors"
              [resetFilterOnHide]="true"
            >
            </p-dropdown>
            <i
              class="icon pi pi-ellipsis-v cursor-pointer"
              (click)="menuItems_8.toggle($event)"
            ></i>
            <p-menu
              #menuItems_8
              [popup]="true"
              [model]="menuItems"
              (onShow)="onMenuShow(fieldNameKeys.timezone, project)"
              class="menu-table-item"
            ></p-menu>
          </div>
        </div>

        <div class="col-12 mb-2">
          <div class="add-input-title">
            Units<span class="p-error"> *</span>
          </div>
          <div class="w-100 add-input p-inputgroup p-input-icon-right">
            <p-dropdown
              [options]="units"
              optionLabel="name"
              [filter]="true"
              [showClear]="true"
              placeholder="Select units"
              class="custom-p-drop"
              scrollHeight="160px"
              [formControlName]="fieldNameKeys.unit"
              [innerMsg]="messageErrors"
              [resetFilterOnHide]="true"
            >
            </p-dropdown>
            <i
              class="icon pi pi-ellipsis-v cursor-pointer"
              (click)="menuItems_9.toggle($event)"
            ></i>
            <p-menu
              #menuItems_9
              [popup]="true"
              [model]="menuItems"
              (onShow)="onMenuShow(fieldNameKeys.unit, project)"
              class="menu-table-item"
            ></p-menu>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div *ngIf="!!formEditProjectGroup" class="d-flex justify-content-end gap-2">
      <p-button
        [attr.data-cy]="'cancel-button'"
        label="Cancel"
        (onClick)="closeDialog()"
        class="custom-p-button custom-p-button--secondary"
      ></p-button>
      <p-button
        [attr.data-cy]="'save-button'"
        label="Save"
        (onClick)="submitForm($event)"
        class="custom-p-button custom-p-button--primary"
        [disabled]="formEditProjectGroup.status === 'INVALID' || isViewer"
        [ngClass]=" formEditProjectGroup.status === 'INVALID' || isViewer ? 'custom-button-disable' : ''"
      ></p-button>
    </div>
  </ng-template>

  <!-- Loading -->
  <app-loading [isLoading]="isLoading"></app-loading>
</p-dialog>
