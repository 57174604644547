import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { FileUpload } from 'primeng/fileupload';
import { finalize, Subject, takeUntil, tap } from 'rxjs';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { ChatWindowService } from 'src/app/shared/services/chat-window.service';
import { ChatReportService } from 'src/app/shared/services/chat-report.service';
import { IMessage } from 'src/app/shared/interface/common';

@Component({
  selector: 'app-chat-report',
  templateUrl: './chat-report.component.html',
  styleUrls: ['./chat-report.component.scss'],
})
export class ChatReportComponent implements OnInit {
  
  @ViewChild('supportCaseAttachments') supportCaseAttachments!: FileUpload;
  priorities = [
    { priorityId: 0, priorityName: "Low" },
    { priorityId: 1, priorityName: "Medium" },
    { priorityId: 2, priorityName: "High" },
  ];
  uploadedFiles: File[] = [];
  formGroupReportIssue: FormGroup = this.fb.group({
    subject: ['', [Validators.required, Validators.maxLength(50)]],
    description: ['', [Validators.required, Validators.maxLength(2000)]],
    supportCaseAttachments: [this.uploadedFiles],
    priority: [this.priorities[0]]
  });
  isOnDrag: boolean = false;
  isViewer: boolean = false;
  destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private userInfoService: UserInfoService,
    private chatWindowService: ChatWindowService,
    private chatReportService: ChatReportService,
  ) { }

  ngOnInit(): void {
    this.preventWhiteSpace('subject');
    this.preventWhiteSpace('description');
    this.userInfoService.userSubject.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (user) => {
        if (user) {
          this.isViewer = user.role === AppConstant.ROLES.VIEWER.label;
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private buildPayload(): FormData {
    const payload = new FormData();
    payload.append('subject', AppHelper.UtileFunctions.encodeBase64(this.formGroupReportIssue.value.subject));
    payload.append('description', AppHelper.UtileFunctions.encodeBase64(this.formGroupReportIssue.value.description));
    if (this.formGroupReportIssue.value.supportCaseAttachments)
      this.formGroupReportIssue.value.supportCaseAttachments.forEach((file: File) => {
        const fileName =  AppHelper.UtileFunctions.encodeFileName(file.name)
        payload.append('supportCaseAttachments', file, fileName);
      });
    payload.append('priority', this.formGroupReportIssue.value.priority.priorityId);
    return payload;
  }

  public onCancel(): void {
    this.chatWindowService.setCurrentStep();
  }

  public onSend(): void {
    this.chatWindowService.setLoading(true)
    this.chatReportService.sendReportIssue(this.buildPayload()).pipe(
      finalize(() => this.chatWindowService.setLoading(false))
    ).subscribe({
      next: (res) => {
        if (res)
          this.notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.SUCCESS,
            header: 'Information',
            content: 'Report issue was sent successfully.',
          });
          this.chatWindowService.setCurrentStep('SENT');
      },
      error: (error) => {
        console.log(error);
        this.notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.ERROR,
          header: 'Information',
          content: error,
        });
      },
    }) 
  }

  public onUploaderSelect(uploader: string, event: any): void {
    const filesList: Array<File> = [...event.files];
    const MAXIMIN_TOTAL_FILES_SIZE = 10485760; // 10MB = 10485760bytes
    const MAXIMUN_NUMBER_FILE_UPLOAD = 5;
    const listErr: IMessage[] = [];

    // Calculate total file size is uploaded
    let currentTotalFileSize =
      this.uploadedFiles?.reduce(
        (acc: number, file: File) => acc + file.size,
        0
      ) || 0;

    for (let i = 0; i < filesList.length; i++) {
      let file = filesList[i];

      // Verify duplicate
      const isDuplicated = this.uploadedFiles?.some((f: File) => {
        return f.name === file.name && f.size == file.size;
      });
      if (isDuplicated) {
        listErr.push({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Duplicated file',
          content: 'Your uploading file is duplicated.',
        });
        // Remove file dublicate from list, decrease i, continue process.
        filesList.splice(i--, 1);
        continue;
      }

      // Verify file type
      const mimeTypeAllow = [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'application/pdf',
      ];
      if (!mimeTypeAllow.includes(file.type.toLowerCase())) {
        listErr.push({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: `Invalid File Type`,
          content:
            'Invalid file type. Allowed file types: .pdf,.jpg, .jpeg, .png',
        });
        // Remove Invalid file type from list, decrease i, continue process.
        filesList.splice(i--, 1);
        continue;
      }

      // Verify file name
      let formatSpecialChar = /[!()\[\]{}\\|<>\/?'"]+/;
      if (
        formatSpecialChar.test(
          AppHelper.StringFunctions.extractFileName(file.name)
        )
      ) {
        listErr.push({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Detected: Invalid file name',
          content:
            "A file name can't contain any of the following characters: ! ' \" { } [ ] ( ) \\ / : ? < > | ",
        });
        filesList.splice(i--, 1);
        continue;
      }

      // Verify file size 0 byte
      if (file.size  == 0) {
        listErr.push({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Invalid file size',
          content: 'Empty file cannot be uploaded.',
        });
        // Remove Invalid file size from list, decrease i, continue process.
        filesList.splice(i--, 1);
        continue;
      }

      // Verify file size 10000000
      if (file.size > MAXIMIN_TOTAL_FILES_SIZE!) {
        listErr.push({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Invalid file size',
          content: 'Allowed maximum file size: 10MB',
        });
        // Remove Invalid file size from list, decrease i, continue process.
        filesList.splice(i--, 1);
        continue;
      }

      if (currentTotalFileSize + file.size > MAXIMIN_TOTAL_FILES_SIZE) {
        listErr.push({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Invalid total file size',
          content:
            'File make exceeded total allowed file size. Should be removed',
        });

        filesList.splice(i--, 1);
        continue;
      } else {
        currentTotalFileSize += file.size;
      }
    }

    // Verify file number
    if (
      this.uploadedFiles?.length + filesList?.length >
      MAXIMUN_NUMBER_FILE_UPLOAD
    ) {
      const numberFilesCanAdd =
        MAXIMUN_NUMBER_FILE_UPLOAD - this.uploadedFiles?.length;
      const numberFileRemoved = filesList.length - numberFilesCanAdd;

      listErr.push({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: `Exceeded allowed number of files`,
        content: `Maximum ${MAXIMUN_NUMBER_FILE_UPLOAD} files are allowed`,
      });
      filesList.splice(numberFilesCanAdd, numberFileRemoved);
    }

    // Remove notification duplicate
    for (let i = 0; i < listErr.length; i++) {
      this.notificationService.setMessage(listErr[i]);
    }

    this.uploadedFiles = [...this.uploadedFiles, ...filesList];
    this.formGroupReportIssue.get('supportCaseAttachments')?.setValue(this.uploadedFiles);
  }

  public onUploaderClear(uploader: string): void {
    this.uploadedFiles = [];
    this.formGroupReportIssue.get(uploader)?.setValue(null);
    this.formGroupReportIssue.get(uploader)?.markAsUntouched();
  }

  private preventWhiteSpace(formControl: string): void {
    this.formGroupReportIssue
      .get(formControl)
      ?.valueChanges.subscribe((value) => {
        if (value === ' ')
          this.formGroupReportIssue.get(formControl)?.patchValue('');
          this.formGroupReportIssue.get(formControl)?.markAsPristine();
          this.formGroupReportIssue.get(formControl)?.markAsUntouched();
      });
  }

  public openSelectFileUpload(): void {
    this.supportCaseAttachments.choose();
  }

  public removeItemUpload(index: number): void {
    this.supportCaseAttachments._files.splice(index, 1);
    this.uploadedFiles.splice(index, 1);
    this.formGroupReportIssue.get('supportCaseAttachments')?.setValue(this.uploadedFiles);
  }

  public dragTime(event: any): void {
    this.isOnDrag = true;
    this.uploadedFiles = [];
    this.supportCaseAttachments._files.forEach((file) => this.uploadedFiles.push(file));
    this.formGroupReportIssue.get('supportCaseAttachments')?.setValue(this.uploadedFiles);
  }
}
